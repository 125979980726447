import { AtlasImageLoadingInfo } from './AtlasImageLoadingInfo';
import { PrimitiveSet } from '../structure/PrimitiveSet';
import { TimeoutTimer } from '../../utils/TimeoutTimer';
import { PIXIAtlasHelper } from '../PIXIAtlasHelper';
import { clog } from '../../utils/logs';
// @ts-ignore
import each from 'lodash/each';
var TIME_OUT_DELAY = 1000;
var AtlasImageLoader = /** @class */ (function () {
    function AtlasImageLoader(_onLoadCallback) {
        this._onLoadCallback = _onLoadCallback;
        this._path_info_map = {};
        this._timer = new TimeoutTimer();
    }
    /**
     * model 의 이미지를 로드 후, rect.scaleFactor가 1이 아닐경우 rect 만큼 리사이즈한 canvas 를 소스로 설정하긔
     * @param model
     * @param imgRect
     * @param subCallback
     */
    AtlasImageLoader.prototype.load = function (model, imgRect) {
        var path = PIXIAtlasHelper.getRawPath(model);
        var info = this._path_info_map[path];
        if (info) {
            return;
        }
        info = new AtlasImageLoadingInfo(model, imgRect, this._onLoadCallback);
        this._path_info_map[path] = info;
        info.load();
    };
    AtlasImageLoader.prototype.getImageInfo = function (rawPath) {
        return this._path_info_map[rawPath];
    };
    /**
     * 엔트리오브젝트에서 사용중인 이미지 리스트와 싱크를 맞춰 사용하지 않는 이미지 정보를 삭제함.
     */
    AtlasImageLoader.prototype._syncWithEntryObjects = function () {
        var _this = this;
        this._syncRequested = false;
        var arrObj = Entry.container.getAllObjects();
        var allPathSet = new PrimitiveSet();
        var LEN = arrObj.length;
        var LEN2;
        var pics;
        var pic;
        for (var i = 0; i < LEN; i++) {
            pics = arrObj[i].pictures;
            if (!pics || !(LEN2 = pics.length)) {
                continue;
            }
            for (var j = 0; j < LEN2; j++) {
                pic = pics[j];
                allPathSet.put(pic.filename || pic.fileurl);
            }
        }
        var deleteCnt = 0;
        each(this._path_info_map, function (info, path) {
            if (allPathSet.hasValue(path)) {
                return;
            }
            info.destroy();
            deleteCnt++;
            delete _this._path_info_map[path];
        });
        if (deleteCnt > 0) {
            clog("".concat(deleteCnt, " image item(s) deleted"));
        }
    };
    /**
     * 로드/케시된 모든 정보를 지움.
     */
    AtlasImageLoader.prototype.empty = function () {
        this._timer.reset();
        this._syncRequested = false;
        each(this._path_info_map, function (info, path) {
            info.destroy();
        });
        this._path_info_map = {};
    };
    AtlasImageLoader.prototype.requestSync = function () {
        var _this = this;
        if (this._syncRequested) {
            return;
        }
        this._syncRequested = true;
        this._timer.timeout(TIME_OUT_DELAY, function () {
            _this._syncWithEntryObjects();
        });
    };
    return AtlasImageLoader;
}());
export { AtlasImageLoader };
