// @ts-ignore
import each from 'lodash/each';
var PrimitiveSet = /** @class */ (function () {
    function PrimitiveSet() {
        this._map = {};
    }
    PrimitiveSet.prototype.hasValue = function (value) {
        return this._map[value];
    };
    PrimitiveSet.prototype.put = function (value) {
        this._map[value] = true;
    };
    PrimitiveSet.prototype.remove = function (value) {
        delete this._map[value];
    };
    PrimitiveSet.prototype.each = function (callback) {
        each(this._map, function (v, key) {
            callback(key);
        });
    };
    return PrimitiveSet;
}());
export { PrimitiveSet };
