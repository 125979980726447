Entry.skeleton.basic_string_field = {
    path: function (blockView) {
        var width = blockView.contentWidth < 10 ? blockView.contentWidth : blockView.contentWidth + 2;
        var height = blockView.contentHeight;
        height = Math.max(20, height);
        width = Math.max(0, width - height + 12);
        var halfHeight = height / 2;
        return "m ".concat(halfHeight, " 0\n                h ").concat(width, "\n                a ").concat(halfHeight, " ").concat(halfHeight, " 0 1 1 0 ").concat(height, "\n                H ").concat(halfHeight, "\n                a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 -").concat(height, "\n                z");
    },
    color: '#FFF',
    outerLine: '#FF9C00',
    fontSize: 10,
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 5;
        var height = blockView ? blockView.contentHeight : 0;
        return {
            offsetX: -8,
            offsetY: 0,
            width: width + 15,
            height: Math.max(height, 20),
            marginBottom: 0,
        };
    },
    magnets: function () {
        return {
            string: {},
        };
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight, 20);
        return { x: 7, y: height / 2 };
    },
};
