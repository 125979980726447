"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pixiGetChildAt = pixiGetChildAt;
var _pixi = require("pixi.js");
/**
 * PIXI.Container.getChildAt 은 잘못된 index를 넣으면 throw 함. createjs 는 안함.
 * 동일한 스펙을 맞추기 위해 새로 정의함.
 */

function pixiGetChildAt() {
  var p = _pixi.Container.prototype;
  p.getChildAt = function (index) {
    return this.children[index];
  };
  p.getChildIndex = function (child) {
    return this.children.indexOf(child);
  };
  p.___addChildAt = p.addChildAt;
  p.addChildAt = function (child, index) {
    try {
      return this.___addChildAt(child, index);
    } catch (e) {
      return child;
    }
  };
}