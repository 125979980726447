import intersection from 'lodash/intersection';
var ModelClass = /** @class */ (function () {
    function ModelClass(schema, isSeal) {
        this.observers = []; // new Entry.Observer
        this.schema = schema;
        this.generateSchema();
        if (isSeal === undefined || isSeal) {
            Object.seal(this);
        }
    }
    ModelClass.prototype.generateSchema = function () {
        var _this = this;
        var schema = this.schema;
        if (schema === undefined) {
            return;
        }
        try {
            schema = JSON.parse(JSON.stringify(schema));
        }
        catch (e) {
            console.log(schema);
            console.error(e);
        }
        this.data = {};
        Object.keys(schema).forEach(function (key) {
            // @ts-ignore
            _this.data[key] = schema[key];
            Object.defineProperty(_this, key, {
                get: function () {
                    return this.data[key];
                },
            });
        });
    };
    ModelClass.prototype.set = function (data, isSilent) {
        var oldValue = {};
        var keys = Object.keys(data);
        for (var key in this.data) {
            if (data[key] !== undefined) {
                if (data[key] === this.data[key]) {
                    keys.splice(keys.indexOf(key), 1);
                }
                else {
                    oldValue[key] = this.data[key];
                    // @ts-ignore
                    if (data[key] instanceof Array) {
                        this.data[key] = data[key].concat();
                    }
                    else {
                        this.data[key] = data[key];
                    }
                }
            }
        }
        !isSilent && this.notify(keys, oldValue);
    };
    ModelClass.prototype.observe = function (object, funcName, attrs, isNotify) {
        // @ts-ignore
        var observer = new Entry.Observer(this.observers, object, funcName, attrs);
        if (isNotify !== false) {
            object[funcName]([]);
        }
        return observer;
    };
    ModelClass.prototype.unobserve = function (observer) {
        observer.destroy();
    };
    ModelClass.prototype.notify = function (key, oldValue) {
        var _this = this;
        var keys = typeof key === 'string' ? [key] : key;
        var observers = this.observers;
        if (!observers.length) {
            return;
        }
        observers.forEach(function (observeData) {
            var attrs = keys;
            if (observeData.attrs !== undefined) {
                attrs = intersection(observeData.attrs, keys);
            }
            if (!attrs.length) {
                return;
            }
            observeData.object[observeData.funcName](attrs.forEach(function (key) { return ({
                name: key,
                object: _this,
                oldValue: oldValue[key],
            }); }));
        });
    };
    ModelClass.prototype._toJSON = function () {
        var json = {};
        for (var key in this.data) {
            json[key] = this.data[key];
        }
        return json;
    };
    return ModelClass;
}());
export default ModelClass;
