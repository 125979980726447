var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import fetch from 'isomorphic-fetch';
import cryptojs from 'crypto-js';
var EntryModuleLoader = /** @class */ (function () {
    function EntryModuleLoader() {
        this.moduleList = [];
        this.moduleListLite = [];
    }
    /**
     * @deprecated
     * 해당 url 을 동적으로 로드한다.
     * 해당 함수는 굉장히 위험하므로 추가적인 방어로직이 필요하다.
     * key는 로컬에서 파일을 암호화 하여 entry-hw 에서 전달, 해당 파일을 로컬에 있는 키로 1차 검증, 서버로 2차 검증을 통한 무결성/보안 확보
     * 오프라인의 경우, 오픈소스임으로, 로컬상태에서의 비정상적인 사용에 대한 제약이 힘든 부분이 있음. 다만, 온라인이 되는 경우 서버 검증을 사용 할 수 있음
     */
    // bl.loadModule(moduleName: string) bl.loadBlock(blockName, block)...
    EntryModuleLoader.prototype.loadModule = function (moduleInfo) {
        return __awaiter(this, void 0, Promise, function () {
            var blockFile, key, sha1Result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!moduleInfo.file || !moduleInfo.name) {
                            return [2 /*return*/];
                        }
                        blockFile = moduleInfo.file;
                        if (Entry.offlineModulePath) {
                            if (window.sendSync) {
                                blockFile = window.sendSync('decryptBlock', blockFile);
                            }
                        }
                        key = cryptojs.SHA1(blockFile);
                        if (!window.navigator.onLine) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch("".concat(Entry.moduleBaseUrl, "key/").concat(moduleInfo.name, "/").concat(key))];
                    case 2:
                        sha1Result = _a.sent();
                        if (sha1Result.status != 200) {
                            throw new Error('MODULE NOT VERIFIED');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        throw new Error('MODULE NOT VERIFIED');
                    case 4:
                        if (!moduleInfo) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.loadScript(moduleInfo.name, blockFile)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EntryModuleLoader.prototype.loadModuleFromLocalOrOnline = function (name, isLite) {
        return __awaiter(this, void 0, void 0, function () {
            var lowerCaseName, baseUrl, path, response, result, key, isValid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lowerCaseName = isLite ? name : name.toLowerCase();
                        baseUrl = isLite ? Entry.moduleliteBaseUrl : Entry.moduleBaseUrl;
                        path = "".concat(baseUrl).concat(name, "/files/block");
                        if (Entry.offlineModulePath) {
                            path = "file://".concat(Entry.offlineModulePath, "/").concat(lowerCaseName, "/block");
                        }
                        return [4 /*yield*/, fetch(path)];
                    case 1:
                        response = _a.sent();
                        if (response.status != 200) {
                            throw new Error('MODULE NOT EXIST');
                        }
                        return [4 /*yield*/, response.text()];
                    case 2:
                        result = _a.sent();
                        key = cryptojs.SHA1(result).toString();
                        isValid = Entry.HARDWARE_LITE_LIST.some(function (item) { return item.name == name && item.sha1 == key; });
                        if (!isValid) {
                            return [2 /*return*/, alert('NOT VALID MODULE')];
                        }
                        if (Entry.offlineModulePath) {
                            if (window.sendSync) {
                                result = window.sendSync('decryptBlock', result);
                            }
                        }
                        return [4 /*yield*/, this.loadScript(name, result, isLite)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EntryModuleLoader.prototype.loadScript = function (name, code, isLite) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                            var scriptElementId, scriptElement, blobedBlock, blobUrl;
                            var _this = this;
                            return __generator(this, function (_a) {
                                scriptElementId = "entryModuleScript".concat(Date.now());
                                scriptElement = document.createElement('script');
                                scriptElement.id = scriptElementId;
                                scriptElement.onload = function () {
                                    _this.moduleListLite = [name];
                                    scriptElement.remove();
                                    resolve();
                                };
                                scriptElement.onerror = function (e) {
                                    scriptElement.remove();
                                    reject(e);
                                };
                                blobedBlock = new Blob([code], {
                                    type: 'text/javascript',
                                });
                                blobUrl = URL.createObjectURL(blobedBlock);
                                scriptElement.src = blobUrl;
                                // noinspection JSCheckFunctionSignatures
                                document.body.appendChild(scriptElement);
                                URL.revokeObjectURL(blobUrl);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // noinspection JSUnusedGlobalSymbols
    /**
     * [!] 외부에서 사용하는 함수입니다. 모듈화된 블록이 엔트리 등록을 위해 사용하는 함수임
     * 각 블록정보가 존재해야할 위치에 모든 데이터를 뿌려준다. 위치는 아래와 같다
     * - Entry.HARDWARE_LIST
     * - Entry.block : 실제 블록 정보를 담는다.
     *
     * 워크스페이스 리로드시 정보가 저장되지 않는다.
     * 이 후 블록메뉴에 블록들을 실시간으로 추가한 뒤 reDraw 한다.
     * @param moduleObject 하드웨어 모듈. 여타 하드웨어 모듈 파일 참조
     */
    EntryModuleLoader.prototype.registerHardwareModule = function (moduleObject) {
        var _this = this;
        if (!moduleObject.getBlocks || !moduleObject.blockMenuBlocks) {
            return;
        }
        if (typeof moduleObject.id === 'string') {
            var prevModuleBlocks = Entry.HARDWARE_LIST[moduleObject.id] &&
                Entry.HARDWARE_LIST[moduleObject.id].blockMenuBlocks;
            if (prevModuleBlocks) {
                this.removePrevModuleBlock(prevModuleBlocks);
            }
            Entry.HARDWARE_LIST[moduleObject.id] = moduleObject;
        }
        else if (moduleObject.id instanceof Array) {
            moduleObject.id.forEach(function (id) {
                var prevModuleBlocks = Entry.HARDWARE_LIST[id] && Entry.HARDWARE_LIST[id].blockMenuBlocks;
                if (prevModuleBlocks) {
                    _this.removePrevModuleBlock(prevModuleBlocks);
                }
                Entry.HARDWARE_LIST[id] = moduleObject;
            });
        }
        this.setLanguageTemplates(moduleObject);
        var blockObjects = moduleObject.getBlocks();
        var blockMenuBlocks = moduleObject.blockMenuBlocks;
        this.loadBlocks({
            categoryName: 'arduino',
            blockSchemas: Object.entries(blockObjects).map(function (_a) {
                var _b = __read(_a, 2), blockName = _b[0], block = _b[1];
                return ({
                    blockName: blockName,
                    block: block,
                    isBlockShowBlockMenu: blockMenuBlocks.indexOf(blockName) > -1,
                });
            }),
        });
        Entry.hw.setExternalModule(moduleObject);
        Entry.dispatchEvent('hwChanged');
    };
    // clear prevModule if present
    EntryModuleLoader.prototype.removePrevModuleBlock = function (prevModuleBlocks) {
        var e_2, _a;
        var removedCnt = 0;
        try {
            for (var _b = __values(Object.keys(Entry.block)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                if (prevModuleBlocks.indexOf(key) > -1) {
                    delete Entry.block[key];
                    removedCnt++;
                }
                if (removedCnt == prevModuleBlocks.length) {
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    // 모듈화 적용시, 팝업이벤트로부터 모듈name값만 받아서 동적으로 로드한다.
    EntryModuleLoader.prototype.registerHardwareLiteModule = function (moduleObject) {
        return __awaiter(this, void 0, void 0, function () {
            var blockObjects, blockMenuBlocks;
            return __generator(this, function (_a) {
                if (!moduleObject.getBlocks || !moduleObject.blockMenuBlocks) {
                    return [2 /*return*/];
                }
                Entry.hwLite.banClassAllHardwareLite();
                this.setLanguageTemplates(moduleObject);
                blockObjects = moduleObject.getBlocks();
                blockMenuBlocks = moduleObject.blockMenuBlocks;
                this.loadBlocks({
                    categoryName: 'arduino',
                    blockSchemas: Object.entries(blockObjects).map(function (_a) {
                        var _b = __read(_a, 2), blockName = _b[0], block = _b[1];
                        return ({
                            blockName: blockName,
                            block: block,
                            isBlockShowBlockMenu: blockMenuBlocks.indexOf(blockName) > -1,
                        });
                    }),
                });
                Entry.hwLite.setExternalModule(moduleObject);
                return [2 /*return*/];
            });
        });
    };
    /**
     * 이 함수는 외부 블록 모듈 URL 의 코드가 호출한다.
     * 엔트리 내 '확장' 카테고리에 블록을 추가한다.
     * 블록은 moduleObject 의 정보에 따라 타이틀, 설명 TextBlock 이 같이 추가된다.
     * @param moduleObject
     */
    EntryModuleLoader.prototype.registerBlockModule = function (moduleObject) {
        var name = moduleObject.name, title = moduleObject.title, description = moduleObject.description, getBlocks = moduleObject.getBlocks;
        var blockSchemas = [];
        title && blockSchemas.push(this.createTextBlock(name, title.ko));
        description && blockSchemas.push(this.createTextBlock(name, description));
        getBlocks && blockSchemas.push.apply(blockSchemas, __spreadArray([], __read(Object.entries(getBlocks()).map(function (_a) {
            var _b = __read(_a, 2), blockName = _b[0], block = _b[1];
            return ({
                blockName: blockName,
                block: block,
                isBlockShowBlockMenu: true,
            });
        })), false));
        this.loadBlocks({
            categoryName: 'expansion',
            blockSchemas: blockSchemas,
        });
    };
    EntryModuleLoader.prototype.loadBlocks = function (_a) {
        var _this = this;
        var _b;
        var categoryName = _a.categoryName, blockSchemas = _a.blockSchemas;
        var blockMenu = (_b = Entry.getMainWS()) === null || _b === void 0 ? void 0 : _b.blockMenu;
        blockSchemas.forEach(function (blockSchema) {
            _this.applyDefaultProperties(blockSchema);
            var blockName = blockSchema.blockName, block = blockSchema.block, isBlockShowBlockMenu = blockSchema.isBlockShowBlockMenu;
            // 블록의 카테고리를 정의할때 사용
            if (!block.category) {
                block.category = categoryName;
            }
            Entry.block[blockName] = block;
            if (isBlockShowBlockMenu && blockMenu) {
                blockMenu.addCategoryData(categoryName, blockName);
            }
        });
        if (!blockMenu) {
            return;
        }
        blockMenu.reDraw();
    };
    EntryModuleLoader.prototype.createTextBlock = function (moduleName, content) {
        var blockName = "".concat(moduleName, "_").concat(Math.random());
        var block = {
            color: EntryStatic.colorSet.common.TRANSPARENT,
            skeleton: 'basic_text',
            class: moduleName,
            template: '%1',
            params: [
                {
                    type: 'Text',
                    text: content,
                    color: EntryStatic.colorSet.common.TEXT,
                    align: 'center',
                },
            ],
            def: {
                type: blockName,
            },
            isNotFor: [moduleName],
            events: {},
        };
        return { blockName: blockName, block: block };
    };
    /**
     * TODO 리로드 되는 경우 다시 불러오지 않기 때문에 템플릿정보 저장이 필요함
     */
    EntryModuleLoader.prototype.setLanguageTemplates = function (moduleObject) {
        if (moduleObject.setLanguage) {
            var langTemplate = moduleObject.setLanguage();
            var data = langTemplate[Lang.type] || langTemplate[Lang.fallbackType];
            for (var key in data) {
                Object.assign(Lang[key], data[key]);
            }
        }
    };
    /**
     * 블록 모델이 블록객체로서 구현되기 전에 불충분한 프로퍼티나 잘못된 값이 있는 경우 이쪽에서 수정한다.
     */
    EntryModuleLoader.prototype.applyDefaultProperties = function (_a) {
        var blockName = _a.blockName, block = _a.block;
        if (!block.color) {
            block.color = EntryStatic.colorSet.block.default.EXPANSION;
            block.outerLine = EntryStatic.colorSet.block.darken.EXPANSION;
        }
        if (!block.type) {
            block.type = blockName;
        }
    };
    return EntryModuleLoader;
}());
var instance = new EntryModuleLoader();
export default instance;
Entry.moduleManager = instance;
/**
 * 프로젝트 가 외부 모듈이 사용되었는지 확인하고, 로드한다
 * @param {*} project 엔트리 프로젝트
 * @return Promise
 */
Entry.loadExternalModules = function (project) {
    if (project === void 0) { project = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var _a, externalModules;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = project.externalModules, externalModules = _a === void 0 ? [] : _a;
                    return [4 /*yield*/, Promise.all(externalModules.map(instance.loadModuleFromLocalOrOnline.bind(instance)))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
Entry.loadLiteExternalModules = function (project) {
    if (project === void 0) { project = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var _a, externalModulesLite;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = project.externalModulesLite, externalModulesLite = _a === void 0 ? [] : _a;
                    Entry.externalModulesLite = externalModulesLite;
                    return [4 /*yield*/, Promise.all(externalModulesLite.map(instance.registerHardwareLiteModule.bind(instance)))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
/**
 * 개발용 코드, path를 통해서 블럭을 로딩할수 있음.
 * @param path
 */
Entry.loadLiteTestModule = function (file, name) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, file.text()];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, Entry.moduleManager.loadScript(name, result, true)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
Entry.loadLiteTestModuleUploader = function () {
    var headerBtns = document.querySelector('section');
    var fileInput = document.createElement('input');
    fileInput.type = 'file';
    var nameInput = document.createElement('input');
    nameInput.type = 'text';
    var loadButton = document.createElement('button');
    loadButton.innerText = '적용';
    var handleUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    file = fileInput.files[0];
                    fileInput.value = null;
                    return [4 /*yield*/, Entry.loadLiteTestModule(file, nameInput.value)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    loadButton.onclick = handleUpdate;
    headerBtns.prepend(loadButton);
    headerBtns.prepend(fileInput);
    headerBtns.prepend(nameInput);
};
