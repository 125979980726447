"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _construct2 = _interopRequireDefault(require("@babel/runtime/helpers/construct"));
function singleInstance(MyClass) {
  function getInstance() {
    if (!MyClass.instance) {
      for (var _len = arguments.length, props = new Array(_len), _key = 0; _key < _len; _key++) {
        props[_key] = arguments[_key];
      }
      MyClass.instance = (0, _construct2["default"])(MyClass, props);
    }
    return MyClass.instance;
  }
  return {
    getInstance: getInstance
  };
}
var _default = singleInstance;
exports["default"] = _default;