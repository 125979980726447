var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { renderSoundEditor, loadSoundByUrl, loadSound, clearSound, clearHistory, registExportFunction, unregistExportFunction, isModified, getAudioBuffer, destroy, } from '@entrylabs/sound-editor';
import toWav from 'audiobuffer-to-wav';
var SoundEditor = /** @class */ (function () {
    function SoundEditor(soundView) {
        this.initialize(soundView);
    }
    SoundEditor.prototype.initialize = function (soundView) {
        renderSoundEditor(soundView);
        Entry.addEventListener('soundSelected', this.changeSound.bind(this));
        Entry.addEventListener('soundUnselected', this.clearSound.bind(this));
        Entry.addEventListener('saveCompleteSound', this.saveAfterEvent.bind(this));
        registExportFunction(this.saveSound.bind(this));
    };
    SoundEditor.prototype.getEntryAudioBuffer = function (id) {
        if (!Entry.soundQueue) {
            return;
        }
        return Entry.soundQueue.getResult(id);
    };
    SoundEditor.prototype.updateSound = function (sound, object) {
        return __awaiter(this, void 0, void 0, function () {
            var audioBuffer, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        if (Entry.playground.object.id !== object.id) {
                            return [2 /*return*/];
                        }
                        this.sound = sound;
                        this.object = object;
                        audioBuffer = this.getEntryAudioBuffer(sound.id);
                        if (!!audioBuffer) return [3 /*break*/, 2];
                        Entry.dispatchEvent('startLoading', Lang.Msgs.sound_loading);
                        return [4 /*yield*/, loadSoundByUrl(sound.path)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        loadSound(audioBuffer);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 6];
                    case 5:
                        Entry.dispatchEvent('endLoading');
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 이 메서드는 소리와 오브젝트를 매개변수로 받아,
     * 소리가 없거나 소리의 경로가 없는 경우를 제외하고 현재 소리를 업데이트합니다.
     * 만약 변경 사항이 있다면, 사용자에게 저장할지 확인한 후,
     * 오디오 버퍼를 얻고 소리를 저장합니다.
     * 변경 사항이 없다면, 소리와 오브젝트를 업데이트합니다.
     * 메서드 실행 중에 오류가 발생하면, 오류를 콘솔에 기록합니다.
     *
     * @param {ISound} sound - 업데이트할 소리
     * @param {IObject} object - 오브젝트
     */
    SoundEditor.prototype.changeSound = function (sound, object) {
        try {
            if (!sound || !sound.path || (this.sound && this.sound.id === sound.id)) {
                return;
            }
            if (isModified()) {
                this.alertSaveModifiedSound(sound, object);
            }
            else {
                this.updateSound(sound, object);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    SoundEditor.prototype.clearSound = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertSaveModifiedSound(this.sound, this.object)];
                    case 1:
                        _a.sent();
                        this.sound = null;
                        this.object = null;
                        clearSound();
                        return [2 /*return*/];
                }
            });
        });
    };
    SoundEditor.prototype.audioBufferToArrayBuffer = function (audioBuffer) {
        var numberOfChannels = audioBuffer.numberOfChannels;
        var length = audioBuffer.length * numberOfChannels * 2; // 16-bit PCM
        var buffer = new ArrayBuffer(length);
        var view = new DataView(buffer);
        for (var channel = 0; channel < numberOfChannels; channel++) {
            var channelData = audioBuffer.getChannelData(channel);
            var offset = channel * 2;
            for (var i = 0; i < channelData.length; i++) {
                var sample = Math.max(-1, Math.min(1, channelData[i])); // Clamp to -1 to 1
                // eslint-disable-next-line max-len
                view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true); // 16-bit PCM
                offset += numberOfChannels * 2;
            }
        }
        return buffer;
    };
    SoundEditor.prototype.writeUTFBytes = function (view, offset, string) {
        for (var i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    };
    SoundEditor.prototype.saveSound = function (audioBuffer, isSelect) {
        if (isSelect === void 0) { isSelect = true; }
        if (!audioBuffer) {
            return;
        }
        Entry.dispatchEvent('saveSoundBuffer', toWav(audioBuffer), __assign(__assign({}, this.sound), { objectId: this.object.id }), isSelect, function () {
            clearHistory();
        });
    };
    SoundEditor.prototype.saveAfterEvent = function (soundInfo) {
        var sound;
        var object;
        if (this.lastChangeSoundInfo) {
            sound = this.lastChangeSoundInfo.sound;
            object = this.lastChangeSoundInfo.object;
        }
        else if (soundInfo) {
            sound = soundInfo;
            object = {
                id: soundInfo.objectId,
            };
        }
        if (sound && object) {
            this.updateSound(sound, object);
        }
        this.lastChangeSoundInfo = null;
    };
    SoundEditor.prototype.alertSaveModifiedSound = function (sound, object) {
        var _this = this;
        return new Promise(function (res) {
            if (!isModified || !isModified()) {
                res();
                return;
            }
            Entry.modal.confirm(Lang.Menus.save_modified_shape).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!result) return [3 /*break*/, 2];
                            this.lastChangeSoundInfo = { sound: sound, object: object };
                            _a = this.saveSound;
                            return [4 /*yield*/, getAudioBuffer()];
                        case 1:
                            _a.apply(this, [_b.sent(), false]);
                            return [3 /*break*/, 3];
                        case 2:
                            clearHistory();
                            this.updateSound(sound, object);
                            _b.label = 3;
                        case 3:
                            res();
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    SoundEditor.prototype.hide = function () {
        this.alertSaveModifiedSound(this.sound, this.object);
    };
    SoundEditor.prototype.destory = function () {
        unregistExportFunction();
        destroy();
    };
    return SoundEditor;
}());
export default SoundEditor;
