/*
 *
 */
'use strict';

(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.editText] = {
    "do": function _do(text, prevText) {
      Entry.playground.object.setText(text);
      Entry.playground.object.entity.setText(text);
      Entry.dispatchEvent('textEdited');
    },
    state: function state(text, prevText) {
      return [prevText, text];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'editText'
  };
})(Entry.Command);