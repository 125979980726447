var emptyLog = function () {
    var arg = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arg[_i] = arguments[_i];
    }
};
var USE_NATIVE_LOG = false;
var nlog = console.log.bind(console);
export var clog = USE_NATIVE_LOG ? nlog : emptyLog;
var nwarn = console.warn.bind(console);
export var cwarn = USE_NATIVE_LOG ? nwarn : emptyLog;
