var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExtraBlockUtils from '../util/extrablockUtils';
import HardwareMonitor from './hardware/hardwareMonitor';
import WebUsbFlasher from './hardware/webUsbFlasher';
import WebSerialConnector from './hardware/webSerialConnector';
import WebBluetoothConnector from './hardware/webBluetoothConnector';
var ARDUINO_BOARD_IDS = ['010101', '040201', '080101'];
var HardwareLite = /** @class */ (function () {
    function HardwareLite(playground) {
        this.playground = playground;
        this.hwModule = null;
        this.status = 'disconnected';
        Entry.addEventListener('hwLiteChanged', this.refreshHardwareLiteBlockMenu.bind(this));
        this.setExternalModule.bind(this);
    }
    HardwareLite.prototype.setZero = function () {
        var _a;
        (_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.setZero();
    };
    HardwareLite.prototype.isActive = function (name) {
        if (this.hwModule) {
            var data = {};
            // @ts-ignore
            data[name] = this.hwModule;
            return ExtraBlockUtils.isActive(name, data);
        }
        return false;
    };
    /**
     * 모든 하드웨어를 숨김처리한다. 현재 연결된 하드웨어도 예외는 없다.
     * @private
     */
    HardwareLite.prototype.banClassAllHardwareLite = function () {
        var workspace = Entry.getMainWS();
        var blockMenu = workspace && workspace.blockMenu;
        if (!blockMenu) {
            return;
        }
        Object.values(Entry.HARDWARE_LITE_LIST || {}).forEach(function (hardware) {
            blockMenu.banClass(hardware.name, true);
        });
        ExtraBlockUtils.banAllBlocks(this.playground, Entry.HARDWARE_LITE_LIST);
        blockMenu.reDraw();
    };
    HardwareLite.prototype.setExternalModule = function (moduleObject) {
        this.hwModule = moduleObject;
        this.banClassAllHardwareLite();
        Entry.block.changeBlockText('arduino_lite_device_name', this.hwModule.title.ko);
        Entry.dispatchEvent('hwLiteChanged');
        this.setWebConnector();
        this.setFlasher();
        if (Entry.propertyPanel && this.hwModule.monitorTemplate) {
            this._setHardwareMonitorTemplate();
        }
    };
    HardwareLite.prototype.getConnectFailedMenu = function () {
        this.status = 'connectFailed';
        this.refreshHardwareLiteBlockMenu();
    };
    HardwareLite.prototype.setFirmwareDownloadButton = function (callback) {
        var _this = this;
        var workspace = Entry.getMainWS();
        var blockMenu = workspace && workspace.blockMenu;
        if (!blockMenu) {
            return;
        }
        Entry.block.changeBlockEvent('arduino_lite_download_firmware', 'mousedown', function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, callback()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        blockMenu.changeTypeThreadByBlockKey('arduino_lite_download_firmware');
    };
    HardwareLite.prototype._setHardwareMonitorTemplate = function () {
        if (!this.hwMonitor) {
            this.hwMonitor = new HardwareMonitor(this.hwModule);
        }
        else {
            this.hwMonitor.setHwModule(this.hwModule);
            this.hwMonitor.initView();
        }
        Entry.propertyPanel.addMode('hw', this.hwMonitor);
        this.hwMonitor.generateViewByMode();
    };
    HardwareLite.prototype._updatePortData = function () {
        if (this.hwMonitor && Entry.propertyPanel && Entry.propertyPanel.selected === 'hw') {
            this.hwMonitor.update(this.hwModule.getMonitorPort(), null);
        }
    };
    HardwareLite.prototype.isHwLiteSupportAgent = function () {
        var userAgentString = navigator.userAgent.toLowerCase();
        // INFO: 디바이스가 모바일이거나 일렉트론이면 1차적으로 제외
        if (userAgentString.includes('mobile') || userAgentString.includes('electron')) {
            return false;
        }
        else if (userAgentString.includes('whale') ||
            userAgentString.includes('edge') ||
            userAgentString.includes('chrome')) {
            return true;
        }
        else {
            return false;
        }
    };
    HardwareLite.prototype.refreshHardwareLiteBlockMenu = function () {
        var _a, _b, _c;
        var blockMenu = (_a = Entry.getMainWS()) === null || _a === void 0 ? void 0 : _a.blockMenu;
        if (!blockMenu) {
            return;
        }
        switch (this.status) {
            case 'disconnected': {
                blockMenu.banClass('arduinoLiteConnected', true);
                blockMenu.banClass('arduinoLiteConnectFailed', true);
                blockMenu.banClass('arduinoLiteGuide', true);
                blockMenu.unbanClass('arduinoLiteDisconnected', true);
                blockMenu.unbanClass('arduinoDisconnected', true);
                this.isHwLiteSupportAgent()
                    ? blockMenu.unbanClass('arduinoLiteSupported', true)
                    : blockMenu.banClass('arduinoLiteSupported', true);
                this.banClassAllHardwareLite();
                break;
            }
            case 'connected':
                blockMenu.banClass('arduinoLiteConnectFailed', true);
                blockMenu.banClass('arduinoLiteDisconnected', true);
                blockMenu.banClass('arduinoDisconnected', true);
                blockMenu.banClass('arduinoLiteSupported', true);
                blockMenu.banClass('arduinoLiteGuide', true);
                blockMenu.unbanClass('arduinoLiteConnected', true);
                blockMenu.unbanClass((_b = this.hwModule) === null || _b === void 0 ? void 0 : _b.name, true);
                break;
            case 'connectFailed':
                blockMenu.banClass('arduinoLiteDisconnected', true);
                blockMenu.banClass('arduinoLiteConnected', true);
                blockMenu.banClass('arduinoDisconnected', true);
                blockMenu.banClass('arduinoLiteSupported', true);
                blockMenu.unbanClass('arduinoLiteConnectFailed', true);
                if (typeof ((_c = this.hwModule) === null || _c === void 0 ? void 0 : _c.id) === 'string') {
                    if (ARDUINO_BOARD_IDS.includes(this.hwModule.id)) {
                        blockMenu.unbanClass('arduinoLiteGuide', true);
                    }
                }
                // else if (this.hwModule?.id instanceof Array) {
                //     for (const id in this.hwModule.id) {
                //         if (ARDUINO_BOARD_IDS.includes(this.hwModule.id[id])) {
                //             blockMenu.unbanClass('arduinoLiteGuide', true);
                //             break;
                //         }
                //     }
                // }
                this.banClassAllHardwareLite();
                break;
        }
        blockMenu.hwCodeOutdated = true;
        blockMenu._generateHwCode(true);
        blockMenu.reDraw();
    };
    HardwareLite.prototype.connect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.status === 'connected') {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.webConnector.connect()];
                    case 2:
                        _a.sent();
                        this.setStatus('connected');
                        this.refreshHardwareLiteBlockMenu();
                        return [4 /*yield*/, this.webConnector.initialDevice()];
                    case 3:
                        _a.sent();
                        Entry.toast.success(Lang.Msgs.hw_connection_success, Lang.Msgs.hw_connection_success_desc2);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error(error_1);
                        Entry.toast.alert(Lang.Msgs.hw_connection_failed_title, Lang.Msgs.hw_connection_failed_desc, false);
                        this.getConnectFailedMenu();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    HardwareLite.prototype.disconnect = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        Entry.hardwareLiteBlocks = [];
                        this.status = 'willDisconnect';
                        return [4 /*yield*/, ((_a = this.webConnector) === null || _a === void 0 ? void 0 : _a.disconnect())];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _b.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this.hwModule = null;
                        this.status = 'disconnected';
                        this.removeWebConnector();
                        this.removeFlasher();
                        Entry.dispatchEvent('hwLiteChanged');
                        Entry.toast.alert(Lang.Msgs.hw_connection_termination_title, Lang.Msgs.hw_connection_termination_desc, false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HardwareLite.prototype.addHardwareLiteModule = function (module) {
        Entry.do('objectAddHardwareLiteBlocks', module);
    };
    HardwareLite.prototype.removeHardwareLiteModule = function () {
        Entry.do('objectRemoveHardwareLiteBlocks', this.hwModule);
    };
    HardwareLite.prototype.setWebConnector = function () {
        var webapiType = this.hwModule.webapiType;
        switch (webapiType) {
            case 'ble': {
                if (!this.hwModule.bluetoothInfo) {
                    console.error('Invalid bluetooth hwModule');
                    this.getConnectFailedMenu();
                    return;
                }
                this.bluetooth = new WebBluetoothConnector(this.hwModule, this);
                this.webConnector = this.bluetooth;
                break;
            }
            case 'serial':
            case undefined: {
                if (!this.hwModule.portData) {
                    console.error('Invalid serial hwModule');
                    this.getConnectFailedMenu();
                    return;
                }
                this.serial = new WebSerialConnector(this.hwModule, this);
                this.webConnector = this.serial;
            }
        }
    };
    HardwareLite.prototype.removeWebConnector = function () {
        this.webConnector = undefined;
        this.serial = undefined;
        this.bluetooth = undefined;
    };
    HardwareLite.prototype.setFlasher = function () {
        if (this.hwModule.firmwareFlash) {
            this.flasher = new WebUsbFlasher();
        }
    };
    HardwareLite.prototype.removeFlasher = function () {
        this.flasher = undefined;
    };
    HardwareLite.prototype.getStatus = function () {
        return this.status;
    };
    HardwareLite.prototype.setStatus = function (state) {
        this.status = state;
    };
    return HardwareLite;
}());
export default HardwareLite;
Entry.HWLite = HardwareLite;
