var ExternalProgramLauncher = /** @class */ (function () {
    function ExternalProgramLauncher() {
        this.programNotInstalled = false;
    }
    ExternalProgramLauncher.prototype.executeUrl = function (schemeUrl, notInstalledCallback) {
        if (navigator.userAgent.indexOf('MSIE') > 0 || navigator.userAgent.indexOf('Trident') > 0) {
            if (navigator.msLaunchUri !== undefined) {
                executeIe(schemeUrl);
            }
            else {
                var ieVersion = void 0;
                // @ts-ignore IE 에 실제 있는 프로퍼티이다.
                if (document.documentMode > 0) {
                    // @ts-ignore IE 에 실제 있는 프로퍼티이다.
                    ieVersion = document.documentMode;
                }
                else {
                    ieVersion = navigator.userAgent.match(/(?:MSIE) ([0-9.]+)/)[1];
                }
                if (ieVersion < 9) {
                    alert(Lang.msgs.not_support_browser);
                }
                else {
                    this.init(schemeUrl, function (programInstalled) {
                        if (programInstalled === false) {
                            notInstalledCallback();
                        }
                    });
                }
            }
        }
        else if (navigator.userAgent.indexOf('Firefox') > 0) {
            executeFirefox(schemeUrl);
        }
        else if (navigator.userAgent.indexOf('Chrome') > 0) {
            executeChrome(schemeUrl);
        }
        else if (navigator.userAgent.indexOf('Safari') > 0) {
            executeSafari(schemeUrl);
        }
        else {
            alert(Lang.msgs.not_support_browser);
        }
        function executeIe(customUrl) {
            navigator.msLaunchUri(customUrl, function () { }, function () {
                notInstalledCallback();
            });
        }
        function executeFirefox(customUrl) {
            var iFrame = document.createElement('iframe');
            iFrame.src = 'about:blank';
            iFrame.setAttribute('style', 'display:none');
            document.getElementsByTagName('body')[0].appendChild(iFrame);
            var fnTimeout = setTimeout(function () {
                var isInstalled = false;
                try {
                    iFrame.contentWindow.location.href = customUrl;
                    isInstalled = true;
                }
                catch (e) {
                    if (e.name === 'NS_ERROR_UNKNOWN_PROTOCOL') {
                        isInstalled = false;
                    }
                }
                if (!isInstalled) {
                    notInstalledCallback();
                }
                document.getElementsByTagName('body')[0].removeChild(iFrame);
                clearTimeout(fnTimeout);
            }, 500);
        }
        function executeChrome(customUrl) {
            var isInstalled = false;
            window.focus();
            $(window).one('blur', function () {
                isInstalled = true;
            });
            Entry.dispatchEvent('workspaceUnbindUnload', true);
            location.assign(encodeURI(customUrl));
            setTimeout(function () {
                Entry.dispatchEvent('workspaceBindUnload', true);
            }, 100);
            setTimeout(function () {
                if (isInstalled === false) {
                    notInstalledCallback();
                }
                window.onblur = null;
            }, 3000);
        }
        /**
         * safari 브라우저에서 ${customUrl} 인식하여 페이지 이동 처리되서 분기처리(미설치 안내팝업)
         *
         * @param customUrl
         */
        function executeSafari(customUrl) {
            var iFrame = document.createElement('iframe');
            iFrame.src = 'about:blank';
            iFrame.setAttribute('style', 'display:none');
            document.getElementsByTagName('body')[0].appendChild(iFrame);
            var isInstalled;
            try {
                iFrame.contentWindow.location.href = customUrl;
                isInstalled = true;
            }
            catch (err) {
                isInstalled = false;
            }
            if (!isInstalled) {
                notInstalledCallback();
            }
            setTimeout(function () {
                document.getElementsByTagName('body')[0].removeChild(iFrame);
            }, 500);
        }
    };
    ExternalProgramLauncher.prototype.set = function () {
        this.programNotInstalled = true;
    };
    ExternalProgramLauncher.prototype.init = function (schemeUrl, callback) {
        var _this = this;
        var settings = this.getLoadingViewStyle();
        this.loadingView = window.open('/views/hwLoading.html', 'entry_hw_launcher', settings);
        var fnInterval = undefined;
        fnInterval = setTimeout(function () {
            _this.runViewer(schemeUrl, callback);
            clearInterval(fnInterval);
        }, 1000);
    };
    ExternalProgramLauncher.prototype.runViewer = function (schemeUrl, callback) {
        var _this = this;
        this.loadingView.document.write(
        // eslint-disable-next-line max-len
        "<iframe src='".concat(schemeUrl, "' onload='opener.Entry.hw.programLauncher.set()' style='display:none;width:0;height:0'></iframe>"));
        var tryCounter = 0;
        var programNotInstalled = false;
        var tryExecutionTimeout = undefined;
        tryExecutionTimeout = setInterval(function () {
            try {
                _this.loadingView.location.href;
            }
            catch (e) {
                _this.programNotInstalled = true;
            }
            if (programNotInstalled || tryCounter > 10) {
                clearInterval(tryExecutionTimeout);
                var nCloseCounter_1 = 0;
                var nCloseInterval_1 = undefined;
                nCloseInterval_1 = setInterval(function () {
                    nCloseCounter_1++;
                    if (_this.loadingView.closed || nCloseCounter_1 > 2) {
                        clearInterval(nCloseInterval_1);
                    }
                    else {
                        _this.loadingView.close();
                    }
                    _this.programNotInstalled = false;
                    tryCounter = 0;
                }, 5000);
                callback(!_this.programNotInstalled);
            }
            tryCounter++;
        }, 100);
    };
    ExternalProgramLauncher.prototype.getLoadingViewStyle = function () {
        var width = 220;
        var height = 225;
        var left = window.screenLeft;
        var top = window.screenTop;
        return "width=".concat(width, ", height=").concat(height, ",  top=").concat(top, ", left=").concat(left);
    };
    return ExternalProgramLauncher;
}());
export default ExternalProgramLauncher;
