/*
 *
 */
'use strict';

(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.playgroundChangeViewMode] = {
    "do": function _do(newType, oldType) {
      Entry.variableContainer.selected = null;
      Entry.variableContainer.updateList();
      Entry.playground.changeViewMode(newType);
      if (Entry.disposeEvent) {
        Entry.disposeEvent.notify();
      }
      Entry.Utils.forceStopSounds();
    },
    state: function state(newType, oldType) {
      return [oldType, newType];
    },
    log: function log(newType, oldType) {
      oldType = oldType || 'code';
      return [['newType', newType], ['oldType', oldType]];
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'playgroundChangeViewMode',
    dom: ['playground', 'tabViewElements', '&0']
  };
  c[COMMAND_TYPES.playgroundClickAddPicture] = {
    "do": function _do() {
      Entry.dispatchEvent('openPictureManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    //skipUndoStack: true,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: function restrict(data, domQuery, callback, restrictor) {
      Entry.dispatchEvent('dismissModal');
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        restrict: true,
        dimmed: true,
        callBack: callback
      });
      return tooltip;
    },
    undo: 'playgroundClickAddPictureCancel',
    dom: ['playground', 'pictureAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddPictureCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    //skipUndoStack: true,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'pictureAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddSound] = {
    "do": function _do() {
      Entry.dispatchEvent('openSoundManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: function restrict(data, domQuery, callback, restrictor) {
      Entry.dispatchEvent('dismissModal');
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        restrict: true,
        dimmed: true,
        callBack: callback
      });
      return tooltip;
    },
    undo: 'playgroundClickAddSoundCancel',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddSoundCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddTable] = {
    "do": function _do() {
      Entry.dispatchEvent('openTableManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    undo: 'playgroundClickAddTableCancel',
    dom: ['playground', 'tableAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddTableCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'tableAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddExpansionBlock] = {
    "do": function _do() {
      Entry.dispatchEvent('openExpansionBlockManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: function restrict(data, domQuery, callback, restrictor) {
      Entry.dispatchEvent('dismissModal');
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        restrict: true,
        dimmed: true,
        callBack: callback
      });
      return tooltip;
    },
    undo: 'playgroundClickAddExpansionBlockCancel',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddExpansionBlockCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddAIUtilizeBlock] = {
    "do": function _do() {
      Entry.dispatchEvent('openAIUtilizeBlockManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: function restrict(data, domQuery, callback, restrictor) {
      Entry.dispatchEvent('dismissModal');
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        restrict: true,
        dimmed: true,
        callBack: callback
      });
      return tooltip;
    },
    undo: 'playgroundClickAddAIUtilizeBlockCancel',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddAIUtilizeBlockCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddHardwareLiteBlock] = {
    "do": function _do() {
      Entry.dispatchEvent('openHardwareLiteBlockManager');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: function restrict(data, domQuery, callback, restrictor) {
      Entry.dispatchEvent('dismissModal');
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        restrict: true,
        dimmed: true,
        callBack: callback
      });
      return tooltip;
    },
    undo: 'playgroundClickAddHardwareLiteBlockCancel',
    dom: ['playground', 'soundAddButton']
  };
  c[COMMAND_TYPES.playgroundClickAddHardwareLiteBlockCancel] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
    },
    state: function state() {
      return [];
    },
    log: function log() {
      return [];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: '',
    dom: ['playground', 'soundAddButton']
  };
})(Entry.Command);