'use strict';

Entry.Arduino = {
  id: ['1.1', '4.2', '8.1'],
  name: 'arduino',
  url: 'http://www.arduino.cc/',
  imageName: 'arduino.png',
  title: {
    ko: '아두이노',
    en: 'Arduino'
  },
  setZero: function setZero() {
    Entry.hw.sendQueue.readablePorts = [];
    for (var port = 0; port < 20; port++) {
      Entry.hw.sendQueue[port] = 0;
      Entry.hw.sendQueue.readablePorts.push(port);
    }
    Entry.hw.update();
  },
  monitorTemplate: {
    imgPath: 'hw/arduino.png',
    width: 605,
    height: 434,
    listPorts: {
      '2': {
        name: "".concat(Lang.Hw.port_en, " 2 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '3': {
        name: "".concat(Lang.Hw.port_en, " 3 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '4': {
        name: "".concat(Lang.Hw.port_en, " 4 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '5': {
        name: "".concat(Lang.Hw.port_en, " 5 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '6': {
        name: "".concat(Lang.Hw.port_en, " 6 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '7': {
        name: "".concat(Lang.Hw.port_en, " 7 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '8': {
        name: "".concat(Lang.Hw.port_en, " 8 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '9': {
        name: "".concat(Lang.Hw.port_en, " 9 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '10': {
        name: "".concat(Lang.Hw.port_en, " 10 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '11': {
        name: "".concat(Lang.Hw.port_en, " 11 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '12': {
        name: "".concat(Lang.Hw.port_en, " 12 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '13': {
        name: "".concat(Lang.Hw.port_en, " 13 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a0: {
        name: "".concat(Lang.Hw.port_en, " A0 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a1: {
        name: "".concat(Lang.Hw.port_en, " A1 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a2: {
        name: "".concat(Lang.Hw.port_en, " A2 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a3: {
        name: "".concat(Lang.Hw.port_en, " A3 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a4: {
        name: "".concat(Lang.Hw.port_en, " A4 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a5: {
        name: "".concat(Lang.Hw.port_en, " A5 ").concat(Lang.Hw.port_ko),
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      }
    },
    mode: 'both'
  }
};
Entry.Arduino.setLanguage = function () {
  return {
    ko: {
      template: {
        arduino_get_number_sensor_value: '아날로그 %1 번 센서값  ',
        arduino_get_digital_value: '디지털 %1 번 센서값  ',
        arduino_toggle_led: '디지털 %1 번 핀 %2 %3',
        arduino_toggle_pwm: '디지털 %1 번 핀을 %2 (으)로 정하기 %3',
        arduino_convert_scale: '%1 값의 범위를 %2 ~ %3 에서 %4 ~ %5 (으)로 바꾼값  '
      },
      Device: {
        arduino: '아두이노'
      },
      Menus: {
        arduino: '아두이노'
      }
    },
    en: {
      template: {
        arduino_get_number_sensor_value: 'Analog %1 Sensor value  ',
        arduino_get_digital_value: 'Digital %1 Sensor value  ',
        arduino_toggle_led: 'Digital %1 Pin %2 %3',
        arduino_toggle_pwm: 'Digital %1 Pin %2 %3',
        arduino_convert_scale: 'Map Value %1 %2 ~ %3 to %4 ~ %5  '
      },
      Device: {
        arduino: 'arduino'
      },
      Menus: {
        arduino: 'Arduino'
      }
    }
  };
};
Entry.Arduino.blockMenuBlocks = ['arduino_get_number_sensor_value', 'arduino_get_digital_value', 'arduino_toggle_led', 'arduino_toggle_pwm', 'arduino_convert_scale'];
Entry.Arduino.getBlocks = function () {
  return {
    //region arduino 아두이노
    arduino_text: {
      color: '#FFD974',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'TextInput'
      }],
      events: {},
      def: {
        params: ['10']
      },
      paramsKeyMap: {
        NAME: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('NAME');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'TextInput',
            value: 10,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          keyOption: 'arduino_text'
        }]
      }
    },
    arduino_get_sensor_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['0', 'A0'], ['1', 'A1'], ['2', 'A2'], ['3', 'A3'], ['4', 'A4'], ['5', 'A5']],
        value: 'A0',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [['0', 'A0'], ['1', 'A1'], ['2', 'A2'], ['3', 'A3'], ['4', 'A4'], ['5', 'A5']],
            value: 'A0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          keyOption: 'arduino_get_sensor_number'
        }]
      }
    },
    arduino_get_port_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'], ['10', '10'], ['11', '11'], ['12', '12'], ['13', '13']],
        value: '0',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'], ['10', '10'], ['11', '11'], ['12', '12'], ['13', '13']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
          }],
          keyOption: 'arduino_get_port_number'
        }]
      }
    },
    arduino_get_pwm_port_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['3', '3'], ['5', '5'], ['6', '6'], ['9', '9'], ['10', '10'], ['11', '11']],
        value: '3',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [['3', '3'], ['5', '5'], ['6', '6'], ['9', '9'], ['10', '10'], ['11', '11']],
            value: '3',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          keyOption: 'arduino_get_pwm_port_number'
        }]
      }
    },
    arduino_get_number_sensor_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_sensor_number'
        }],
        type: 'arduino_get_number_sensor_value'
      },
      paramsKeyMap: {
        VALUE: 0
      },
      "class": 'arduino_value',
      isNotFor: ['arduino'],
      func: function func(sprite, script) {
        var signal = script.getValue('VALUE', script);
        return Entry.hw.getAnalogPortValue(signal[1]);
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.sensor_value(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    arduino_get_digital_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number'
        }],
        type: 'arduino_get_digital_value'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'arduino_value',
      isNotFor: ['arduino'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule === void 0 ? {} : _Entry$hw$hwModule;
        var name = hwModule.name;
        if (name === 'ArduinoExt') {
          return Entry.block.arduino_ext_get_digital.func(sprite, script);
        } else {
          var signal = script.getNumberValue('PORT', script);
          return Entry.hw.getDigitalPortValue(signal);
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          replaceBlockType: 'arduino_ext_get_digital',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    arduino_toggle_led: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.ARDUINO_on, 'on'], [Lang.Blocks.ARDUINO_off, 'off']],
        value: 'on',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number'
        }, null, null],
        type: 'arduino_toggle_led'
      },
      paramsKeyMap: {
        VALUE: 0,
        OPERATOR: 1
      },
      "class": 'arduino_set',
      isNotFor: ['arduino'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('VALUE');
        var operator = script.getField('OPERATOR');
        var value = operator == 'on' ? 255 : 0;
        Entry.hw.setDigitalPortValue(port, value);
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.pin_digital(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.ARDUINO_on, 'on'], [Lang.Blocks.ARDUINO_off, 'off']],
            value: 'on',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    arduino_toggle_pwm: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_pwm_port_number'
        }, {
          type: 'arduino_text',
          params: ['255']
        }, null],
        type: 'arduino_toggle_pwm'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'arduino_set',
      isNotFor: ['arduino'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.max(value, 0);
        value = Math.min(value, 255);
        Entry.hw.setDigitalPortValue(port, value);
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.set_pin_digital(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    arduino_convert_scale: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_number_sensor_value',
          params: [{
            type: 'arduino_get_sensor_number',
            id: 'bl5e'
          }]
        }, {
          type: 'number',
          params: ['0']
        }, {
          type: 'number',
          params: ['1023']
        }, {
          type: 'number',
          params: ['0']
        }, {
          type: 'number',
          params: ['100']
        }],
        type: 'arduino_convert_scale'
      },
      paramsKeyMap: {
        VALUE1: 0,
        VALUE2: 1,
        VALUE3: 2,
        VALUE4: 3,
        VALUE5: 4
      },
      "class": 'arduino',
      isNotFor: ['arduino'],
      func: function func(sprite, script) {
        var value1 = script.getNumberValue('VALUE1', script);
        var value2 = script.getNumberValue('VALUE2', script);
        var value3 = script.getNumberValue('VALUE3', script);
        var value4 = script.getNumberValue('VALUE4', script);
        var value5 = script.getNumberValue('VALUE5', script);
        var stringValue4 = script.getValue('VALUE4', script);
        var stringValue5 = script.getValue('VALUE5', script);
        var isFloat = false;
        if (Entry.Utils.isNumber(stringValue4) && stringValue4.indexOf('.') > -1 || Entry.Utils.isNumber(stringValue5) && stringValue5.indexOf('.') > -1) {
          isFloat = true;
        }
        var result = value1;
        if (value2 > value3) {
          var swap = value2;
          value2 = value3;
          value3 = swap;
        }
        if (value4 > value5) {
          var swap = value4;
          value4 = value5;
          value5 = swap;
        }
        result -= value2;
        result = result * ((value5 - value4) / (value3 - value2));
        result += value4;
        result = Math.min(value5, result);
        result = Math.max(value4, result);
        if (isFloat) {
          result = Math.round(result * 100) / 100;
        } else {
          result = Math.round(result);
        }
        return result;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.convert_scale(%1, %2, %3, %4, %5)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    }
    //endregion arduino 아두이노
  };
};

module.exports = Entry.Arduino;