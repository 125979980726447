var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseTexture, resources } from 'pixi.js';
var AtlasBaseTexture = /** @class */ (function (_super) {
    __extends(AtlasBaseTexture, _super);
    function AtlasBaseTexture(_viewer, scaleMode) {
        var _this = _super.call(this, null, { scaleMode: scaleMode }) || this;
        _this._viewer = _viewer;
        return _this;
    }
    Object.defineProperty(AtlasBaseTexture.prototype, "activated", {
        get: function () {
            return this._activated;
        },
        enumerable: false,
        configurable: true
    });
    AtlasBaseTexture.prototype.setCanvas = function (canvas) {
        var resource = new resources.CanvasResource(canvas);
        this.setResource(resource);
        this._canvas = canvas;
        this._ctx = canvas.getContext('2d');
    };
    AtlasBaseTexture.prototype.getCanvas = function () {
        return this._canvas;
    };
    AtlasBaseTexture.prototype.cleanCanvas = function () {
        this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    };
    AtlasBaseTexture.prototype.activate = function (MAX_SIZE) {
        this._activated = true;
        this._canvas.width = MAX_SIZE;
        this._canvas.height = MAX_SIZE;
        // this.hasLoaded = true; deprecated v5
        this._viewer.add(this._canvas);
    };
    AtlasBaseTexture.prototype.deactivate = function () {
        this._activated = false;
        this._canvas.width = 1;
        this._canvas.height = 1;
        this.dispose();
    };
    AtlasBaseTexture.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        this._canvas = null;
        this._ctx = null;
    };
    AtlasBaseTexture.prototype.dispose = function () {
        _super.prototype.dispose.call(this);
        if (this._viewer) {
            this._viewer.removeCanvas(this._canvas);
        }
    };
    AtlasBaseTexture.prototype.getCtx = function () {
        return this._ctx;
    };
    return AtlasBaseTexture;
}(BaseTexture));
export { AtlasBaseTexture };
