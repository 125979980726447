"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PIXIShortPropPlugin = PIXIShortPropPlugin;
var _pixi = require("pixi.js");
function PIXIShortPropPlugin() {
  Object.defineProperties(_pixi.DisplayObject.prototype, {
    scaleX: {
      get: function get() {
        return this.scale.x;
      },
      set: function set(v) {
        this.scale.x = v;
      }
    },
    scaleY: {
      get: function get() {
        return this.scale.y;
      },
      set: function set(v) {
        this.scale.y = v;
      }
    },
    regX: {
      get: function get() {
        return this.pivot.x;
      },
      set: function set(v) {
        this.pivot.x = v;
      }
    },
    regY: {
      get: function get() {
        return this.pivot.y;
      },
      set: function set(v) {
        this.pivot.y = v;
      }
    },
    mouseEnabled: {
      get: function get() {
        return this.interactive;
      },
      set: function set(v) {
        this.interactive = v;
      }
    }
  });
  Object.defineProperties(_pixi.Container.prototype, {
    mouseChildren: {
      get: function get() {
        return this.interactiveChildren;
      },
      set: function set(v) {
        this.interactiveChildren = v;
      }
    }
  });
}