import PopupHelper from '../../popup_helper';
export default function createHardwarePopup(onOkButtonClicked) {
    var popupHelper = window.popupHelper || new PopupHelper(true);
    popupHelper.addPopup('hwDownload', {
        type: 'confirm',
        title: Lang.Msgs.not_install_title,
        setPopupLayout: function (popup) {
            var content = Entry.Dom('div', {
                class: 'contentArea',
            });
            var text = Entry.Dom('div', {
                class: 'textArea',
                parent: content,
            });
            var text1 = Entry.Dom('div', {
                class: 'text1',
                parent: text,
            });
            var text2 = Entry.Dom('div', {
                class: 'text2',
                parent: text,
            });
            var text3 = Entry.Dom('div', {
                class: 'text3',
                parent: text,
            });
            var text4 = Entry.Dom('div', {
                class: 'text4',
                parent: text,
            });
            var cancel = Entry.Dom('div', {
                classes: ['popupCancelBtn', 'popupDefaultBtn'],
                parent: content,
            });
            var ok = Entry.Dom('div', {
                classes: ['popupOkBtn', 'popupDefaultBtn'],
                parent: content,
            });
            text1.text(Lang.Msgs.hw_download_text1);
            text2.html(Lang.Msgs.hw_download_text2);
            text3.text(Lang.Msgs.hw_download_text3);
            text4.text(Lang.Msgs.hw_download_text4);
            cancel.text(Lang.Buttons.cancel);
            ok.html(Lang.Msgs.hw_download_btn);
            content.bindOnClick('.popupDefaultBtn', function () {
                var $this = $(this);
                if ($this.hasClass('popupOkBtn')) {
                    onOkButtonClicked();
                }
                popupHelper.hide( /*'hwDownload'*/);
            });
            popup.append(content);
        },
    });
    return popupHelper;
}
