var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Texture, Rectangle } from 'pixi.js';
var EntryTextureBase = /** @class */ (function (_super) {
    __extends(EntryTextureBase, _super);
    function EntryTextureBase(baseTexture, imageRect) {
        var _this = this;
        var frame = new Rectangle(0, 0, imageRect.width, imageRect.height);
        _this = _super.call(this, baseTexture, frame) || this;
        _this.textureScaleFactorX = imageRect.scaleFactorX;
        _this.textureScaleFactorY = imageRect.scaleFactorY;
        return _this;
    }
    EntryTextureBase.prototype.assignTextureScaleFactor = function (target) {
        if (target) {
            var tex = target;
            //textureScaleFactor 변수 네이밍을 여기저기서 쓰지 않으려고 메서드를 만듬.
            tex.textureScaleFactorX = this.textureScaleFactorX;
            tex.textureScaleFactorY = this.textureScaleFactorY;
        }
    };
    return EntryTextureBase;
}(Texture));
export { EntryTextureBase };
