Entry.skeleton.basic_loop = {
    executable: true,
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(28, height);
        width = Math.max(0, width + 6 - height / 2);
        var statementHeight = blockView._statements[0] ? blockView._statements[0].height : 20;
        statementHeight = Math.max(statementHeight, 20);
        var bw = width - 8;
        var halfHeight = height / 2;
        return "m 0 0\n        l 6 6\n        l 6 -6\n        h ".concat(width, "\n        a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 ").concat(height, "\n        H 26\n        l -6 6\n        l -6 -6\n        v ").concat(statementHeight, "\n        l 6 6\n        l 6 -6\n        h ").concat(bw, "\n        a 7.5 7.5 0 0 1 0 15\n        H 12\n        l -6 6\n        l -6 -6\n        z");
    },
    magnets: function (blockView) {
        var contentHeight = Math.max(blockView.contentHeight + 2, 28);
        var statementHeight = blockView._statements[0] ? blockView._statements[0].height : 20;
        statementHeight = Math.max(statementHeight, 20);
        return {
            previous: { x: 0, y: 0 },
            next: { x: 0, y: statementHeight + contentHeight + 15 + blockView.offsetY },
        };
    },
    box: function (blockView) {
        var contentWidth = blockView.contentWidth;
        var contentHeight = Math.max(blockView.contentHeight + 2, 28);
        var statementHeight = blockView._statements[0] ? blockView._statements[0].height : 20;
        statementHeight = Math.max(statementHeight, 20);
        return {
            topFieldHeight: contentHeight,
            offsetX: -8,
            offsetY: 0,
            width: contentWidth + 30,
            height: contentHeight + statementHeight + 17,
            marginBottom: 0,
        };
    },
    statementPos: function (blockView) {
        var height = Math.max(30, blockView.contentHeight + 2);
        return [{ x: 14, y: height - 2 }];
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight, 28);
        return { x: 14, y: height / 2 };
    },
};
