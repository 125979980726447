var TimeoutTimer = /** @class */ (function () {
    function TimeoutTimer() {
    }
    TimeoutTimer.prototype.timeout = function (timeMS, handler) {
        var _this = this;
        this.reset();
        this._id = window.setTimeout(function () {
            handler();
            _this._id = 0;
        }, timeMS);
    };
    Object.defineProperty(TimeoutTimer.prototype, "isRunning", {
        get: function () {
            return Boolean(this._id);
        },
        enumerable: false,
        configurable: true
    });
    TimeoutTimer.prototype.reset = function () {
        if (!this._id) {
            return;
        }
        clearTimeout(this._id);
        this._id = 0;
    };
    return TimeoutTimer;
}());
export { TimeoutTimer };
