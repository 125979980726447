'use strict';

Entry.playcode = {
  id: '1E.1',
  name: 'playcode',
  url: 'http://www.playcode.kr/product',
  imageName: 'playcode.png',
  title: {
    en: 'playcode',
    ko: '플레이코드'
  },
  setZero: function setZero() {
    if (!Entry.hw.sendQueue.SET) {
      Entry.hw.sendQueue = {
        GET: {},
        SET: {}
      };
    } else {
      var keySet = Object.keys(Entry.hw.sendQueue.SET);
      keySet.forEach(function (key) {
        Entry.hw.sendQueue.SET[key].data = 0;
        Entry.hw.sendQueue.SET[key].time = new Date().getTime();
      });
    }
    Entry.hw.update();
  },
  toByte: function toByte(data) {
    switch (data) {
      case ' ':
        data = 32;
        break;
      case '!':
        data = 33;
        break;
      case '"':
        data = 34;
        break;
      case '#':
        data = 35;
        break;
      case '$':
        data = 36;
        break;
      case '%':
        data = 37;
        break;
      case '&':
        data = 38;
        break;
      case "'":
        data = 39;
        break;
      case '(':
        data = 40;
        break;
      case ')':
        data = 41;
        break;
      case '*':
        data = 42;
        break;
      case '+':
        data = 43;
        break;
      case ',':
        data = 44;
        break;
      case '-':
        data = 45;
        break;
      case '.':
        data = 46;
        break;
      case '/':
        data = 47;
        break;
      case '0':
        data = 48;
        break;
      case '1':
        data = 49;
        break;
      case '2':
        data = 50;
        break;
      case '3':
        data = 51;
        break;
      case '4':
        data = 52;
        break;
      case '5':
        data = 53;
        break;
      case '6':
        data = 54;
        break;
      case '7':
        data = 55;
        break;
      case '8':
        data = 56;
        break;
      case '9':
        data = 57;
        break;
      case ':':
        data = 58;
        break;
      case ';':
        data = 59;
        break;
      case '<':
        data = 60;
        break;
      case '=':
        data = 61;
        break;
      case '>':
        data = 62;
        break;
      case '?':
        data = 63;
        break;
      case '@':
        data = 64;
        break;
      case 'A':
        data = 65;
        break;
      case 'B':
        data = 66;
        break;
      case 'C':
        data = 67;
        break;
      case 'D':
        data = 68;
        break;
      case 'E':
        data = 69;
        break;
      case 'F':
        data = 70;
        break;
      case 'G':
        data = 71;
        break;
      case 'H':
        data = 72;
        break;
      case 'I':
        data = 73;
        break;
      case 'J':
        data = 74;
        break;
      case 'K':
        data = 75;
        break;
      case 'L':
        data = 76;
        break;
      case 'M':
        data = 77;
        break;
      case 'N':
        data = 78;
        break;
      case 'O':
        data = 79;
        break;
      case 'P':
        data = 80;
        break;
      case 'Q':
        data = 81;
        break;
      case 'R':
        data = 82;
        break;
      case 'S':
        data = 83;
        break;
      case 'T':
        data = 84;
        break;
      case 'U':
        data = 85;
        break;
      case 'V':
        data = 86;
        break;
      case 'W':
        data = 87;
        break;
      case 'X':
        data = 88;
        break;
      case 'Y':
        data = 89;
        break;
      case 'Z':
        data = 90;
        break;
      case '[':
        data = 91;
        break;
      case '\\':
        data = 92;
        break;
      case ']':
        data = 93;
        break;
      case '^':
        data = 94;
        break;
      case '_':
        data = 95;
        break;
      case '`':
        data = 96;
        break;
      case 'a':
        data = 97;
        break;
      case 'b':
        data = 98;
        break;
      case 'c':
        data = 99;
        break;
      case 'd':
        data = 100;
        break;
      case 'e':
        data = 101;
        break;
      case 'f':
        data = 102;
        break;
      case 'g':
        data = 103;
        break;
      case 'h':
        data = 104;
        break;
      case 'i':
        data = 105;
        break;
      case 'j':
        data = 106;
        break;
      case 'k':
        data = 107;
        break;
      case 'l':
        data = 108;
        break;
      case 'm':
        data = 109;
        break;
      case 'n':
        data = 110;
        break;
      case 'o':
        data = 111;
        break;
      case 'p':
        data = 112;
        break;
      case 'q':
        data = 113;
        break;
      case 'r':
        data = 114;
        break;
      case 's':
        data = 115;
        break;
      case 't':
        data = 116;
        break;
      case 'u':
        data = 117;
        break;
      case 'v':
        data = 118;
        break;
      case 'w':
        data = 119;
        break;
      case 'x':
        data = 120;
        break;
      case 'y':
        data = 121;
        break;
      case 'z':
        data = 122;
        break;
      case '{':
        data = 123;
        break;
      case '|':
        data = 124;
        break;
      case '}':
        data = 125;
        break;
      case '~':
        data = 126;
        break;
    }
    return data;
  },
  sensorTypes: {
    ALIVE: 0,
    DIGITAL: 1,
    ANALOG: 2,
    PWM: 3,
    SERVO_PIN: 4,
    TONE: 5,
    PULSEIN: 6,
    ULTRASONIC: 7,
    TIMER: 8,
    LIGHT: 9,
    MICROPHONE: 10
  },
  toneTable: {
    '0': 0,
    C: 1,
    CS: 2,
    D: 3,
    DS: 4,
    E: 5,
    F: 6,
    FS: 7,
    G: 8,
    GS: 9,
    A: 10,
    AS: 11,
    B: 12
  },
  toneMap: {
    '1': [33, 65, 131, 262, 523, 1046, 2093, 4186],
    '2': [35, 69, 139, 277, 554, 1109, 2217, 4435],
    '3': [37, 73, 147, 294, 587, 1175, 2349, 4699],
    '4': [39, 78, 156, 311, 622, 1245, 2849, 4978],
    '5': [41, 82, 165, 330, 659, 1319, 2637, 5274],
    '6': [44, 87, 175, 349, 698, 1397, 2794, 5588],
    '7': [46, 92, 185, 370, 740, 1480, 2960, 5920],
    '8': [49, 98, 196, 392, 784, 1568, 3136, 6272],
    '9': [52, 104, 208, 415, 831, 1661, 3322, 6645],
    '10': [55, 110, 220, 440, 880, 1760, 3520, 7040],
    '11': [58, 117, 233, 466, 932, 1865, 3729, 7459],
    '12': [62, 123, 247, 494, 988, 1976, 3951, 7902]
  },
  directionTable: {
    Forward: 0,
    Backward: 1
  },
  highList: ['high', '1', 'on'],
  lowList: ['low', '0', 'off'],
  BlockState: {}
};
Entry.playcode.blockMenuBlocks = [
// playcode Added 2018-01-02
'playcode_get_light_value', 'playcode_get_mic_value', 'playcode_gpio', 'playcode_servo', 'playcode_speed'
// playcode Added 2018-01-02
];

Entry.playcode.getBlocks = function () {
  return {
    //region playcode
    playcode_port_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getField('PORT');
      }
    },
    playcode_first_port_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getField('PORT');
      }
    },
    playcode_get_light_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      template: '빛센서 : %1 포트 값',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }],
      events: {},
      def: {
        params: [{
          type: 'playcode_first_port_list'
        }],
        type: 'playcode_get_light_value'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'playcode_get',
      isNotFor: ['playcode'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.LIGHT;
        if (port[0] === 'A') port = port.substring(1);
        if (!Entry.hw.sendQueue['GET']) {
          Entry.hw.sendQueue['GET'] = {};
        }
        Entry.hw.sendQueue['GET'][Entry.playcode.sensorTypes.LIGHT] = {
          port: [port],
          data: 1,
          time: new Date().getTime()
        };
        return ANALOG;
      },
      syntax: {
        js: [],
        py: ['playcode.get_analog_value(%1)']
      }
    },
    playcode_get_mic_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      template: '마이크센서 : %1 포트 값',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }],
      events: {},
      def: {
        params: [{
          type: 'playcode_first_port_list'
        }],
        type: 'playcode_get_mic_value'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'playcode_get',
      isNotFor: ['playcode'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.MIC;
        if (port[0] === 'A') port = port.substring(1);
        if (!Entry.hw.sendQueue['GET']) {
          Entry.hw.sendQueue['GET'] = {};
        }
        Entry.hw.sendQueue['GET'][Entry.playcode.sensorTypes.MICROPHONE] = {
          port: [port],
          data: 1,
          time: new Date().getTime()
        };
        return ANALOG;
      },
      syntax: {
        js: [],
        py: ['playcode.get_analog_value(%1)']
      }
    },
    playcode_gpio: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic',
      template: 'GPIO : %1포트에 %2 보내기 %3',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.ARDUINO_on, 'on'], [Lang.Blocks.ARDUINO_off, 'off']],
        value: 'on',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'playcode_port_list'
        }, null, null],
        type: 'playcode_gpio'
      },
      paramsKeyMap: {
        PORT: 0,
        OPERATOR: 1
      },
      "class": 'playcode_set',
      isNotFor: ['playcode'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var operator = script.getField('OPERATOR');
        var value = operator == 'on' ? 1 : 0;
        var sq = Entry.hw.sendQueue;
        if (!sq['SET']) {
          sq['SET'] = {};
        }
        sq['SET'][port] = {
          type: Entry.playcode.sensorTypes.DIGITAL,
          data: value,
          time: new Date().getTime()
        };
        Entry.hw.setDigitalPortValue(port, value);
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: ['playcode.playcode_gpio(%1)']
      }
    },
    playcode_servo: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      template: 'Servo : %1포트에 %2 보내기 %3',
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'playcode_first_port_list',
          params: [1]
        }, {
          type: 'number',
          params: ['0']
        }, null],
        type: 'playcode_servo'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'playcode_set',
      isNotFor: ['playcode'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.min(value, 180);
        value = Math.max(value, 0);
        if (!Entry.hw.sendQueue['SET']) {
          Entry.hw.sendQueue['SET'] = {};
        }
        Entry.hw.sendQueue['SET'][port] = {
          type: Entry.playcode.sensorTypes.SERVO_PIN,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      }
    },
    playcode_speed: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      template: 'Speed : %1포트에 %2 보내기 %3',
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'playcode_first_port_list',
          params: [1]
        }, {
          type: 'number',
          params: ['0']
        }, null],
        type: 'playcode_speed'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'playcode_set',
      isNotFor: ['playcode'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.min(value, 255);
        value = Math.max(value, 0);
        if (!Entry.hw.sendQueue['SET']) {
          Entry.hw.sendQueue['SET'] = {};
        }
        Entry.hw.sendQueue['SET'][port] = {
          type: Entry.playcode.sensorTypes.PWM,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      }
    }
    //endregion playcode
  };
};

module.exports = Entry.playcode;