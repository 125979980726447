import PIXIHelper from '../helper/PIXIHelper';
import { Rectangle, SCALE_MODES, MIPMAP_MODES } from 'pixi.js';
import { MaxRectsPacker } from '../../maxrect-packer/maxrects_packer';
import { clog } from '../utils/logs';
var EntryTextureOption = /** @class */ (function () {
    function EntryTextureOption(stageWidth, stageHeight) {
        this._USE_ATLAS = false;
        this.scaleMode = SCALE_MODES.LINEAR;
        this.mipmap = MIPMAP_MODES.OFF;
        this._USE_ATLAS = this._isSpriteSheetEnabled();
        this.GPU_TEX_MAX_SIZE = this.computeMaxTextureSize(4096);
        this._texStageRatio = 1;
        this.texMaxRect = this.getTexRect(stageWidth, stageHeight, this._texStageRatio, this.GPU_TEX_MAX_SIZE);
        this.atlasOption = {
            extrudeSize: 2,
            atlasSize: this.GPU_TEX_MAX_SIZE,
            newPacker: this.newPacker.bind(this),
        };
    }
    Object.defineProperty(EntryTextureOption.prototype, "USE_ATLAS", {
        get: function () {
            return this._USE_ATLAS;
        },
        enumerable: false,
        configurable: true
    });
    EntryTextureOption.prototype.newPacker = function () {
        //https://www.npmjs.com/package/maxrects-packer
        var PADDING = 6; //텍스쳐 사이의 간격.
        var BORDER = 2; //베이스 텍스쳐 테두리 간격
        var MAX = this.atlasOption.atlasSize;
        var OPTION = {
            smart: false,
            pot: true,
            square: false,
        };
        return new MaxRectsPacker(MAX, MAX, BORDER, PADDING, OPTION);
    };
    EntryTextureOption.prototype.getTexRect = function (w, h, ratio, max) {
        return new Rectangle(0, 0, Math.min(Math.round(w * ratio), max), Math.min(Math.round(h * ratio), max));
    };
    EntryTextureOption.prototype.computeMaxTextureSize = function (LIMIT) {
        var canvas = PIXIHelper.getOffScreenCanvas(true);
        // @ts-ignore
        var ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        var size = ctx ? ctx.getParameter(ctx.MAX_TEXTURE_SIZE) : 2048;
        size = Math.min(size, LIMIT);
        clog("Max texture size : ".concat(size));
        return size;
    };
    EntryTextureOption.prototype._isSpriteSheetEnabled = function () {
        var spriteSheetString = 'ss=1';
        var url = window.location.href;
        var query = url.split('?')[1];
        if (!query)
            return false;
        var arr = query.split('&');
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == spriteSheetString)
                return true;
        }
        return false;
    };
    return EntryTextureOption;
}());
export { EntryTextureOption };
