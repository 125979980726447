/*
 *
 */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _DataTable = _interopRequireDefault(require("../../class/DataTable"));
(function (c) {
  var _Entry$STATIC = Entry.STATIC,
    COMMAND_TYPES = _Entry$STATIC.COMMAND_TYPES,
    RECORDABLE = _Entry$STATIC.RECORDABLE;
  c[COMMAND_TYPES.dataTableAddSource] = {
    "do": function _do(table) {
      Entry.playground.selectTable(table);
    },
    state: function state(table) {
      return [table];
    },
    log: function log(table) {
      return [['table', table]];
    },
    recordable: RECORDABLE.SUPPORT,
    validate: false,
    undo: 'dataTableRemoveSource',
    dom: ['playground', 'tableAddButton']
  };
  c[COMMAND_TYPES.dataTableRemoveSource] = {
    "do": function _do() {
      var table = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var index = _DataTable["default"].getIndex(table);
      if (index < 0) {
        console.warn('not found table', table);
        return;
      }
      _DataTable["default"].tables.splice(index, 1);
      var isWorkspace = Entry.type === 'workspace';
      if (isWorkspace) {
        Entry.playground.reloadPlayground();
        Entry.playground.refreshPlayground();
        !_DataTable["default"].tables.length && _DataTable["default"].banAllBlock();
      }
    },
    state: function state(table) {
      return [table];
    },
    log: function log(table) {
      return [['table', table]];
    },
    recordable: RECORDABLE.SUPPORT,
    validate: false,
    undo: 'dataTableAddSource',
    dom: ['playground', 'tableAddButton']
  };
})(Entry.Command);