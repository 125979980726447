var Destroyer = /** @class */ (function () {
    function Destroyer() {
        this._items = [];
    }
    Destroyer.prototype.add = function (item) {
        if (item == this) {
            throw new Error("Can't add self.");
        }
        this._items.push(item);
    };
    Destroyer.prototype.remove = function (item) {
        var i = this._items.indexOf(item);
        if (i == -1) {
            return;
        }
        this._items.splice(i, 1);
    };
    Destroyer.prototype.destroy = function () {
        var LEN = this._items.length;
        for (var i = 0; i < LEN; i++) {
            this._items[i].destroy();
        }
        this._items = [];
    };
    return Destroyer;
}());
export { Destroyer };
