var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Visible from '@egjs/visible';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import head from 'lodash/head';
import find from 'lodash/find';
import ModelClass from '../core/modelClass';
import Hammer from 'hammerjs';
var VARIABLE = 'variable';
var HW = 'arduino';
var practicalCourseCategoryList = ['hw_motor', 'hw_melody', 'hw_sensor', 'hw_led', 'hw_robot'];
var splitterHPadding = EntryStatic.splitterHPadding || 20;
var BETA_LIST = [];
var BlockMenu = /** @class */ (function (_super) {
    __extends(BlockMenu, _super);
    function BlockMenu(dom, align, categoryData, scroll) {
        var _this = _super.call(this, {
            code: null,
            dragBlock: null,
            closeBlock: null,
            selectedBlockView: null,
        }, false) || this;
        _this.visible = true;
        _this.hwCodeOutdated = false;
        _this._svgId = "blockMenu".concat(Date.now());
        _this.suffix = 'blockMenu';
        _this._setDynamic = debounce(function (blocks) {
            if (blocks === void 0) { blocks = []; }
            if (!_this._isOn()) {
                return;
            }
            var data;
            _this._dynamicThreads = blocks
                .map(function (block) {
                if (typeof block === 'string') {
                    return block;
                }
                else if (block.constructor === Array) {
                    var keyName = block[0];
                    if (!_this.getThreadByBlockKey(keyName)) {
                        data = block[1];
                        data.category = 'extra';
                        _this._createThread([data], undefined, keyName);
                    }
                    return keyName;
                }
            })
                .filter(identity);
            _this._selectDynamic = true;
            _this.selectMenu(undefined, true);
        }, 150);
        _this.reDraw = debounce(function () {
            if (!_this._isOn()) {
                return;
            }
            var selector = _this.lastSelector;
            if (_this._selectDynamic) {
                selector = undefined;
            }
            _this.selectMenu(selector, true);
            _this._getSortedBlocks()
                .shift()
                .forEach(function (_a) {
                var view = _a.view;
                return view.reDraw();
            });
        }, 100);
        var hardwareEnable = Entry.hardwareEnable;
        _this._dSelectMenu = debounce(_this.selectMenu, 0);
        _this._align = align || 'CENTER';
        _this._svgWidth = 254;
        _this._scroll = scroll !== undefined ? scroll : false;
        _this._bannedClass = [];
        _this._categories = [];
        _this._dynamicThreads = [];
        _this._setDynamicTimer = null;
        _this._renderedCategories = {};
        _this._threadsMap = {};
        var $dom = typeof dom === 'string' ? $("#".concat(dom)) : $(dom);
        if ($dom.prop('tagName') !== 'DIV') {
            throw new Error('Dom is not div element');
        }
        _this.view = $dom;
        _this._clearCategory();
        // hardwareEnable 인 경우, 하드웨어 카테고리와 실과형 로봇카테고리 전부를 제외한다.
        _this._categoryData = remove(categoryData, function (_a) {
            var category = _a.category;
            return hardwareEnable || __spreadArray(__spreadArray([], __read(practicalCourseCategoryList), false), [HW], false).indexOf(category) <= -1;
        });
        _this._generateView(_this._categoryData);
        _this._splitters = [];
        _this.svg = Entry.SVG(_this._svgId);
        Entry.Utils.addFilters(_this.svg, _this.suffix);
        var pattern = Entry.Utils.addBlockPattern(_this.svg, _this.suffix).pattern;
        _this.pattern = pattern;
        _this.svgGroup = _this.svg.elem('g');
        _this.svgThreadGroup = _this.svgGroup.elem('g');
        _this.svgThreadGroup.board = _this;
        _this.svgBlockGroup = _this.svgGroup.elem('g');
        _this.svgBlockGroup.board = _this;
        _this.svgCommentGroup = _this.svgGroup.elem('g');
        _this.svgCommentGroup.board = _this;
        _this.changeEvent = new Entry.Event(_this);
        _this.categoryDoneEvent = new Entry.Event(_this);
        _this.observe(_this, '_handleDragBlock', ['dragBlock']);
        _this.changeCode(new Entry.Code([]));
        _this._categoryData && _this._generateCategoryCodes();
        if (_this._scroll) {
            _this._scroller = new Entry.BlockMenuScroller(_this);
            _this._addControl($dom.find('.blockMenuContainer'));
        }
        if (_this.code && Entry.keyPressed) {
            Entry.keyPressed.attach(_this, _this._captureKeyEvent);
        }
        if (Entry.windowResized) {
            Entry.windowResized.attach(_this, debounce(_this.updateOffset, 200));
        }
        Entry.addEventListener('setBlockMenuDynamic', function () {
            this._setDynamicTimer = this._setDynamic.apply(this, arguments);
        }.bind(_this));
        Entry.addEventListener('cancelBlockMenuDynamic', _this._cancelDynamic.bind(_this));
        Entry.addEventListener('fontLoaded', _this.reDraw.bind(_this));
        _this.initBlockDoubleClickEvent();
        return _this;
    }
    BlockMenu.prototype.initBlockDoubleClickEvent = function () {
        var _this = this;
        var hammer = new Hammer(this.code.board.svgBlockGroup);
        hammer.on('doubletap', function (e) {
            var _a;
            var blockId = e.target.getAttribute('blockId');
            if (!blockId) {
                return;
            }
            var targetBlock = _this.code.findById(blockId);
            if (targetBlock.type.startsWith('func_') ||
                ['basic_text', 'clickable_text', 'basic_button'].includes((_a = targetBlock === null || targetBlock === void 0 ? void 0 : targetBlock._schema) === null || _a === void 0 ? void 0 : _a.skeleton)) {
                return;
            }
            var _b = targetBlock.view, width = _b.width, height = _b.height;
            var _c = _this.workspace.board, scale = _c.scale, svg = _c.svg;
            var _d = svg.getBoundingClientRect(), left = _d.left, top = _d.top, right = _d.right, bottom = _d.bottom;
            var boardCenterX = (Math.min(window.innerWidth, right) - left) / 2;
            var boardCenterY = (Math.min(window.innerHeight, bottom) - top) / 2;
            var block = targetBlock.toJSON(true);
            block.x = boardCenterX - (width * scale) / 2;
            block.y = boardCenterY - (height * scale) / 2;
            Entry.do('addThread', [block]);
        });
    };
    BlockMenu.prototype.foldBlockMenu = function () {
        var playground = Entry.playground;
        if (!playground || playground.resizing) {
            return;
        }
        if (this._scroller) {
            this._scroller.setOpacity(0);
        }
        var widthBackup = this.widthBackup;
        if (widthBackup) {
            $(this.blockMenuWrapper).css('width', widthBackup);
        }
        delete this.widthBackup;
    };
    BlockMenu.prototype.changeCode = function (code) {
        var _this = this;
        var _a, _b;
        if (code instanceof Array) {
            code = new Entry.Code(code);
        }
        if (!(code instanceof Entry.Code)) {
            return console.error('You must inject code instance');
        }
        // this.codeListener?.destory();
        (_b = (_a = this.codeListener) === null || _a === void 0 ? void 0 : _a.destroy) === null || _b === void 0 ? void 0 : _b.call(_a);
        this.set({ code: code });
        this.codeListener = this.code.changeEvent.attach(this, function () {
            _this.changeEvent.notify();
        });
        code.createView(this);
        this.align();
    };
    BlockMenu.prototype.bindCodeView = function (codeView) {
        this.svgBlockGroup.remove();
        this.svgThreadGroup.remove();
        this.svgBlockGroup = codeView.svgBlockGroup;
        this.svgThreadGroup = codeView.svgThreadGroup;
        this.svgGroup.appendChild(this.svgThreadGroup);
        this.svgGroup.appendChild(this.svgBlockGroup);
        if (this._scroller) {
            this.svgGroup.appendChild(this._scroller.svgGroup);
        }
    };
    BlockMenu.prototype.align = function () {
        var _this = this;
        var code = this.code;
        if (!(this._isOn() && code)) {
            return;
        }
        this._clearSplitters();
        var vPadding = 15;
        var marginFromTop = 10;
        var hPadding = this._align === 'LEFT' ? 10 : this.svgDom.width() / 2;
        var pastClass;
        var blocks = this._getSortedBlocks();
        var _a = __read(blocks, 2), _b = _a[0], visibles = _b === void 0 ? [] : _b, _c = _a[1], inVisibles = _c === void 0 ? [] : _c;
        inVisibles.forEach(function (_a) {
            var _b = _a === void 0 ? {} : _a, blockView = _b.view;
            if (!blockView) {
                return;
            }
            blockView.set({ display: false });
            blockView.detach();
        });
        var lastSelector = this.lastSelector;
        var shouldReDraw = !this._renderedCategories[lastSelector];
        visibles.forEach(function (_a, index) {
            var _b, _c, _d, _e;
            var _f = _a === void 0 ? {} : _a, blockView = _f.view, type = _f.type;
            if (!blockView) {
                return;
            }
            blockView.attach();
            blockView.set({ display: true });
            if (shouldReDraw || (((_b = Entry === null || Entry === void 0 ? void 0 : Entry.Func) === null || _b === void 0 ? void 0 : _b.isEdit) && ((_e = (_d = (_c = blockView === null || blockView === void 0 ? void 0 : blockView.block) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.params) === null || _e === void 0 ? void 0 : _e.length))) {
                blockView.reDraw();
            }
            if (Entry.block[type]) {
                var className = Entry.block[type].class;
                if (pastClass && pastClass !== className) {
                    _this._createSplitter(marginFromTop);
                    marginFromTop += vPadding;
                }
                pastClass = className;
            }
            var left = hPadding - blockView.offsetX;
            if (_this._align === 'CENTER') {
                left -= blockView.width / 2;
            }
            marginFromTop -= blockView.offsetY;
            blockView.moveTo(left, marginFromTop, false);
            if (index > 0) {
                marginFromTop += blockView.marginBottom || 0;
            }
            marginFromTop += blockView.height + vPadding;
        });
        this.updateSplitters();
        if (this.workspace) {
            var mode = this.workspace.getMode();
            switch (mode) {
                case Entry.Workspace.MODE_BOARD:
                case Entry.Workspace.MODE_OVERLAYBOARD:
                    this.renderBlock(blocks);
                    break;
                case Entry.Workspace.MODE_VIMBOARD:
                    this.renderText(blocks);
                    break;
                default:
                    this.renderBlock(blocks);
            }
        }
        if (lastSelector !== 'func') {
            this._renderedCategories[lastSelector] = true;
        }
        this.changeEvent.notify();
    };
    BlockMenu.prototype.cloneToGlobal = function (e) {
        var blockView = this.dragBlock;
        if (this._boardBlockView || blockView === null) {
            if (this.widthBackup) {
                this.foldBlockMenu();
            }
            return;
        }
        var GS = Entry.GlobalSvg;
        var workspace = this.workspace;
        var workspaceMode = workspace.getMode();
        var _a = Entry.Workspace, MODE_BOARD = _a.MODE_BOARD, MODE_OVERLAYBOARD = _a.MODE_OVERLAYBOARD;
        var svgWidth = this._svgWidth;
        var board = workspace.selectedBoard;
        var _b = blockView.mouseDownCoordinate || {}, _c = _b.x, x = _c === void 0 ? 0 : _c, _d = _b.y, y = _d === void 0 ? 0 : _d;
        var dx = e.pageX - x;
        var dy = e.pageY - y;
        if (board && (workspaceMode === MODE_BOARD || workspaceMode === MODE_OVERLAYBOARD)) {
            if (!board.code) {
                if (Entry.toast && !(this.objectAlert && Entry.toast.isOpen(this.objectAlert))) {
                    this.objectAlert = Entry.toast.alert(Lang.Workspace.add_object_alert, Lang.Workspace.add_object_alert_msg);
                }
                if (this.selectedBlockView) {
                    this.selectedBlockView.removeSelected();
                    this.set({
                        selectedBlockView: null,
                        dragBlock: null,
                    });
                }
                return;
            }
            var block = blockView.block;
            var currentThread = block.getThread();
            if (block && currentThread) {
                var distance = this.offset().top - board.offset().top - $(window).scrollTop();
                var datum = currentThread.toJSON(true);
                var firstBlock = head(datum);
                firstBlock.x = firstBlock.x - svgWidth + (dx || 0);
                firstBlock.y = firstBlock.y + distance + (dy || 0);
                var newBlock = Entry.do('addThreadFromBlockMenu', datum).value.getFirstBlock();
                var newBlockView = newBlock === null || newBlock === void 0 ? void 0 : newBlock.view;
                // if some error occured
                // blockView is not exist
                if (!newBlockView) {
                    newBlock === null || newBlock === void 0 ? void 0 : newBlock.destory();
                    return;
                }
                this._boardBlockView = newBlockView;
                newBlockView.onMouseDown.call(newBlockView, e);
                if (newBlockView.dragInstance) {
                    newBlockView.dragInstance.set({
                        isNew: true,
                    });
                }
                GS.setView(newBlockView, workspaceMode);
            }
            else {
            }
        }
        else {
            if (GS.setView(blockView, workspaceMode)) {
                GS.adjust(dx, dy);
                GS.addControl(e);
            }
        }
    };
    BlockMenu.prototype.terminateDrag = function () {
        var boardBlockView = this._boardBlockView;
        if (!boardBlockView) {
            return;
        }
        this._boardBlockView = null;
        //board block should be removed below the amount of range
        var _a = Entry.GlobalSvg, left = _a.left, width = _a.width;
        return left < boardBlockView.getBoard().offset().left - width / 2;
    };
    BlockMenu.prototype.getCode = function () {
        return this.code;
    };
    BlockMenu.prototype.setSelectedBlock = function (blockView) {
        var _a;
        (_a = this.selectedBlockView) === null || _a === void 0 ? void 0 : _a.removeSelected();
        if (blockView instanceof Entry.BlockView) {
            blockView.addSelected();
        }
        else {
            blockView = null;
        }
        this.set({ selectedBlockView: blockView });
    };
    BlockMenu.prototype.hide = function () {
        this.view.addClass('entryRemove');
    };
    BlockMenu.prototype.show = function () {
        this.view.removeClass('entryRemove');
    };
    BlockMenu.prototype.renderText = function (blocks) {
        var _this = this;
        if (!this._isOn()) {
            return;
        }
        blocks = blocks || this._getSortedBlocks();
        var targetMode = Entry.BlockView.RENDER_MODE_TEXT;
        blocks[0].forEach(function (block) {
            if (targetMode === block.view.renderMode) {
                return;
            }
            var thread = block.getThread();
            var view = thread.view;
            if (view) {
                view.renderText();
            }
            else {
                thread.createView(_this, Entry.BlockView.RENDER_MODE_TEXT);
            }
        });
        return blocks;
    };
    BlockMenu.prototype.renderBlock = function (blocks) {
        var _this = this;
        if (!this._isOn()) {
            return;
        }
        blocks = blocks || this._getSortedBlocks();
        var targetMode = Entry.BlockView.RENDER_MODE_BLOCK;
        blocks[0].forEach(function (block) {
            if (targetMode === block.view.renderMode) {
                return;
            }
            var thread = block.getThread();
            var view = thread.view;
            if (view) {
                view.renderBlock();
            }
            else {
                thread.createView(_this, Entry.BlockView.RENDER_MODE_BLOCK);
            }
        });
        return blocks;
    };
    BlockMenu.prototype._createSplitter = function (topPos) {
        var _a = (EntryStatic.colorSet || {}).common, common = _a === void 0 ? {} : _a;
        this._splitters.push(this.svgBlockGroup.elem('line', {
            x1: splitterHPadding,
            y1: topPos,
            x2: this._svgWidth - splitterHPadding,
            y2: topPos,
            stroke: common.SPLITTER || '#AAC5D5',
        }));
    };
    BlockMenu.prototype.updateSplitters = function (y) {
        if (y === void 0) { y = 0; }
        var xDest = this._svgWidth - splitterHPadding;
        var yDest;
        this._splitters.forEach(function (line) {
            yDest = parseFloat(line.getAttribute('y1')) + y;
            line.attr({
                x2: xDest,
                y1: yDest,
                y2: yDest,
            });
        });
    };
    BlockMenu.prototype.setMenu = function (doNotAlign) {
        var _this = this;
        if (!this.hasCategory()) {
            return;
        }
        var sorted = [[], []];
        this._categoryData.forEach(function (_a) {
            var category = _a.category, threads = _a.blocks;
            if (category === 'func') {
                var funcThreads = _this.code
                    .getThreadsByCategory('func')
                    .map(function (thread) { return thread.getFirstBlock().type; });
                threads = funcThreads.length ? funcThreads : threads;
            }
            var inVisible = threads.reduce(function (count, type) { return (_this.checkBanClass(Entry.block[type]) ? count - 1 : count); }, threads.length) === 0;
            var elem = _this._categoryElems[category];
            if (inVisible) {
                sorted[1].push(elem);
            }
            else {
                sorted[0].push(elem);
            }
        });
        requestAnimationFrame(function () {
            //visible
            sorted[0].forEach(function (elem) { return elem.removeClass('entryRemove'); });
            //invisible
            sorted[1].forEach(function (elem) { return elem.addClass('entryRemove'); });
            _this.selectMenu(0, true, doNotAlign);
        });
    };
    BlockMenu.prototype.toggleBlockMenu = function () {
        var board = this.workspace.board;
        var boardView = board.view;
        if (!boardView.hasClass('folding')) {
            boardView.addClass('folding');
            Entry.playground.resizeHandle_.addClass('highIndex');
            Entry.playground.resizeHandle_.addClass('folding');
            Entry.playground.resizeHandle_.removeClass('unfolding');
            Entry.playground.hideTabs();
            this.visible = false;
        }
        else {
            if (!this.visible) {
                boardView.addClass('foldOut');
                Entry.playground.showTabs();
            }
            boardView.removeClass('folding');
            Entry.playground.resizeHandle_.addClass('highIndex');
            Entry.playground.resizeHandle_.addClass('unfolding');
            Entry.playground.resizeHandle_.removeClass('folding');
            this.visible = true;
        }
        Entry.bindAnimationCallbackOnce(boardView, function () {
            board.scroller.resizeScrollBar.call(board.scroller);
            boardView.removeClass('foldOut');
            Entry.playground.resizeHandle_.removeClass('highIndex');
            Entry.windowResized.notify();
        });
        this.align();
    };
    BlockMenu.prototype.selectMenu = function (selector, doNotFold, doNotAlign) {
        if (Entry.disposeEvent) {
            Entry.disposeEvent.notify();
        }
        if (!this._isOn() || !this._categoryData) {
            return;
        }
        var oldView = this._selectedCategoryView;
        var name = this._convertSelector(selector);
        if (selector !== undefined && !name) {
            this.align();
            return;
        }
        if (name) {
            this.lastSelector = name;
        }
        switch (name) {
            case VARIABLE:
                Entry.playground.checkVariables();
                break;
            case HW:
                this._generateHwCode();
                this.align();
                break;
        }
        var elem = this._categoryElems[name];
        var animate = false;
        var board = this.workspace.board;
        var boardView = board.view;
        var handle = Entry.playground.resizeHandle_;
        var className = 'entrySelectedCategory';
        var className2 = 'entryUnSelectedCategory';
        if (oldView) {
            oldView.removeClass(className);
            oldView.addClass(className2);
        }
        if (elem === oldView && !(doNotFold || !this.hasCategory())) {
            elem.removeClass(className);
            elem.addClass(className2);
        }
        this._selectedCategoryView = elem;
        if (elem) {
            elem.removeClass(className2);
            elem.addClass(className);
        }
        doNotAlign !== true && this.align();
    };
    BlockMenu.prototype.banCategory = function (categoryName) {
        var categoryElem = this._categoryElems[categoryName];
        if (!categoryElem) {
            return;
        }
        categoryElem.addClass('entryRemoveCategory');
        if (this.lastSelector === categoryName) {
            this._dSelectMenu(this.firstSelector, true);
        }
    };
    BlockMenu.prototype.unbanCategory = function (category) {
        var _this = this;
        var blockList = this._getCategoryBlocks(category);
        if (!blockList) {
            return;
        }
        var count = blockList.reduce(function (count, block) { return (_this.checkBanClass(Entry.block[block]) ? count - 1 : count); }, blockList.length);
        var categoryElem = this._categoryElems[category];
        if (categoryElem && count > 0) {
            categoryElem.removeClass('entryRemoveCategory');
            categoryElem.removeClass('entryRemove');
        }
    };
    BlockMenu.prototype.banClass = function (className, doNotAlign) {
        var banned = this._bannedClass;
        if (!includes(banned, className)) {
            banned.push(className);
            doNotAlign !== true && this.align();
        }
    };
    BlockMenu.prototype.unbanClass = function (className, doNotAlign) {
        var banned = this._bannedClass;
        var index = banned.indexOf(className);
        if (index > -1) {
            banned.splice(index, 1);
            doNotAlign !== true && this.align();
        }
    };
    BlockMenu.prototype.checkBanClass = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.isNotFor, isNotFor = _c === void 0 ? [] : _c;
        if (isEmpty(isNotFor)) {
            return false;
        }
        var banned = this._bannedClass;
        var filteredIsNotFor = isNotFor.filter(identity);
        for (var i = 0; i < filteredIsNotFor.length; i++) {
            if (!includes(banned, filteredIsNotFor[i])) {
                return false;
            }
        }
        return true;
    };
    BlockMenu.prototype.checkCategory = function (blockInfo) {
        if (!this.hasCategory() || !blockInfo) {
            return;
        }
        if (!this.lastSelector || this._selectDynamic) {
            return true;
        }
        return !includes(blockInfo.isFor || [], "category_".concat(this.lastSelector));
    };
    /**
     * 특정 카테고리에 특정 블록명을 추가한다.
     * 카테고리가 존재하지 않거나 블록명이 이미 등록된 경우 스킵한다.
     * Entry.block 목록에 실제 데이터가 있는지, blockMenu 의 그리기 갱신이 필요한지는 상관하지 않는다.
     * @param categoryName {string}
     * @param blockName {string}
     */
    BlockMenu.prototype.addCategoryData = function (categoryName, blockName) {
        var selectedCategory = this._categoryData.find(function (element) { return element.category === categoryName; });
        if ((selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.blocks.indexOf(blockName)) === -1) {
            selectedCategory.blocks.push(blockName);
        }
        if (!this.getThreadByBlockKey(blockName)) {
            var threadDatum = this._buildCategoryCodes([blockName], categoryName);
            this._createThread(threadDatum[0]);
        }
    };
    BlockMenu.prototype.destroy = function () {
        this.categoryIndicatorVisible.off();
        this._categoryCol.off();
        $(document).off('.blockMenuScroll');
    };
    BlockMenu.prototype.removeControl = function (eventType) {
        this.svgDom.off(eventType);
    };
    BlockMenu.prototype.onMouseMove = function (e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (Entry.isMobile()) {
            this._scroller.setOpacity(0.8);
        }
        var pageY = Entry.Utils.convertMouseEvent(e).pageY;
        var dragInstance = this.dragInstance;
        this._scroller.scroll(-pageY + dragInstance.offsetY);
        dragInstance.set({ offsetY: pageY });
    };
    BlockMenu.prototype.onMouseUp = function (e) {
        if (Entry.isMobile()) {
            this._scroller.setOpacity(0);
        }
        if (e.which == 2) {
            console.log('mouse wheel click disabled');
            return;
        }
        if (e.button != 1) {
            $(document).unbind('.blockMenu');
            delete this.dragInstance;
        }
    };
    BlockMenu.prototype.onMouseDown = function (e) {
        var _a;
        if (e.preventDefault) {
            e.preventDefault();
        }
        if (e.which == 2) {
            console.log('mouse wheel click disabled');
            return;
        }
        if (e.button === 0 || ((_a = e.originalEvent) === null || _a === void 0 ? void 0 : _a.touches)) {
            var mouseEvent = Entry.Utils.convertMouseEvent(e);
            if (Entry.documentMousedown) {
                Entry.documentMousedown.notify(mouseEvent);
            }
            var doc = $(document);
            doc.bind('mousemove.blockMenu touchmove.blockMenu', this.onMouseMove.bind(this));
            doc.bind('mouseup.blockMenu touchend.blockMenu', this.onMouseUp.bind(this));
            this.dragInstance = new Entry.DragInstance({
                startY: mouseEvent.pageY,
                offsetY: mouseEvent.pageY,
            });
        }
    };
    BlockMenu.prototype.dominate = function (_a) {
        var svgGroup = _a.view.svgGroup;
        this.svgBlockGroup.appendChild(svgGroup);
    };
    BlockMenu.prototype._handleDragBlock = function () {
        this._boardBlockView = null;
        if (this._scroller) {
            this._scroller.setOpacity(0);
        }
    };
    BlockMenu.prototype._handleBoardDragBlock = function () {
        var _a, _b;
        this._toggleTrashcan(!!((_b = (_a = this.workspace) === null || _a === void 0 ? void 0 : _a.board) === null || _b === void 0 ? void 0 : _b.dragBlock));
    };
    BlockMenu.prototype._toggleTrashcan = function (visible) {
        var _a;
        (_a = this.blockMenuWrapperForTrashcan) === null || _a === void 0 ? void 0 : _a.toggleClass('entryRemove', !visible);
    };
    BlockMenu.prototype.enablePattern = function () {
        this.pattern.removeAttribute('style');
    };
    BlockMenu.prototype.disablePattern = function () {
        this.pattern.attr({ style: 'display: none' });
    };
    /**
     * lms, entry-web 에서 사용 중
     */
    BlockMenu.prototype.setCategoryData = function (data) {
        this._clearCategory();
        this._categoryData = data;
        this._generateCategoryView(data);
        this._generateCategoryCodes();
        this.setMenu();
        Entry.resizeElement();
    };
    /**
     * lms 에서 사용 중
     */
    BlockMenu.prototype.setNoCategoryData = function (data) {
        this._clearCategory();
        Entry.resizeElement();
        this.changeCode(data);
        this.categoryDoneEvent.notify();
    };
    BlockMenu.prototype.makeScrollIndicator = function () {
        var _this = this;
        ['append', 'prepend'].forEach(function (action) {
            var point = Entry.Dom('li', {
                class: "visiblePoint ".concat(action),
            });
            var indicator = Entry.Dom('a', {
                class: "scrollIndicator ".concat(action),
            });
            indicator.bindOnClick(function () {
                point[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            });
            point.attr('data-action', action);
            indicator.attr('data-action', action);
            //@ts-ignore
            _this._categoryCol[action](point);
            //@ts-ignore
            _this._categoryCol[action](indicator);
        });
        this.categoryIndicatorVisible = new Visible('.entryCategoryListWorkspace', {
            targetClass: 'visiblePoint',
            expandSize: 0,
        });
        this.categoryIndicatorVisible.on('change', function (e) {
            e.visible.forEach(function (dom) {
                var dataset = dom.dataset;
                var action = dataset.action;
                $(".scrollIndicator.".concat(action)).css('display', 'none');
            });
            e.invisible.forEach(function (dom) {
                var dataset = dom.dataset;
                var action = dataset.action;
                $(".scrollIndicator.".concat(action)).css('display', 'block');
            });
        });
        this._categoryCol.on('scroll', debounce(function () {
            _this.categoryIndicatorVisible.check();
        }, 100));
        setTimeout(function () {
            _this.categoryIndicatorVisible.check();
        }, 0);
        if (Entry.windowResized) {
            Entry.windowResized.attach(this, function () {
                _this.categoryIndicatorVisible.check();
            });
        }
        $(document).on('visibilitychange.blockMenuScroll', function (e) {
            if (document.visibilityState === 'visible') {
                requestAnimationFrame(function () {
                    _this.categoryIndicatorVisible.check();
                });
            }
        });
    };
    BlockMenu.prototype.updateOffset = function () {
        this._offset = this.svgDom.offset();
    };
    BlockMenu.prototype.offset = function () {
        var _a = this._offset || {}, _b = _a.top, top = _b === void 0 ? 0 : _b, _c = _a.left, left = _c === void 0 ? 0 : _c;
        if (top === 0 && left === 0) {
            this.updateOffset();
        }
        return this._offset;
    };
    BlockMenu.prototype._generateHwCode = function (shouldHide) {
        var _this = this;
        var threads = this.code.getThreadsByCategory(HW);
        if (!(this._categoryData && this.shouldGenerateHwCode(threads))) {
            return;
        }
        threads.forEach(function (t) {
            _this._deleteThreadsMap(t);
            t.destroy();
        });
        var blocks = this._getCategoryBlocks(HW);
        if (isEmpty(blocks)) {
            return;
        }
        this._buildCategoryCodes(blocks.filter(function (b) { return !_this.checkBanClass(Entry.block[b]); }), HW).forEach(function (t) {
            if (shouldHide) {
                t[0].x = -99999;
            }
            _this._createThread(t);
            delete t[0].x;
        });
        this.hwCodeOutdated = false;
        Entry.dispatchEvent('hwCodeGenerated');
    };
    /**
     * Ntry systems/entryPlayground.js#loadConfig 에서 사용됨
     * 그 외에는 쓸모없음
     * @deprecated
     */
    BlockMenu.prototype.setAlign = function (align) {
        this._align = align || 'CENTER';
    };
    BlockMenu.prototype._cancelDynamic = function (fromElement, cb) {
        if (this._setDynamicTimer) {
            clearTimeout(this._setDynamicTimer);
            this._setDynamicTimer = null;
        }
        this._selectDynamic = false;
        this._dynamicThreads = [];
        if (fromElement !== true) {
            this.selectMenu(this.lastSelector, true);
        }
        cb && cb();
    };
    BlockMenu.prototype.deleteRendered = function (name) {
        delete this._renderedCategories[name];
    };
    BlockMenu.prototype.clearRendered = function () {
        this._renderedCategories = {};
    };
    BlockMenu.prototype.hasCategory = function () {
        return !!this._categoryData;
    };
    BlockMenu.prototype.getDom = function (query) {
        if (isEmpty(query)) {
            return;
        }
        if (query[0] === 'category') {
            return this._categoryElems[query[1]];
        }
        else {
            var _a = query[0][0], type = _a.type, _b = _a.params, params = _b === void 0 ? [] : _b;
            this.align();
            this.scrollToType(type, params);
            return this.getSvgDomByType(type, params);
        }
    };
    BlockMenu.prototype.getSvgDomByType = function (blockType, params) {
        var thread = find(this.code.getThreads(), function (thread) {
            if (!thread) {
                return;
            }
            var _a = thread.getFirstBlock(), type = _a.type, threadParams = _a.params;
            var option = true;
            if (blockType === 'calc_basic' ||
                blockType === 'boolean_basic_operator' ||
                blockType === 'boolean_and_or') {
                option = type === blockType && threadParams[1] === params[1];
            }
            return type === blockType && option;
        });
        if (!thread) {
            return;
        }
        return thread.getFirstBlock().view.svgGroup;
    };
    BlockMenu.prototype.scrollToType = function (type, params) {
        if (!type) {
            return;
        }
        var block = head(this.code.getBlockList(false, type));
        if (!block) {
            return;
        }
        this.hasCategory() && this.selectMenu(block.category, true);
        if (isOverFlow(this.getSvgDomByType(type, params).getBoundingClientRect())) {
            this._scroller.scrollByPx(block.view.y - 20);
        }
        function isOverFlow(_a) {
            var bottom = _a.bottom;
            return bottom > $(window).height() - 10;
        }
    };
    BlockMenu.prototype.shouldGenerateHwCode = function (threads) {
        return this.hwCodeOutdated || threads.length === 0;
    };
    BlockMenu.prototype.getThreadByBlockKey = function (key) {
        return this._threadsMap[key];
    };
    BlockMenu.prototype._buildCategoryCodes = function (blocks, category) {
        return blocks.reduce(function (threads, type) {
            var block = Entry.block[type];
            if (!block || !block.def) {
                return __spreadArray(__spreadArray([], __read(threads), false), [[{ type: type, category: category }]], false);
            }
            else {
                return (block.defs || [block.def]).reduce(function (threads, d) { return __spreadArray(__spreadArray([], __read(threads), false), [[Object.assign(d, { category: category })]], false); }, threads);
            }
        }, []);
    };
    BlockMenu.prototype.enableTrashcan = function () {
        var _this = this;
        var _a, _b;
        this.blockMenuWrapperForTrashcan = Entry.Dom('div', {
            class: 'blockMenuWrapper blockMenuTrashcan entryRemove',
            parent: this.blockMenuContainer,
        })
            .on('pointerenter', function () { return _this.blockMenuWrapperForTrashcan.addClass('open'); })
            .on('pointerleave', function () { return _this.blockMenuWrapperForTrashcan.removeClass('open'); });
        Entry.Dom('span')
            .text(Lang.Workspace.drag_to_remove)
            .appendTo(this.blockMenuWrapperForTrashcan);
        (_b = (_a = this.workspace) === null || _a === void 0 ? void 0 : _a.board) === null || _b === void 0 ? void 0 : _b.observe(this, '_handleBoardDragBlock', ['dragBlock']);
    };
    BlockMenu.prototype.changeTypeThreadByBlockKey = function (key) {
        var _a;
        (_a = this.getThreadByBlockKey(key)) === null || _a === void 0 ? void 0 : _a.getFirstBlock().changeType();
    };
    BlockMenu.prototype._generateView = function (categoryData) {
        var _this = this;
        categoryData && this._generateCategoryView(categoryData);
        this.blockMenuContainer = Entry.Dom('div', {
            class: 'blockMenuContainer',
            parent: this.view,
        }).css({
            position: 'relative',
        });
        Entry.Utils.disableContextmenu(this.blockMenuContainer);
        this.blockMenuWrapper = Entry.Dom('div', {
            class: 'blockMenuWrapper',
            parent: this.blockMenuContainer,
        });
        this.svgDom = Entry.Dom($(
        // eslint-disable-next-line max-len
        "<svg id=\"".concat(this._svgId, "\" class=\"blockMenu\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"></svg>")), { parent: this.blockMenuWrapper });
        this.svgDom.mouseenter(function () {
            var _a;
            (_a = _this._scroller) === null || _a === void 0 ? void 0 : _a.setOpacity(0.8);
            var selectedBlockView = _this.workspace.selectedBlockView;
            if (!Entry.playground ||
                Entry.playground.resizing ||
                (selectedBlockView === null || selectedBlockView === void 0 ? void 0 : selectedBlockView.dragMode) === Entry.DRAG_MODE_DRAG ||
                Entry.GlobalSvg.isShow) {
                return;
            }
            var bBox = _this.svgGroup.getBBox();
            var adjust = _this.hasCategory() ? 64 : 0;
            var expandWidth = bBox.width + bBox.x + adjust + 2;
            var menuWidth = 319;
            if (expandWidth > menuWidth) {
                _this.widthBackup = menuWidth - adjust - 2;
                $(_this.blockMenuWrapper).css('width', expandWidth - adjust);
            }
        });
        this.svgDom.mouseleave(function () {
            _this.foldBlockMenu();
        });
        Entry.Utils.bindBlockViewHoverEvent(this, this.svgDom);
        $(window).scroll(this.updateOffset.bind(this));
    };
    BlockMenu.prototype._clearSplitters = function () {
        while (this._splitters.length) {
            this._splitters.pop().remove();
        }
    };
    BlockMenu.prototype._convertSelector = function (selector) {
        if (!Entry.Utils.isNumber(selector)) {
            return selector;
        }
        var selectorNumber = Number(selector);
        var categories = this._categories;
        var elems = this._categoryElems;
        for (var i = 0; i < categories.length; i++) {
            var key = categories[i];
            var visible = !elems[key].hasClass('entryRemove');
            if (visible) {
                if (selectorNumber-- === 0) {
                    return key;
                }
            }
        }
    };
    BlockMenu.prototype._generateCategoryCodes = function (elems) {
        var _this = this;
        var elemKeys;
        if (!elems) {
            this.view.addClass('init');
            elemKeys = Object.keys(this._categoryElems);
        }
        else {
            elemKeys = elems;
        }
        if (isEmpty(elemKeys)) {
            return;
        }
        var key = elemKeys.shift();
        if (key !== HW) {
            this._generateCategoryCode(key);
        }
        else {
            this._generateHwCode(true);
        }
        if (elemKeys.length) {
            this._generateCodesTimer = setTimeout(function () { return _this._generateCategoryCodes(elemKeys); }, 0);
        }
        else {
            this._generateCodesTimer = null;
            this.view.removeClass('init');
            this.align();
            this.categoryDoneEvent.notify();
        }
    };
    BlockMenu.prototype._generateCategoryCode = function (category) {
        var _this = this;
        if (!this._categoryData) {
            return;
        }
        var code = this.code;
        var blocks = this._getCategoryBlocks(category);
        if (!blocks) {
            return;
        }
        this._categories.push(category);
        var index;
        if (category === 'func') {
            var threads = this.code.getThreadsByCategory('func');
            if (threads.length) {
                index = this.code.getThreadIndex(threads[0]);
            }
        }
        this._buildCategoryCodes(blocks, category).forEach(function (t) {
            if (!t || !t[0]) {
                return;
            }
            t[0].x = -99999;
            _this._createThread(t, index);
            if (index !== undefined) {
                index++;
            }
            delete t[0].x;
        });
        code.changeEvent.notify();
    };
    BlockMenu.prototype._addControl = function (dom) {
        dom.on('wheel', this._mouseWheel.bind(this));
        if (this._scroller) {
            $(this.svg).bind('mousedown touchstart', this.onMouseDown.bind(this));
        }
    };
    // WheelEvent?
    BlockMenu.prototype._mouseWheel = function (e) {
        var originalEvent = e.originalEvent;
        originalEvent.preventDefault();
        var disposeEvent = Entry.disposeEvent;
        if (disposeEvent) {
            disposeEvent.notify(originalEvent);
        }
        this._scroller.scroll(-originalEvent.wheelDeltaY || originalEvent.deltaY / 3);
    };
    BlockMenu.prototype._captureKeyEvent = function (e) {
        var _this = this;
        var keyCode = Entry.Utils.inputToKeycode(e);
        if (!keyCode) {
            return;
        }
        if (e.ctrlKey && Entry.type === 'workspace' && keyCode > 48 && keyCode < 58) {
            e.preventDefault();
            setTimeout(function () {
                _this._cancelDynamic(true);
                _this._dSelectMenu(keyCode, true);
            }, 200);
        }
    };
    BlockMenu.prototype._clearCategory = function () {
        var _a;
        if (this._generateCodesTimer) {
            clearTimeout(this._generateCodesTimer);
            this._generateCodesTimer = null;
        }
        this._selectedCategoryView = null;
        this._categories = [];
        this._threadsMap = {};
        each(this._categoryElems, function (elem) { return elem.remove(); });
        this._categoryElems = {};
        var code = this.code;
        if ((code === null || code === void 0 ? void 0 : code.constructor) == Entry.Code) {
            code.clear();
        }
        (_a = this._categoryCol) === null || _a === void 0 ? void 0 : _a.remove();
        this._categoryData = null;
    };
    /**
     * 카테고리의 목록 뷰를 그린다.
     * @param data {{category: string, blocks: object[]}[]} EntryStatic.getAllBlocks
     * @private
     */
    BlockMenu.prototype._generateCategoryView = function (data) {
        var _this = this;
        var _a;
        if (!data) {
            return;
        }
        (_a = this._categoryCol) === null || _a === void 0 ? void 0 : _a.remove();
        // 카테고리가 이미 만들어져있는 상태에서 데이터만 새로 추가된 경우,
        // categoryWrapper 는 살리고 내부 컬럼 엘리먼트만 치환한다.
        if (!this.categoryWrapper) {
            this.categoryWrapper = Entry.Dom('div', {
                class: 'entryCategoryListWorkspace',
            });
        }
        else {
            this.categoryWrapper.textContent = '';
        }
        this._categoryCol = Entry.Dom('ul', {
            class: 'entryCategoryList',
            parent: this.categoryWrapper,
        });
        this.view.prepend(this.categoryWrapper);
        var fragment = document.createDocumentFragment();
        /*
        visible = static_mini 의 실과형 하드웨어에서만 사용됩니다. (EntryStatic 에 책임)
         */
        data.forEach(function (_a) {
            var category = _a.category, visible = _a.visible;
            return fragment.appendChild(_this._generateCategoryElement(category, visible)[0]);
        });
        this.firstSelector = head(data).category;
        this._categoryCol[0].appendChild(fragment);
        this.makeScrollIndicator();
    };
    BlockMenu.prototype._generateCategoryElement = function (name, visible) {
        var _this = this;
        this._categoryElems[name] = Entry.Dom('li', {
            id: "entryCategory".concat(name),
            classes: [
                'entryCategoryElementWorkspace',
                'entryRemove',
                visible === false ? 'entryRemoveCategory' : '',
            ],
        })
            .bindOnClick(function () {
            _this._cancelDynamic(true, function () {
                _this.selectMenu(name, undefined, true);
                _this.align();
            });
        })
            .text(Lang.Blocks[name.toUpperCase()]);
        if (BETA_LIST.includes(name)) {
            this._categoryElems[name][0].appendChild(Entry.Dom('div', {
                id: "entryCategory".concat(name, "BetaTag"),
                classes: ['entryCategoryBetaTag'],
            })[0]);
        }
        return this._categoryElems[name];
    };
    BlockMenu.prototype._isNotVisible = function (blockInfo) {
        return this.checkCategory(blockInfo) || this.checkBanClass(blockInfo);
    };
    BlockMenu.prototype._getSortedBlocks = function () {
        var _this = this;
        var visibles = [];
        var inVisibles;
        var block;
        var allBlocks = compact(this.code.getThreads().map(function (thread) { return thread.getFirstBlock(); }));
        if (this._selectDynamic) {
            var threadsMap_1 = this._threadsMap;
            visibles = this._dynamicThreads.reduce(function (visibles, type) {
                block = threadsMap_1[type].getFirstBlock();
                if (block) {
                    visibles.push(block);
                }
                return visibles;
            }, []);
            inVisibles = allBlocks;
        }
        else {
            inVisibles = [];
            allBlocks.forEach(function (block) {
                if (!_this._isNotVisible(Entry.block[block.type])) {
                    visibles.push(block);
                }
                else {
                    inVisibles.push(block);
                }
            });
        }
        return [visibles, inVisibles];
    };
    BlockMenu.prototype._isOn = function () {
        return this.view.css('display') !== 'none';
    };
    BlockMenu.prototype._registerThreadsMap = function (type, thread) {
        if (!(type && (thread === null || thread === void 0 ? void 0 : thread.getFirstBlock()))) {
            return;
        }
        this._threadsMap[type] = thread;
    };
    BlockMenu.prototype._deleteThreadsMap = function (thread) {
        var block = thread === null || thread === void 0 ? void 0 : thread.getFirstBlock();
        if (!block) {
            return;
        }
        delete this._threadsMap[block.type];
    };
    BlockMenu.prototype._createThread = function (data, index, keyName) {
        if (typeof keyName !== 'string') {
            keyName = undefined;
        }
        keyName = keyName || data[0].type;
        var thread = this.code.createThread(data, index);
        this._registerThreadsMap(keyName, thread);
        return thread;
    };
    BlockMenu.prototype._getCategoryBlocks = function (category) {
        var selectedCategory = find(this._categoryData, { category: category });
        return selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.blocks;
    };
    return BlockMenu;
}(ModelClass));
export default BlockMenu;
Entry.BlockMenu = BlockMenu;
