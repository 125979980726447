Entry.skeleton.basic_boolean_field = {
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(20, height);
        width = Math.max(0, width - height + 19);
        var halfHeight = height / 2;
        var x = height * 0.4;
        return "m ".concat(halfHeight, " 0\n                l -").concat(x, " ").concat(halfHeight, "\n                l ").concat(x, " ").concat(halfHeight, "\n                h ").concat(width, "\n                l ").concat(x, " -").concat(halfHeight, "\n                l -").concat(x, " -").concat(halfHeight, "\n                z");
    },
    color: '#FFF',
    outerLine: '#1b3ad8',
    fontSize: 10,
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 5;
        var height = blockView ? blockView.contentHeight : 18;
        return {
            offsetX: 0,
            offsetY: 0,
            width: width + 19,
            height: Math.max(height, 20),
            marginBottom: 0,
        };
    },
    magnets: function () {
        return {
            boolean: {},
        };
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight, 20);
        return { x: 10, y: height / 2 };
    },
};
