import { PrimitiveSet } from './structure/PrimitiveSet';
import { ImageRect } from '../../maxrect-packer/geom/ImageRect';
import { autoFit } from '../utils/AutoFit';
var _PIXIAtlasHelper = /** @class */ (function () {
    function _PIXIAtlasHelper() {
    }
    /**
     * rawData.fileurl || rawData.filename
     * @param rawData
     */
    _PIXIAtlasHelper.prototype.getRawPath = function (rawData) {
        return rawData.fileurl || rawData.filename;
    };
    /**
     * EntryObject를 전부 조회하여 지정된 장면에서 사용하는 picture의 경로 set 객체를 리턴.
     * @param sceneID
     */
    _PIXIAtlasHelper.prototype.getScenePathSet = function (sceneID) {
        var arrObj = Entry.container.getAllObjects();
        var pathSet = new PrimitiveSet();
        var LEN = arrObj.length;
        var LEN2;
        var pics;
        var obj;
        for (var i = 0; i < LEN; i++) {
            obj = arrObj[i];
            if (sceneID != obj.scene.id) {
                continue;
            }
            pics = obj.pictures;
            if (!pics || !(LEN2 = pics.length)) {
                continue;
            }
            for (var j = 0; j < LEN2; j++) {
                pathSet.put(this.getRawPath(pics[j]));
            }
        }
        return pathSet;
    };
    /**
     * 이미지별 텍스쳐 사이즈 리턴 (정수)
     * @param pic, texMaxRect
     */
    _PIXIAtlasHelper.prototype.getNewImageRect = function (pic, texMaxRect) {
        var w = pic.dimension.width | 0;
        var h = pic.dimension.height | 0;
        var r = new ImageRect(0, 0, w, h);
        if (w > texMaxRect.width || h > texMaxRect.height) {
            autoFit.fit(texMaxRect, r, autoFit.ScaleMode.INSIDE, autoFit.AlignMode.TL);
            r.width = Math.ceil(r.width);
            r.height = Math.ceil(r.height);
            r.scaleFactor = w / r.width;
            r.scaleFactorX = w / r.width;
            r.scaleFactorY = h / r.height;
        }
        return r;
    };
    return _PIXIAtlasHelper;
}());
export var PIXIAtlasHelper = new _PIXIAtlasHelper();
