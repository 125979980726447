import { SceneBins } from './SceneBins';
import { AtlasCanvasViewer } from './AtlasCanvasViewer';
import { AtlasImageLoader } from './loader/AtlasImageLoader';
import { PIXIAtlasHelper } from './PIXIAtlasHelper';
import { EntryTextureOption } from './EntryTextureOption';
import { SceneTextures } from './SceneTextures';
import { clog } from '../utils/logs';
import each from 'lodash/each';
var PIXIAtlasManager = /** @class */ (function () {
    function PIXIAtlasManager() {
        this._sceneID_sceneBin_map = {};
    }
    /**
     * @private
     * @constructor
     */
    PIXIAtlasManager.prototype.INIT = function () {
        var _this = this;
        if (this._imageLoader) {
            throw new Error('do not call twice');
        }
        this._option = new EntryTextureOption(640, 360);
        this._viewer = new AtlasCanvasViewer();
        this._imageLoader = new AtlasImageLoader(this._onImageLoaded.bind(this));
        Entry.addEventListener('saveCanvasImage', function () {
            _this.imageRemoved('canvas image saved');
        });
    };
    PIXIAtlasManager.prototype._onImageLoaded = function (info) {
        this._activatedScene && this._activatedScene.putImage(info);
    };
    PIXIAtlasManager.prototype.activateScene = function (sceneID) {
        if (this._activatedScene) {
            this._activatedScene.deactivate();
        }
        this._activatedScene = this._getSceneBin(sceneID);
        this._activatedScene.activate();
    };
    PIXIAtlasManager.prototype.reqResource = function (spriteNullable, sceneID, pic, callback) {
        var bin = this._getSceneBin(sceneID);
        bin.addPicInfo(pic);
        var tex = bin.getTexture(PIXIAtlasHelper.getRawPath(pic));
        if (spriteNullable) {
            spriteNullable.texture = tex;
        }
    };
    PIXIAtlasManager.prototype._getSceneBin = function (sceneID, createIfNotExist) {
        if (createIfNotExist === void 0) { createIfNotExist = true; }
        var s = this._sceneID_sceneBin_map[sceneID];
        if (!s && createIfNotExist) {
            if (this._option.USE_ATLAS) {
                s = new SceneBins(sceneID, this._option, this._imageLoader, this._viewer);
            }
            else {
                s = new SceneTextures(sceneID, this._option, this._imageLoader);
            }
            this._sceneID_sceneBin_map[sceneID] = s;
        }
        return s;
    };
    PIXIAtlasManager.prototype.removeScene = function (sceneID) {
        var s = this._getSceneBin(sceneID, false);
        if (!s) {
            return;
        }
        if (this._activatedScene == s) {
            this._activatedScene = null;
        }
        s.destroy();
        delete this._sceneID_sceneBin_map[sceneID];
        this.imageRemoved("scend(".concat(sceneID, ") removed."));
    };
    PIXIAtlasManager.prototype.imageRemoved = function (reason) {
        clog("AtlasManager::imageRemoved - ".concat(reason));
        this._activatedScene && this._activatedScene._internal_imageRemoved();
        this._imageLoader.requestSync();
    };
    PIXIAtlasManager.prototype.clearProject = function () {
        clog('clearProject');
        this._imageLoader.empty();
        each(this._sceneID_sceneBin_map, function (bin) {
            bin.destroy();
        });
        this._sceneID_sceneBin_map = {};
        this._activatedScene = null;
    };
    return PIXIAtlasManager;
}());
export { PIXIAtlasManager };
