var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import LearningView from './LearningView';
import DataTable from '../DataTable';
var LearningBase = /** @class */ (function () {
    function LearningBase(params) {
        if (params === void 0) { params = {}; }
        var _this = this;
        this.type = 'base';
        this.attrLength = 0;
        this.name = '';
        this.view = null;
        this.trained = false;
        this.chartEnable = false;
        this.fields = [];
        this.predictFields = [];
        this.result = {};
        this.trainParam = null;
        this.chart = null;
        this.predictResult = null;
        this.view = new LearningView({ name: params.name || '', status: 0 });
        // 정지시 data 초기화.
        Entry.addEventListener('stop', function () {
            _this.init(__assign({}, params));
        });
        this.init(__assign({}, params));
    }
    LearningBase.prototype.init = function (_a) {
        var _this = this;
        var _b, _c, _d, _e;
        var name = _a.name, result = _a.result, table = _a.table, trainParam = _a.trainParam;
        this.name = name;
        this.trainParam = trainParam;
        this.result = result;
        this.table = table;
        this.trainCallback = function (value) {
            _this.view.setValue(value);
        };
        this.trained = true;
        this.attrLength = ((_c = (_b = table === null || table === void 0 ? void 0 : table.select) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.length) || 0;
        if (this.attrLength === 2) {
            this.chartEnable = true;
        }
        this.fields = (_e = (_d = table === null || table === void 0 ? void 0 : table.select) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.map(function (index) { return table === null || table === void 0 ? void 0 : table.fields[index]; });
    };
    LearningBase.prototype.isTrained = function () {
        return this.trained;
    };
    LearningBase.prototype.setTable = function () {
        var tableSource = DataTable.getSource(this.table.id);
        if (this.table.fieldsInfo.length !== tableSource.fields.length) {
            Entry.toast.alert(Lang.Msgs.warn, Lang.AiLearning.train_param_error);
            throw Error(Lang.AiLearning.train_param_error);
        }
        this.table.data = tableSource.rows;
    };
    LearningBase.prototype.destroy = function () {
        this.view.destroy();
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    };
    LearningBase.prototype.setVisible = function (visible) {
        this.view.setVisible(visible);
    };
    LearningBase.prototype.unbanBlocks = function (blockMenu) {
        blockMenu.unbanClass("ai_learning_train");
        blockMenu.unbanClass("ai_learning_".concat(this.type));
        blockMenu.unbanClass("".concat(this.type, "_attr_").concat(this.attrLength));
        if (this.chartEnable) {
            blockMenu.unbanClass('ai_learning_train_chart');
        }
    };
    LearningBase.prototype.openChart = function () {
        if (!this.chartEnable) {
            return;
        }
        if (!this.chart) {
            this.generateChart();
        }
        else {
            this.chart.show();
        }
    };
    LearningBase.prototype.closeChart = function () {
        var _a;
        (_a = this.chart) === null || _a === void 0 ? void 0 : _a.hide();
    };
    LearningBase.prototype.setTrainOption = function (type, value) {
        var _a;
        this.trainParam = __assign(__assign({}, this.trainParam), (_a = {}, _a[type] = value, _a));
    };
    LearningBase.prototype.getTrainOption = function () {
        return this.trainParam;
    };
    LearningBase.prototype.getTrainResult = function () {
        return this.result;
    };
    LearningBase.prototype.getResult = function () {
        return this.predictResult;
    };
    LearningBase.prototype.generateChart = function () {
        throw new Error('Method not implemented.');
    };
    LearningBase.prototype.train = function () {
        throw new Error('Method not implemented.');
    };
    LearningBase.prototype.load = function () {
        throw new Error('Method not implemented.');
    };
    LearningBase.prototype.predict = function () {
        throw new Error('Method not implemented.');
    };
    return LearningBase;
}());
export default LearningBase;
