var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Container, Graphics } from 'pixi.js';
import { PIXISprite } from '../plugins/PIXISprite';
import { PIXITempStore } from '../etc/PIXITempStore';
import { PIXIText } from '../text/PIXIText';
var PIXIGraphics = /** @class */ (function (_super) {
    __extends(PIXIGraphics, _super);
    function PIXIGraphics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.destroyed = false;
        return _this;
    }
    PIXIGraphics.prototype.destroy = function (options) {
        this.destroyed = true;
        _super.prototype.destroy.call(this, options);
    };
    return PIXIGraphics;
}(Graphics));
export { PIXIGraphics };
var PIXIHelper = /** @class */ (function () {
    function PIXIHelper() {
    }
    PIXIHelper.sprite = function (debugName, texture) {
        return new PIXISprite(texture);
    };
    PIXIHelper.container = function (debugName) {
        return new Container();
    };
    PIXIHelper.text = function (str, font, color, textBaseline, textAlign) {
        // console.log(str, font);
        var reg = /((\d+)(pt|sp|px))?\s*(.+)/gi;
        var result = reg.exec(font) || [];
        var fontName = result[4] || "NanumGothic, 'Nanum Gothic'";
        var size = result[1] || '10pt';
        var nColor = parseInt(color.replace('#', '0x')) || 0;
        // var t = new PIXI.Text(str, {
        var t = new PIXIText(str, {
            fontFamily: fontName,
            fontSize: size,
            fill: nColor,
            // textBaseline: textBaseline || 'alphabetic',
            textBaseline: 'middle',
            align: textAlign || 'left',
            miterLimit: 2.5,
            padding: 5, //바운드를 삐져나오는 경우를 대비한 패딩
        });
        return t;
    };
    PIXIHelper.getOffScreenCanvas = function (forceHTMLCanvas) {
        if (forceHTMLCanvas === void 0) { forceHTMLCanvas = false; }
        forceHTMLCanvas = true;
        var WIN = window;
        if (!forceHTMLCanvas && 'OffscreenCanvas' in WIN) {
            return new WIN.OffscreenCanvas(1, 1);
        }
        else {
            return document.createElement('canvas');
        }
    };
    /**
     * #ff00ff --> 0xff00ff
     * @param strColor
     */
    PIXIHelper.colorToUint = function (strColor) {
        return strColor ? Number(strColor.replace('#', '0x')) : undefined;
    };
    PIXIHelper.todo = function (msg) { };
    PIXIHelper.newPIXIGraphics = function () {
        return new PIXIGraphics(false);
    };
    PIXIHelper.randomRGBAString = function (alpha) {
        if (alpha === void 0) { alpha = 0.3; }
        var rr = this._rand255;
        return "rgba(".concat(rr(), ",").concat(rr(), ",").concat(rr(), ",").concat(alpha, ")");
    };
    PIXIHelper._rand255 = function () {
        return Math.floor(Math.random() * 255);
    };
    /**
     * createjs.DisplayObject#getTransformBound()
     * @param {PIXI.DisplayObject} target
     */
    PIXIHelper.getTransformBound = function (target) {
        var bounds = target.getLocalBounds(PIXITempStore.rect1);
        var x = bounds.x;
        var y = bounds.y;
        var width = bounds.width;
        var height = bounds.height;
        var mtx = PIXITempStore.matrix1;
        target.localTransform.copyTo(mtx);
        if (x || y) {
            var mat2 = PIXITempStore.matrix2.identity().translate(-x, -y);
            mtx.append(mat2);
        }
        var x_a = width * mtx.a;
        var x_b = width * mtx.b;
        var y_c = height * mtx.c;
        var y_d = height * mtx.d;
        var tx = mtx.tx;
        var ty = mtx.ty;
        var minX = tx;
        var maxX = tx;
        var minY = ty;
        var maxY = ty;
        if ((x = x_a + tx) < minX) {
            minX = x;
        }
        else if (x > maxX) {
            maxX = x;
        }
        if ((x = x_a + y_c + tx) < minX) {
            minX = x;
        }
        else if (x > maxX) {
            maxX = x;
        }
        if ((x = y_c + tx) < minX) {
            minX = x;
        }
        else if (x > maxX) {
            maxX = x;
        }
        if ((y = x_b + ty) < minY) {
            minY = y;
        }
        else if (y > maxY) {
            maxY = y;
        }
        if ((y = x_b + y_d + ty) < minY) {
            minY = y;
        }
        else if (y > maxY) {
            maxY = y;
        }
        if ((y = y_d + ty) < minY) {
            minY = y;
        }
        else if (y > maxY) {
            maxY = y;
        }
        bounds.x = minX;
        bounds.y = minY;
        bounds.width = maxX - minX;
        bounds.height = maxY - minY;
        return bounds;
    };
    return PIXIHelper;
}());
export default PIXIHelper;
