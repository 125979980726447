Entry.skeleton.basic_param = {
    path: function (blockView) {
        var width = blockView.contentWidth;
        var output = blockView._contents[blockView._contents.length - 1];
        if (output) {
            width -= output.box.width + Entry.BlockView.PARAM_SPACE - 2;
        }
        width = Math.max(0, width);
        return "m 2 0\n                h ".concat(width + 11, "\n                a 3 3 0 0 1 3 3\n                h 2.2\n                a 2 2 0 0 1 2 2\n                v 12\n                a 2 2 0 0 1 -2 2\n                h -2.2\n                a 3 3 0 0 1 -3 3\n                H 3\n                a 3 3 0 0 1 -3 -3\n                h 2\n                a 2 2 0 0 0 2 -2\n                V 5\n                a 2 2 0 0 0 -2 -2\n                H 0\n                a 3 3 0 0 1 3 -3\n                z");
    },
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 5;
        return {
            offsetX: -8,
            offsetY: 0,
            width: width + 11,
            height: 22,
            marginBottom: 0,
        };
    },
    magnets: function () {
        return {
            param: {},
        };
    },
    contentPos: function () {
        return { x: 11, y: 11 };
    },
};
