"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _sttVariable = _interopRequireDefault(require("./sttVariable"));
var _answerVariable = _interopRequireDefault(require("./answerVariable"));
var _listVariable = _interopRequireDefault(require("./listVariable"));
var _slideVariable = _interopRequireDefault(require("./slideVariable"));
var _timerVariable = _interopRequireDefault(require("./timerVariable"));
var _variable = _interopRequireDefault(require("./variable"));
Entry.Variable = _variable["default"];
Entry.Variable.create = function (variableMetadata) {
  var variableType = variableMetadata.variableType;
  switch (variableType) {
    case 'stt':
      return new _sttVariable["default"](variableMetadata);
    case 'answer':
      return new _answerVariable["default"](variableMetadata);
    case 'list':
      return new _listVariable["default"](variableMetadata);
    case 'slide':
      return new _slideVariable["default"](variableMetadata);
    case 'timer':
      return new _timerVariable["default"](variableMetadata);
    case 'variable':
    default:
      return new _variable["default"](variableMetadata);
  }
};