'use strict';
import { GEHelper } from '../graphicEngine/GEHelper';
var _cache = new Map();
var EntryDialog = /** @class */ (function () {
    function EntryDialog(entity, message, mode, isStamp) {
        this.padding = 10;
        this.border = 2;
        if (entity.isEngineStop) {
            return;
        }
        if (entity.dialog) {
            entity.dialog.remove(true);
        }
        entity.dialog = this;
        this.parent = entity;
        var messageString = typeof message == 'number' ? String(message) : message;
        if (Entry.console) {
            Entry.console.print(message, mode);
        }
        messageString = messageString.match(/.{1,15}/g).join('\n');
        this.message_ = messageString;
        this.mode_ = mode;
        if (mode === 'speak' || mode === 'ask' || mode === 'think' || mode === 'yell') {
            this.generateSpeak(mode);
        }
        if (!isStamp) {
            Entry.stage.loadDialog(this);
        }
    }
    EntryDialog.prototype.getNotchType = function (bound) {
        var parentObj = this.parent.object;
        var entity = parentObj.entity;
        var cache = _cache.get(parentObj.id) || {};
        var x = cache.x, y = cache.y;
        var notchType = cache.notchType;
        if (entity.x != x || entity.y != y) {
            if (bound.y - 20 - this.border > -135) {
                notchType = 'n';
            }
            else {
                notchType = 's';
            }
            if (bound.x + bound.width / 2 < 0) {
                notchType += 'e';
            }
            else {
                notchType += 'w';
            }
            _cache.set(parentObj.id, {
                notchType: notchType,
                x: entity.x,
                y: entity.y,
            });
        }
        return notchType;
    };
    EntryDialog.prototype.setNotchPositionForPixi = function (bound) {
        var notchType = this.getNotchType(bound);
        if (notchType.includes('n')) {
            this.object.y = Math.max(bound.y - this.height / 2 - 20 - this.padding, -135 + this.height / 2 + this.padding);
        }
        else {
            this.object.y = Math.min(bound.y + bound.height + this.height / 2 + 20 + this.padding, 135 - this.height / 2 - this.padding);
        }
        if (notchType.includes('e')) {
            this.object.x = Math.min(bound.x + bound.width + this.width / 2, 240 - this.width / 2 - this.padding);
        }
        else {
            this.object.x = Math.max(bound.x - this.width / 2, -240 + this.width / 2 + this.padding);
        }
        return notchType;
    };
    EntryDialog.prototype.setNotchPositionForCreateJs = function (bound) {
        var notchType = '';
        if (bound.y - 20 - this.border > -135) {
            this.object.y = Math.max(bound.y - this.height / 2 - 20 - this.padding, -135 + this.height / 2 + this.padding);
            notchType += 'n';
        }
        else {
            this.object.y = Math.min(bound.y + bound.height + this.height / 2 + 20 + this.padding, 135 - this.height / 2 - this.padding);
            notchType += 's';
        }
        if (bound.x + bound.width / 2 < 0) {
            this.object.x = Math.min(bound.x + bound.width + this.width / 2, 240 - this.width / 2 - this.padding);
            notchType += 'e';
        }
        else {
            this.object.x = Math.max(bound.x - this.width / 2, -240 + this.width / 2 + this.padding);
            notchType += 'w';
        }
        return notchType;
    };
    /**
     * Set position
     */
    EntryDialog.prototype.update = function () {
        var parentObj = this.parent.object;
        var bound = GEHelper.calcParentBound(parentObj);
        if (!bound && this.parent.type === 'textBox') {
            if (!this._isNoContentTried) {
                this.parent.setText(' ');
                bound = GEHelper.calcParentBound(parentObj);
                this._isNoContentTried = true;
            }
            else {
                delete this._isNoContentTried;
                return;
            }
        }
        if (!this.object) {
            return;
        }
        var notchType = '';
        if (GEHelper.isWebGL) {
            notchType = this.setNotchPositionForPixi(bound);
        }
        else {
            notchType = this.setNotchPositionForCreateJs(bound);
        }
        if (this.notch && this.notch.type != notchType) {
            this.object.removeChild(this.notch);
            if (this.mode_ === 'think') {
                this.notch = this.createThinkkNotch(notchType);
                this.object.addChild(this.notch);
            }
            else if (this.mode_ === 'speak' || this.mode_ === 'ask') {
                this.notch = this.createSpeakNotch(notchType);
                this.object.addChild(this.notch);
            }
        }
        this._isNoContentTried && this.parent.setText('');
        Entry.requestUpdate = true;
    };
    EntryDialog.prototype.remove = function (saveCache) {
        if (!saveCache) {
            _cache.clear();
        }
        Entry.stage.unloadDialog(this);
        this.parent.dialog = null;
        Entry.requestUpdate = true;
    };
    EntryDialog.prototype.createSpeakNotch = function (type) {
        var notch = GEHelper.newGraphic();
        notch.type = type;
        var colorSet = EntryStatic.colorSet.canvas || {};
        var height = this.height + this.padding;
        var padding = this.padding;
        var width = this.width;
        if (type === 'ne') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(3, height)
                .lt(11, height)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(2, height)
                .lt(2, height + 9)
                .lt(12, height);
        }
        else if (type === 'nw') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(width - 3, height)
                .lt(width - 11, height)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(width - 2, height)
                .lt(width - 2, height + 9)
                .lt(width - 12, height);
        }
        else if (type === 'se') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(3, -padding)
                .lt(11, -padding)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(2, -padding)
                .lt(2, -padding - 9)
                .lt(12, -padding);
        }
        else if (type === 'sw') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(width - 3, -padding)
                .lt(width - 11, -padding)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(this.width - 2, -padding)
                .lt(width - 2, -padding - 9)
                .lt(width - 12, -padding);
        }
        return notch;
    };
    EntryDialog.prototype.createThinkkNotch = function (type) {
        var notch = GEHelper.newGraphic();
        notch.type = type;
        var colorSet = EntryStatic.colorSet.canvas || {};
        var height = this.height + this.padding;
        var padding = this.padding;
        var width = this.width;
        if (type === 'ne') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(3, height)
                .lt(11, height)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(2, height)
                .qt(2, height + 9, 12, height)
                .de(2, height + 9, 5, 4);
        }
        else if (type === 'nw') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(width - 3, height)
                .lt(width - 11, height)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(width, height)
                .qt(width, height + 9, width - 12, height)
                .de(width - 3, height + 9, 5, 4);
        }
        else if (type === 'se') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(3, -padding)
                .lt(11, -padding)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(2, -padding)
                .qt(2, -padding - 9, 12, -padding)
                .de(0, -padding - 12, 5, 4);
        }
        else if (type === 'sw') {
            notch.graphics
                .f('#ffffff')
                .ss(3, 2)
                .s('#ffffff')
                .mt(width - 3, -padding)
                .lt(width - 11, -padding)
                .ss(2, 1, 1)
                .s(colorSet.dialog || '#92d050')
                .mt(this.width - 2, -padding)
                .qt(width - 2, -padding - 9, width - 12, -padding)
                .de(width - 6, -padding - 12, 5, 4);
        }
        return notch;
    };
    EntryDialog.prototype.drawZigZagLineX = function (_a) {
        var ctx = _a.ctx, x1 = _a.x1, y1 = _a.y1, x2 = _a.x2, y2 = _a.y2, padding = _a.padding, zigzagSpacing = _a.zigzagSpacing, oneZigZagLength = _a.oneZigZagLength;
        var zx = 0;
        ctx.moveTo(x1 + padding, y1);
        for (var n = 0; zx < x2 - padding; n++) {
            zx = (n + 1) * zigzagSpacing + x1 + padding;
            var zy = (n % 2 == 0 ? -oneZigZagLength : oneZigZagLength) + y1;
            ctx.lineTo(zx, zy);
        }
        ctx.lineTo(x2 - padding, y2);
    };
    EntryDialog.prototype.drawZigZagLineY = function (_a) {
        var ctx = _a.ctx, x1 = _a.x1, y1 = _a.y1, x2 = _a.x2, y2 = _a.y2, padding = _a.padding, zigzagSpacing = _a.zigzagSpacing, oneZigZagLength = _a.oneZigZagLength;
        var zy = 0;
        ctx.moveTo(x1, y1 + padding);
        for (var n = 0; zy < y2 - padding; n++) {
            zy = (n + 1) * zigzagSpacing + y1 + padding;
            var zx = (n % 2 !== 0 ? -oneZigZagLength : oneZigZagLength) + x1;
            ctx.lineTo(zx, zy);
        }
        ctx.lineTo(x2, y2 - padding);
    };
    EntryDialog.prototype.drawZigZagRect = function (_a) {
        var x = _a.x, y = _a.y, w = _a.w, h = _a.h, borderColor = _a.borderColor, fillColor = _a.fillColor;
        var rect = GEHelper.newGraphic();
        var zigzagSpacing = 3;
        var oneZigZagLength = 5;
        var ctx = rect.graphics;
        ctx.setStrokeStyle(1);
        ctx.beginStroke(borderColor);
        ctx.beginFill(fillColor);
        var padding = 10;
        this.drawZigZagLineX({
            ctx: ctx,
            x1: x,
            y1: y,
            x2: x + w,
            y2: y,
            padding: padding,
            zigzagSpacing: zigzagSpacing,
            oneZigZagLength: oneZigZagLength,
        });
        ctx.lineTo(x + w, y + padding);
        this.drawZigZagLineY({
            ctx: ctx,
            x1: x + w,
            y1: y,
            x2: x + w,
            y2: y + h,
            padding: padding,
            zigzagSpacing: zigzagSpacing,
            oneZigZagLength: oneZigZagLength,
        });
        ctx.lineTo(x + w - padding, y + h);
        this.drawZigZagLineX({
            ctx: ctx,
            x1: x,
            y1: y + h,
            x2: x + w,
            y2: y + h,
            padding: padding,
            zigzagSpacing: zigzagSpacing,
            oneZigZagLength: oneZigZagLength,
        });
        ctx.moveTo(x + padding, y + h);
        ctx.lineTo(x, y + h - padding);
        this.drawZigZagLineY({
            ctx: ctx,
            x1: x,
            y1: y,
            x2: x,
            y2: y + h,
            padding: padding,
            zigzagSpacing: zigzagSpacing,
            oneZigZagLength: oneZigZagLength,
        });
        ctx.moveTo(x + padding, y);
        ctx.lineTo(x, y + padding);
        return rect;
    };
    EntryDialog.prototype.drawYellStart = function (_a) {
        var x = _a.x, y = _a.y, w = _a.w, h = _a.h, fillColor = _a.fillColor, borderColor = _a.borderColor;
        var star = GEHelper.newGraphic();
        var pointSize = 0.2;
        var radius = Math.sqrt(w * w + h * h) / 2;
        var sides = 30;
        var angle = -90;
        star.graphics
            .f(fillColor)
            .ss(2, 'round')
            .s(borderColor)
            .drawPolyStar(x + radius / 2, y + radius / 2, radius, sides, pointSize, angle);
        return star;
    };
    EntryDialog.prototype.generateSpeak = function (mode) {
        this.object = GEHelper.newContainer('[dialog] container');
        var fontFamily = EntryStatic.fontFamily || "NanumGothic, 'Nanum Gothic'";
        var text = GEHelper.textHelper.newText(this.message_, "15px ".concat(fontFamily), '#000000', 'top', 'left');
        var bound;
        if (GEHelper.isWebGL) {
            bound = text;
        }
        else {
            bound = text.getTransformedBounds();
        }
        var height = bound.height;
        var width = bound.width >= 10 ? bound.width : 17;
        var colorSet = EntryStatic.colorSet.canvas || {};
        if (mode === 'yell') {
            var rect = this.drawZigZagRect({
                x: -this.padding,
                y: -this.padding,
                w: width + 2 * this.padding,
                h: height + 2 * this.padding,
                fillColor: colorSet.dialogBG || '#ffffff',
                borderColor: colorSet.dialog || '#92d050',
            });
            this.object.addChild(rect);
        }
        else {
            var rect = GEHelper.newGraphic();
            rect.graphics
                .f(colorSet.dialogBG || '#ffffff')
                .ss(2, 'round')
                .s(colorSet.dialog || '#92d050')
                .rr(-this.padding, -this.padding, width + 2 * this.padding, height + 2 * this.padding, this.padding);
            this.object.addChild(rect);
        }
        this.object.regX = width / 2;
        this.object.regY = height / 2;
        this.width = width;
        this.height = height;
        if (mode === 'think') {
            this.notch = this.createThinkkNotch('nw');
            this.object.addChild(this.notch);
        }
        else if (mode === 'speak' || mode === 'ask') {
            this.notch = this.createSpeakNotch('nw');
            this.object.addChild(this.notch);
        }
        this.update();
        this.object.addChild(text);
        Entry.requestUpdate = true;
    };
    return EntryDialog;
}());
export default EntryDialog;
Entry.Dialog = EntryDialog;
