/**
 * @fileoverview View element constructor
 * @author Kyumin Sim
 * @version 0.2
 */
'use strict';

/**
 * Function for construct html dom element.
 * @function
 * @param {string} tag or html to construct dom element.
 * @param {?object} options include id, classes, parent etc.
 */
Entry.SVG = function (id, svgDom) {
  var element = svgDom ? svgDom : document.getElementById(id);
  return Entry.SVG.createElement(element);
};
Entry.SVG.NS = 'http://www.w3.org/2000/svg';
Entry.SVG.NS_XLINK = 'http://www.w3.org/1999/xlink';
Entry.SVG.createElement = function (tag, options) {
  var el;
  if (typeof tag === 'string') {
    el = document.createElementNS(Entry.SVG.NS, tag);
  } else {
    el = tag;
  }
  if (options) {
    if (options.href) {
      el.setAttributeNS(Entry.SVG.NS_XLINK, 'href', options.href);
      delete options.href;
    }
    for (var key in options) {
      el.setAttribute(key, options[key]);
    }
  }

  //add util functions
  el.elem = Entry.SVG.createElement;
  el.prepend = Entry.SVG.prepend;
  el.attr = Entry.SVG.attr;
  el.addClass = Entry.SVG.addClass;
  el.removeClass = Entry.SVG.removeClass;
  el.hasClass = Entry.SVG.hasClass;
  el.remove = Entry.SVG.remove;
  el.removeAttr = Entry.SVG.removeAttr;
  if (tag === 'text') {
    el.setAttributeNS('http://www.w3.org/XML/1998/namespace', 'xml:space', 'preserve');
  }
  if (this instanceof SVGElement) {
    this.appendChild(el);
  }
  return el;
};
Entry.SVG.prepend = function (tag) {
  var el;
  if (typeof tag === 'string') {
    el = document.createElementNS(Entry.SVG.NS, tag);
  } else {
    el = tag;
  }
  //add util functions
  el.elem = Entry.SVG.createElement;
  el.prepend = Entry.SVG.prepend;
  el.attr = Entry.SVG.attr;
  el.addClass = Entry.SVG.addClass;
  el.removeClass = Entry.SVG.removeClass;
  el.hasClass = Entry.SVG.hasClass;
  el.remove = Entry.SVG.remove;
  el.removeAttr = Entry.SVG.removeAttr;
  if (this instanceof SVGElement) {
    if (this.childNodes.length) {
      this.insertBefore(el, this.childNodes[0]);
    } else {
      this.appendChild(el);
    }
  }
  return el;
};
Entry.SVG.attr = function (options, property) {
  if (typeof options === 'string') {
    var o = {};
    o[options] = property;
    options = o;
  }
  if (options) {
    if (options.href) {
      this.setAttributeNS(Entry.SVG.NS_XLINK, 'href', options.href);
      delete options.href;
    }
    for (var key in options) {
      this.setAttribute(key, options[key]);
    }
  }
  return this;
};
Entry.SVG.addClass = function () {
  var _this = this;
  for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
    classes[_key] = arguments[_key];
  }
  var className = classes.reduce(function (acc, className) {
    if (!_this.hasClass(className)) {
      acc += " ".concat(className);
    }
    return acc;
  }, this.getAttribute('class'));
  this.setAttribute('class', className.replace(/\s+/g, ' '));
  return this;
};
Entry.SVG.removeClass = function () {
  var _this2 = this;
  for (var _len2 = arguments.length, classes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    classes[_key2] = arguments[_key2];
  }
  var className = classes.reduce(function (acc, className) {
    if (_this2.hasClass(className)) {
      acc = acc.replace(new RegExp("(\\s|^)".concat(className, "(\\s|$)")), ' ');
    }
    return acc;
  }, this.getAttribute('class'));
  if (className) {
    this.setAttribute('class', className.replace(/\s+/g, ' '));
  }
  return this;
};
Entry.SVG.hasClass = function (className) {
  var attr = this.getAttribute('class');
  if (!attr) {
    return false;
  } else {
    return attr.match(new RegExp("(\\s|^)".concat(className, "(\\s|$)")));
  }
};
Entry.SVG.remove = function () {
  if (this.parentNode) {
    this.parentNode.removeChild(this);
  }
};
Entry.SVG.removeAttr = function (attrName) {
  this.removeAttribute(attrName);
};