'use strict';

Entry.iboard = {
  id: '9.1',
  name: 'iboard',
  url: 'http://www.io-tech.co.kr',
  imageName: 'iboard.png',
  title: {
    en: 'iboard',
    ko: '아이보드'
  },
  setZero: function setZero() {
    if (!Entry.hw.sendQueue.SET) {
      Entry.hw.sendQueue = {
        GET: {},
        SET: {}
      };
    } else {
      var keySet = Object.keys(Entry.hw.sendQueue.SET);
      keySet.forEach(function (key) {
        Entry.hw.sendQueue.SET[key].data = 0;
        Entry.hw.sendQueue.SET[key].time = new Date().getTime();
      });
    }
    Entry.hw.update();
  },
  sensorTypes: {
    ALIVE: 0,
    DIGITAL: 1,
    ANALOG: 2,
    PWM: 3,
    SERVO_PIN: 4,
    TONE: 5,
    PULSEIN: 6,
    ULTRASONIC: 7,
    TIMER: 8
  },
  toneTable: {
    '0': 0,
    C: 1,
    CS: 2,
    D: 3,
    DS: 4,
    E: 5,
    F: 6,
    FS: 7,
    G: 8,
    GS: 9,
    A: 10,
    AS: 11,
    B: 12
  },
  toneMap: {
    '1': [33, 65, 131, 262, 523, 1046, 2093, 4186],
    '2': [35, 69, 139, 277, 554, 1109, 2217, 4435],
    '3': [37, 73, 147, 294, 587, 1175, 2349, 4699],
    '4': [39, 78, 156, 311, 622, 1245, 2849, 4978],
    '5': [41, 82, 165, 330, 659, 1319, 2637, 5274],
    '6': [44, 87, 175, 349, 698, 1397, 2794, 5588],
    '7': [46, 92, 185, 370, 740, 1480, 2960, 5920],
    '8': [49, 98, 196, 392, 784, 1568, 3136, 6272],
    '9': [52, 104, 208, 415, 831, 1661, 3322, 6645],
    '10': [55, 110, 220, 440, 880, 1760, 3520, 7040],
    '11': [58, 117, 233, 466, 932, 1865, 3729, 7459],
    '12': [62, 123, 247, 494, 988, 1976, 3951, 7902]
  },
  highList: ['high', '1', 'on'],
  lowList: ['low', '0', 'off'],
  BlockState: {}
};
Entry.iboard.setLanguage = function () {
  return {
    ko: {
      template: {
        iboard_get_analog_value: '아날로그 %1 번 센서값',
        iboard_get_analog_value_map: '%1 의 범위를 %2 ~ %3 에서 %4 ~ %5 로 바꾼값',
        iboard_get_ultrasonic_value: '울트라소닉 Trig %1 Echo %2 센서값',
        iboard_toggle_led: '디지털 %1 번 핀 %2 %3',
        iboard_digital_pwm: '디지털 %1 번 핀을 %2 (으)로 정하기 %3',
        iboard_set_tone: '디지털 %1 번 핀의 버저를 %2 %3 음으로 %4 초 연주하기 %5',
        iboard_set_servo: '디지털 %1 번 핀의 서보모터를 %2 의 각도로 정하기 %3',
        iboard_get_digital: '디지털 %1 번 센서값',
        iboard_button: '%1 버튼을 눌렀는가?',
        iboard_motor: '모터를 %2 (으)로 동작하기',
        iboard_LED_pwm: 'LED %1 번의 밝기를 %2 (으)로 정하기',
        iboard_led: 'LED %1 번을 %2',
        iboard_buzzer: '버저를 %2 %3 음으로 %4 초 연주하기',
        iboard_rgb_pwm: 'RGB LED의 %1 LED의 밝기를 %2 (으)로 정하기',
        iboard_get_cds: '현재 밝기',
        iboard_get_res: '가변저항 값',
        iboard_get_mic: '소리센서 값',
        iboard_get_tmp: '현재 섭씨온도'
      }
    },
    en: {
      template: {
        iboard_get_analog_value: 'Analog %1 Sensor value',
        iboard_get_analog_value_map: 'Map Value %1 %2 ~ %3 to %4 ~ %5',
        iboard_get_ultrasonic_value: 'Read ultrasonic sensor trig pin %1 echo pin %2',
        iboard_toggle_led: 'Digital %1 Pin %2 %3',
        iboard_digital_pwm: 'Digital %1 Pin %2 %3',
        iboard_set_tone: 'Play tone pin %1 on note %2 octave %3 beat %4 %5',
        iboard_set_servo: 'Set servo pin %1 angle as %2 %3',
        iboard_get_digital: 'Digital %1 Sensor value',
        iboard_button: 'Did you press button %1 ?',
        iboard_motor: 'Turn on the fan %2',
        iboard_LED_pwm: 'LED %1 Pin %2',
        iboard_led: 'LED %1 pin %2',
        iboard_buzzer: 'Play tone on note %2 octave %3 beat %4 %5',
        iboard_rgb_pwm: 'RGB LED %1 Pin %2',
        iboard_get_cds: 'Light Sensor Value',
        iboard_get_res: 'Potentiometer Value',
        iboard_get_mic: 'Mic Value',
        iboard_get_tmp: 'Now Temp(C)'
      }
    }
  };
};
Entry.iboard.blockMenuBlocks = ['iboard_get_tmp', 'iboard_get_res', 'iboard_get_cds', 'iboard_get_mic', 'iboard_button', 'iboard_led', 'iboard_LED_pwm', 'iboard_rgb_pwm', 'iboard_buzzer', 'iboard_motor', 'iboard_get_analog_value', 'iboard_get_analog_value_map', 'iboard_get_ultrasonic_value', 'iboard_get_digital', 'iboard_toggle_led', 'iboard_digital_pwm', 'iboard_set_servo', 'iboard_set_tone'];
Entry.iboard.setLanguage = function () {
  return {
    ko: {
      template: {
        iboard_button: '%1  버튼을 눌렀는가?',
        iboard_digital_pwm: '디지털 %1 번 핀을 %2 (으)로 정하기 %3',
        iboard_get_analog_value: '아날로그 %1 번 센서값  ',
        iboard_get_analog_value_map: '%1 값의 범위를 %2 ~ %3 에서 %4 ~ %5 (으)로 바꾼값  ',
        iboard_get_digital: '디지털 %1 번 센서값  ',
        iboard_led: 'LED %1 번을 %2 %3',
        iboard_motor: '모터를 %2 으로 동작하기 %3',
        iboard_pwm_led: 'LED %1 번의 밝기를 %2 (으)로 정하기 %3',
        iboard_rgb_led: 'RGB LED의 %1 LED %2 %3',
        iboard_set_tone: '디지털 %1 번 핀의 버저를 %2 %3 음으로 %4 초 연주하기 %5',
        iboard_toggle_led: '디지털 %1 번 핀 %2 %3'
      },
      Blocks: {
        iboard_analog_number_0: 'A0',
        iboard_analog_number_1: 'A1',
        iboard_analog_number_2: 'A2',
        iboard_analog_number_3: 'A3',
        iboard_analog_number_4: 'A4',
        iboard_analog_number_5: 'A5',
        iboard_light: '빛센서가 ',
        iboard_num_pin_1: 'LED 상태를',
        iboard_num_pin_2: '번 스위치가',
        iboard_num_pin_3: '아날로그',
        iboard_num_pin_4: '번 ',
        iboard_num_pin_5: '센서값',
        iboard_string_1: '켜짐',
        iboard_string_2: '꺼짐',
        iboard_string_3: '밝음',
        iboard_string_4: '어두움',
        iboard_string_5: '눌림',
        iboard_string_6: '열림',
        iboard_switch: '스위치 ',
        iboard_tilt: '기울기센서 상태가'
      },
      Device: {
        iboard: '아이보드'
      },
      Menus: {
        iboard: '아이보드'
      }
    },
    en: {
      template: {
        iboard_button: 'Pressed %1 button?',
        iboard_digital_pwm: 'Digital %1 Pin %2 %3',
        iboard_get_analog_value: 'Analog %1 Sensor value  ',
        iboard_get_analog_value_map: 'Map Value %1 %2 ~ %3 to %4 ~ %5  ',
        iboard_get_digital: 'Digital %1 Sensor value  ',
        iboard_led: 'Digital %1 Pin %2 %3',
        iboard_motor: 'Digital %1 Pin %2 %3',
        iboard_pwm_led: 'Digital %1 Pin %2 %3',
        iboard_rgb_led: ' %1 LED %2 %3',
        iboard_set_tone: 'Play tone pin %1 on note %2 octave %3 beat %4 %5',
        iboard_toggle_led: 'Digital %1 Pin %2 %3'
      },
      Blocks: {
        iboard_analog_number_0: 'A0',
        iboard_analog_number_1: 'A1',
        iboard_analog_number_2: 'A2',
        iboard_analog_number_3: 'A3',
        iboard_analog_number_4: 'A4',
        iboard_analog_number_5: 'A5',
        iboard_light: 'Light sensor is ',
        iboard_num_pin_1: 'LED status',
        iboard_num_pin_2: 'switch',
        iboard_num_pin_3: 'analogue',
        iboard_num_pin_4: ' ',
        iboard_num_pin_5: 'sensor value',
        iboard_string_1: 'on',
        iboard_string_2: 'off',
        iboard_string_3: 'bright',
        iboard_string_4: 'dark',
        iboard_string_5: 'pressed',
        iboard_string_6: 'opened',
        iboard_switch: 'switch ',
        iboard_tilt: 'Slope sensor status is'
      },
      Device: {
        iboard: 'iboard'
      },
      Menus: {
        iboard: 'iboard'
      }
    }
  };
};
Entry.iboard.getBlocks = function () {
  return {
    iboard_analog_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['A0', '0'], ['A1', '1'], ['A2', '2'], ['A3', '3'], ['A4', '4'], ['A5', '5']],
        value: '0',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [['A0', '0'], ['A1', '1'], ['A2', '2'], ['A3', '3'], ['A4', '4'], ['A5', '5']],
            value: '0',
            fontSize: 11,
            converter: Entry.block.converters.returnStringKey,
            codeMap: 'Entry.CodeMap.Arduino.iboard_analog_list[0]',
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
          }],
          keyOption: 'iboard_analog_list'
        }]
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////////
    iboard_button_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['A0', '0'], ['A1', '1']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [['A0', '0'], ['A1', '1']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringKey,
            codeMap: 'Entry.CodeMap.Arduino.iboard_temp_sensor_get_value[0]'
          }],
          keyOption: 'iboard_temp_sensor_get_value'
        }]
      }
    },
    iboard_button: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Dropdown',
        options: [['A2', '2'], ['A3', '3']],
        value: '2',
        fontSize: 11
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_button_list'
        }],
        type: 'iboard_button'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule === void 0 ? {} : _Entry$hw$hwModule;
        var name = hwModule.name;
        if (name === 'iboard' || name === 'ArduinoNano') {
          var port = script.getNumberValue('PORT', script);
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.iboard.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        } else {
          return Entry.block.arduino_get_digital_value.func(sprite, script);
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [['A2', '2'], ['A3', '3']],
            value: '2',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringKey,
            codeMap: 'Entry.CodeMap.Arduino.iboard_cds_sensor_get_value[0]'
          }]
        }]
      }
    },
    iboard_motor_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['A3', '3'], ['A4', '4']],
        value: '3',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        VALUE: 0
      },
      func: function func(sprite, script) {
        return script.getField('VALUE', script);
      }
    },
    iboard_motor: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['6']
        }, {
          type: 'iboard_motor_list'
        }, null],
        type: 'iboard_motor'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.max(value, 0);
        value = Math.min(value, 255);
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        Entry.hw.sendQueue.SET[port] = {
          type: Entry.iboard.sensorTypes.PWM,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogWrite(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [['A3', '3'], ['A4', '4']],
            value: '3',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringKey,
            codeMap: 'Entry.CodeMap.Arduino.iboard_mic_get_value[0]'
          }]
        }]
      }
    },
    //
    iboard_pwm_rgb_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['D2', '2'], ['D3', '3'], ['D4', '4']],
        value: '2',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      }
    },
    iboard_rgb_pwm: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['D5', '5'], ['D6', '6']],
        value: '5',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_pwm_rgb_list'
        }, {
          type: 'text',
          params: ['255']
        }, null],
        type: 'iboard_rgb_pwm'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.max(value, 0);
        value = Math.min(value, 255);
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        Entry.hw.sendQueue.SET[port] = {
          type: Entry.ArduinoExt.sensorTypes.PWM,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogWrite(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    //
    iboard_led_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['D10', '10'], ['D11', '11']],
        value: '10',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      }
    },
    iboard_led_list2: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['빨간', '5'], ['초록', '6'], ['파란', '9']],
        value: '5',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        VALUE: 0
      },
      func: function func(sprite, script) {
        return script.getField('VALUE', script);
      }
    },
    iboard_led: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string'
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_led_list'
        }, {
          type: 'iboard_led_list2'
        }, null],
        type: 'iboard_led'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getValue('VALUE');
        if (typeof value === 'string') {
          value = value.toLowerCase();
        }
        if (Entry.iboard.highList.indexOf(value) > -1) {
          value = 255;
        } else if (Entry.iboard.lowList.indexOf(value) > -1) {
          value = 0;
        } else {
          throw new Error();
        }
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        Entry.hw.sendQueue.SET[port] = {
          type: Entry.iboard.sensorTypes.DIGITAL,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalWrite(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_pwm_led_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['정지', '0'], ['미풍', '100'], ['약풍', '170'], ['강풍', '255']],
        value: '0',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      }
    },
    iboard_LED_pwm: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_pwm_led_list'
        }, {
          type: 'text',
          params: ['255']
        }, null],
        type: 'iboard_LED_pwm'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') port = port.substring(1);
        var value1 = ANALOG ? ANALOG[port] || 0 : 0;
        var value2 = 5;
        var value3 = 1024;
        var value4 = 100;
        var value5 = 50;
        var result = value1 * value2 / value3 * value4 - value5;
        return result;
      }
    },
    iboard_buzzer: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['5']
        }, {
          type: 'iboard_tone_list'
        }, {
          type: 'iboard_octave_list'
        }, {
          type: 'text',
          params: ['1']
        }, null],
        type: 'iboard_buzzer'
      },
      paramsKeyMap: {
        PORT: 0,
        NOTE: 1,
        OCTAVE: 2,
        DURATION: 3
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var sq = Entry.hw.sendQueue;
        var port = script.getNumberValue('PORT', script);
        if (!script.isStart) {
          var note = script.getValue('NOTE', script);
          if (!Entry.Utils.isNumber(note)) {
            note = Entry.iboard.toneTable[note];
          }
          if (note < 0) {
            note = 0;
          } else if (note > 12) {
            note = 12;
          }
          var duration = script.getNumberValue('DURATION', script);
          if (duration < 0) {
            duration = 0;
          }
          if (!sq.SET) {
            sq.SET = {};
          }
          if (duration === 0) {
            sq.SET[port] = {
              type: Entry.iboard.sensorTypes.TONE,
              data: 0,
              time: new Date().getTime()
            };
            return script.callReturn();
          }
          var octave = script.getNumberValue('OCTAVE', script) - 1;
          if (octave < 0) {
            octave = 0;
          } else if (octave > 5) {
            octave = 5;
          }
          var value = 0;
          if (note != 0) {
            value = Entry.iboard.toneMap[note][octave];
          }
          duration = duration * 1000;
          script.isStart = true;
          script.timeFlag = 1;
          sq.SET[port] = {
            type: Entry.iboard.sensorTypes.TONE,
            data: {
              value: value,
              duration: duration
            },
            time: new Date().getTime()
          };
          setTimeout(function () {
            script.timeFlag = 0;
          }, duration + 32);
          return script;
        } else if (script.timeFlag == 1) {
          return script;
        } else {
          delete script.timeFlag;
          delete script.isStart;
          sq.SET[port] = {
            type: Entry.iboard.sensorTypes.TONE,
            data: 0,
            time: new Date().getTime()
          };
          Entry.engine.isContinue = false;
          return script.callReturn();
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.tone(%1, %2, %3, %4)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_cds: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['2']
        }],
        type: 'iboard_get_cds'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') {
          port = port.substring(1);
        }
        return ANALOG ? ANALOG[port] || 0 : 0;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_res: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['0']
        }],
        type: 'iboard_get_res'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') {
          port = port.substring(1);
        }
        return ANALOG ? ANALOG[port] || 0 : 0;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_mic: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['3']
        }],
        type: 'iboard_get_mic'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') {
          port = port.substring(1);
        }
        return ANALOG ? ANALOG[port] || 0 : 0;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_tmp: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['1']
        }],
        type: 'iboard_get_tmp'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') {
          port = port.substring(1);
        }
        var value1 = ANALOG ? ANALOG[port] || 0 : 0;
        var value2 = 5;
        var value3 = 1023;
        var value4 = 100;
        var value5 = 50;
        var result = value1 * value2 / value3 * value4 - value5;
        return result;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    iboard_get_analog_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_analog_list'
        }],
        type: 'iboard_get_analog_value'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        if (port[0] === 'A') {
          port = port.substring(1);
        }
        return ANALOG ? ANALOG[port] || 0 : 0;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_analog_value_map: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_get_analog_value',
          params: [{
            type: 'iboard_analog_list'
          }]
        }, {
          type: 'number',
          params: ['0']
        }, {
          type: 'number',
          params: ['1023']
        }, {
          type: 'number',
          params: ['0']
        }, {
          type: 'number',
          params: ['100']
        }],
        type: 'iboard_get_analog_value_map'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE2: 1,
        VALUE3: 2,
        VALUE4: 3,
        VALUE5: 4
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var result = script.getValue('PORT', script);
        var ANALOG = Entry.hw.portData.ANALOG;
        var value2 = script.getNumberValue('VALUE2', script);
        var value3 = script.getNumberValue('VALUE3', script);
        var value4 = script.getNumberValue('VALUE4', script);
        var value5 = script.getNumberValue('VALUE5', script);
        var stringValue4 = script.getValue('VALUE4', script);
        var stringValue5 = script.getValue('VALUE5', script);
        var isFloat = false;
        if (Entry.Utils.isNumber(stringValue4) && stringValue4.indexOf('.') > -1 || Entry.Utils.isNumber(stringValue5) && stringValue5.indexOf('.') > -1) {
          isFloat = true;
        }
        if (value2 > value3) {
          var swap = value2;
          value2 = value3;
          value3 = swap;
        }
        if (value4 > value5) {
          var swap = value4;
          value4 = value5;
          value5 = swap;
        }
        result -= value2;
        result = result * ((value5 - value4) / (value3 - value2));
        result += value4;
        result = Math.min(value5, result);
        result = Math.max(value4, result);
        if (isFloat) {
          result = Math.round(result * 100) / 100;
        } else {
          result = Math.round(result);
        }
        return result;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.map(%1, %2, %3, %4, %5)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_ultrasonic_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number',
          params: ['2']
        }, {
          type: 'arduino_get_port_number',
          params: ['4']
        }],
        type: 'iboard_get_ultrasonic_value'
      },
      paramsKeyMap: {
        PORT1: 0,
        PORT2: 1
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port1 = script.getNumberValue('PORT1', script);
        var port2 = script.getNumberValue('PORT2', script);
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        delete Entry.hw.sendQueue.SET[port1];
        delete Entry.hw.sendQueue.SET[port2];
        if (!Entry.hw.sendQueue.GET) {
          Entry.hw.sendQueue.GET = {};
        }
        Entry.hw.sendQueue.GET[Entry.iboard.sensorTypes.ULTRASONIC] = {
          port: [port1, port2],
          time: new Date().getTime()
        };
        return Entry.hw.portData.ULTRASONIC || 0;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.ultrasonicRead(%1, %2)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_get_digital: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number',
          params: [2]
        }],
        type: 'iboard_get_digital'
      },
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'iboardGet',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule2 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule2 === void 0 ? {} : _Entry$hw$hwModule2;
        var name = hwModule.name;
        if (name === 'iboard' || name === 'ArduinoNano') {
          var port = script.getNumberValue('PORT', script);
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.iboard.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        } else {
          return Entry.block.arduino_get_digital_value.func(sprite, script);
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_digital_toggle: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.ARDUINO_on, 'on'], [Lang.Blocks.ARDUINO_off, 'off']],
        value: 'on',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        OPERATOR: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('OPERATOR');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.ARDUINO_on, 'on'], [Lang.Blocks.ARDUINO_off, 'off']],
            value: 'on',
            fontSize: 11,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValueUpperCase,
            codeMap: 'Entry.CodeMap.Arduino.arduino_get_digital_toggle[0]',
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE
          }],
          keyOption: 'arduino_get_digital_toggle'
        }]
      }
    },
    iboard_toggle_led: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number',
          params: [3]
        }, {
          type: 'arduino_get_digital_toggle',
          params: ['on']
        }, null],
        type: 'iboard_toggle_led'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getValue('VALUE');
        if (typeof value === 'string') {
          value = value.toLowerCase();
        }
        if (Entry.iboard.highList.indexOf(value) > -1) {
          value = 255;
        } else if (Entry.iboard.lowList.indexOf(value) > -1) {
          value = 0;
        } else {
          throw new Error();
        }
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        Entry.hw.sendQueue.SET[port] = {
          type: Entry.iboard.sensorTypes.DIGITAL,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalWrite(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_digital_pwm: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_pwm_port_number'
        }, {
          type: 'text',
          params: ['255']
        }, null],
        type: 'iboard_digital_pwm'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.max(value, 0);
        value = Math.min(value, 255);
        if (!Entry.hw.sendQueue.SET) {
          Entry.hw.sendQueue.SET = {};
        }
        Entry.hw.sendQueue.SET[port] = {
          type: Entry.iboard.sensorTypes.PWM,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.analogWrite(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_tone_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.silent, '0'], [Lang.Blocks.do_name, 'C'], [Lang.Blocks.do_sharp_name, 'CS'], [Lang.Blocks.re_name, 'D'], [Lang.Blocks.re_sharp_name, 'DS'], [Lang.Blocks.mi_name, 'E'], [Lang.Blocks.fa_name, 'F'], [Lang.Blocks.fa_sharp_name, 'FS'], [Lang.Blocks.sol_name, 'G'], [Lang.Blocks.sol_sharp_name, 'GS'], [Lang.Blocks.la_name, 'A'], [Lang.Blocks.la_sharp_name, 'AS'], [Lang.Blocks.si_name, 'B']],
        value: 'C',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        NOTE: 0
      },
      func: function func(sprite, script) {
        return script.getField('NOTE');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.silent, '0'], [Lang.Blocks.do_name, 'C'], [Lang.Blocks.do_sharp_name, 'CS'], [Lang.Blocks.re_name, 'D'], [Lang.Blocks.re_sharp_name, 'DS'], [Lang.Blocks.mi_name, 'E'], [Lang.Blocks.fa_name, 'F'], [Lang.Blocks.fa_sharp_name, 'FS'], [Lang.Blocks.sol_name, 'G'], [Lang.Blocks.sol_sharp_name, 'GS'], [Lang.Blocks.la_name, 'A'], [Lang.Blocks.la_sharp_name, 'AS'], [Lang.Blocks.si_name, 'B']],
            value: 'C',
            fontSize: 11,
            converter: Entry.block.converters.returnStringValueUpperCase,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
          }],
          keyOption: 'iboard_tone_list'
        }]
      }
    },
    iboard_tone_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Block',
        accept: 'string'
      }],
      events: {},
      def: {
        params: [{
          type: 'iboard_tone_list'
        }],
        type: 'iboard_tone_value'
      },
      paramsKeyMap: {
        NOTE: 0
      },
      func: function func(sprite, script) {
        return script.getNumberValue('NOTE');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          keyOption: 'iboard_tone_value'
        }]
      }
    },
    iboard_octave_list: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      template: '%1',
      params: [{
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6']],
        value: '4',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        OCTAVE: 0
      },
      func: function func(sprite, script) {
        return script.getField('OCTAVE');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          keyOption: 'iboard_octave_list'
        }]
      }
    },
    iboard_set_tone: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number',
          params: [3]
        }, {
          type: 'iboard_tone_list'
        }, {
          type: 'iboard_octave_list'
        }, {
          type: 'text',
          params: ['1']
        }, null],
        type: 'iboard_set_tone'
      },
      paramsKeyMap: {
        PORT: 0,
        NOTE: 1,
        OCTAVE: 2,
        DURATION: 3
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var sq = Entry.hw.sendQueue;
        var port = script.getNumberValue('PORT', script);
        if (!script.isStart) {
          var note = script.getValue('NOTE', script);
          if (!Entry.Utils.isNumber(note)) {
            note = Entry.iboard.toneTable[note];
          }
          if (note < 0) {
            note = 0;
          } else if (note > 12) {
            note = 12;
          }
          var duration = script.getNumberValue('DURATION', script);
          if (duration < 0) {
            duration = 0;
          }
          if (!sq.SET) {
            sq.SET = {};
          }
          if (duration === 0) {
            sq.SET[port] = {
              type: Entry.iboard.sensorTypes.TONE,
              data: 0,
              time: new Date().getTime()
            };
            return script.callReturn();
          }
          var octave = script.getNumberValue('OCTAVE', script) - 1;
          if (octave < 0) {
            octave = 0;
          } else if (octave > 5) {
            octave = 5;
          }
          var value = 0;
          if (note != 0) {
            value = Entry.iboard.toneMap[note][octave];
          }
          duration = duration * 1000;
          script.isStart = true;
          script.timeFlag = 1;
          sq.SET[port] = {
            type: Entry.iboard.sensorTypes.TONE,
            data: {
              value: value,
              duration: duration
            },
            time: new Date().getTime()
          };
          setTimeout(function () {
            script.timeFlag = 0;
          }, duration + 32);
          return script;
        } else if (script.timeFlag == 1) {
          return script;
        } else {
          delete script.timeFlag;
          delete script.isStart;
          sq.SET[port] = {
            type: Entry.iboard.sensorTypes.TONE,
            data: 0,
            time: new Date().getTime()
          };
          Entry.engine.isContinue = false;
          return script.callReturn();
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.tone(%1, %2, %3, %4)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    iboard_set_servo: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'arduino_get_port_number',
          params: ['3']
        }, null],
        type: 'iboard_set_servo'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'iboard',
      isNotFor: ['iboard'],
      func: function func(sprite, script) {
        var sq = Entry.hw.sendQueue;
        var port = script.getNumberValue('PORT', script);
        var value = script.getNumberValue('VALUE', script);
        value = Math.min(180, value);
        value = Math.max(0, value);
        if (!sq.SET) {
          sq.SET = {};
        }
        sq.SET[port] = {
          type: Entry.iboard.sensorTypes.SERVO_PIN,
          data: value,
          time: new Date().getTime()
        };
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.servomotorWrite(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    }
  };
};
module.exports = Entry.iboard;