"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PIXIPixelPerfectInteractionPlugIn = PIXIPixelPerfectInteractionPlugIn;
var _pixi = require("pixi.js");
var tempPoint = new _pixi.Point();
var hitTestCanvas = document.createElement('canvas');
hitTestCanvas.width = 1;
hitTestCanvas.height = 1;
var hitTestConText = hitTestCanvas.getContext('2d');
function PIXIPixelPerfectInteractionPlugIn() {
  var p = _pixi.Sprite.prototype;

  /**
   * if true use pixel-perfect hit test
   * @type {boolean}
   */
  p.pixelPerfect = false;

  /** @type {number} not ratio. 16 bit value.
   *  [박봉배] - createjs 에서 testAlpha > 1 이면 히트로 처리.
   */
  p.pixelPerfectAlpha = 1;
  /**
   * PIXISprite.ts 에서 override 함. 메서드명 바꾸지 말긔.
   * @return {PIXI.BaseTexture | (HTMLImageElement | HTMLCanvasElement | HTMLVideoElement)}
   */
  p.internal_getOriginalTex = function () {
    return this.texture;
  };
  p.containsPoint = function (point) {
    //overwrite PIXI.Sprite.containsPoint
    var tex = this.internal_getOriginalTex();
    // if (!tex.baseTexture) {
    //     return false;
    // }
    // if (!tex.baseTexture.source) {
    //     return false;
    // }

    this.worldTransform.applyInverse(point, tempPoint);
    var width = tex.orig.width;
    var height = tex.orig.height;
    var x1 = -width * this.anchor.x;
    var y1 = 0;
    if (tempPoint.x >= x1 && tempPoint.x < x1 + width) {
      y1 = -height * this.anchor.y;
      if (tempPoint.y >= y1 && tempPoint.y < y1 + height) {
        if (this.pixelPerfect) {
          return this._pixelHasAlpha(tempPoint.x, tempPoint.y, tex);
        }
        return true;
      }
    }
    return false;
  }; // end p.containsPoint

  p._pixelHasAlpha = function (x, y, tex) {
    //add method into PIXI.Sprite
    var left = x;
    var top = y;
    var frame = tex.frame;
    var anchor = this.anchor;
    if (anchor.x !== 0) {
      left -= -frame.width * anchor.x;
    }
    if (anchor.y !== 0) {
      top -= -frame.height * anchor.y;
    }
    left += frame.x;
    top += frame.y;
    var resource = tex.baseTexture.resource;
    var _ref = resource || {},
      source = _ref.source;
    if (!source) {
      return false;
    }
    var ctx = hitTestConText;
    ctx.clearRect(0, 0, 1, 1);
    ctx.drawImage(source, left, top, 1, 1, 0, 0, 1, 1);
    var rgba = ctx.getImageData(0, 0, 1, 1);
    return rgba.data[3] > this.pixelPerfectAlpha;
  }; //end p._checkPixel
}