'use strict';

Entry.Bridge = {
  id: '55.1',
  name: 'Bridge',
  imageName: 'bridge.png',
  title: {
    ko: '브릿지',
    en: 'Bridge'
  },
  setZero: function setZero() {
    if (!Entry.hw.sendQueue.SET) {
      Entry.hw.sendQueue = {
        GET: {},
        SET: {}
      };
    } else {
      var keySet = Object.keys(Entry.hw.sendQueue.SET);
      keySet.forEach(function (key) {
        Entry.hw.sendQueue.SET[key].data = 0;
        Entry.hw.sendQueue.SET[key].time = new Date().getTime();
      });
    }
    Entry.hw.update();
  },
  sensorTypes: {
    DIGITAL: 1
  },
  BlockState: {}
};
Entry.Bridge.setLanguage = function () {
  return {
    ko: {
      template: {
        bridge_get_digital1: ' 도 누름 ',
        bridge_get_digital2: ' 레 누름 ',
        bridge_get_digital3: ' 미 누름 ',
        bridge_get_digital4: ' 파 누름 ',
        bridge_get_digital5: ' 솔 누름 ',
        bridge_get_digital6: ' 라 누름 ',
        bridge_get_digital7: ' 시 누름 ',
        bridge_get_digital8: ' 윗 도 누름 ',
        bridge_get_digital9: ' 도# 누름 ',
        bridge_get_digital10: ' 레# 누름 ',
        bridge_get_digital11: ' 파# 누름 ',
        bridge_get_digital12: ' 솔# 누름 ',
        bridge_get_digital13: ' 라# 누름 '
      }
    }
  };
};
Entry.Bridge.blockMenuBlocks = ['bridge_get_digital1', 'bridge_get_digital2', 'bridge_get_digital3', 'bridge_get_digital4', 'bridge_get_digital5', 'bridge_get_digital6', 'bridge_get_digital7', 'bridge_get_digital8', 'bridge_get_digital9', 'bridge_get_digital10', 'bridge_get_digital11', 'bridge_get_digital12', 'bridge_get_digital13'];
Entry.Bridge.getBlocks = function () {
  return {
    bridge_get_digital1: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule === void 0 ? {} : _Entry$hw$hwModule;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 0;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital2: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule2 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule2 === void 0 ? {} : _Entry$hw$hwModule2;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 1;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital3: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule3 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule3 === void 0 ? {} : _Entry$hw$hwModule3;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 2;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital4: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule4 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule4 === void 0 ? {} : _Entry$hw$hwModule4;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 3;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital5: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule5 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule5 === void 0 ? {} : _Entry$hw$hwModule5;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 4;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital6: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule6 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule6 === void 0 ? {} : _Entry$hw$hwModule6;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 5;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital7: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule7 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule7 === void 0 ? {} : _Entry$hw$hwModule7;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 6;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital8: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule8 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule8 === void 0 ? {} : _Entry$hw$hwModule8;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 7;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital9: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule9 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule9 === void 0 ? {} : _Entry$hw$hwModule9;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 8;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital10: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule10 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule10 === void 0 ? {} : _Entry$hw$hwModule10;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 9;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital11: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule11 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule11 === void 0 ? {} : _Entry$hw$hwModule11;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 10;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital12: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule12 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule12 === void 0 ? {} : _Entry$hw$hwModule12;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 11;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    bridge_get_digital13: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      params: [{
        type: 'Block',
        accept: 'string',
        defaultType: 'number'
      }],
      paramsKeyMap: {
        PORT: 0
      },
      "class": 'BridgeGet',
      isNotFor: ['Bridge'],
      func: function func(sprite, script) {
        var _Entry$hw$hwModule13 = Entry.hw.hwModule,
          hwModule = _Entry$hw$hwModule13 === void 0 ? {} : _Entry$hw$hwModule13;
        var name = hwModule.name;
        if (name === 'Bridge' || name === 'ArduinoNano') {
          var port = 12;
          var DIGITAL = Entry.hw.portData.DIGITAL;
          if (!Entry.hw.sendQueue.GET) {
            Entry.hw.sendQueue.GET = {};
          }
          Entry.hw.sendQueue.GET[Entry.Bridge.sensorTypes.DIGITAL] = {
            port: port,
            time: new Date().getTime()
          };
          return DIGITAL ? DIGITAL[port] || 0 : 0;
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Arduino.digitalRead(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    }
  };
};
module.exports = Entry.Bridge;