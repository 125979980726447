var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/**
 * createjs.Graphics 스타일의 lagacy 메서드 호출을 pixi.Graphics 로 변경해주는 adaptor 클라스.
 */
var PIXIPaintAdaptor = /** @class */ (function () {
    function PIXIPaintAdaptor() {
        //아래 값들은 내부에서만 사용함.
        this._alpha = 1;
    }
    PIXIPaintAdaptor.prototype.endFill = function () {
        // #10141 때문에 closePath 사용안함.
        if (!this._shape || this._shape.destroyed) {
            return;
        }
        this.currentPath = null;
        this._shape.closePath();
    };
    PIXIPaintAdaptor.prototype.beginFill = function (color) {
        this._parseRGBCssStyleColor(color);
        this._shape.beginFill(this._color, this._alpha);
    };
    PIXIPaintAdaptor.prototype.beginFillFast = function (color, alpha) {
        this._color = color;
        this._alpha = alpha;
        this._shape.beginFill(color, alpha);
    };
    PIXIPaintAdaptor.prototype.moveTo = function (x, y) {
        if (!this._shape || this._shape.destroyed) {
            return;
        }
        this._shape.moveTo(Number(x), Number(y));
        this.currentPath = this._shape.currentPath;
    };
    PIXIPaintAdaptor.prototype.lineTo = function (x, y) {
        if (!this._shape || this._shape.destroyed) {
            return;
        }
        if (!this.currentPath) {
            this._shape.moveTo(0, 0);
            this.currentPath = this._shape.currentPath;
        }
        var points = this.currentPath.points;
        var fromX = points[points.length - 2];
        var fromY = points[points.length - 1];
        if (fromX !== x || fromY !== y) {
            points.push(Number(x), Number(y));
        }
        this._shape.currentPath = this.currentPath;
        this._shape.lineTo(Number(x), Number(y)); // 박봉배: #9374 x,y 좌표가 문자로 넘어와서 생긴 이슈
        this._shape.geometry.invalidate();
    };
    /** @param shape - drawing 을 할 대상을 지정 */
    PIXIPaintAdaptor.prototype.internalSetShape = function (shape) {
        this._shape = shape;
        this._setStyle();
    };
    PIXIPaintAdaptor.prototype._setStyle = function () {
        if (!this._shape || this._shape.destroyed) {
            return;
        }
        this._shape.lineStyle(this._thickness, this._color, this._alpha);
    };
    PIXIPaintAdaptor.prototype._parseRGBCssStyleColor = function (colorOld) {
        var color = colorOld.replace('/s/', '');
        if (color[0] == '#') {
            this._color = parseInt(color.replace('#', ''), 16);
            return;
        }
        var result;
        //rgb 보다 rgba 문자열을 더 많이 사용하는것 같아 이 조건문을 위로 올림
        if ((result = /^rgba\((\d+),(\d+),(\d+),(\d+(\.?\d*))\)$/i.exec(color))) {
            this._color = this._RGBToNumber(result);
            this._alpha = Number(result[4]);
            return;
        }
        if ((result = /^rgb\((\d+),(\d+),(\d+)\)$/i.exec(color))) {
            this._color = this._RGBToNumber(result);
        }
    };
    PIXIPaintAdaptor.prototype._RGBToNumber = function (regexResult) {
        var _a = __read(regexResult, 4), x = _a[0], r = _a[1], g = _a[2], b = _a[3];
        return (r << 16) + (g << 8) + Number(b);
    };
    // Matched the method name to createjs for fulfilling the purpose of wrapper class (#11626)
    PIXIPaintAdaptor.prototype.clear = function () {
        var _a = this.entity || {}, _b = _a.x, x = _b === void 0 ? 0 : _b, _c = _a.y, y = _c === void 0 ? 0 : _c;
        this._shape.clear();
        // Disconnect the previously continued path
        this._shape.moveTo(x, -y);
    };
    return PIXIPaintAdaptor;
}());
export { PIXIPaintAdaptor };
