/**
 * 하나의 Scene에서 사용하는 이미지들을 TextureAtlas 로 만들어줌.
 */
import PIXIHelper from '../helper/PIXIHelper';
import { AtlasTexture } from './texture/AtlasTexture';
import { AtlasBaseTexture } from './texture/AtlasBaseTexture';
import { PrimitiveMap } from './structure/PrimitiveMap';
import { PIXIAtlasHelper } from './PIXIAtlasHelper';
import { TimeoutTimer } from '../utils/TimeoutTimer';
import each from 'lodash/each';
var TIMEOUT_INTERVAL = 250;
/**
 * packing 이 되기전에 texture 객체를 생성하기 위한 BaseTexture
 */
var EMPTY_BASE_TEX;
var SceneBins = /** @class */ (function () {
    function SceneBins(sceneID, _option, _loader, _viewer) {
        this.sceneID = sceneID;
        this._option = _option;
        this._loader = _loader;
        this._viewer = _viewer;
        //private _pathSet:PrimitiveSet = new PrimitiveSet();//패킹 전/후 pathf르 모두 저장.
        this._packedRects = [];
        this._notPackedRects = [];
        this._arrBaseTexture = [];
        this._path_tex_map = new PrimitiveMap();
        this._timer = new TimeoutTimer();
        SceneBins.initEmptyTex(_option.atlasOption.atlasSize);
        this._packer = _option.atlasOption.newPacker();
    }
    SceneBins.initEmptyTex = function (maxSize) {
        if (EMPTY_BASE_TEX) {
            return;
        }
        var TEX = new AtlasBaseTexture();
        EMPTY_BASE_TEX = TEX;
        TEX.setRealSize(maxSize, maxSize);
        TEX.setSize(maxSize, maxSize);
        var tex = TEX;
        var emptyEmit = function () { };
        tex.destroy = emptyEmit;
        tex.on = emptyEmit;
        tex.once = emptyEmit;
        tex.emit = emptyEmit;
    };
    SceneBins.prototype.addPicInfo = function (pic) {
        var _this = this;
        var path = PIXIAtlasHelper.getRawPath(pic);
        if (this._path_tex_map.hasValue(path)) {
            return;
        }
        var rect = PIXIAtlasHelper.getNewImageRect(pic, this._option.texMaxRect);
        this._loader.load(pic, rect);
        var tex = this._newTexture(path, rect);
        rect.data = { path: path, tex: tex };
        this._notPackedRects.push(rect);
        if (!this._activated) {
            return;
        }
        if (this._timer.isRunning) {
            return;
        }
        console.log('pack scheduled');
        this._timer.timeout(TIMEOUT_INTERVAL, function () {
            if (_this._imageRemoved) {
                console.log('삭제된 이미지가 있어서 invalidate');
                _this._invalidate();
            }
            else {
                console.log('just packing');
                _this._pack();
            }
        });
    };
    SceneBins.prototype._newTexture = function (path, rect) {
        var tex = new AtlasTexture(EMPTY_BASE_TEX, rect);
        this._path_tex_map.add(path, tex);
        return tex;
    };
    /** 패킹 하지 않은 Rect를 packing 한다. */
    SceneBins.prototype._pack = function () {
        var _this = this;
        if (!this._notPackedRects.length) {
            return;
        }
        var len = this._notPackedRects.length;
        var time = new Date().getTime();
        this._packer.addArray(this._notPackedRects);
        var willUpdateBaseTextures = [];
        this._notPackedRects.forEach(function (r) {
            var base = _this._getBaseTexture(r.binIndex);
            r.data.tex.updateBaseAndUVs(base);
            var imgInfo = _this._loader.getImageInfo(r.data.path);
            if (!imgInfo.isReady) {
                return;
            }
            _this.putImage(imgInfo, false);
            if (willUpdateBaseTextures.indexOf(base) == -1) {
                willUpdateBaseTextures.push(base);
            }
        });
        willUpdateBaseTextures.forEach(function (base) {
            base.update();
        });
        this._destroyBaseTextureAfter(this._packer.bins.length);
        this._packedRects = this._packedRects.concat(this._notPackedRects);
        this._notPackedRects = [];
        time = new Date().getTime() - time;
        console.log("pack ".concat(len, " items. time:").concat(time));
    };
    SceneBins.prototype.activate = function () {
        var _this = this;
        this._activated = true;
        this._invalidate();
        var BASE_TEX_MAX_SIZE = this._option.atlasOption.atlasSize;
        each(this._packer.bins, function (bin, index) {
            var base = _this._arrBaseTexture[index];
            base.activate(BASE_TEX_MAX_SIZE);
            base.update();
        });
        var EXTRUDE_SIZE = this._option.atlasOption.extrudeSize;
        this._path_tex_map.each(function (t, path) {
            var info = _this._loader.getImageInfo(path);
            if (!info || !info.isReady) {
                return;
            }
            t.drawImageAtBaseTexture(info, EXTRUDE_SIZE);
        });
    };
    SceneBins.prototype._getBaseTexture = function (index) {
        var base = this._arrBaseTexture[index];
        if (base) {
            return base;
        }
        var OP = this._option;
        base = new AtlasBaseTexture(this._viewer, OP.scaleMode);
        base.setCanvas(PIXIHelper.getOffScreenCanvas());
        // base.imageType = 'png'; deprecated v5
        base.setRealSize(OP.atlasOption.atlasSize, OP.atlasOption.atlasSize);
        base.setSize(OP.atlasOption.atlasSize, OP.atlasOption.atlasSize);
        base.mipmap = OP.mipmap;
        this._arrBaseTexture[index] = base;
        return base;
    };
    SceneBins.prototype.deactivate = function () {
        this._timer.reset();
        this._activated = false;
        each(this._arrBaseTexture, function (b) {
            b.deactivate();
        });
    };
    SceneBins.prototype.getTexture = function (path) {
        return this._path_tex_map.getValue(path);
    };
    /**
     * Scene이 활성화 되어 있을때 이미지가 로드 되면 이 함수를 통해 로드된 이미지 정보가 주입됨.
     * @param info
     * @param forceUpdateBaseTexture
     */
    SceneBins.prototype.putImage = function (info, forceUpdateBaseTexture) {
        if (forceUpdateBaseTexture === void 0) { forceUpdateBaseTexture = true; }
        if (!info) {
            return;
        }
        var t = this.getTexture(info.path);
        if (!t) {
            return;
        } //이 Scene에서 사용안함
        if (t.isEmptyTexture) {
            return;
        }
        // console.log("put imgageData");
        var atlasOption = this._option.atlasOption;
        var base = t.getBaseTexture();
        if (!base.activated) {
            base.activate(atlasOption.atlasSize);
        }
        t.drawImageAtBaseTexture(info, atlasOption.extrudeSize);
        if (forceUpdateBaseTexture) {
            base.update();
        }
        Entry.requestUpdate = true;
    };
    /**
     * 모든 텍스쳐 패킹을 다시 한다.
     * @private
     */
    SceneBins.prototype._invalidate = function () {
        var _this = this;
        if (!this._activated) {
            return;
        }
        this._imageRemoved = false;
        var usedPathSet = PIXIAtlasHelper.getScenePathSet(this.sceneID);
        this._notPackedRects.length = 0;
        this._packedRects.length = 0;
        var unusedPath = [];
        //사용안하는 path를 검색, 패킹을 다시 할 것이기 때문에 사용하는 텍스쳐의 rect 정보를 저장.
        this._path_tex_map.each(function (tex, path) {
            if (usedPathSet && usedPathSet.hasValue(path)) {
                _this._notPackedRects.push(_this._path_tex_map.getValue(path).imageRectForPacking);
            }
            else {
                unusedPath.push(path);
            }
        });
        console.log('unusedPath', unusedPath);
        //사용안하는 texture를 제거
        unusedPath.forEach(function (path) {
            _this._path_tex_map.remove(path).destroy(false);
        });
        this._packer.empty();
        this._cleanCanvas();
        this._pack();
    };
    SceneBins.prototype._cleanCanvas = function () {
        var LEN = this._arrBaseTexture.length;
        for (var i = 0; i < LEN; i++) {
            this._arrBaseTexture[i].cleanCanvas();
        }
    };
    SceneBins.prototype._internal_imageRemoved = function () {
        this._imageRemoved = true;
    };
    SceneBins.prototype._destroyBaseTextureAfter = function (startIndex) {
        var LEN = this._arrBaseTexture.length;
        for (var i = startIndex; i < LEN; i++) {
            this._arrBaseTexture[i].destroy();
        }
        this._arrBaseTexture.length = startIndex;
    };
    SceneBins.prototype.destroy = function () {
        this._timer.reset();
        this._path_tex_map.each(function (tex, path) {
            tex.destroy(false);
        });
        this._path_tex_map.destroy();
        this._destroyBaseTextureAfter(0);
        this._timer = null;
        this._arrBaseTexture = null;
        this._path_tex_map = null;
        this._packer = null;
        this._viewer = null;
        this._packedRects = null;
        this._notPackedRects = null;
    };
    return SceneBins;
}());
export { SceneBins };
