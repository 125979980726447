"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.voiceApiConnect = voiceApiConnect;
var _audioUtils = _interopRequireDefault(require("./audioUtils"));
var _socket = _interopRequireDefault(require("socket.io-client"));
var GATEWAY_CONNECT_TIMEOUT = 5000;
var DEFAULT_ADDR = {
  hostname: window.origin,
  path: '/vc'
};
function voiceApiConnect() {
  var addr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_ADDR;
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Kor';
  var cb = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise(function (resolve, reject) {
    var client = (0, _socket["default"])(addr.hostname, {
      path: addr.path,
      query: "language=".concat(language),
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      timeout: GATEWAY_CONNECT_TIMEOUT,
      transports: ['websocket', 'polling']
    });
    client.on('open', function () {
      console.log('NSASR Voice Server Connected');
      resolve(client);
    });
    client.on('connect', function () {
      console.log('socket connected');
    });
    client.on('disconnect', function () {
      console.log('closed');
      Entry.engine.hideAllAudioPanel();
      _audioUtils["default"].isRecording = false;
    });
    var isFirst = true;
    client.on('connect_error', function (error) {
      if (isFirst) {
        isFirst = false;
        client.io.opts.transports = ['polling', 'websocket'];
      } else {
        console.error('connect_error', error);
        Entry.engine.hideAllAudioPanel();
        _audioUtils["default"].isRecording = false;
        reject(error);
      }
    });
    client.on('connect_timeout', function (timeout) {
      console.error('connect_timeout', timeout);
      Entry.engine.hideAllAudioPanel();
      _audioUtils["default"].isRecording = false;
      reject(timeout);
    });
    client.on('error', function (error) {
      console.error('error', error);
      Entry.engine.hideAllAudioPanel();
      _audioUtils["default"].isRecording = false;
      reject(error);
    });
  });
}