var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var DataStore = /** @class */ (function () {
    function DataStore() {
        this.dataMap = new Map();
    }
    // 데이터 추가 메서드
    DataStore.prototype.add = function (key, value) {
        if (!this.dataMap.has(key)) {
            this.dataMap.set(key, []);
        }
        this.dataMap.get(key).push(value); // "!"는 값이 null 또는 undefined가 아님을 나타냅니다.
    };
    // 특정 키에 대한 모든 데이터 가져오기
    DataStore.prototype.getAll = function (key) {
        return this.dataMap.get(key) || [];
    };
    // 특정 키를 제외한 모든 데이터 가져오기
    DataStore.prototype.getAllExcept = function (excludedKey) {
        var e_1, _a;
        var allData = [];
        try {
            for (var _b = __values(this.dataMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], values = _d[1];
                if (key !== excludedKey) {
                    allData = allData.concat(values);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return allData;
    };
    // 모든 키와 데이터 가져오기
    DataStore.prototype.getAllData = function () {
        return Array.from(this.dataMap.entries());
    };
    DataStore.prototype.getAllValues = function () {
        var e_2, _a;
        var allValues = [];
        try {
            for (var _b = __values(this.dataMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var values = _c.value;
                allValues = allValues.concat(values);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return allValues;
    };
    // 특정 키의 데이터 삭제하기
    DataStore.prototype.delete = function (key) {
        this.dataMap.delete(key);
    };
    DataStore.prototype.deleteItemByKeyAndValue = function (key, value) {
        var valuesForKey = this.dataMap.get(key);
        if (!valuesForKey) {
            return false;
        }
        var index = valuesForKey.indexOf(value);
        if (index === -1) {
            return false;
        }
        valuesForKey.splice(index, 1);
        if (valuesForKey.length === 0) {
            this.dataMap.delete(key);
        }
        return true;
    };
    // 모든 데이터 삭제하기
    DataStore.prototype.clear = function () {
        this.dataMap.clear();
    };
    return DataStore;
}());
export default DataStore;
