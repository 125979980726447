import { MaxRectsBin } from "./maxrects_bin";
import { OversizedElementBin } from "./oversized_element_bin";
export var EDGE_MAX_VALUE = 4096;
export var EDGE_MIN_VALUE = 128;
var MaxRectsPacker = /** @class */ (function () {
    /**
     * Creates an instance of MaxRectsPacker.
     * @param {number} width of the output atlas (default is 4096)
     * @param {number} height of the output atlas (default is 4096)
     * @param {number} border of bin-texture (default is 0)
     * @param {number} padding between glyphs/images (default is 0)
     * @param {IOption} [options={}] (Optional) packing options
     * @memberof MaxRectsPacker
     */
    function MaxRectsPacker(width, height, border, padding, options) {
        if (width === void 0) { width = EDGE_MAX_VALUE; }
        if (height === void 0) { height = EDGE_MAX_VALUE; }
        if (border === void 0) { border = 0; }
        if (padding === void 0) { padding = 0; }
        if (options === void 0) { options = { smart: true, pot: true, square: true }; }
        this.width = width;
        this.height = height;
        this.border = border;
        this.padding = padding;
        this.options = options;
        this.bins = [];
    }
    MaxRectsPacker.prototype.add = function (rect) {
        var width = rect.width;
        var height = rect.height;
        var binIndex = this.bins.length;
        if (width > this.width || height > this.height) {
            this.bins.push(new OversizedElementBin(binIndex, rect));
        }
        else {
            var added = this.bins.find(function (bin) { return bin.add(rect); });
            if (!added) {
                var bin = new MaxRectsBin(binIndex, this.width, this.height, this.border, this.padding, this.options);
                bin.add(rect);
                this.bins.push(bin);
            }
        }
    };
    /**
     * Add an Array of bins/rectangles to the packer.
     * Object structure: { width, height, data }
     * @param {IRectangle[]} rects Array of bin/rectangles
     * @memberof MaxRectsPacker
     */
    MaxRectsPacker.prototype.addArray = function (rects) {
        var _this = this;
        this.sort(rects).forEach(function (r) { return _this.add(r); });
    };
    MaxRectsPacker.prototype.sort = function (rects) {
        return rects.slice().sort(function (a, b) { return Math.max(b.width, b.height) - Math.max(a.width, a.height); });
    };
    MaxRectsPacker.prototype.empty = function () {
        this.bins.length = 0;
    };
    return MaxRectsPacker;
}());
export { MaxRectsPacker };
