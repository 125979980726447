var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import sortBy from 'lodash/sortBy';
var defaultTokenizer = function (text) {
    var rgxPunctuation = /[^(a-zA-ZA-Яa-я0-9_)+\s]/g;
    var sanitized = text.replace(rgxPunctuation, ' ');
    return sanitized.split(/\s+/);
};
var Naivebayes = /** @class */ (function () {
    function Naivebayes(options) {
        this.options = {};
        this.vocabulary = {};
        this.vocabularySize = 0;
        this.totalDocuments = 0;
        this.docCount = {};
        this.wordCount = {};
        this.wordFrequencyCount = {};
        this.categories = {};
        if (typeof options !== 'undefined') {
            if (!options || typeof options !== 'object' || Array.isArray(options)) {
                throw TypeError("NaiveBayes got invalid options: ".concat(options, ". Pass in an object."));
            }
            this.options = options;
        }
        this.tokenizer = this.options.tokenizer || defaultTokenizer;
    }
    Naivebayes.prototype.frequencyTable = function (tokens) {
        return tokens.reduce(function (acc, cur) {
            if (!acc[cur]) {
                acc[cur] = 0;
            }
            acc[cur] = acc[cur] + 1;
            return acc;
        }, {});
    };
    Naivebayes.prototype.initializeCategory = function (categoryName) {
        if (!this.categories[categoryName]) {
            this.docCount[categoryName] = 0;
            this.wordCount[categoryName] = 0;
            this.wordFrequencyCount[categoryName] = {};
            this.categories[categoryName] = true;
        }
        return this;
    };
    Naivebayes.prototype.learn = function (text, category) {
        return __awaiter(this, void 0, void 0, function () {
            var tokens, frequencyTable;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.initializeCategory(category);
                        this.docCount[category]++;
                        this.totalDocuments++;
                        return [4 /*yield*/, this.tokenizer(text)];
                    case 1:
                        tokens = _a.sent();
                        frequencyTable = this.frequencyTable(tokens);
                        Object
                            .keys(frequencyTable)
                            .forEach(function (token) {
                            if (!_this.vocabulary[token]) {
                                _this.vocabulary[token] = true;
                                _this.vocabularySize++;
                            }
                            var frequencyInText = frequencyTable[token];
                            if (!_this.wordFrequencyCount[category][token]) {
                                _this.wordFrequencyCount[category][token] = frequencyInText;
                            }
                            else {
                                _this.wordFrequencyCount[category][token] += frequencyInText;
                            }
                            _this.wordCount[category] += frequencyInText;
                        });
                        return [2 /*return*/, this];
                }
            });
        });
    };
    Naivebayes.prototype.categorize = function (text) {
        return __awaiter(this, void 0, void 0, function () {
            var totalProbabilities, categoryProbabilities, tokens, frequencyTable, probabilities;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        totalProbabilities = 0;
                        categoryProbabilities = {};
                        return [4 /*yield*/, this.tokenizer(text)];
                    case 1:
                        tokens = _a.sent();
                        frequencyTable = this.frequencyTable(tokens);
                        Object
                            .keys(this.categories)
                            .forEach(function (category) {
                            var categoryProbability = _this.docCount[category] / _this.totalDocuments;
                            var logProbability = Math.log(categoryProbability);
                            Object
                                .keys(frequencyTable)
                                .forEach(function (token) {
                                var frequencyInText = frequencyTable[token];
                                var tokenProbability = _this.tokenProbability(token, category);
                                logProbability += frequencyInText * Math.log(tokenProbability);
                            });
                            // if (logProbability > maxProbability) {
                            //     maxProbability = logProbability;
                            //     chosenCategory = category;
                            // }
                            var expected = Math.exp(logProbability);
                            totalProbabilities = totalProbabilities + expected;
                            categoryProbabilities[category] = expected;
                        });
                        probabilities = Object
                            .keys(categoryProbabilities)
                            .map(function (category) { return ({
                            className: category,
                            probability: categoryProbabilities[category] /= totalProbabilities
                        }); });
                        return [2 /*return*/, sortBy(probabilities, ['probability']).reverse()];
                }
            });
        });
    };
    Naivebayes.prototype.tokenProbability = function (token, category) {
        var wordFrequencyCount = this.wordFrequencyCount[category][token] || 0;
        var wordCount = this.wordCount[category];
        return (wordFrequencyCount + 1) / (wordCount + this.vocabularySize);
    };
    Naivebayes.prototype.toJson = function () {
        var _this = this;
        var state = Naivebayes.STATE_KEYS.reduce(function (acc, cur) {
            acc[cur] = _this[cur];
            return acc;
        }, {});
        return JSON.stringify(state);
    };
    Naivebayes.STATE_KEYS = [
        'categories',
        'docCount',
        'totalDocuments',
        'vocabulary',
        'vocabularySize',
        'wordCount',
        'wordFrequencyCount',
        'options'
    ];
    return Naivebayes;
}());
export function fromJson(jsonStr) {
    try {
        var parsed_1 = JSON.parse(jsonStr);
        var classifier_1 = new Naivebayes(parsed_1.options);
        Naivebayes.STATE_KEYS.forEach(function (k) {
            if (typeof parsed_1[k] === 'undefined' || parsed_1[k] === null) {
                throw new Error("FromJson: JSON string is missing an expected property: ".concat(k, "."));
            }
            classifier_1[k] = parsed_1[k];
        });
        return classifier_1;
    }
    catch (e) {
        throw new Error('Naivebayes.fromJson expects a valid JSON string.');
    }
}
export default Naivebayes;
