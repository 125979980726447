'use strict';

var _require = require('../command_util'),
  createTooltip = _require.createTooltip,
  returnEmptyArr = _require.returnEmptyArr,
  getExpectedData = _require.getExpectedData;
(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.containerSelectObject] = {
    "do": function _do(objectId) {
      Entry.container.selectObject(objectId);
    },
    state: function state(objectId) {
      return [Entry.playground.object.id, objectId];
    },
    log: function log(objectId) {
      return [['objectId', objectId], ['objectIndex', Entry.container.getObjectIndex(objectId)]];
    },
    restrict: function restrict(data, domQuery, callback) {
      Entry.container.scrollToObject(data.content[1][1]);
      return new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: domQuery
      }], {
        dimmed: true,
        restrict: true,
        callBack: function callBack() {
          callback();
        }
      });
    },
    undo: 'containerSelectObject',
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['container', 'objectIndex', '&1']
  };
  c[COMMAND_TYPES.removeObject] = {
    "do": function _do(objectId) {
      Entry.Utils.forceStopSounds();
      var _Entry$container$getO = Entry.container.getObject(objectId),
        name = _Entry$container$getO.name;
      Entry.container.removeObject(objectId);
      Entry.Utils.doCodeChange();
      Entry.toast.success(Lang.Workspace.remove_object, "".concat(name, " ").concat(Lang.Workspace.remove_object_msg));
    },
    state: function state(objectId) {
      var object = Entry.container.getObject(objectId);
      return [object.toJSON(), object.getIndex()];
    },
    log: function log(objectId) {
      return [['objectId', objectId]];
    },
    undo: 'addObject',
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['container', 'objectId', '&0', 'removeButton']
  };
  c[COMMAND_TYPES.addObject] = {
    "do": function _do(objectModel, index) {
      objectModel.id = getExpectedData('objectModel', {}).id || objectModel.id;
      Entry.container.addObjectFunc(objectModel, index);
      Entry.dispatchEvent('dismissModal');
      Entry.Utils.doCodeChange();
    },
    state: function state(objectModel, index) {
      objectModel.id = getExpectedData('objectModel', {}).id || objectModel.id;
      return [objectModel.id, index];
    },
    log: function log(objectModel, index) {
      var sprite = objectModel.sprite,
        _objectModel$options = objectModel.options,
        options = _objectModel$options === void 0 ? {} : _objectModel$options;
      var font = options.font;

      //$$hashKey can't saved for db
      var _omitFunc = _.partial(_.omit, _, '$$hashKey');
      objectModel.sprite = _omitFunc(sprite);
      if (_.isObject(font)) {
        objectModel.options.font = _omitFunc(font);
      }
      return [['objectModel', objectModel], ['objectIndex', index], ['spriteId', sprite._id]];
    },
    dom: ['.btn_confirm_modal'],
    restrict: function restrict(data, domQuery, callback) {
      Entry.dispatchEvent('dismissModal');
      var _data$tooltip = data.tooltip,
        title = _data$tooltip.title,
        content = _data$tooltip.content;
      var tooltip = createTooltip(title, content, '.btn_confirm_modal', callback, {
        render: false
      });
      var event = Entry.getMainWS().widgetUpdateEvent;
      if (!data.skip) {
        Entry.dispatchEvent('openSpriteManager', getExpectedData('spriteId'), event.notify.bind(event));
      }
      return tooltip;
    },
    undo: 'removeObject',
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT
  };
  c[COMMAND_TYPES.addObjectButtonClick] = {
    "do": function _do() {
      Entry.dispatchEvent('dismissModal');
      Entry.dispatchEvent('openSpriteManager');
    },
    state: returnEmptyArr,
    log: returnEmptyArr,
    undo: 'dismissModal',
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    validate: false,
    dom: ['engine', 'objectAddButton']
  };
})(Entry.Command);