/*
생성자에서 empty base texture 를 받은 후, sprite 를 통해 즉시 stage에서 사용 할 수 있도록 함.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntryTextureBase } from './EntryTextureBase';
var AtlasTexture = /** @class */ (function (_super) {
    __extends(AtlasTexture, _super);
    function AtlasTexture(baseTexture, imageRect) {
        var _this = _super.call(this, baseTexture, imageRect) || this;
        _this._isEmptyTexture = true;
        _this.imageRectForPacking = imageRect;
        return _this;
    }
    Object.defineProperty(AtlasTexture.prototype, "isEmptyTexture", {
        get: function () {
            return this._isEmptyTexture;
        },
        enumerable: false,
        configurable: true
    });
    AtlasTexture.prototype.getBaseTexture = function () {
        return this.baseTexture;
    };
    /**
     * @param info
     * @param extrude - must uint value. Reduce flickering in some cases where sprites have to be put next to each other in the final program.
     * example: https://www.codeandweb.com/texturepacker/documentation/texture-settings
     */
    AtlasTexture.prototype.drawImageAtBaseTexture = function (info, extrude) {
        if (this._isEmptyTexture) {
            return;
        }
        var img = info.source();
        var ctx = this.getBaseTexture().getCtx();
        var r = this.frame;
        var w = r.width;
        var h = r.height;
        var rx = r.x;
        var ry = r.y;
        var sw = info.srcWidth;
        var sh = info.srcHeight;
        /* //for debug background
        ctx.fillStyle = PIXIHelper.randomRGBAString(0.3);
        ctx.fillRect(rx, ry, w, h);
        //*/
        ctx.drawImage(img, 0, 0, sw, sh, rx, ry, w, h);
        if (extrude) {
            ctx.save();
            ctx.imageSmoothingEnabled = false;
            //top
            ctx.drawImage(img, 0, 0, sw, 1, rx, ry - extrude, w, extrude);
            //down
            ctx.drawImage(img, 0, sh - 1, sw, 1, rx, ry + h, w, extrude);
            //left
            ctx.drawImage(img, 0, 0, 1, sh, rx - extrude, ry, extrude, h);
            //right
            ctx.drawImage(img, sw - 1, 0, 1, sh, rx + w, ry, extrude, h);
            ctx.restore();
        }
    };
    /**
     * 진짜로 사용할 BaseTexture를 할당 받고, uv를 업데이트 함.
     * @param base
     */
    AtlasTexture.prototype.updateBaseAndUVs = function (base) {
        this._isEmptyTexture = false;
        this.baseTexture = base;
        if (this.frame.x != this.imageRectForPacking.x ||
            this.frame.y != this.imageRectForPacking.y) {
            this.frame.x = this.imageRectForPacking.x;
            this.frame.y = this.imageRectForPacking.y;
            this._updateUvs();
        }
    };
    return AtlasTexture;
}(EntryTextureBase));
export { AtlasTexture };
