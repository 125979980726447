Entry.skeleton.basic = {
    executable: true,
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(28, height + 2);
        width = Math.max(0, width + 6 - height / 2);
        var halfHeight = height / 2;
        return "M 0 0\n        l 6 6\n        l 6 -6\n        h ".concat(width, "\n        a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 ").concat(height, "\n        h -").concat(width, "\n        l -6 6\n        l -6 -6\n        z");
    },
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 150;
        var height = blockView ? blockView.contentHeight : 28;
        return {
            offsetX: -8,
            offsetY: 0,
            width: width + 30,
            height: Math.max(28, height + 2),
            marginBottom: 0,
        };
    },
    magnets: function (blockView) {
        // apply scale required.
        var height = blockView ? Math.max(blockView.height, 28) : 28;
        return {
            previous: { x: 0, y: 0 },
            next: { x: 0, y: height + blockView.offsetY },
        };
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight, 28);
        return { x: 14, y: height / 2 };
    },
};
Entry.skeleton.basic_without_next = {
    executable: true,
    outerLine: '#13bf68',
    box: Entry.skeleton.basic.box,
    contentPos: Entry.skeleton.basic.contentPos,
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(28, height + 2);
        width = Math.max(0, width + 6 - height / 2);
        var halfHeight = height / 2;
        return "m 0 0\n        l 6 6\n        l 6 -6\n        h ".concat(width, "\n        a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 ").concat(height, "\n        H 0\n        z");
    },
    magnets: function () {
        return {
            previous: { x: 0, y: 0 },
        };
    },
};
