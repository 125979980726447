var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * 취소선, 밑줄 추가 하기 위해 만튼 스타일.
 */
import { TextStyle } from 'pixi.js';
var PIXITextStyle = /** @class */ (function (_super) {
    __extends(PIXITextStyle, _super);
    function PIXITextStyle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /** 취소선 */
        _this._cancelLine = false;
        _this._underLine = false;
        /** 멀티 라인일때 ( wordWrap = true ) 글씨가 보여지는 최대 높이. 음수일때는 글씨를 모두 보여줌. */
        _this._maxHeight = -1;
        _this.styleID = 0;
        return _this;
    }
    Object.defineProperty(PIXITextStyle.prototype, "cancelLine", {
        /**
         *
         * @return {boolean}
         */
        get: function () {
            return this._cancelLine;
        },
        set: function (value) {
            if (this._cancelLine !== value) {
                this._cancelLine = value;
                this.styleID++;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PIXITextStyle.prototype, "wordBreakAll", {
        /** css wordBreak 의 break-all 속성을 구현하기 위함. entryjs 는 break-all 이 기본이기 때문에 return true 로 해버림. */
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PIXITextStyle.prototype, "underLine", {
        /**
         *
         * @return {boolean}
         */
        get: function () {
            return this._underLine;
        },
        set: function (value) {
            if (this._underLine !== value) {
                this._underLine = value;
                this.styleID++;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PIXITextStyle.prototype, "maxHeight", {
        get: function () {
            return this._maxHeight;
        },
        set: function (value) {
            if (this._maxHeight !== value) {
                this._maxHeight = value;
                this.styleID++;
            }
        },
        enumerable: false,
        configurable: true
    });
    return PIXITextStyle;
}(TextStyle));
export default PIXITextStyle;
