Entry.skeleton.basic_create = {
    executable: true,
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(28, height);
        width = Math.max(0, width + 4);
        var statementHeight = blockView._statements[0] ? blockView._statements[0].height : 20;
        statementHeight = Math.max(statementHeight, 20);
        var bw = width - 20;
        var halfHeight = height / 2;
        return "m 0 0\n        V 1\n        h ".concat(width, "\n        a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 ").concat(height, "\n        H 26\n        l -6 6\n        l -6 -6\n        v ").concat(statementHeight, "\n        l 6 6\n        l 6 -6\n        h ").concat(bw, "\n        a 7.5 7.5 0 0 1 0 15\n        H 0\n        z");
    },
    magnets: function (blockView) {
        return {};
    },
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 150;
        var height = blockView ? blockView.contentHeight : 28;
        return {
            offsetX: -8,
            offsetY: 0,
            width: width + 30,
            height: Math.max(30, height + 2),
            marginBottom: 0,
        };
    },
    statementPos: function (blockView) {
        var height1 = Math.max(30, (blockView.contentHeight % 1000000) + 2) + 1;
        return [{ x: 14, y: height1 - 3 }];
    },
    contentPos: function (blockView) {
        var height = Math.max(blockView.contentHeight % 1000000, 28);
        return { x: 14, y: height / 2 + 1 };
    },
};
