/*
 *
 */
'use strict';

(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.editPicture] = {
    "do": function _do() {
      Entry.playground.painter.redo();
    },
    state: function state() {},
    log: function log(objectId) {
      return [objectId];
    },
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    undo: 'uneditPicture'
  };
  c[COMMAND_TYPES.uneditPicture] = {
    type: Entry.STATIC.COMMAND_TYPES.uneditPicture,
    "do": function _do() {
      Entry.playground.painter.undo();
    },
    state: function state() {},
    log: function log(objectId) {
      return [objectId];
    },
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    undo: 'editPicture'
  };
  c[COMMAND_TYPES.processPicture] = {
    "do": function _do() {
      Entry.playground.painter.redo();
    },
    state: function state() {},
    log: function log(objectId) {
      return [objectId];
    },
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    undo: 'unprocessPicture',
    isPass: true
  };
  c[COMMAND_TYPES.unprocessPicture] = {
    "do": function _do() {
      Entry.playground.painter.undo();
    },
    state: function state() {},
    log: function log(objectId) {
      return [objectId];
    },
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    undo: 'processPicture',
    isPass: true
  };
})(Entry.Command);