export var autoFit;
(function (autoFit) {
    var ScaleMode;
    (function (ScaleMode) {
        ScaleMode[ScaleMode["NONE"] = 0] = "NONE";
        ScaleMode[ScaleMode["WIDTH"] = 1] = "WIDTH";
        ScaleMode[ScaleMode["HEIGHT"] = 2] = "HEIGHT";
        ScaleMode[ScaleMode["INSIDE"] = 3] = "INSIDE";
        ScaleMode[ScaleMode["OUTSIDE"] = 4] = "OUTSIDE";
    })(ScaleMode = autoFit.ScaleMode || (autoFit.ScaleMode = {}));
    var AlignMode;
    (function (AlignMode) {
        AlignMode[AlignMode["TL"] = 0] = "TL";
        AlignMode[AlignMode["TC"] = 1] = "TC";
        AlignMode[AlignMode["CC"] = 2] = "CC";
        AlignMode[AlignMode["BC"] = 3] = "BC";
        AlignMode[AlignMode["BL"] = 4] = "BL";
        AlignMode[AlignMode["NONE"] = 5] = "NONE";
    })(AlignMode = autoFit.AlignMode || (autoFit.AlignMode = {}));
    function fit(targetArea, rSource, scaleMode, alignMode) {
        if (alignMode === void 0) { alignMode = AlignMode.CC; }
        switch (scaleMode) {
            case ScaleMode.NONE:
                align(targetArea, rSource, alignMode);
                break;
            case ScaleMode.INSIDE:
                align(targetArea, proportional_inside(targetArea, rSource), alignMode);
                break;
            case ScaleMode.OUTSIDE:
                align(targetArea, proportional_outside(targetArea, rSource), alignMode);
                break;
            case ScaleMode.WIDTH:
                align(targetArea, width_only(targetArea, rSource), alignMode);
                break;
            case ScaleMode.HEIGHT:
                align(targetArea, height_only(targetArea, rSource), alignMode);
                break;
        }
        return rSource;
    }
    autoFit.fit = fit;
    function width_only(targetArea, rSource) {
        var s = targetArea.width / rSource.width;
        rSource.width *= s;
        rSource.height *= s;
        return rSource;
    }
    function height_only(targetArea, rSource) {
        var s = targetArea.height / rSource.height;
        rSource.width *= s;
        rSource.height *= s;
        return rSource;
    }
    function proportional_inside(targetArea, rSource) {
        var sx = targetArea.width / rSource.width;
        var sy = targetArea.height / rSource.height;
        var s = Math.min(sx, sy);
        rSource.width *= s;
        rSource.height *= s;
        return rSource;
    }
    function proportional_outside(targetArea, rSource) {
        var sx = targetArea.width / rSource.width;
        var sy = targetArea.height / rSource.height;
        var s = Math.max(sx, sy);
        rSource.width = rSource.width * s;
        rSource.height = rSource.height * s;
        return rSource;
    }
    function align(targetArea, rSource, strAlign) {
        switch (strAlign) {
            case AlignMode.TC:
                setCenter(targetArea, rSource, true, false);
                rSource.x = targetArea.x;
                break;
            case AlignMode.CC:
                setCenter(targetArea, rSource);
                break;
            case AlignMode.TL:
                rSource.x = targetArea.x;
                rSource.y = targetArea.y;
                break;
            case AlignMode.BC:
                setCenter(targetArea, rSource, true, false);
                rSource.y = targetArea.y + targetArea.height - rSource.height;
                break;
            case AlignMode.BL:
                rSource.x = targetArea.x;
                rSource.y = targetArea.y + targetArea.height - rSource.height;
                break;
            case AlignMode.NONE:
                //아무것도 안함.
                break;
        }
        return rSource;
    }
    function setCenter(targetArea, rSource, axisX, axisY) {
        if (axisX === void 0) { axisX = true; }
        if (axisY === void 0) { axisY = true; }
        var r = targetArea;
        if (axisX) {
            rSource.x = r.x + r.width / 2 - rSource.width / 2;
        }
        if (axisY) {
            rSource.y = r.y + r.height / 2 - rSource.height / 2;
        }
    }
    autoFit.setCenter = setCenter;
})(autoFit || (autoFit = {}));
