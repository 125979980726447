/*
 *
 */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _legacyVideo = _interopRequireDefault(require("@entrylabs/legacy-video"));
var _webUsbFlasher = _interopRequireDefault(require("../../class/hardware/webUsbFlasher"));
var _require = require('../command_util'),
  returnEmptyArr = _require.returnEmptyArr,
  createTooltip = _require.createTooltip;
(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.selectObject] = {
    "do": function _do(objectId) {
      return Entry.container.selectObject(objectId);
    },
    state: function state(objectId) {
      var playground = Entry.playground;
      if (playground && playground.object) {
        return [playground.object.id];
      }
    },
    log: function log(objectId) {
      return [objectId];
    },
    undo: 'selectObject'
  };
  c[COMMAND_TYPES.objectEditButtonClick] = {
    "do": function _do(objectId) {
      Entry.container.getObject(objectId).toggleEditObject();
    },
    state: function state(objectId) {
      return [];
    },
    log: function log(objectId) {
      return [['objectId', objectId], ['objectIndex', Entry.container.getObjectIndex(objectId)]];
    },
    skipUndoStack: true,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['container', 'objectIndex', '&1', 'editButton'],
    undo: 'selectObject'
  };
  c[COMMAND_TYPES.objectAddPicture] = {
    "do": function _do(objectId, picture) {
      var isSelect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var hashId = c[COMMAND_TYPES.objectAddPicture].hashId;
      if (hashId) {
        picture.id = hashId;
        delete c[COMMAND_TYPES.objectAddPicture].hashId;
      }
      Entry.container.getObject(objectId).addPicture(picture);
      Entry.playground.injectPicture(isSelect);
      isSelect && Entry.playground.selectPicture(picture);
      Entry.dispatchEvent('dismissModal');
    },
    state: function state(objectId, picture) {
      return [objectId, picture];
    },
    log: function log(objectId, picture) {
      var o = {};
      o._id = picture._id;
      o.id = picture.id;
      o.dimension = picture.dimension;
      o.filename = picture.filename;
      o.fileurl = picture.fileurl;
      o.thumbUrl = picture.thumbUrl;
      o.name = picture.name;
      o.scale = picture.scale;
      return [['objectId', objectId], ['picture', o]];
    },
    dom: ['.btn_confirm_modal'],
    restrict: function restrict(data, domQuery, callback) {
      this.hashId = data.content[2][1].id;
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: '.btn_confirm_modal'
      }], {
        restrict: true,
        dimmed: true,
        render: false,
        callBack: callback
      });
      var event = Entry.getMainWS().widgetUpdateEvent;
      if (!data.skip) {
        Entry.dispatchEvent('openPictureManager', data.content[2][1]._id, event.notify.bind(event));
      }
      return tooltip;
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    validate: false,
    undo: 'objectRemovePicture'
  };
  c[COMMAND_TYPES.objectRemovePicture] = {
    "do": function _do(objectId, picture) {
      Entry.container.getObject(objectId).removePicture(picture.id);
    },
    state: function state(objectId, picture) {
      return [objectId, picture];
    },
    log: function log(objectId, picture) {
      return [['objectId', objectId], ['pictureId', picture._id]];
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    validate: false,
    undo: 'objectAddPicture'
  };
  c[COMMAND_TYPES.objectAddSound] = {
    "do": function _do(objectId, sound) {
      var isSelect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var hashId = c[COMMAND_TYPES.objectAddSound].hashId;
      if (hashId) {
        sound.id = hashId;
        delete c[COMMAND_TYPES.objectAddSound].hashId;
      }
      var object = Entry.container.getObject(objectId);
      if (!object.selectedSound) {
        object.selectedSound = sound;
      }
      object.addSound(sound);
      Entry.playground.injectSound(isSelect);
      isSelect && Entry.playground.selectSound(sound);
      Entry.dispatchEvent('dismissModal');
    },
    state: function state(objectId, sound) {
      return [objectId, sound];
    },
    log: function log(objectId, sound) {
      var o = {};
      o._id = sound._id;
      o.duration = sound.duration;
      o.ext = sound.ext;
      o.id = sound.id;
      o.filename = sound.filename;
      o.fileurl = sound.fileurl;
      o.name = sound.name;
      return [['objectId', objectId], ['sound', o]];
    },
    dom: ['.btn_confirm_modal'],
    restrict: function restrict(data, domQuery, callback) {
      this.hashId = data.content[2][1].id;
      var tooltip = new Entry.Tooltip([{
        title: data.tooltip.title,
        content: data.tooltip.content,
        target: '.btn_confirm_modal'
      }], {
        callBack: callback,
        dimmed: true,
        restrict: true,
        render: false
      });
      var event = Entry.getMainWS().widgetUpdateEvent;
      if (!data.skip) {
        Entry.dispatchEvent('openSoundManager', data.content[2][1]._id, event.notify.bind(event));
      }
      return tooltip;
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    validate: false,
    undo: 'objectRemoveSound'
  };
  c[COMMAND_TYPES.objectRemoveSound] = {
    "do": function _do(objectId, sound) {
      return Entry.container.getObject(objectId).removeSound(sound.id);
    },
    state: function state(objectId, sound) {
      return [objectId, sound];
    },
    log: function log(objectId, sound) {
      return [['objectId', objectId], ['soundId', sound._id]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    validate: false,
    undo: 'objectAddSound'
  };
  c[COMMAND_TYPES.objectAddExpansionBlocks] = {
    "do": function _do(blockNames) {
      blockNames.forEach(function (blockName) {
        if (typeof Entry.EXPANSION_BLOCK !== 'undefined' && typeof Entry.EXPANSION_BLOCK[blockName] !== 'undefined') {
          Entry.EXPANSION_BLOCK[blockName].init();
          if (typeof Entry.expansionBlocks == 'undefined') {
            Entry.expansionBlocks = [];
          }
          Entry.expansionBlocks = _.union(Entry.expansionBlocks, [blockName]);
        }
        Entry.playground.blockMenu.unbanClass(blockName);
      });
      // Entry.dispatchEvent('dismissModal');
    },
    state: function state(blockNames) {
      return [blockNames];
    },
    log: function log(blockNames) {
      return [['blockName', blockNames]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectRemoveExpansionBlocks'
  };
  c[COMMAND_TYPES.objectRemoveExpansionBlocks] = {
    "do": function _do(blockNames) {
      // 사용된 블록 전체에서 검색가능해질때 사용가능.
      blockNames.forEach(function (blockName) {
        Entry.playground.blockMenu.banClass(blockName);
      });
      Entry.expansionBlocks = _.pullAll(Entry.expansionBlocks, blockNames);
    },
    state: function state(blockNames) {
      return [blockNames];
    },
    log: function log(blockNames) {
      return [['blockName', blockNames]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectAddExpansionBlocks'
  };
  c[COMMAND_TYPES.objectAddAIUtilizeBlocks] = {
    "do": function _do(blockNames) {
      blockNames.forEach(function (blockName) {
        if (typeof Entry.AI_UTILIZE_BLOCK !== 'undefined' && typeof Entry.AI_UTILIZE_BLOCK[blockName] !== 'undefined') {
          Entry.AI_UTILIZE_BLOCK[blockName].init();
          if (typeof Entry.aiUtilizeBlocks == 'undefined') {
            Entry.aiUtilizeBlocks = [];
          }
          Entry.aiUtilizeBlocks = _.union(Entry.aiUtilizeBlocks, [blockName]);
        }
        Entry.playground.blockMenu.unbanClass(blockName);
      });
      // Entry.dispatchEvent('dismissModal');
    },
    state: function state(blockName) {
      return [blockName];
    },
    log: function log(blockName) {
      return [['blockName', blockName]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectRemoveAIUtilizeBlocks'
  };
  c[COMMAND_TYPES.objectRemoveAIUtilizeBlocks] = {
    "do": function _do(blockNames) {
      // 사용된 블록 전체에서 검색가능해질때 사용가능.
      // Entry.expansionBlocks = _.pull(Entry.expansionBlocks, blockName);
      // 사용된 블록 전체에서 검색가능해질때 사용가능.
      blockNames.forEach(function (blockName) {
        if (blockName === 'video') {
          _legacyVideo["default"].destroy();
        }
        Entry.playground.blockMenu.banClass(blockName);
      });
      Entry.aiUtilizeBlocks = _.pullAll(Entry.aiUtilizeBlocks, blockNames);
    },
    state: function state(blockName) {
      return [blockName];
    },
    log: function log(blockName) {
      return [['blockName', blockName]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectAddAIUtilizeBlocks'
  };
  c[COMMAND_TYPES.objectAddHardwareLiteBlocks] = {
    "do": function _do(module) {
      Entry.hwLite.getConnectFailedMenu();
      if (typeof Entry.hardwareLiteBlocks == 'undefined') {
        Entry.hardwareLiteBlocks = [];
      }
      Entry.hardwareLiteBlocks = _.union(Entry.hardwareLiteBlocks, [module.id]);
      Entry.hwLite.setExternalModule(module);
    },
    state: function state(module) {
      return [module];
    },
    log: function log(module) {
      return [['module', module]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectRemoveHardwareLiteBlocks'
  };
  c[COMMAND_TYPES.objectRemoveHardwareLiteBlocks] = {
    "do": function _do(module) {
      Entry.hardwareLiteBlocks = [];
      Entry.hwLite.disconnect();
      Entry.hwLite.removeWebConnector();
      Entry.hwLite.removeFlasher();
    },
    state: function state(module) {
      return [module];
    },
    log: function log(module) {
      return [['module', module]];
    },
    dom: ['.btn_confirm_modal'],
    recordable: Entry.STATIC.RECORDABLE.SKIP,
    validate: false,
    undo: 'objectAddHardwareLiteBlocks'
  };
  c[COMMAND_TYPES.objectNameEdit] = {
    "do": function _do(objectId, newName) {
      var object = Entry.container.getObject(objectId);
      object.setName(newName);
      object.setInputBlurred('nameInput');
      Entry.playground.updateObjectTitle(object);
      Entry.playground.reloadPlayground();
    },
    state: function state(objectId, newName) {
      var object = Entry.container.getObject(objectId);
      return [objectId, object.getName()];
    },
    log: function log(objectId, newName) {
      var object = Entry.container.getObject(objectId);
      return [['objectId', objectId], ['newName', newName]];
    },
    dom: ['container', 'objectId', '&0', 'nameInput'],
    restrict: _inputRestrictor,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'objectNameEdit'
  };
  c[COMMAND_TYPES.objectReorder] = {
    "do": function _do(newIndex, oldIndex) {
      Entry.container.moveElement(newIndex, oldIndex);
    },
    state: function state(newIndex, oldIndex) {
      return [oldIndex, newIndex];
    },
    log: function log(newIndex, oldIndex) {
      return [['newIndex', newIndex], ['oldIndex', oldIndex]];
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'objectReorder'
  };
  c[COMMAND_TYPES.objectUpdatePosX] = {
    "do": function _do(objectId) {
      var newX = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var object = Entry.container.getObject(objectId);
      object.entity.setX(Number(newX));
      object.updateCoordinateView();
      object.setInputBlurred('xInput');
      Entry.stage.updateObject();
    },
    state: function state(objectId, newX) {
      var _Entry$container$getO = Entry.container.getObject(objectId),
        entity = _Entry$container$getO.entity;
      return [objectId, entity.getX()];
    },
    log: function log(objectId, newX) {
      var _Entry$container$getO2 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO2.entity;
      return [['objectId', objectId], ['newX', newX]];
    },
    dom: ['container', 'objectId', '&0', 'xInput'],
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: _inputRestrictor,
    undo: 'objectUpdatePosX'
  };
  c[COMMAND_TYPES.objectUpdatePosY] = {
    "do": function _do(objectId) {
      var newY = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var object = Entry.container.getObject(objectId);
      object.entity.setY(Number(newY));
      object.updateCoordinateView();
      object.setInputBlurred('yInput');
      Entry.stage.updateObject();
    },
    state: function state(objectId, newY) {
      var _Entry$container$getO3 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO3.entity;
      return [objectId, entity.getY()];
    },
    log: function log(objectId, newY) {
      var _Entry$container$getO4 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO4.entity;
      return [['objectId', objectId], ['newY', newY]];
    },
    dom: ['container', 'objectId', '&0', 'yInput'],
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: _inputRestrictor,
    undo: 'objectUpdatePosY'
  };
  c[COMMAND_TYPES.objectUpdateSize] = {
    "do": function _do(objectId) {
      var newSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var object = Entry.container.getObject(objectId);
      object.entity.setSize(Number(newSize));
      object.updateCoordinateView();
      object.setInputBlurred('sizeInput');
      Entry.stage.updateObject();
    },
    state: function state(objectId, newSize) {
      var _Entry$container$getO5 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO5.entity;
      return [objectId, entity.getSize()];
    },
    log: function log(objectId, newSize) {
      var _Entry$container$getO6 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO6.entity;
      return [['objectId', objectId], ['newSize', newSize]];
    },
    dom: ['container', 'objectId', '&0', 'sizeInput'],
    restrict: _inputRestrictor,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'objectUpdateSize'
  };
  c[COMMAND_TYPES.objectUpdateRotationValue] = {
    "do": function _do(objectId) {
      var newValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var object = Entry.container.getObject(objectId);
      object.entity.setRotation(Number(newValue));
      object.updateCoordinateView();
      object.setInputBlurred('rotationInput');
      Entry.stage.updateObject();
    },
    state: function state(objectId, newValue) {
      var _Entry$container$getO7 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO7.entity;
      return [objectId, entity.getRotation()];
    },
    log: function log(objectId, newValue) {
      var _Entry$container$getO8 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO8.entity;
      return [['objectId', objectId], ['newRotationValue', newValue]];
    },
    dom: ['container', 'objectId', '&0', 'rotationInput'],
    restrict: _inputRestrictor,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'objectUpdateRotationValue'
  };
  c[COMMAND_TYPES.objectUpdateDirectionValue] = {
    "do": function _do(objectId) {
      var newValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var object = Entry.container.getObject(objectId);
      object.entity.setDirection(Number(newValue));
      object.updateCoordinateView();
      object.setInputBlurred('directionInput');
      Entry.stage.updateObject();
    },
    state: function state(objectId, newValue) {
      var _Entry$container$getO9 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO9.entity;
      return [objectId, entity.getDirection()];
    },
    log: function log(objectId, newValue) {
      var _Entry$container$getO10 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO10.entity;
      return [['objectId', objectId], ['newDirectionValue', newValue]];
    },
    dom: ['container', 'objectId', '&0', 'directionInput'],
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    restrict: _inputRestrictor,
    undo: 'objectUpdateDirectionValue'
  };
  c[COMMAND_TYPES.objectUpdateRotateMethod] = {
    "do": function _do(objectId, newMethod, rotation) {
      var object = Entry.container.getObject(objectId);
      object.initRotateValue(newMethod);
      object.setRotateMethod(newMethod);
      if (rotation !== undefined) {
        object.entity.setRotation(rotation);
      }
      Entry.stage.updateObject();
    },
    state: function state(objectId, newMethod) {
      var _Entry$container$getO11 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO11.entity,
        rotateMethod = _Entry$container$getO11.rotateMethod;
      return [objectId, rotateMethod, entity.getRotation()];
    },
    log: function log(objectId, newValue) {
      var _Entry$container$getO12 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO12.entity;
      return [['objectId', objectId], ['newDirectionValue', newValue]];
    },
    dom: ['container', 'objectId', '&0', 'rotationMethod', '&1'],
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'objectUpdateRotateMethod'
  };
  c[COMMAND_TYPES.entitySetModel] = {
    "do": function _do(objectId, newModel, oldModel) {
      var _Entry$container$getO13 = Entry.container.getObject(objectId),
        entity = _Entry$container$getO13.entity;
      entity.setModel(newModel);
    },
    state: function state(objectId, newModel, oldModel) {
      return [objectId, oldModel, newModel];
    },
    log: function log(objectId, newModel, oldModel) {
      return [['objectId', objectId], ['newModel', newModel], ['oldModel', oldModel]];
    },
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    undo: 'entitySetModel'
  };
  function _inputRestrictor(_ref, domQuery, callback) {
    var tooltip = _ref.tooltip,
      content = _ref.content;
    var tooltipTitle = tooltip.title,
      tooltipContent = tooltip.content;
    _activateEdit(content[1][1], domQuery, callback);
    return createTooltip(tooltipTitle, tooltipContent, domQuery, callback);
  }
  function _activateEdit(objectId, domQuery, callback) {
    var object = Entry.container.getObject(objectId);
    if (!object.isEditing) {
      object.editObjectValues(true);
    }
    if (!_.isEmpty(domQuery)) {
      domQuery = Entry.getDom(domQuery);
      if (domQuery && !Entry.Utils.isDomActive(domQuery)) {
        domQuery.focus();
        callback();
      }
    }
  }
})(Entry.Command);