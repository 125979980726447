// INFO : 웹연결 api의 라이프사이클 함수를 모아놓은 추상 클래스
var WebApiConnector = /** @class */ (function () {
    function WebApiConnector() {
    }
    WebApiConnector.prototype.connect = function () { };
    WebApiConnector.prototype.disconnect = function () { };
    WebApiConnector.prototype.initialDevice = function () { };
    return WebApiConnector;
}());
export default WebApiConnector;
