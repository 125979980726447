"use strict";

//add methods to HTMLElement prototype
(function (p) {
  p.hasClass = function (className) {
    return $(this).hasClass(className);
  };
  p.addClass = function () {
    for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
      classes[_key] = arguments[_key];
    }
    return _.head($(this).addClass(classes.filter(_.identity).join(' ')));
  };
  p.removeClass = function () {
    for (var _len2 = arguments.length, classes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      classes[_key2] = arguments[_key2];
    }
    return _.head($(this).removeClass(classes.join(' ')));
  };
  p.text = function (str) {
    if (str) {
      this.textContent = str;
    }
    return this;
  };
  p.bindOnClick = function (func) {
    $(this).on('click tab', function (e) {
      if (this.disabled) {
        return;
      }
      func.call(this, e);
    });
    return this;
  };
  p.unBindOnClick = function () {
    $(this).off('click tab');
    return this;
  };
  p.appendTo = function (parent) {
    if (parent) {
      parent.appendChild(this);
    }
    return this;
  };
})(HTMLElement.prototype);