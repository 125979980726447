/*
 *
 */
'use strict';

var _require = require('../command_util'),
  createTooltip = _require.createTooltip,
  returnEmptyArr = _require.returnEmptyArr,
  getExpectedData = _require.getExpectedData;
(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.funcCreateStart] = {
    "do": function _do(funcId) {
      Entry.getMainWS().setMode(Entry.Workspace.MODE_BOARD, 'cancelEdit');
      funcId = getExpectedData('funcId') || funcId;
      Entry.playground.changeViewMode('code');
      var blockMenu = Entry.variableContainer._getBlockMenu();
      if (blockMenu.lastSelector !== 'func') {
        blockMenu.selectMenu('func');
      }
      Entry.variableContainer.createFunction({
        id: funcId
      });
    },
    state: function state(funcId) {
      return [getExpectedData('funcId', funcId), 'remove'];
    },
    log: function log(funcId) {
      return [['funcId', getExpectedData('funcId') || funcId]];
    },
    restrict: function restrict(data, domQuery, callback) {
      Entry.playground.changeViewMode('variable');
      Entry.variableContainer.selectFilter('func');
      var _data$tooltip = data.tooltip,
        title = _data$tooltip.title,
        content = _data$tooltip.content;
      return createTooltip(title, content, domQuery, callback);
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcEditCancel'
  };
  c[COMMAND_TYPES.funcEditStart] = {
    "do": function _do(id, json, type) {
      var func = Entry.variableContainer.getFunction(id);
      if (func) {
        Entry.Func.edit(id);
      } else {
        Entry.variableContainer.createFunction({
          id: id,
          type: type
        });
      }
      if (json) {
        Entry.Func.targetFunc.content.load(json);
      }
    },
    state: function state(id, json) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'cancel';
      var isExist = arguments.length > 3 ? arguments[3] : undefined;
      if (type === 'save' && id && !isExist) {
        Entry.variableContainer.removeFunction({
          id: id
        });
      }
      return [type];
    },
    log: function log(funcId) {
      return [['funcId', funcId]];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcEditEnd'
  };
  c[COMMAND_TYPES.funcEditEnd] = {
    "do": function _do(type) {
      Entry.Func.isEdit = false;
      if (type === 'save') {
        Entry.getMainWS().setMode(Entry.Workspace.MODE_BOARD, 'save');
      } else {
        Entry.getMainWS().setMode(Entry.Workspace.MODE_BOARD, 'cancelEdit');
      }
    },
    state: function state(type) {
      var json = Entry.Func.targetFunc.content.toJSON();
      var func = Entry.variableContainer.getFunction(Entry.Func.targetFunc.id);
      return [Entry.Func.targetFunc.id, json, type, !!func];
    },
    log: function log(type) {
      return [['funcId', Entry.Func.targetFunc.id]];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcEditStart'
  };
  c[COMMAND_TYPES.funcRemove] = {
    "do": function _do(_ref) {
      var id = _ref.id;
      Entry.variableContainer.removeFunction({
        id: id
      });
    },
    state: function state(_ref2) {
      var id = _ref2.id;
      var func = Entry.variableContainer.getFunction(id);
      return [func];
    },
    log: function log(func) {
      return [['funcId', func.id]];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcCreate'
  };
  c[COMMAND_TYPES.funcCreate] = {
    "do": function _do(func) {
      Entry.variableContainer.saveFunction(func);
      Entry.Func.registerFunction(func);
      Entry.Func.updateMenu();
    },
    state: function state(_ref3) {
      var id = _ref3.id;
      return [{
        id: id
      }];
    },
    log: function log(func) {
      return [['funcId', func.id]];
    },
    validate: false,
    recordable: Entry.STATIC.RECORDABLE.SUPPORT,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcRemove'
  };
  c[COMMAND_TYPES.funcChangeType] = {
    "do": function _do(func) {
      var type = 'value';
      if (func.type === 'value') {
        type = 'normal';
      }
      Entry.Func.changeType(func, type);
    },
    state: function state(func) {
      return [func];
    },
    log: function log(func) {
      return [['funcId', func.id]];
    },
    validate: false,
    dom: ['variableContainer', 'functionAddButton'],
    undo: 'funcChangeType'
  };
  c[COMMAND_TYPES.funcLocalVarChangeLength] = {
    "do": function _do(func, value) {
      if (value === 'minus') {
        func.removeLastLocalVariable();
      } else if (value === 'plus') {
        var _value2 = func.defaultLocalVariable();
        func.appendLocalVariable(_value2);
      } else {
        var _func$localVariables;
        var length = ((_func$localVariables = func.localVariables) === null || _func$localVariables === void 0 ? void 0 : _func$localVariables.length) || 0;
        if (Entry.Utils.isNumber(value) && value >= 0) {
          length = value;
        }
        func.setLocalVariableLength(length);
      }
    },
    state: function state(func, value) {
      var prevValue;
      if (value === 'minus') {
        prevValue = 'plus';
      } else if (value === 'plus') {
        prevValue = 'minus';
      } else {
        var _func$localVariables2;
        prevValue = ((_func$localVariables2 = func.localVariables) === null || _func$localVariables2 === void 0 ? void 0 : _func$localVariables2.length) || 0;
      }
      return [func, prevValue];
    },
    log: function log(func, value) {
      return [['funcId', func.id], ['value', value]];
    },
    validate: false,
    dom: ['variableContainer', 'funcLocalVarChangeLength', '&2'],
    undo: 'funcLocalVarChangeLength'
  };
  c[COMMAND_TYPES.toggleFuncUseLocalVariables] = {
    "do": function _do(func) {
      func.toggleFunctionUseLocalVariables();
    },
    state: function state(func) {
      return [func];
    },
    log: function log(func, value) {
      return [['funcId', func.id], ['value', value]];
    },
    validate: false,
    dom: ['variableContainer', 'toggleFuncUseLocalVariables', '&2'],
    undo: 'toggleFuncUseLocalVariables'
  };
  c[COMMAND_TYPES.insertFuncLocalVariable] = {
    "do": function _do(func, value, index) {
      func.insertFuncLocalVariable(value, index);
    },
    state: function state(func, _value, index) {
      return [func, index];
    },
    log: function log(func, value, index) {
      return [['funcId', func.id], ['value', value], ['index', index]];
    },
    validate: false,
    dom: ['variableContainer', 'insertFuncLocalVariable', '&2'],
    undo: 'removeFuncLocalVariableByIndex'
  };
  c[COMMAND_TYPES.removeFuncLocalVariableByIndex] = {
    "do": function _do(func, index) {
      func.removeLocalVariable(index);
    },
    state: function state(func, index) {
      var value = func.localVariables[index];
      return [func, value, index];
    },
    log: function log(func, index) {
      return [['funcId', func.id], ['index', index]];
    },
    validate: false,
    dom: ['variableContainer', 'removeFuncLocalVariableByIndex', '&2'],
    undo: 'insertFuncLocalVariable'
  };
})(Entry.Command);