'use strict';

/**
 * 기존 색상 팔레트
 */
module.exports = {
  arrow: {
    "default": {
      DEFAULT: '#FFFFFF',
      START: '#2F975A',
      FLOW: '#3A71BC',
      MOVING: '#8641B6',
      LOOKS: '#D8234E',
      TEXT: '#DC9C32',
      SOUND: '#83A617',
      JUDGE: '#89A1F7',
      CALC: '#E8B349',
      VARIABLE: '#CE38CE',
      HARDWARE: '#097E84',
      EXPANSION: '#FF8888'
    }
  },
  block: {
    "default": {
      START: '#3BBD70',
      FLOW: '#498DEB',
      MOVING: '#A751E3',
      LOOKS: '#EC4466',
      BRUSH: '#FF9E20',
      SOUNDS: '#A4D01D',
      HARDWARE: '#00979D',
      CALC: '#FFD974',
      VARIABLE: '#E457DC',
      FUNCTION: '#CC7337',
      JUDGE: '#AEB8FF',
      TEXT: '#FFCA36',
      EXPANSION: '#FF8888'
    },
    lighten: {
      //NOTE not have boolean, extension block color
      START: '#53E68E',
      FLOW: '#4ADAFB',
      MOVING: '#CA7DFF',
      LOOKS: '#FF7792',
      BRUSH: '#FF9831',
      SOUND: '#9FEC35',
      HARDWARE: '#65E3E0',
      CALC: '#FFDE82',
      VARIABLE: '#FAA0F7',
      FUNC: '#F3853B',
      TEXT: '#FF9354'
    },
    darken: {
      START: '#13BF68',
      FLOW: '#08ACDD',
      MOVING: '#B13EFE',
      LOOKS: '#EE3157',
      BRUSH: '#FC5E01',
      SOUND: '#6EBC02',
      HARDWARE: '#04B5B0',
      CALC: '#FF9C00',
      VARIABLE: '#EC52E7',
      FUNC: '#C85404',
      JUDGE: '#6173F5',
      TEXT: '#E43500',
      EXPANSION: '#EF6D6D'
    },
    emphasize: {
      '#3BBD70': '#5BC982',
      '#498DEB': '#62A5F4',
      '#A751E3': '#C08FF7',
      '#EC4466': '#F46487',
      '#FF9E20': '#FFB05A',
      '#A4D01D': '#C4DD31',
      '#00979D': '#09BAB5',
      '#FFD974': '#FCDA90',
      '#E457DC': '#F279F2',
      '#CC7337': '#DD884E',
      '#AEB8FF': '#C0CBFF',
      '#FFCA36': '#F2C670'
    },
    common: {
      WHITE: '#FFFFFF',
      DARK: '#000000'
    }
  }
};