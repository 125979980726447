var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * 텍스쳐가 너무 커서 리사이즈 된 경우에 그만큼 보정하기 위한 클래스.
 * 예를 들어 원본크기가 2048, 리사이즈를 1024로 했으면 scaleFactor = 2;
 * entity에서 scale = 5 로 설정하면
 * Sprite.scale = entiry.scale(5) * scaleFactor(2) = 10 이 된다.
 */
import { Sprite } from 'pixi.js';
export var PIXIScaleAdaptor;
(function (PIXIScaleAdaptor) {
    function factory(target) {
        var clazz = target instanceof Sprite ? ScaleFactorSpriteAdaptor : ScaleFactorNormalAdaptor;
        var adaptor = new clazz();
        adaptor._internal_init(target);
        return adaptor;
    }
    PIXIScaleAdaptor.factory = factory;
})(PIXIScaleAdaptor || (PIXIScaleAdaptor = {}));
var ScaleFactorNormalAdaptor = /** @class */ (function () {
    function ScaleFactorNormalAdaptor() {
    }
    ScaleFactorNormalAdaptor.prototype._internal_init = function (target) {
        this._target = target;
        this.scale = new ScaleFactorPoint(target.scale, 'scale');
        this.pivot = new ScaleFactorPoint(target.pivot, 'pivot');
    };
    ScaleFactorNormalAdaptor.prototype.updateScaleFactor = function () {
        //do nothing
    };
    ScaleFactorNormalAdaptor.prototype.destroy = function () {
        this.pivot.destroy();
        this.scale.destroy();
        this._target = null;
        this.pivot = null;
        this.scale = null;
    };
    return ScaleFactorNormalAdaptor;
}());
var ScaleFactorSpriteAdaptor = /** @class */ (function (_super) {
    __extends(ScaleFactorSpriteAdaptor, _super);
    function ScaleFactorSpriteAdaptor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScaleFactorSpriteAdaptor.prototype.updateScaleFactor = function () {
        var sp = this._target;
        var tex = sp.texture;
        var sfx = tex.textureScaleFactorX;
        var sfy = tex.textureScaleFactorY;
        if (!sfx || !sfy) {
            return;
        }
        this.scale.internal_setScaleFactor(sfx, sfy);
        this.pivot.internal_setScaleFactor(1 / sfx, 1 / sfy);
    };
    return ScaleFactorSpriteAdaptor;
}(ScaleFactorNormalAdaptor));
var ScaleFactorPoint = /** @class */ (function () {
    // eslint-disable-next-line no-useless-constructor
    function ScaleFactorPoint(_point, name) {
        this._point = _point;
        this.name = name;
        this._scaleX = 1;
        this._scaleY = 1;
        this._x = 0;
        this._y = 0;
    }
    ScaleFactorPoint.prototype.internal_setScaleFactor = function (x, y) {
        this._scaleX = x;
        this._scaleY = y;
        this.set(this._x, this._y);
    };
    ScaleFactorPoint.prototype.setX = function (value) {
        this._x = value;
        this._point.x = value * this._scaleX;
    };
    ScaleFactorPoint.prototype.setY = function (value) {
        this._y = value;
        this._point.y = value * this._scaleY;
    };
    ScaleFactorPoint.prototype.set = function (x, y) {
        this._x = x;
        this._y = y || (y != 0 ? x : 0);
        this._point.set(this._x * this._scaleX, this._y * this._scaleY);
    };
    ScaleFactorPoint.prototype.destroy = function () {
        this._point = null;
    };
    return ScaleFactorPoint;
}());
