/* eslint-disable max-len */
export default (function () {
    var green = 'color:#48D57C;font-weight:bold;';
    var blue = 'color:#6B8DF5;font-weight:bold;';
    var purple = 'color:#7B46E6;font-weight:bold;';
    var yellow = 'color:#F5CE10;font-weight:bold;';
    var red = 'color:#EF583C;font-weight:bold;';
    var black = 'color:black;font-weight:bold;';
    function isUA(userAgent) {
        return navigator.userAgent.toLowerCase().indexOf(userAgent) > -1;
    }
    if (typeof console === 'object' && console.log && typeof console.log === 'function') {
        if (isUA('chrome') && !isUA('edge')) {
            var bannerText = "\n%c\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557%c\u2588\u2588\u2588\u2557   \u2588\u2588\u2557%c\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557%c\u2588\u2588\u2588\u2588\u2588\u2588\u2557 %c\u2588\u2588\u2557   \u2588\u2588\u2557 %c\n%c\u2588\u2588\u2554\u2550\u2550\u2550\u2550\u255D%c\u2588\u2588\u2588\u2588\u2557  \u2588\u2588\u2551%c\u255A\u2550\u2550\u2588\u2588\u2554\u2550\u2550\u255D%c\u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557%c\u255A\u2588\u2588\u2557 \u2588\u2588\u2554\u255D %c   _     \n%c\u2588\u2588\u2588\u2588\u2588\u2557  %c\u2588\u2588\u2554\u2588\u2588\u2557 \u2588\u2588\u2551%c   \u2588\u2588\u2551   %c\u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D%c \u255A\u2588\u2588\u2588\u2588\u2554\u255D  %c  |_|___ \n%c\u2588\u2588\u2554\u2550\u2550\u255D  %c\u2588\u2588\u2551\u255A\u2588\u2588\u2557\u2588\u2588\u2551%c   \u2588\u2588\u2551   %c\u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557%c  \u255A\u2588\u2588\u2554\u255D   %c  | |_ -|\n%c\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557%c\u2588\u2588\u2551 \u255A\u2588\u2588\u2588\u2588\u2551%c   \u2588\u2588\u2551   %c\u2588\u2588\u2551  \u2588\u2588\u2551%c   \u2588\u2588\u2551    %c _| |___|\n%c\u255A\u2550\u2550\u2550\u2550\u2550\u2550\u255D%c\u255A\u2550\u255D  \u255A\u2550\u2550\u2550\u255D%c   \u255A\u2550\u255D   %c\u255A\u2550\u255D  \u255A\u2550\u255D%c   \u255A\u2550\u255D    %c|___|";
            console.log(bannerText, green, blue, purple, yellow, red, black, green, blue, purple, yellow, red, black, green, blue, purple, yellow, red, black, green, blue, purple, yellow, red, black, green, blue, purple, yellow, red, black, green, blue, purple, yellow, red, black);
        }
        else {
            console.log("\n\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557\u2588\u2588\u2588\u2557   \u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2588\u2588\u2557 \u2588\u2588\u2557   \u2588\u2588\u2557\n\u2588\u2588\u2554\u2550\u2550\u2550\u2550\u255D\u2588\u2588\u2588\u2588\u2557  \u2588\u2588\u2551\u255A\u2550\u2550\u2588\u2588\u2554\u2550\u2550\u255D\u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557\u255A\u2588\u2588\u2557 \u2588\u2588\u2554\u255D    _\n\u2588\u2588\u2588\u2588\u2588\u2557  \u2588\u2588\u2554\u2588\u2588\u2557 \u2588\u2588\u2551   \u2588\u2588\u2551   \u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D \u255A\u2588\u2588\u2588\u2588\u2554\u255D    |_|___\n\u2588\u2588\u2554\u2550\u2550\u255D  \u2588\u2588\u2551\u255A\u2588\u2588\u2557\u2588\u2588\u2551   \u2588\u2588\u2551   \u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557  \u255A\u2588\u2588\u2554\u255D     | |_ -|\n\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557\u2588\u2588\u2551 \u255A\u2588\u2588\u2588\u2588\u2551   \u2588\u2588\u2551   \u2588\u2588\u2551  \u2588\u2588\u2551   \u2588\u2588\u2551     _| |___|\n\u255A\u2550\u2550\u2550\u2550\u2550\u2550\u255D\u255A\u2550\u255D  \u255A\u2550\u2550\u2550\u255D   \u255A\u2550\u255D   \u255A\u2550\u255D  \u255A\u2550\u255D   \u255A\u2550\u255D    |___|");
        }
    }
});
