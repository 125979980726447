"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var banAllBlocks = function banAllBlocks(playground, type) {
  var mainWorkspace = playground.mainWorkspace;
  if (!mainWorkspace) {
    return;
  }
  var blockMenu = _.result(mainWorkspace, 'blockMenu');
  if (!blockMenu) {
    return;
  }
  Object.values(type).forEach(function (block) {
    if (!block) {
      console.log(type, block);
      return;
    }
    blockMenu.banClass(block.name, true);
    blockMenu.banClass("".concat(block.name, "_legacy"), true);
    blockMenu.banClass("".concat(block.name.toLowerCase()), true);
  });
};
var banBlocks = function banBlocks() {
  var blockNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var typedList = arguments.length > 1 ? arguments[1] : undefined;
  var callback = arguments.length > 2 ? arguments[2] : undefined;
  if (!blockNames.length) {
    return;
  }
  var extraBlocks = Object.keys(typedList);
  var extraBlockTypes = blockNames.filter(function (x) {
    return extraBlocks.includes(x);
  });
  if (!extraBlockTypes.length) {
    console.warn('not exist extra block', extraBlockTypes);
    return;
  }
  var currentObjectId = Entry.playground.object.id;
  Entry["do"]('selectObject', currentObjectId);
  extraBlockTypes.forEach(function (type) {
    if (isActive(type, typedList)) {
      var blocks = typedList[type].getBlocks();
      Object.keys(blocks).forEach(function (blockType) {
        Entry.Utils.removeBlockByType(blockType);
      });
    }
  });
  Entry["do"]('selectObject', currentObjectId).isPass(true);
  callback(extraBlockTypes);
};
var isActive = function isActive(name, typedList) {
  var activeList = typedList[name];
  if (!activeList) {
    console.warn('not exist extra block', activeList);
    return;
  }
  var blocks = activeList.getBlocks();
  return Object.keys(blocks).some(function (blockName) {
    return Entry.Utils.isUsedBlockType(blockName);
  });
};
var getExtras = function getExtras(blockList, categoryFlag) {
  var resultList = [];
  blockList.forEach(function (block) {
    var _ref = block || {},
      _ref$_schema = _ref._schema,
      _schema = _ref$_schema === void 0 ? {} : _ref$_schema;
    var isFor = _schema.isFor,
      _schema$isNotFor = _schema.isNotFor,
      isNotFor = _schema$isNotFor === void 0 ? [] : _schema$isNotFor;
    var _isNotFor = (0, _slicedToArray2["default"])(isNotFor, 1),
      key = _isNotFor[0];
    if (key && isFor && isFor.indexOf(categoryFlag) >= 0) {
      resultList = _.union(resultList, [key]);
    }
  });
  return resultList;
};
module.exports = {
  isActive: isActive,
  banAllBlocks: banAllBlocks,
  banBlocks: banBlocks,
  getExtras: getExtras
};