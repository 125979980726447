var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Bin } from "./abstract_bin";
var OversizedElementBin = /** @class */ (function (_super) {
    __extends(OversizedElementBin, _super);
    function OversizedElementBin(index, rect) {
        var _this = _super.call(this) || this;
        _this.index = index;
        _this.rects = [];
        _this.freeRects = [];
        _this.maxWidth = rect.width;
        _this.maxHeight = rect.height;
        _this.options = { smart: false, pot: false, square: false };
        _this.pushRect(rect, true);
        return _this;
    }
    OversizedElementBin.prototype.add = function (rect) { return false; };
    return OversizedElementBin;
}(Bin));
export { OversizedElementBin };
