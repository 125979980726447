"use strict";

/**
 * Add event listener
 * @param {!string} eventName
 * @param {function} fn
 */
Entry.addEventListener = function (eventName, fn) {
  if (!this.events_) {
    this.events_ = {};
  }
  if (!this.events_[eventName]) {
    this.events_[eventName] = [];
  }
  if (fn instanceof Function) {
    this.events_[eventName].push(fn);
  }
  return true;
};

/**
 * Dispatch event
 * @param {!string} eventName
 * @param {*} args
 */
Entry.dispatchEvent = function (eventName) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  if (!this.events_) {
    this.events_ = {};
    return;
  }
  var events = this.events_[eventName];
  if (_.isEmpty(events)) {
    return;
  }
  events.forEach(function (func) {
    return func.apply(window, args);
  });
};

/**
 * Remove event listener
 * @param {!string} eventName
 * @param {function} fn
 */
Entry.removeEventListener = function (eventName, fn) {
  var events = this.events_[eventName];
  if (_.isEmpty(events)) {
    return;
  }
  this.events_[eventName] = events.filter(function (a) {
    return fn !== a;
  });
};

/**
 * Remove event listener
 * @param {!string} eventName
 */
Entry.removeAllEventListener = function (eventName) {
  if (!this.events_ || !this.events_[eventName]) {
    return;
  }
  delete this.events_[eventName];
};