import { AtlasImageLoader } from '../class/pixi/atlas/loader/AtlasImageLoader';
import { PIXIAtlasHelper } from '../class/pixi/atlas/PIXIAtlasHelper';
var EaselResManager = /** @class */ (function () {
    function EaselResManager() {
        this._MAX_TEX_RECT = { x: 0, y: 0, width: 99999, height: 99999 };
    }
    EaselResManager.prototype.INIT = function () {
        this._imgLoader = new AtlasImageLoader(this._onImageLoad.bind(this));
    };
    EaselResManager.prototype._onImageLoad = function (info) {
        //do nothing
    };
    EaselResManager.prototype.imageRemoved = function (reason) {
        this._imgLoader.requestSync();
    };
    EaselResManager.prototype.activateScene = function (sceneID) {
        //do nothing
    };
    EaselResManager.prototype.reqResource = function (spriteNullable, sceneID, pic, callback) {
        var loader = this._imgLoader;
        var path = PIXIAtlasHelper.getRawPath(pic);
        var info = loader.getImageInfo(path);
        if (!info) {
            var rect = PIXIAtlasHelper.getNewImageRect(pic, this._MAX_TEX_RECT);
            loader.load(pic, rect);
            info = loader.getImageInfo(path);
        }
        if (spriteNullable) {
            spriteNullable.image = info.source();
        }
        if (!info.isReady) {
            info.addCallback(callback);
        }
        else {
            callback && callback(info);
        }
    };
    EaselResManager.prototype.clearProject = function () {
        this._imgLoader.empty();
    };
    EaselResManager.prototype.removeScene = function (sceneID) {
        this._imgLoader.requestSync();
    };
    return EaselResManager;
}());
export { EaselResManager };
