/**
 * 사용처: Entry#lecture 관련 로직 한군데
 */
var EntryPDF = /** @class */ (function () {
    function EntryPDF(filename) {
        this.generateView(filename);
    }
    EntryPDF.prototype.generateView = function (file) {
        var pdfView = Entry.createElement('div', 'entryPdfWorkspace');
        // @ts-ignore 아무리봐도 순수 HTMLDivElement 에는 이 함수가 없음. 그러나 모르는 무언가가 있을 수 있으므로 그냥 둠
        pdfView.addClass('entryRemove');
        var url = '/pdfjs/web/viewer.html';
        if (file && file !== '') {
            url += "?file=".concat(file);
        }
        var pdfViewIframe = Entry.createElement('iframe', 'entryPdfIframeWorkspace');
        pdfViewIframe.setAttribute('id', 'pdfViewIframe');
        pdfViewIframe.setAttribute('frameborder', '0');
        pdfViewIframe.setAttribute('src', url);
        pdfView.appendChild(pdfViewIframe);
        this._view = pdfView;
    };
    EntryPDF.prototype.getView = function () {
        return this._view;
    };
    EntryPDF.prototype.resize = function () {
        var container = document.getElementById('entryContainerWorkspaceId');
        var viewFrame = document.getElementById('pdfViewIframe');
        var width = container.offsetWidth;
        viewFrame.width = "".concat(width, "px");
        viewFrame.height = "".concat((width * 9) / 16, "px");
    };
    return EntryPDF;
}());
export default EntryPDF;
Entry.Pdf = EntryPDF;
