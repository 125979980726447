var hwMonitorSvgTemplate = '<svg id="hwMonitor" width="100%" height="100%"' +
    'version="1.1" xmlns="http://www.w3.org/2000/svg"></svg>';
var HardwareMonitor = /** @class */ (function () {
    function HardwareMonitor(hwModule) {
        var _this = this;
        this.svgDom = Entry.Dom($(hwMonitorSvgTemplate));
        this.changeOffset = 0; // 0 : off 1: on
        this.scale = 0.5;
        this._listPortViews = {};
        this._hwModule = hwModule;
        Entry.addEventListener('windowResized', function () {
            var mode = _this._hwModule.monitorTemplate.mode;
            if (mode == 'both') {
                _this.resize();
                _this.resizeList();
            }
            if (mode == 'list') {
                _this.resizeList();
            }
            else {
                _this.resize();
            }
        });
        Entry.addEventListener('hwModeChange', function () {
            _this.changeMode();
        });
    }
    HardwareMonitor.prototype.initView = function () {
        this.svgDom = Entry.Dom($(hwMonitorSvgTemplate));
    };
    HardwareMonitor.prototype.generateViewByMode = function () {
        this._template =
            typeof this._hwModule.monitorTemplate === 'function'
                ? this._hwModule.monitorTemplate()
                : this._hwModule.monitorTemplate;
        var monitorTemplate = this._template;
        if (monitorTemplate.mode === 'both') {
            this.generateListView();
            this.generateView();
        }
        else if (monitorTemplate.mode === 'list') {
            this.generateListView();
        }
        else {
            this.generateView();
        }
    };
    HardwareMonitor.prototype.generateView = function () {
        this.snap = Entry.SVG('hwMonitor');
        this._svgGroup = this.snap.elem('g');
        this._portMap = {
            n: [],
            e: [],
            s: [],
            w: [],
        };
        var monitorTemplate = this._template;
        var imgObj = {
            href: monitorTemplate.imgPath
                ? Entry.mediaFilePath + monitorTemplate.imgPath
                : undefined,
            x: -monitorTemplate.width / 2,
            y: -monitorTemplate.height / 2,
            width: monitorTemplate.width,
            height: monitorTemplate.height,
        };
        this._portViews = {};
        if (imgObj.href) {
            this.hwView = this._svgGroup.elem('image');
            this.hwView = this.hwView.attr(imgObj);
        }
        var ports = monitorTemplate.ports;
        this.pathGroup = null;
        this.pathGroup = this._svgGroup.elem('g');
        var portsTemp = [];
        for (var key in ports) {
            var port = ports[key];
            var portView = this.generatePortView(port, '_svgGroup');
            this._portViews[key] = portView;
            portsTemp.push(portView);
        }
        portsTemp.sort(function (a, b) { return a.box.x - b.box.x; });
        var portMap = this._portMap;
        portsTemp.map(function (v) {
            var degree = (Math.atan2(-v.box.y, v.box.x) / Math.PI + 2) % 2;
            var map;
            if (degree < 1) {
                map = portMap.n;
            }
            else {
                map = portMap.s;
            }
            map.push(v);
        });
        this.resize();
    };
    HardwareMonitor.prototype.toggleMode = function (mode) {
        var monitorTemplate = this._hwModule.monitorTemplate;
        if (mode === 'list') {
            monitorTemplate.TempPort = null;
            if (this._hwModule.monitorTemplate.ports) {
                this._hwModule.monitorTemplate.TempPort = this._hwModule.monitorTemplate.ports;
                this._hwModule.monitorTemplate.listPorts = this.addPortEle(this._hwModule.monitorTemplate.listPorts, this._hwModule.monitorTemplate.ports);
            }
            $(this._svglistGroup).remove();
            if (this._svgGroup) {
                $(this._svgGroup).remove();
            }
            $(this._pathGroup).remove();
            this._hwModule.monitorTemplate.mode = 'list';
            this.generateListView();
        }
        else {
            if (this._hwModule.monitorTemplate.TempPort) {
                this._hwModule.monitorTemplate.ports = this._hwModule.monitorTemplate.TempPort;
                this._hwModule.monitorTemplate.listPorts = this.removePortEle(this._hwModule.monitorTemplate.listPorts, this._hwModule.monitorTemplate.ports);
            }
            $(this._svglistGroup).remove();
            this._hwModule.monitorTemplate.mode = 'both';
            this.generateListView();
            this.generateView();
        }
    };
    HardwareMonitor.prototype.setHwModule = function (hwModule) {
        this._hwModule = hwModule;
    };
    HardwareMonitor.prototype.changeMode = function () {
        if (this._hwModule.monitorTemplate.mode === 'both') {
            this.toggleMode('list');
        }
        else if (this._hwModule.monitorTemplate.mode === 'list') {
            this.toggleMode('both');
        }
    };
    HardwareMonitor.prototype.addPortEle = function (listPort, ports) {
        if (typeof ports != 'object') {
            return listPort;
        }
        for (var item in ports) {
            listPort[item] = ports[item];
        }
        return listPort;
    };
    HardwareMonitor.prototype.removePortEle = function (listPort, ports) {
        if (typeof ports != 'object') {
            return listPort;
        }
        for (var item in ports) {
            delete listPort[item];
        }
        return listPort;
    };
    HardwareMonitor.prototype.generateListView = function () {
        this._portMapList = {
            n: [],
        };
        this._svglistGroup = null;
        this.listsnap = Entry.SVG('hwMonitor');
        this._svglistGroup = this.listsnap.elem('g');
        var monitorTemplate = this._template;
        var ports = monitorTemplate.listPorts;
        this.pathGroup = this._svglistGroup.elem('g');
        var portsTempList = [];
        for (var key in ports) {
            var port = ports[key];
            var portView = this.generatePortView(port, '_svglistGroup');
            this._listPortViews[key] = portView;
            portsTempList.push(portView);
        }
        var portMapList = this._portMapList;
        portsTempList.map(function (v) {
            portMapList.n.push(v);
        });
        this.resizeList();
    };
    HardwareMonitor.prototype.generatePortView = function (port, target) {
        // @ts-ignore
        var svgGroup = this[target].elem('g');
        svgGroup.addClass('hwComponent');
        var path = null;
        path = this.pathGroup.elem('path').attr({
            d: 'm0,0',
            fill: 'none',
            stroke: port.type === 'input' ? '#00CFCA' : '#CA7DFF',
            'stroke-width': 3,
        });
        var wrapperRect = svgGroup.elem('rect').attr({
            x: 0,
            y: 0,
            width: 150,
            height: 22,
            rx: 4,
            ry: 4,
            fill: '#fff',
            stroke: '#a0a1a1',
        });
        var nameView = svgGroup.elem('text').attr({
            x: 4,
            y: 12,
            fill: '#000',
            class: 'hwComponentName',
            'alignment-baseline': 'central',
        });
        nameView.textContent = port.name;
        var width = nameView.getComputedTextLength();
        var valueRect = svgGroup.elem('rect').attr({
            x: width + 8,
            y: 2,
            width: 30,
            height: 18,
            rx: 9,
            ry: 9,
            fill: port.type === 'input' ? '#00CFCA' : '#CA7DFF',
        });
        var valueView = svgGroup.elem('text').attr({
            x: width + 13,
            y: 12,
            fill: '#fff',
            class: 'hwComponentValue',
            'alignment-baseline': 'central',
        });
        valueView.textContent = 0;
        width += 40;
        wrapperRect.attr({
            width: width,
        });
        var returnObj = {
            group: svgGroup,
            value: valueView,
            type: port.type,
            path: path,
            box: {
                x: port.pos.x - this._template.width / 2,
                y: port.pos.y - this._template.height / 2,
                width: width,
            },
            width: width,
        };
        return returnObj;
    };
    HardwareMonitor.prototype.getView = function () {
        return this.svgDom;
    };
    HardwareMonitor.prototype.update = function (portData, sendQueue) {
        var mode = this._template.mode;
        var objectKeys = this._template.keys || [];
        var portView = [];
        if (mode == 'list') {
            portView = this._listPortViews;
        }
        else if (mode == 'both' && this._portViews) {
            portView = this._listPortViews;
            for (var elem in this._portViews) {
                portView[elem] = this._portViews[elem];
            }
        }
        else {
            portView = this._portViews;
        }
        if (sendQueue) {
            for (var elem in sendQueue) {
                if (sendQueue[elem] != 0 && portView[elem]) {
                    portView[elem].type = 'output';
                }
            }
        }
        var _loop_1 = function (key) {
            var port = portView[key];
            if (port.type == 'input') {
                var value_1 = portData[key];
                if (objectKeys.length > 0) {
                    $.each(objectKeys, function (idx, valueKey) {
                        if ($.isPlainObject(value_1)) {
                            value_1 = value_1[valueKey] || 0;
                        }
                        else {
                            return false;
                        }
                    });
                    port.value.textContent = value_1 ? value_1 : 0;
                    port.group.getElementsByTagName('rect')[1].attr({ fill: '#00CFCA' });
                }
                else {
                    port.value.textContent = value_1 ? value_1 : 0;
                    port.group.getElementsByTagName('rect')[1].attr({ fill: '#00CFCA' });
                }
            }
            else {
                var value_2 = sendQueue ? sendQueue[key] : portData[key];
                if (objectKeys.length > 0) {
                    $.each(objectKeys, function (idx, valueKey) {
                        if ($.isPlainObject(value_2)) {
                            value_2 = value_2[valueKey] || 0;
                        }
                        else {
                            return false;
                        }
                    });
                    port.value.textContent = value_2 ? value_2 : 0;
                    port.group.getElementsByTagName('rect')[1].attr({ fill: '#CA7DFF' });
                }
                else {
                    port.value.textContent = value_2 ? value_2 : 0;
                    port.group.getElementsByTagName('rect')[1].attr({ fill: '#CA7DFF' });
                }
            }
        };
        for (var key in portView) {
            _loop_1(key);
        }
    };
    HardwareMonitor.prototype.resize = function () {
        if (!this.svgDom) {
            return;
        }
        var bRect = this.svgDom.get(0).getBoundingClientRect();
        this._svgGroup.attr({
            transform: "translate(".concat(bRect.width / 2, ",").concat(bRect.height / 1.8, ")"),
        });
        this._rect = bRect;
        if (this._template.height <= 0 || bRect.height <= 0) {
            return;
        }
        this.scale = (this._template.height * (bRect.height / this._template.height)) / 1000;
        if (this.hwView && this.scale) {
            this.hwView.attr({
                transform: "scale(".concat(this.scale, ")"),
            });
        }
        this.align();
    };
    HardwareMonitor.prototype.resizeList = function () {
        var bRect = this.svgDom.get(0).getBoundingClientRect();
        this._svglistGroup.attr({
            transform: "translate(".concat(bRect.width / 2, ",").concat(bRect.height / 2, ")"),
        });
        this._rect = bRect;
        this.alignList();
    };
    HardwareMonitor.prototype.align = function () {
        this._alignNS(this._portMap.s.concat(), this._template.height * (this.scale / 3) + 5, 27);
        this._alignNS(this._portMap.n.concat(), (-this._template.height * this.scale) / 3 - 32, -27);
    };
    HardwareMonitor.prototype.alignList = function () {
        var ports = this._hwModule.monitorTemplate.listPorts || {};
        var length = ports.length;
        for (var i = 0; i < ports.length; i++) {
            var port = ports[i];
            port.group.attr({
                transform: "translate(".concat(this._template.width * (i / length - 0.5), ",").concat(-this._template
                    .width /
                    2 -
                    30, ")"),
            });
        }
        ports = this._portMapList.n.concat();
        this._alignNSList(ports, (-this._template.width * this.scale) / 2 - 32 /*, -27*/);
    };
    HardwareMonitor.prototype._alignNS = function (ports, yCursor, gap) {
        var length = ports.length;
        var mid = (length - 1) / 2;
        var lP = -this._rect.width / 2;
        var rP = this._rect.width / 2;
        var width = this._rect.width;
        var wholeWidth = 0;
        var listLine = 0;
        var mode = this._hwModule.monitorTemplate.mode;
        for (var i = 0; i < ports.length; i++) {
            wholeWidth += ports[i].width + 5;
        }
        if (wholeWidth < rP - lP) {
            rP = wholeWidth / 2 + 3;
            lP = -wholeWidth / 2 - 3;
        }
        while (ports.length > 1) {
            var lPort = ports.shift();
            var rPort = ports.pop();
            var prevLP = lP;
            var prevRP = rP;
            var gapTemp = gap;
            if (wholeWidth <= rP - lP) {
                lP += lPort.width + 5;
                rP -= rPort.width + 5;
                gapTemp = 0;
            }
            else if (ports.length === 0) {
                lP = (lP + rP) / 2 - 3;
                rP = lP + 6;
            }
            else {
                lP = Math.max(lP, -width / 2 + lPort.width) + 15;
                rP = Math.min(rP, width / 2 - rPort.width) - 15;
            }
            this._movePort(lPort, lP, yCursor, prevLP);
            this._movePort(rPort, rP, yCursor, prevRP);
            wholeWidth -= lPort.width + rPort.width + 10;
            yCursor += gapTemp;
        }
        if (ports.length) {
            this._movePort(ports[0], (rP + lP - ports[0].width) / 2, yCursor, 100);
        }
    };
    HardwareMonitor.prototype._alignNSList = function (ports, yCursor) {
        var length = ports.length;
        var width = this._rect.width;
        var height = this._rect.height;
        var initX = -this._rect.width / 2 + 10;
        var initY = -this._rect.height / 2 + 10;
        var wholeWidth = 0;
        var listLine = 0;
        for (var i = 0; i < ports.length; i++) {
            wholeWidth += ports[i].width; // 전체 width
        }
        var lineIndent = 0;
        var currentWidth = 0;
        var tempXpos = initX;
        var Yval = 0;
        var cPort = 0;
        var nPort = 0;
        for (var i = 0; i < ports.length; i++) {
            cPort = ports[i];
            if (i != ports.length - 1) {
                nPort = ports[i + 1];
            }
            currentWidth += cPort.width;
            var lP = initX;
            Yval = initY + lineIndent * 30;
            cPort.group.attr({
                transform: "translate(".concat(lP, ",").concat(Yval, ")"),
            });
            initX += cPort.width + 10;
            if (currentWidth > width - (cPort.width + nPort.width / 2.2)) {
                lineIndent += 1;
                initX = tempXpos;
                currentWidth = 0;
            }
        }
    };
    HardwareMonitor.prototype._movePort = function (port, x, y, prevPointer) {
        var groupX = x;
        var path;
        var portX = port.box.x * this.scale;
        var portY = port.box.y * this.scale;
        if (x > prevPointer) {
            // left side
            groupX = x - port.width;
            if (x > portX && portX > prevPointer) {
                path = "M".concat(portX, ",").concat(y, "L").concat(portX, ",").concat(portY);
            }
            else {
                path = "M".concat((x + prevPointer) / 2, ",").concat(y, "l0,").concat(portY > y ? 28 : -3, "H").concat(portX, "L").concat(portX, ",").concat(portY);
            }
        }
        else if (x < portX && portX < prevPointer) {
            // right side
            path = "m".concat(portX, ",").concat(y, "L").concat(portX, ",").concat(portY);
        }
        else {
            path = "m".concat((prevPointer + x) / 2, ",").concat(y, "l0,").concat(portY > y ? 28 : -3, "H").concat(portX, "L").concat(portX, ",").concat(portY);
        }
        port.group.attr({ transform: "translate(".concat(groupX, ",").concat(y, ")") });
        port.path.attr({ d: path });
    };
    return HardwareMonitor;
}());
export default HardwareMonitor;
