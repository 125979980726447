var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import HardwareSocketMessageHandler from './hardware/hardwareSocketMessageHandler';
import HardwareMonitor from './hardware/hardwareMonitor';
import createHardwarePopup from './hardware/functions/createHardwarePopup';
import ExternalProgramLauncher from './hardware/externalProgramLauncher';
var HardwareModuleType;
(function (HardwareModuleType) {
    HardwareModuleType["builtIn"] = "builtin";
    HardwareModuleType["module"] = "module";
})(HardwareModuleType || (HardwareModuleType = {}));
var HardwareStatement;
(function (HardwareStatement) {
    HardwareStatement["disconnected"] = "disconnected";
    HardwareStatement["socketConnected"] = "socketConnected";
    HardwareStatement["hardwareConnected"] = "hardwareConnected";
})(HardwareStatement || (HardwareStatement = {}));
var Hardware = /** @class */ (function () {
    function Hardware() {
        this.socketConnectionRetryCount = 3;
        var prevRoomId = localStorage.getItem(this.cloudRoomIdKey);
        this.sessionRoomId = prevRoomId || this._createRandomRoomId();
        if (!prevRoomId) {
            localStorage.setItem(this.cloudRoomIdKey, this.sessionRoomId);
        }
        this.programConnected = false;
        this.communicationType = 'auto';
        this.portData = {};
        this.sendQueue = {};
        this.hwModuleType = HardwareModuleType.builtIn;
        this._initHardwareObject();
        this._addEntryEventListener();
    }
    Object.defineProperty(Hardware.prototype, "httpsServerAddress", {
        get: function () {
            return 'http://127.0.0.1:23518';
        } // 하드웨어 프로그램 접속용 주소
        ,
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Hardware.prototype, "httpsServerAddress2", {
        get: function () {
            return 'https://hardware.playentry.org:23518';
        } // legacy
        ,
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Hardware.prototype, "httpServerAddress", {
        get: function () {
            return 'http://127.0.0.1:23518';
        } // http 인 오프라인 접속용 주소
        ,
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Hardware.prototype, "cloudRoomIdKey", {
        get: function () {
            return 'entryhwRoomId';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Hardware.prototype, "socketConnectOption", {
        get: function () {
            return {
                transports: ['websocket', 'polling'],
                query: {
                    client: true,
                    roomId: this.sessionRoomId,
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Hardware.prototype._loadExternalHardwareBlock = function (moduleinfo) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Entry.moduleManager.loadModuleFromLocalOrOnline(moduleinfo.name)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        Entry.toast.alert(Lang.Msgs.hw_module_load_fail_title, "".concat(moduleinfo.name, " ").concat(Lang.Msgs.hw_module_load_fail_desc));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Hardware.prototype.retryConnect = function () {
        this._initSocket();
    };
    Hardware.prototype.openHardwareProgram = function (args) {
        var _this = this;
        this._alertUnderVersionUsed().then(function () {
            _this._executeHardware(args);
            if (!_this.socket || !_this.socket.connected) {
                setTimeout(function () {
                    _this._initSocket();
                }, 1000);
            }
        });
    };
    /**
     * 외부 하드웨어 모듈을 등록한다.
     * 이때 기존 외부 하드웨어 모듈이 추가되어있는 경우를 대비하여,
     * 현재 보여지고 있는 하드웨어 블록들을 전부 숨김처리한다.
     * @param moduleObject
     */
    Hardware.prototype.setExternalModule = function (moduleObject) {
        this.hwModule = moduleObject;
        this.hwModuleType = HardwareModuleType.module;
        this._banClassAllHardware();
        Entry.dispatchEvent('hwChanged');
    };
    /**
     * 하드웨어 블록메뉴의 노출상태를 변경한다.
     * 최초 실행시 모든 하드웨어 블록 숨김 / 미연결 상태 버튼 출력
     * 현재 하드웨어 로드가 외부 모듈에 의한 것인 경우는 연결이 해제되어도 블록숨김을 실행하지 않는다.
     */
    Hardware.prototype.refreshHardwareBlockMenu = function () {
        if (Entry.hwLite.getStatus() !== 'disconnected') {
            console.log('canel refreshHardwareBlockMenu() for HwLITE');
            return;
        }
        var workspace = Entry.getMainWS();
        var blockMenu = workspace && workspace.blockMenu;
        if (!blockMenu) {
            return;
        }
        if (!this.hwModule) {
            // NOTE 이 코드는 하드웨어 블록 초기화 작업도 겸하므로 삭제금지
            this._banClassAllHardware();
        }
        var disconnected = HardwareStatement.disconnected, socketConnected = HardwareStatement.socketConnected, hardwareConnected = HardwareStatement.hardwareConnected;
        if (this.programConnected) {
            if (this.hwModule) {
                blockMenu.unbanClass(this.hwModule.name);
                this._setHardwareDefaultMenu(hardwareConnected);
            }
            else {
                this._setHardwareDefaultMenu(socketConnected);
            }
        }
        else {
            this._setHardwareDefaultMenu(disconnected);
        }
        blockMenu.hwCodeOutdated = true;
        blockMenu._generateHwCode(true);
        blockMenu.reDraw();
    };
    Hardware.prototype.disconnectSocket = function () {
        if (this.programConnected) {
            Entry.propertyPanel && Entry.propertyPanel.removeMode('hw');
            this.programConnected = false;
            this.currentDeviceKey = undefined;
            /*
            entryjs 내에 존재하던 기존 하드웨어의 경우 원래 프로세스에 따라 연결 종료시 보여주지 않는다.
            만약 외부모듈인 경우, 하드웨어가 연결종료 되더라도 블록은 남는다.
             */
            if (this.hwModuleType === HardwareModuleType.builtIn) {
                this.hwModule = undefined;
            }
            this.socket && this.socket.close();
            this.socket = undefined;
            Entry.dispatchEvent('hwChanged');
            Entry.toast.alert(Lang.Msgs.hw_connection_termination_title, Lang.Msgs.hw_connection_termination_desc, false);
        }
    };
    /**
     * @deprecated
     */
    Hardware.prototype.setDigitalPortValue = function (port, value) {
        console.warn('this function will be deprecated. please use Entry.hw.sendQueue directly.');
        this.sendQueue[port] = value;
        this.removePortReadable(port);
    };
    /**
     * @deprecated
     */
    Hardware.prototype.getAnalogPortValue = function (port) {
        console.warn('this function will be deprecated. please use Entry.hw.portData directly.');
        if (!this.programConnected || !this.hwModule) {
            return 0;
        }
        return this.portData["a".concat(port)];
    };
    /**
     * @deprecated
     */
    Hardware.prototype.getDigitalPortValue = function (port) {
        console.warn('this function will be deprecated. please use Entry.hw.portData directly.');
        if (!this.programConnected || !this.hwModule) {
            return 0;
        }
        this.setPortReadable(port);
        if (this.portData[port] !== undefined) {
            return this.portData[port];
        }
        else {
            return 0;
        }
    };
    /**
     * @deprecated
     */
    Hardware.prototype.setPortReadable = function (port) {
        console.warn('this function will be deprecated. please control port state directly.');
        if (!this.sendQueue.readablePorts) {
            this.sendQueue.readablePorts = [];
        }
        var isPass = false;
        for (var i in this.sendQueue.readablePorts) {
            if (this.sendQueue.readablePorts[i] == port) {
                isPass = true;
                break;
            }
        }
        if (!isPass) {
            this.sendQueue.readablePorts.push(port);
        }
    };
    /**
     * @deprecated
     */
    Hardware.prototype.removePortReadable = function (port) {
        console.warn('this function will be deprecated. please use Entry.hw.sendQueue directly.');
        if (!this.sendQueue.readablePorts && !Array.isArray(this.sendQueue.readablePorts)) {
            return;
        }
        var target;
        for (var i in this.sendQueue.readablePorts) {
            if (this.sendQueue.readablePorts[i] == port) {
                target = Number(i);
                break;
            }
        }
        if (target != undefined) {
            this.sendQueue.readablePorts = this.sendQueue.readablePorts
                .slice(0, target)
                .concat(this.sendQueue.readablePorts.slice(target + 1, this.sendQueue.readablePorts.length));
        }
    };
    Hardware.prototype.update = function () {
        if (!this.socket || this.socket.disconnected) {
            return;
        }
        if (this.hwModule && this.hwModule.sendMessage) {
            this.hwModule.sendMessage(this);
        }
        else {
            this._sendSocketMessage({
                data: JSON.stringify(this.sendQueue),
                mode: this.socketMode,
                type: 'utf8',
            });
        }
        this.hwModule && this.hwModule.afterSend && this.hwModule.afterSend(this.sendQueue);
    };
    Hardware.prototype.closeConnection = function () {
        var _a;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close();
    };
    Hardware.prototype.downloadConnector = function () {
        Entry.dispatchEvent('hwDownload', 'hardware');
    };
    Hardware.prototype.downloadGuide = function () {
        Entry.dispatchEvent('hwDownload', 'manual');
    };
    Hardware.prototype.downloadSource = function () {
        Entry.dispatchEvent('hwDownload', 'ino');
    };
    Hardware.prototype.setZero = function () {
        var _a;
        (_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.setZero();
    };
    /**
     * 디바이스의 연결상태를 체크한다.
     * 만약 이미 바로 직전에 동일한 하드웨어와 연결된 경우는 dataHandler 로 데이터를 전송하기만 한다.
     * 새로운 하드웨어의 연결인 경우는 연결 하드웨어를 치환하고 엔트리에 상태변경을 요청한다.
     * @param data
     */
    Hardware.prototype.checkDevice = function (data) {
        if (data.company === undefined) {
            return;
        }
        var key = "".concat(this._convertHexToString(data.company), ".").concat(this._convertHexToString(data.model));
        if (this.currentDeviceKey && key === this.currentDeviceKey) {
            if (this.hwModule && this.hwModule.dataHandler) {
                this.hwModule.dataHandler(data);
            }
            return;
        }
        this.currentDeviceKey = key;
        this.hwModule = Entry.HARDWARE_LIST[key];
        if (!this.hwModule) {
            return;
        }
        this.communicationType = this.hwModule.communicationType || 'auto';
        this._banClassAllHardware();
        Entry.dispatchEvent('hwChanged');
        if (Entry.propertyPanel && this.hwModule.monitorTemplate) {
            this._setHardwareMonitorTemplate();
        }
        Entry.toast.success(Lang.Msgs.hw_connection_success, Lang.Msgs.hw_connection_success_desc2);
    };
    Hardware.prototype.openHardwareDownloadPopup = function () {
        if (Entry.events_.openHardWareDownloadModal) {
            Entry.dispatchEvent('openHardWareDownloadModal');
        }
        else {
            this.popupHelper.show('hwDownload', true);
        }
    };
    Hardware.prototype._initHardwareObject = function () {
        var _this = this;
        var hardwareEnable = Entry.hardwareEnable;
        this.popupHelper = createHardwarePopup(function () {
            _this.downloadConnector();
        });
        hardwareEnable && this._initSocket();
    };
    Hardware.prototype._addEntryEventListener = function () {
        // hwChanged 에 걸려있는 다른 이벤트 함수와 동일선상에 두기위함
        Entry.addEventListener('hwChanged', this.refreshHardwareBlockMenu.bind(this));
        Entry.addEventListener('stop', this.setZero.bind(this));
    };
    Hardware.prototype._createRandomRoomId = function () {
        return 'xxxxxxxxyx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0;
            var v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
    Hardware.prototype._trySocketConnect = function (url) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var socket = io.connect(url, _this.socketConnectOption);
            socket.io.reconnectionAttempts(_this.socketConnectionRetryCount);
            socket.io.reconnectionDelayMax(1000);
            socket.io.timeout(1000);
            socket.on('connect', function () {
                _this._handleSocketConnected(socket);
                resolve();
            });
            socket.on('reconnect_failed', function () {
                reject();
            });
        });
    };
    Hardware.prototype._handleSocketConnected = function (socket) {
        var _this = this;
        socket.removeEventListener('reconnect_failed');
        socket.removeEventListener('connect');
        // this._initHardware(socket);
        this.socket = socket;
        this._setSocketConnected();
        if (Entry.playground && Entry.playground.object) {
            Entry.playground.setMenu(Entry.playground.object.objectType);
        }
        socket.on('connect', function () {
            _this._setSocketConnected();
        });
        socket.on('mode', function (mode) {
            if (_this.socketMode === 0 && mode === 1) {
                _this._disconnectHardware();
            }
            _this.socketMode = mode;
        });
        var messageHandler = new HardwareSocketMessageHandler(socket);
        messageHandler.addEventListener('init', this._loadExternalHardwareBlock.bind(this));
        messageHandler.addEventListener('state', function (statement, name) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = statement;
                        switch (_a) {
                            case 'disconnectHardware': return [3 /*break*/, 1];
                            case 'connected': return [3 /*break*/, 2];
                        }
                        return [3 /*break*/, 4];
                    case 1:
                        this._disconnectHardware();
                        return [3 /*break*/, 5];
                    case 2: 
                    // init action 과 동일동작
                    return [4 /*yield*/, this._loadExternalHardwareBlock(name)];
                    case 3:
                        // init action 과 동일동작
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4: return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); });
        // 1.7.0 버전 이전 하드웨어 프로그램 종료로직 대응으로 남겨두어야 한다.
        messageHandler.addEventListener('disconnect', this._disconnectHardware.bind(this));
        messageHandler.addEventListener('data', function (portData) {
            _this.portData = portData;
            _this.checkDevice(portData);
            _this._updatePortData(portData);
            if (_this.hwModule && _this.hwModule.afterReceive) {
                _this.hwModule.afterReceive(portData);
            }
        });
        socket.on('disconnect', function () {
            // cloud PC 연결 클릭시 순간 disconnect 되고 재연결을 시도하기 위한 로직
            _this._setSocketClosed();
            _this.reconnectionTimeout = setTimeout(function () {
                _this._initSocket();
            }, 1500);
        });
        socket.on('reconnect_failed', function () {
            _this._setSocketClosed();
        });
    };
    Hardware.prototype._setSocketConnected = function () {
        this.programConnected = true;
        console.log('Hardware Program Connected'); // 하드웨어 프로그램 연결 성공, 스테이터스 변화 필요
        Entry.dispatchEvent('hwChanged');
    };
    Hardware.prototype._setSocketClosed = function (needRedraw) {
        if (needRedraw === void 0) { needRedraw = true; }
        this.programConnected = false;
        this.hwModule = undefined;
        this.currentDeviceKey = undefined;
        needRedraw && Entry.dispatchEvent('hwChanged');
    };
    /**
     * 이미 연결했던 소켓이 존재한다면 재연결을 시도한다.
     * 연결성공했던 소켓이 없다면 전체 리스트를 순회하며 연결을 시도한다.
     * @private
     */
    Hardware.prototype._initSocket = function () {
        var _this = this;
        if (this.socket) {
            if (this.reconnectionTimeout) {
                clearTimeout(this.reconnectionTimeout);
                this.reconnectionTimeout = undefined;
            }
            this.socket.io.reconnection(true);
            this.socket.connect();
        }
        else {
            var connectionTries = [this.httpsServerAddress, this.httpsServerAddress2];
            // http 혹은 파일시스템 프로토콜에서 동작하는 경우, 로컬호스트 를 최우선 연결시도 한다.
            if (['http:', 'file:'].indexOf(location.protocol) > -1) {
                connectionTries.unshift(this.httpServerAddress);
            }
            else {
                connectionTries.push(this.httpServerAddress);
            }
            // 주소에 담겨져있는 순서대로 소켓 연결을 요청한다.
            connectionTries
                .reduce(function (prevPromise, address) { return __awaiter(_this, void 0, void 0, function () {
                var prevResult, e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, prevPromise];
                        case 1:
                            prevResult = _a.sent();
                            if (prevResult) {
                                return [2 /*return*/, true];
                            }
                            // NOTE : 하드웨어 웹연결과 충돌을 방지
                            if (Entry.hwLite.getStatus() !== 'disconnected') {
                                console.log('canel connectionTry for HwLITE');
                                return [2 /*return*/];
                            }
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, this._trySocketConnect(address)];
                        case 3:
                            _a.sent();
                            return [2 /*return*/, true];
                        case 4:
                            e_2 = _a.sent();
                            return [2 /*return*/, !!(this.programConnected || this.socket)];
                        case 5: return [2 /*return*/];
                    }
                });
            }); }, undefined)
                .then(function (result) {
                // 하드웨어 소켓 연결 시도 결과 반환 로직
                if (!result) {
                    console.warn('All hardware socket connection failed');
                    _this._setSocketClosed();
                }
            })
                .catch(function () {
                console.error('Error occurred while try to connect hardware socket');
            });
        }
    };
    /**
     * 하드웨어 버튼 노출상태를 변경한다.
     * @param statement {HardwareStatement}
     * @private
     */
    Hardware.prototype._setHardwareDefaultMenu = function (statement) {
        var _a;
        var workspace = Entry.getMainWS();
        var blockMenu = workspace && workspace.blockMenu;
        if (!blockMenu) {
            return;
        }
        var disconnected = HardwareStatement.disconnected, socketConnected = HardwareStatement.socketConnected, hardwareConnected = HardwareStatement.hardwareConnected;
        switch (statement) {
            case disconnected:
                blockMenu.unbanClass('arduinoDisconnected', true);
                blockMenu.banClass('arduinoConnected', true);
                blockMenu.banClass('arduinoConnect', true);
                ((_a = Entry.hwLite) === null || _a === void 0 ? void 0 : _a.isHwLiteSupportAgent())
                    ? blockMenu.unbanClass('arduinoLiteSupported', true)
                    : blockMenu.banClass('arduinoLiteSupported', true);
                break;
            case socketConnected:
                blockMenu.banClass('arduinoLiteSupported', true);
                blockMenu.banClass('arduinoDisconnected', true);
                blockMenu.banClass('arduinoConnected', true);
                blockMenu.unbanClass('arduinoConnect', true);
                break;
            case hardwareConnected:
                blockMenu.banClass('arduinoLiteSupported', true);
                blockMenu.banClass('arduinoDisconnected', true);
                blockMenu.unbanClass('arduinoConnected', true);
                blockMenu.banClass('arduinoConnect', true);
                break;
        }
    };
    /**
     * 모든 하드웨어를 숨김처리한다. 현재 연결된 하드웨어도 예외는 없다.
     * @private
     */
    Hardware.prototype._banClassAllHardware = function () {
        var workspace = Entry.getMainWS();
        var blockMenu = workspace && workspace.blockMenu;
        if (!blockMenu) {
            return;
        }
        Object.values(Entry.HARDWARE_LIST || {}).forEach(function (hardware) {
            blockMenu.banClass(hardware.name, true);
        });
    };
    Hardware.prototype._disconnectHardware = function () {
        if (this.hwModule) {
            Entry.propertyPanel && Entry.propertyPanel.removeMode('hw');
            this.currentDeviceKey = undefined;
            this.hwModule = undefined;
            Entry.dispatchEvent('hwChanged');
            Entry.toast.alert(Lang.Msgs.hw_connection_termination_title, Lang.Msgs.hw_connection_termination_desc, false);
        }
    };
    Hardware.prototype._sendSocketMessage = function (message) {
        if (this.programConnected && this.socket && !this.socket.disconnected) {
            this.socket.emit('message', message);
        }
    };
    Hardware.prototype._updatePortData = function (data) {
        if (this.hwMonitor && Entry.propertyPanel && Entry.propertyPanel.selected === 'hw') {
            this.hwMonitor.update(data, this.sendQueue);
        }
    };
    Hardware.prototype._setHardwareMonitorTemplate = function () {
        if (!this.hwMonitor) {
            this.hwMonitor = new HardwareMonitor(this.hwModule);
        }
        else {
            this.hwMonitor.setHwModule(this.hwModule);
            this.hwMonitor.initView();
        }
        Entry.propertyPanel.addMode('hw', this.hwMonitor);
        this.hwMonitor.generateViewByMode();
    };
    /**
     * 버전 공지용 함수.
     * 1.9.0 버전으로 올라가면서 SSL 인증서 문제로 과거버전은 소켓연결에 문제가 있음.
     * 그에 따른 조치이기 때문에 추후 유저들이 1.9.0 버전의 사용비중이 높아진다면 삭제해도 무방하다.
     * @returns {Promise<void>}
     * @private
     */
    Hardware.prototype._alertUnderVersionUsed = function () {
        return new Promise(function (resolve) {
            var dontShowChecked = localStorage.getItem('skipNoticeHWOldVersion');
            if (!dontShowChecked) {
                var title = Lang.Msgs.hardware_need_update_title;
                var content = Lang.Msgs.hardware_need_update_content;
                Entry.modal
                    .alert(content, title, {
                    withDontShowAgain: true,
                })
                    .then(function (data) {
                    var dontShowChecked = (data || {}).dontShowChecked;
                    if (dontShowChecked) {
                        localStorage.setItem('skipNoticeHWOldVersion', 'true');
                    }
                    resolve(null);
                });
            }
            else {
                resolve(null);
            }
        });
    };
    Hardware.prototype._executeHardware = function (args) {
        var _this = this;
        this.programLauncher = new ExternalProgramLauncher();
        var customSchemaArgsString = Object.entries(__assign({ roomId: this.sessionRoomId }, args)).reduce(function (result, _a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return result === '' ? "".concat(key, ":").concat(value) : "".concat(result, "&").concat(key, ":").concat(value);
        }, '');
        var entryHardwareUrl = "entryhw://?".concat(customSchemaArgsString);
        console.log('request Hardware using url custom schema.. : ', entryHardwareUrl);
        this.programLauncher.executeUrl(entryHardwareUrl, function () { return _this.openHardwareDownloadPopup(); });
    };
    Hardware.prototype._convertHexToString = function (num) {
        if (typeof num === 'string') {
            return num.toUpperCase();
        }
        return num.toString(16).toUpperCase();
    };
    return Hardware;
}());
export default Hardware;
Entry.HW = Hardware;
