'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
var obj = {
  createTooltip: function createTooltip(title, content, target, callback) {
    var option = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    return new Entry.Tooltip([{
      title: title,
      content: content,
      target: target
    }], (0, _extends2["default"])({
      restrict: true,
      dimmed: true,
      callBack: callback
    }, option));
  },
  returnEmptyArr: function returnEmptyArr() {
    return [];
  },
  getExpectedData: function getExpectedData(name, defaultValue) {
    var expected = (Entry.expectedAction || []).concat();
    if (!name || _.isEmpty(expected)) {
      return defaultValue;
    }
    expected.shift();
    var ret = _.find(expected, function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 1),
        key = _ref2[0];
      return key === name;
    });
    if (ret) {
      return ret[1];
    }
    return defaultValue;
  }
};
Entry.Command[COMMAND_TYPES.dismissModal] = {
  "do": function _do() {
    Entry.dispatchEvent('dismissModal');
  },
  state: obj.returnEmptyArr,
  log: obj.returnEmptyArr,
  undo: 'dismissModal',
  recordable: Entry.STATIC.RECORDABLE.SKIP,
  validate: false,
  dom: []
};
module.exports = obj;