import { PrimitiveMap } from './structure/PrimitiveMap';
import { EntryTexture } from './texture/EntryTexture';
import { EntryBaseTexture } from './texture/EntryBaseTexture';
import { PIXIAtlasHelper } from './PIXIAtlasHelper';
import { clog } from '../utils/logs';
import { TimeoutTimer } from '../utils/TimeoutTimer';
var SceneTextures = /** @class */ (function () {
    function SceneTextures(sceneID, _option, _loader) {
        this.sceneID = sceneID;
        this._option = _option;
        this._loader = _loader;
        this._path_tex_map = new PrimitiveMap();
        this._gcTimer = new TimeoutTimer();
    }
    SceneTextures.prototype._internal_imageRemoved = function () {
        var _this = this;
        if (this._gcTimer.isRunning) {
            return;
        }
        this._gcTimer.timeout(500, function () {
            _this._gcTexture();
        });
    };
    SceneTextures.prototype._gcTexture = function () {
        var _this = this;
        var usedPathSet = PIXIAtlasHelper.getScenePathSet(this.sceneID);
        var deleteCnt = 0;
        this._path_tex_map.each(function (tex, path) {
            if (usedPathSet.hasValue(path)) {
                return;
            }
            tex.destroy(true);
            _this._path_tex_map.remove(path);
            deleteCnt++;
        });
        if (deleteCnt) {
            clog("[SceneTextures] ".concat(deleteCnt, " textures deleted"));
        }
    };
    SceneTextures.prototype.activate = function () {
        var _this = this;
        this._activated = true;
        this._path_tex_map.each(function (tex, path) {
            var info = _this._loader.getImageInfo(path);
            if (!info || !info.isReady) {
                return;
            }
            _this.putImage(info, false);
        });
    };
    SceneTextures.prototype.addPicInfo = function (pic) {
        var path = PIXIAtlasHelper.getRawPath(pic);
        var map = this._path_tex_map;
        if (map.hasValue(path)) {
            return;
        }
        var info = this._loader.getImageInfo(path);
        var rect = PIXIAtlasHelper.getNewImageRect(pic, this._option.texMaxRect);
        if (!info) {
            this._loader.load(pic, rect);
        }
        var tex = this._newTexture(path, rect);
        map.add(path, tex);
        if (info && info.isReady) {
            this.putImage(info, false);
        }
    };
    SceneTextures.prototype._newTexture = function (path, rect) {
        var baseTex = new EntryBaseTexture();
        baseTex.width = rect.width;
        baseTex.height = rect.height;
        baseTex.mipmap = this._option.mipmap;
        baseTex.scaleMode = this._option.scaleMode;
        var tex = new EntryTexture(baseTex, rect);
        this._path_tex_map.add(path, tex);
        return tex;
    };
    SceneTextures.prototype.deactivate = function () {
        this._activated = false;
        this._path_tex_map.each(function (tex, path) {
            tex.getBaseTexture().dispose();
        });
    };
    SceneTextures.prototype.getTexture = function (path) {
        return this._path_tex_map.getValue(path);
    };
    SceneTextures.prototype.putImage = function (info, forceUpdateBaseTexture) {
        var tex = this._path_tex_map.getValue(info.path);
        if (!tex) {
            return;
        }
        var baseTex = tex.getBaseTexture();
        var source = info.source();
        // protected 값에대한 직접접근입니다. 타입체크 하지않습니다.
        // @ts-ignore
        if (tex._frame) {
            // @ts-ignore
            var textureFrame = tex._frame;
            if (textureFrame.height != info.srcHeight) {
                textureFrame.height = info.srcHeight;
            }
            if (textureFrame.width != info.srcWidth) {
                textureFrame.width = info.srcWidth;
            }
        }
        baseTex.updateSource(source);
        Entry.requestUpdate = true;
    };
    SceneTextures.prototype.destroy = function () {
        clog('destroy', this.sceneID);
        this._gcTimer.reset();
        this._gcTimer = null;
        this._path_tex_map.each(function (tex, path) {
            tex.destroy(true);
        });
        this._path_tex_map.destroy();
        this._path_tex_map = null;
    };
    return SceneTextures;
}());
export { SceneTextures };
