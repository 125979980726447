var Bin = /** @class */ (function () {
    function Bin() {
    }
    Bin.prototype.pushRect = function (rect, oversized) {
        rect.binIndex = this.index;
        rect.oversized = oversized;
        this.rects.push(rect);
    };
    return Bin;
}());
export { Bin };
