'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
function getInitialData() {
  var locationObj = {};
  locationObj.initialData = [Lang.Blocks.location_entire, 'all'];
  locationObj.ko = {
    'Seoul': {
      'lang': Lang.Blocks.location_seoul,
      'sub': [[Lang.Blocks.location_seoul_jongno_gu, 'jongno_gu'], [Lang.Blocks.location_seoul_jung_gu, 'jung_gu'], [Lang.Blocks.location_seoul_yongsan_gu, 'yongsan_gu'], [Lang.Blocks.location_seoul_seongdong_gu, 'seongdong_gu'], [Lang.Blocks.location_seoul_gwangjin_gu, 'gwangjin_gu'], [Lang.Blocks.location_seoul_dongdaemun_gu, 'dongdaemun_gu'], [Lang.Blocks.location_seoul_jungnang_gu, 'jungnang_gu'], [Lang.Blocks.location_seoul_seongbuk_gu, 'seongbuk_gu'], [Lang.Blocks.location_seoul_gangbuk_gu, 'gangbuk_gu'], [Lang.Blocks.location_seoul_dobong_gu, 'dobong_gu'], [Lang.Blocks.location_seoul_nowon_gu, 'nowon_gu'], [Lang.Blocks.location_seoul_eunpyeong_gu, 'eunpyeong_gu'], [Lang.Blocks.location_seoul_seodaemun_gu, 'seodaemun_gu'], [Lang.Blocks.location_seoul_mapo_gu, 'mapo_gu'], [Lang.Blocks.location_seoul_yangcheon_gu, 'yangcheon_gu'], [Lang.Blocks.location_seoul_gangseo_gu, 'gangseo_gu'], [Lang.Blocks.location_seoul_guro_gu, 'guro_gu'], [Lang.Blocks.location_seoul_geumcheon_gu, 'geumcheon_gu'], [Lang.Blocks.location_seoul_yeongdeungpo_gu, 'yeongdeungpo_gu'], [Lang.Blocks.location_seoul_dongjak_gu, 'dongjak_gu'], [Lang.Blocks.location_seoul_gwanak_gu, 'gwanak_gu'], [Lang.Blocks.location_seoul_seocho_gu, 'seocho_gu'], [Lang.Blocks.location_seoul_gangnam_gu, 'gangnam_gu'], [Lang.Blocks.location_seoul_songpa_gu, 'songpa_gu'], [Lang.Blocks.location_seoul_gangdong_gu, 'gangdong_gu']]
    },
    'Gangwon': {
      'lang': Lang.Blocks.location_gangwon,
      'sub': [[Lang.Blocks.location_gangwon_chuncheon, 'chuncheon'], [Lang.Blocks.location_gangwon_wonju, 'wonju'], [Lang.Blocks.location_gangwon_gangneung_si, 'gangneung_si'], [Lang.Blocks.location_gangwon_donghae, 'donghae'], [Lang.Blocks.location_gangwon_taebaek_si, 'taebaek_si'], [Lang.Blocks.location_gangwon_sokcho, 'sokcho'], [Lang.Blocks.location_gangwon_samcheok_si, 'samcheok_si'], [Lang.Blocks.location_gangwon_hongcheon, 'hongcheon'], [Lang.Blocks.location_gangwon_hoengseong_gun, 'hoengseong_gun'], [Lang.Blocks.location_gangwon_yeongwol_gun, 'yeongwol_gun'], [Lang.Blocks.location_gangwon_pyeongchang, 'pyeongchang'], [Lang.Blocks.location_gangwon_jeongseon, 'jeongseon'], [Lang.Blocks.location_gangwon_cheolwon, 'cheolwon'], [Lang.Blocks.location_gangwon_hwacheon, 'hwacheon'], [Lang.Blocks.location_gangwon_yanggu, 'yanggu'], [Lang.Blocks.location_gangwon_inje, 'inje'], [Lang.Blocks.location_gangwon_goseong, 'goseong'], [Lang.Blocks.location_gangwon_yangyang, 'yangyang']]
    },
    'Gyeonggi-do': {
      'lang': Lang.Blocks.location_gyeonggi,
      'sub': [[Lang.Blocks.location_gyeonggi_suwon_si, 'suwon_si'], [Lang.Blocks.location_gyeonggi_seongnam, 'seongnam'], [Lang.Blocks.location_gyeonggi_uijeongbu, 'uijeongbu'], [Lang.Blocks.location_gyeonggi_anyang_si, 'anyang_si'], [Lang.Blocks.location_gyeonggi_bucheon_si, 'bucheon_si'], [Lang.Blocks.location_gyeonggi_gwangmyeong_si, 'gwangmyeong_si'], [Lang.Blocks.location_gyeonggi_pyeongtaek_si, 'pyeongtaek_si'], [Lang.Blocks.location_gyeonggi_dongducheon_si, 'dongducheon_si'], [Lang.Blocks.location_gyeonggi_ansan_si, 'ansan_si'], [Lang.Blocks.location_gyeonggi_goyang_si, 'goyang_si'], [Lang.Blocks.location_gyeonggi_gwacheon_si, 'gwacheon_si'], [Lang.Blocks.location_gyeonggi_guri, 'guri'], [Lang.Blocks.location_gyeonggi_namyangju_si, 'namyangju_si'], [Lang.Blocks.location_gyeonggi_osan, 'osan'], [Lang.Blocks.location_gyeonggi_siheung, 'siheung'], [Lang.Blocks.location_gyeonggi_gunpo_si, 'gunpo_si'], [Lang.Blocks.location_gyeonggi_uiwang_si, 'uiwang_si'], [Lang.Blocks.location_gyeonggi_hanam, 'hanam'], [Lang.Blocks.location_gyeonggi_yongin_si, 'yongin_si'], [Lang.Blocks.location_gyeonggi_paju, 'paju'], [Lang.Blocks.location_gyeonggi_icheon, 'icheon'], [Lang.Blocks.location_gyeonggi_anseong_si, 'anseong_si'], [Lang.Blocks.location_gyeonggi_gimpo, 'gimpo'], [Lang.Blocks.location_gyeonggi_hwaseong_si, 'hwaseong_si'], [Lang.Blocks.location_gyeonggi_gwangju, 'gwangju'], [Lang.Blocks.location_gyeonggi_yangju_si, 'yangju_si'], [Lang.Blocks.location_gyeonggi_pocheon, 'pocheon'], [Lang.Blocks.location_gyeonggi_yeoju, 'yeoju'], [Lang.Blocks.location_gyeonggi_yeoncheon_gun, 'yeoncheon_gun'], [Lang.Blocks.location_gyeonggi_gapyeong_gun, 'gapyeong_gun'], [Lang.Blocks.location_gyeonggi_yangpyeong, 'yangpyeong']]
    },
    'Gyeongsangnam-do': {
      'lang': Lang.Blocks.location_gyeongsangnam,
      'sub': [[Lang.Blocks.location_gyeongsangnam_changwon_si, 'changwon_si'], [Lang.Blocks.location_gyeongsangnam_jinju, 'jinju'], [Lang.Blocks.location_gyeongsangnam_tongyeong_si, 'tongyeong_si'], [Lang.Blocks.location_gyeongsangnam_sacheon_si, 'sacheon_si'], [Lang.Blocks.location_gyeongsangnam_gimhae_si, 'gimhae_si'], [Lang.Blocks.location_gyeongsangnam_miryang_si, 'miryang_si'], [Lang.Blocks.location_gyeongsangnam_geoje_si, 'geoje_si'], [Lang.Blocks.location_gyeongsangnam_yangsan_si, 'yangsan_si'], [Lang.Blocks.location_gyeongsangnam_uiryeong, 'uiryeong'], [Lang.Blocks.location_gyeongsangnam_haman, 'haman'], [Lang.Blocks.location_gyeongsangnam_changnyeong, 'changnyeong'], [Lang.Blocks.location_gyeongsangnam_goseong, 'goseong'], [Lang.Blocks.location_gyeongsangnam_namhae, 'namhae'], [Lang.Blocks.location_gyeongsangnam_hadong, 'hadong'], [Lang.Blocks.location_gyeongsangnam_sancheong, 'sancheong'], [Lang.Blocks.location_gyeongsangnam_hamyang, 'hamyang'], [Lang.Blocks.location_gyeongsangnam_geochang, 'geochang'], [Lang.Blocks.location_gyeongsangnam_hapcheon, 'hapcheon']]
    },
    'Gyeongsangbuk-do': {
      'lang': Lang.Blocks.location_gyeongsangbuk,
      'sub': [[Lang.Blocks.location_gyeongsangbuk_pohang_si, 'pohang_si'], [Lang.Blocks.location_gyeongsangbuk_gyeongju_si, 'gyeongju_si'], [Lang.Blocks.location_gyeongsangbuk_gimcheon_si, 'gimcheon_si'], [Lang.Blocks.location_gyeongsangbuk_andong_si, 'andong_si'], [Lang.Blocks.location_gyeongsangbuk_gumi_si, 'gumi_si'], [Lang.Blocks.location_gyeongsangbuk_yeongju_si, 'yeongju_si'], [Lang.Blocks.location_gyeongsangbuk_yeongcheon_si, 'yeongcheon_si'], [Lang.Blocks.location_gyeongsangbuk_sangju_si, 'sangju_si'], [Lang.Blocks.location_gyeongsangbuk_mungyeong_si, 'mungyeong_si'], [Lang.Blocks.location_gyeongsangbuk_gyeongsan_si, 'gyeongsan_si'], [Lang.Blocks.location_gyeongsangbuk_uiseong, 'uiseong'], [Lang.Blocks.location_gyeongsangbuk_cheongsong, 'cheongsong'], [Lang.Blocks.location_gyeongsangbuk_goryong, 'goryong'], [Lang.Blocks.location_gyeongsangbuk_yeongdeok, 'yeongdeok'], [Lang.Blocks.location_gyeongsangbuk_cheongdo, 'cheongdo'], [Lang.Blocks.location_gyeongsangbuk_golyeong, 'golyeong'], [Lang.Blocks.location_gyeongsangbuk_seongju, 'seongju'], [Lang.Blocks.location_gyeongsangbuk_chilgok, 'chilgok'], [Lang.Blocks.location_gyeongsangbuk_yecheon, 'yecheon'], [Lang.Blocks.location_gyeongsangbuk_bonghwa, 'bonghwa'], [Lang.Blocks.location_gyeongsangbuk_uljin, 'uljin'], [Lang.Blocks.location_gyeongsangbuk_ulleung, 'ulleung']]
    },
    'Gwangju': {
      'lang': Lang.Blocks.location_gwangju,
      'sub': [[Lang.Blocks.location_gwangju_dong_gu, 'dong_gu'], [Lang.Blocks.location_gwangju_seo_gu, 'seo_gu'], [Lang.Blocks.location_gwangju_nam_gu, 'nam_gu'], [Lang.Blocks.location_gwangju_buk_gu, 'buk_gu'], [Lang.Blocks.location_gwangju_gwangsan_gu, 'gwangsan_gu']]
    },
    'Daegu': {
      'lang': Lang.Blocks.location_daegu,
      'sub': [[Lang.Blocks.location_daegu_jung_gu, 'jung_gu'], [Lang.Blocks.location_daegu_dong_gu, 'dong_gu'], [Lang.Blocks.location_daegu_seo_gu, 'seo_gu'], [Lang.Blocks.location_daegu_nam_gu, 'nam_gu'], [Lang.Blocks.location_daegu_buk_gu, 'buk_gu'], [Lang.Blocks.location_daegu_suseong_gu, 'suseong_gu'], [Lang.Blocks.location_daegu_dalseo_gu, 'dalseo_gu'], [Lang.Blocks.location_daegu_dalseong, 'dalseong'], [Lang.Blocks.location_daegu_gunwi, 'gunwi']]
    },
    'Daejeon': {
      'lang': Lang.Blocks.location_daejeon,
      'sub': [[Lang.Blocks.location_daejeon_dong_gu, 'dong_gu'], [Lang.Blocks.location_daejeon_jung_gu, 'jung_gu'], [Lang.Blocks.location_daejeon_seo_gu, 'seo_gu'], [Lang.Blocks.location_daejeon_yuseong_gu, 'yuseong_gu'], [Lang.Blocks.location_daejeon_daedeok_gu, 'daedeok_gu']]
    },
    'Busan': {
      'lang': Lang.Blocks.location_busan,
      'sub': [[Lang.Blocks.location_busan_jung_gu, 'jung_gu'], [Lang.Blocks.location_busan_seo_gu, 'seo_gu'], [Lang.Blocks.location_busan_dong_gu, 'dong_gu'], [Lang.Blocks.location_busan_yeongdo_gu, 'yeongdo_gu'], [Lang.Blocks.location_busan_busanjin_gu, 'busanjin_gu'], [Lang.Blocks.location_busan_dongnae_gu, 'dongnae_gu'], [Lang.Blocks.location_busan_nam_gu, 'nam_gu'], [Lang.Blocks.location_busan_buk_gu, 'buk_gu'], [Lang.Blocks.location_busan_haeundae_gu, 'haeundae_gu'], [Lang.Blocks.location_busan_saha_gu, 'saha_gu'], [Lang.Blocks.location_busan_geumjeong_gu, 'geumjeong_gu'], [Lang.Blocks.location_busan_gangseo_gu, 'gangseo_gu'], [Lang.Blocks.location_busan_yeonje, 'yeonje'], [Lang.Blocks.location_busan_suyeong_gu, 'suyeong_gu'], [Lang.Blocks.location_busan_sasang_gu, 'sasang_gu'], [Lang.Blocks.location_busan_gijang, 'gijang']]
    },
    'Sejong': {
      'lang': Lang.Blocks.location_sejong,
      'sub': []
    },
    'Ulsan': {
      'lang': Lang.Blocks.location_ulsan,
      'sub': [[Lang.Blocks.location_ulsan_jung_gu, 'jung_gu'], [Lang.Blocks.location_ulsan_nam_gu, 'nam_gu'], [Lang.Blocks.location_ulsan_dong_gu, 'dong_gu'], [Lang.Blocks.location_ulsan_buk_gu, 'buk_gu'], [Lang.Blocks.location_ulsan_ulju, 'ulju']]
    },
    'Incheon': {
      'lang': Lang.Blocks.location_incheon,
      'sub': [[Lang.Blocks.location_incheon_jung_gu, 'jung_gu'], [Lang.Blocks.location_incheon_dong_gu, 'dong_gu'], [Lang.Blocks.location_incheon_nam_gu, 'nam_gu'], [Lang.Blocks.location_incheon_yeonsu_gu, 'yeonsu_gu'], [Lang.Blocks.location_incheon_namdong_gu, 'namdong_gu'], [Lang.Blocks.location_incheon_bupyeong_gu, 'bupyeong_gu'], [Lang.Blocks.location_incheon_gyeyang_gu, 'gyeyang_gu'], [Lang.Blocks.location_incheon_seo_gu, 'seo_gu'], [Lang.Blocks.location_incheon_ganghwa, 'ganghwa'], [Lang.Blocks.location_incheon_ongjin_gun, 'ongjin_gun']]
    },
    'Jeollanam-do': {
      'lang': Lang.Blocks.location_jeollanam,
      'sub': [[Lang.Blocks.location_jeollanam_mokpo, 'mokpo'], [Lang.Blocks.location_jeollanam_yosu, 'yosu'], [Lang.Blocks.location_jeollanam_suncheon_si, 'suncheon_si'], [Lang.Blocks.location_jeollanam_naju, 'naju'], [Lang.Blocks.location_jeollanam_gwangyang_si, 'gwangyang_si'], [Lang.Blocks.location_jeollanam_damyang, 'damyang'], [Lang.Blocks.location_jeollanam_gokseong, 'gokseong'], [Lang.Blocks.location_jeollanam_gurye, 'gurye'], [Lang.Blocks.location_jeollanam_goheung, 'goheung'], [Lang.Blocks.location_jeollanam_boseong, 'boseong'], [Lang.Blocks.location_jeollanam_hwasun, 'hwasun'], [Lang.Blocks.location_jeollanam_jangheung, 'jangheung'], [Lang.Blocks.location_jeollanam_gangjin, 'gangjin'], [Lang.Blocks.location_jeollanam_haenam, 'haenam'], [Lang.Blocks.location_jeollanam_yeongam, 'yeongam'], [Lang.Blocks.location_jeollanam_jindo, 'jindo'], [Lang.Blocks.location_jeollanam_muan, 'muan'], [Lang.Blocks.location_jeollanam_hampyeong, 'hampyeong'], [Lang.Blocks.location_jeollanam_yeonggwang, 'yeonggwang'], [Lang.Blocks.location_jeollanam_jangseong, 'jangseong'], [Lang.Blocks.location_jeollanam_wando, 'wando'], [Lang.Blocks.location_jeollanam_sinan, 'sinan']]
    },
    'Jeollabuk-do': {
      'lang': Lang.Blocks.location_jeollabuk,
      'sub': [[Lang.Blocks.location_jeollabuk_jeonju, 'jeonju'], [Lang.Blocks.location_jeollabuk_gunsan_si, 'gunsan_si'], [Lang.Blocks.location_jeollabuk_iksan, 'iksan'], [Lang.Blocks.location_jeollabuk_jeongeup, 'jeongeup'], [Lang.Blocks.location_jeollabuk_namwon, 'namwon'], [Lang.Blocks.location_jeollabuk_gimje_si, 'gimje_si'], [Lang.Blocks.location_jeollabuk_wanju, 'wanju'], [Lang.Blocks.location_jeollabuk_jinan, 'jinan'], [Lang.Blocks.location_jeollabuk_muju, 'muju'], [Lang.Blocks.location_jeollabuk_jangsu, 'jangsu'], [Lang.Blocks.location_jeollabuk_imsil, 'imsil'], [Lang.Blocks.location_jeollabuk_sunchang, 'sunchang'], [Lang.Blocks.location_jeollabuk_gochang, 'gochang'], [Lang.Blocks.location_jeollabuk_buan, 'buan']]
    },
    'Jeju': {
      'lang': Lang.Blocks.location_jeju,
      'sub': [[Lang.Blocks.location_jeju_jeju_si, 'jeju_si'], [Lang.Blocks.location_jeju_seogwipo, 'seogwipo']]
    },
    'Chungcheongnam-do': {
      'lang': Lang.Blocks.location_chungcheongnam,
      'sub': [[Lang.Blocks.location_chungcheongnam_cheonan, 'cheonan'], [Lang.Blocks.location_chungcheongnam_gongju, 'gongju'], [Lang.Blocks.location_chungcheongnam_boryeong, 'boryeong'], [Lang.Blocks.location_chungcheongnam_asan, 'asan'], [Lang.Blocks.location_chungcheongnam_seosan, 'seosan'], [Lang.Blocks.location_chungcheongnam_nonsan, 'nonsan'], [Lang.Blocks.location_chungcheongnam_gyeryong, 'gyeryong'], [Lang.Blocks.location_chungcheongnam_dangjin, 'dangjin'], [Lang.Blocks.location_chungcheongnam_geumsan, 'geumsan'], [Lang.Blocks.location_chungcheongnam_buyeo, 'buyeo'], [Lang.Blocks.location_chungcheongnam_seocheon, 'seocheon'], [Lang.Blocks.location_chungcheongnam_cheongyang, 'cheongyang'], [Lang.Blocks.location_chungcheongnam_hongseong, 'hongseong'], [Lang.Blocks.location_chungcheongnam_yesan, 'yesan'], [Lang.Blocks.location_chungcheongnam_taean, 'taean']]
    },
    'Chungcheongbuk-do': {
      'lang': Lang.Blocks.location_chungcheongbuk,
      'sub': [[Lang.Blocks.location_chungcheongbuk_cheongju, 'cheongju'], [Lang.Blocks.location_chungcheongbuk_chungju, 'chungju'], [Lang.Blocks.location_chungcheongbuk_jecheon, 'jecheon'], [Lang.Blocks.location_chungcheongbuk_boeun, 'boeun'], [Lang.Blocks.location_chungcheongbuk_okcheon, 'okcheon'], [Lang.Blocks.location_chungcheongbuk_yeongdong, 'yeongdong'], [Lang.Blocks.location_chungcheongbuk_jeungpyeong, 'jeungpyeong'], [Lang.Blocks.location_chungcheongbuk_jincheon, 'jincheon'], [Lang.Blocks.location_chungcheongbuk_goesan, 'goesan'], [Lang.Blocks.location_chungcheongbuk_eumseong, 'eumseong'], [Lang.Blocks.location_chungcheongbuk_danyang, 'danyang']]
    }
  };
  return locationObj;
}
exports.locationData = getInitialData();
//도 및 광역시
exports.getStateOptions = function () {
  var locationObj = getInitialData();
  return Object.keys(locationObj.ko).map(function (k) {
    return [locationObj.ko[k].lang, k];
  });
};

//시군구
exports.getCityOptions = function (state) {
  var locationObj = getInitialData();
  var init = [locationObj.initialData];
  if (locationObj.ko[state]) {
    return [].concat(init, (0, _toConsumableArray2["default"])(locationObj.ko[state].sub));
  }
  return [].concat(init, (0, _toConsumableArray2["default"])(locationObj.ko.Seoul.sub));
};