var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * PIXI.DisplayObject.cacheAsBitmap 이 원하는대로 작동을 안함.
 * var sp = new PIXI.Sprite(texture);
 * sp.scale.set(2)
 * sp.cacheAsBitmap = true; 이렇게 하면 케시된 텍스쳐가 localTransform에 영향을 받아서 스케일이 2배가 되어버림. 원본과 동일해야 하는데...
 *
 * 그래서 setFilterAndCache() 로 비슷하게 구현.
 */
import { Sprite, RenderTexture, Matrix } from 'pixi.js';
import { EntryTextureBase } from '../atlas/texture/EntryTextureBase';
var FilterData = /** @class */ (function () {
    function FilterData() {
    }
    FilterData.prototype.setOrgTex = function (orgTex) {
        this._orgTex = orgTex;
    };
    FilterData.prototype.isSameRenderTex = function (tex) {
        return tex == this._renderTex;
    };
    Object.defineProperty(FilterData.prototype, "orgTex", {
        get: function () {
            return this._orgTex;
        },
        enumerable: false,
        configurable: true
    });
    FilterData.prototype.getRenderTexture = function (width, height) {
        if (!this._renderTex) {
            this._renderTex = RenderTexture.create({ width: width, height: height });
            return this._renderTex;
        }
        var tex = this._renderTex;
        if (tex.width != width || tex.height != height) {
            this.destroyRenderTexture();
            return this.getRenderTexture(width, height);
        }
        return this._renderTex;
    };
    FilterData.prototype.destroyRenderTexture = function () {
        if (!this._renderTex) {
            return;
        }
        this._renderTex.destroy(true);
        this._renderTex = null;
    };
    FilterData.prototype.destroy = function () {
        this.destroyRenderTexture();
        this._orgTex = null;
        this.filters = null;
    };
    return FilterData;
}());
var EMPTY_SP = new Sprite();
var MAT = new Matrix();
var PIXISprite = /** @class */ (function (_super) {
    __extends(PIXISprite, _super);
    function PIXISprite() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // public _filterData:FilterData;
    //PIXIPixelPerfectInteractionPlugIn 에서 호출함.
    PIXISprite.prototype.internal_getOriginalTex = function () {
        return (this._filterData && this._filterData.orgTex) || this.texture;
    };
    PIXISprite.prototype.setFilterAndCache = function (filters) {
        if (!filters || !filters.length) {
            if (this._filterData) {
                this.texture = this._filterData.orgTex;
                this._filterData.destroy();
                this._filterData = null;
            }
        }
        else {
            if (!this._filterData) {
                this._filterData = new FilterData();
                this._filterData.setOrgTex(this.texture);
            }
            else if (!this._filterData.isSameRenderTex(this.texture)) {
                this._filterData.setOrgTex(this.texture);
            }
            this._filterData.filters = filters;
            this._filterData.invalidate = true;
        }
    };
    /**
     * filter + cache 가 이미 설정되고, 다른 텍스쳐로 할당 되었을때 filter를 다시 설정하기 위한 메서드
     * set texture 를 override 하지 못해서...
     */
    PIXISprite.prototype.refreshFilter = function () {
        if (!this._filterData) {
            return;
        }
        if (!this._filterData.filters) {
            return;
        }
        this.setFilterAndCache(this._filterData.filters);
    };
    PIXISprite.prototype.destroy = function (options) {
        _super.prototype.destroy.call(this, options);
        if (this._filterData) {
            this._filterData.destroy();
            this._filterData = null;
        }
    };
    PIXISprite.prototype.render = function (renderer) {
        if (this._filterData && this._filterData.invalidate) {
            this._filterData.invalidate = false;
            this._initFilterCache(renderer);
        }
        _super.prototype.render.call(this, renderer);
    };
    PIXISprite.prototype._initFilterCache = function (renderer) {
        var fd = this._filterData;
        var tex = fd.orgTex;
        var w = tex.orig.width;
        var h = tex.orig.height;
        var renderTex = fd.getRenderTexture(w, h);
        if (!renderTex) {
            return;
        }
        if (fd.orgTex instanceof EntryTextureBase) {
            // filter 된 sprite 를 도장찍기 하면 sprite.texture 의 type 는 RenderTexture 가 된다.
            fd.orgTex.assignTextureScaleFactor(renderTex);
        }
        var sp = EMPTY_SP;
        sp.filters = this._filterData.filters;
        sp.texture = tex;
        var cachedRenderTarget = renderer.renderTexture.current;
        renderer.batch.flush();
        renderer.render(sp, renderTex, true, MAT, false);
        renderer.renderTexture.bind(cachedRenderTarget);
        this.texture = renderTex;
        sp.texture = null;
    };
    return PIXISprite;
}(Sprite));
export { PIXISprite };
