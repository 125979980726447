/**
 * 프로퍼티 패널쪽에서 프로젝트의 속성을 보여주는 뷰를 담당
 * @use entry-lms
 */
var EntryIntro = /** @class */ (function () {
    function EntryIntro() {
        this.modes = {};
        this.selected = undefined;
    }
    EntryIntro.prototype.generateView = function (introView) {
        this.view_ = introView;
        this.view_.addClass('entryPlaygroundIntro');
    };
    EntryIntro.prototype.setView = function (view) {
        if (this.view_.firstChild) {
            this.view_.removeChild(this.view_.firstChild);
        }
        this.view_.addClass('active');
        view.appendTo(this.view_);
        Entry.windowResized.notify();
    };
    EntryIntro.prototype.removeView = function () {
        if (this.view_.firstChild) {
            this.view_.removeChild(this.view_.firstChild);
        }
        this.view_.removeClass('active');
    };
    return EntryIntro;
}());
export default EntryIntro;
Entry.Intro = EntryIntro;
