Entry.skeleton.basic_double_loop = {
    executable: true,
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height1 = blockView.contentHeight % 1000000;
        height1 = Math.max(30, height1 + 2);
        width = Math.max(0, width + 2 - height1 / 2);
        var statements = blockView._statements;
        var statementHeight1 = statements[0] ? statements[0].height : 20;
        var statementHeight2 = statements[1] ? statements[1].height : 20;
        var bw = width - 8;
        statementHeight1 = Math.max(statementHeight1, 20);
        statementHeight2 = Math.max(statementHeight2, 20);
        return "m 0 0\n        l 6 6\n        l 6 -6\n        h ".concat(width, "\n        a 14 14 0 0 1 0 28\n        H 26\n        l -6 6\n        l -6 -6\n        v ").concat(statementHeight1, "\n        l 6 6\n        l 6 -6\n        h ").concat(bw - 6, "\n        a 14 14 0 0 1 0 28\n        H 26\n        l -6 6\n        l -6 -6\n        v ").concat(statementHeight2, "\n        l 6 6\n        l 6 -6\n        h ").concat(bw, "\n        a 7.5 7.5 0 0 1 0 15\n        H 12\n        l -6 6\n        l -6 -6\n        z");
    },
    magnets: function (blockView) {
        var contentHeight1 = Math.max((blockView.contentHeight % 1000000) + 2, 30);
        var contentHeight2 = Math.max(Math.floor(blockView.contentHeight / 1000000) + 2, 30);
        var statementHeight1 = blockView._statements[0] ? blockView._statements[0].height : 20;
        var statementHeight2 = blockView._statements[1] ? blockView._statements[1].height : 20;
        statementHeight1 = Math.max(statementHeight1, 20);
        statementHeight2 = Math.max(statementHeight2, 20);
        return {
            previous: { x: 0, y: 0 },
            next: {
                x: 0,
                y: statementHeight1 +
                    statementHeight2 +
                    contentHeight1 +
                    contentHeight2 +
                    11 +
                    blockView.offsetY,
            },
        };
    },
    box: function (blockView) {
        var contentWidth = blockView.contentWidth;
        var contentHeight1 = Math.max(Math.floor(blockView.contentHeight / 1000000) + 2, 30);
        var contentHeight2 = Math.max((blockView.contentHeight % 1000000) + 2, 30);
        var statementHeight1 = blockView._statements[0]
            ? blockView._statements[0].height % 1000000
            : 20;
        var statementHeight2 = blockView._statements[1] ? blockView._statements[1].height : 20;
        statementHeight1 = Math.max(statementHeight1, 20);
        statementHeight2 = Math.max(statementHeight2, 20);
        return {
            offsetX: -8,
            offsetY: 0,
            width: contentWidth + 30,
            height: contentHeight1 + contentHeight2 + statementHeight1 + statementHeight2 + 17,
            marginBottom: 0,
            topFieldHeight: contentHeight1,
        };
    },
    statementPos: function (blockView) {
        var statementHeight1 = blockView._statements[0]
            ? blockView._statements[0].height % 1000000
            : 20;
        var height1 = Math.max(30, (blockView.contentHeight % 1000000) + 2) + 1;
        var height2 = height1 +
            Math.max(statementHeight1, 20) +
            Math.max(Math.floor(blockView.contentHeight / 1000000) + 2, 30) +
            1;
        return [{ x: 14, y: height1 - 3 }, { x: 14, y: height2 - 6 }];
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight % 1000000, 28);
        return { x: 14, y: height / 2 };
    },
};
