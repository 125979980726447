var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e;
import singleInstance from '../core/singleInstance';
import { GEHelper } from '../graphicEngine/GEHelper';
import { FilesetResolver, DrawingUtils, GestureRecognizer, PoseLandmarker, ObjectDetector, } from '@mediapipe/tasks-vision';
import { UAParser } from 'ua-parser-js';
import _get from 'lodash/get';
import VideoMotionWorker from './workers/newmotion.worker.ts';
export var getInputList = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!navigator.mediaDevices) return [3 /*break*/, 2];
                return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
            case 1: return [2 /*return*/, (_a.sent()) || []];
            case 2: return [2 /*return*/, []];
        }
    });
}); };
var parser = new UAParser();
export var flipState = {
    NORMAL: 0,
    HORIZONTAL: 1,
    VERTICAL: 2,
    BOTH: 3,
};
var flipActions = (_a = {},
    _a[flipState.NORMAL] = (_b = {},
        _b[flipState.HORIZONTAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
        },
        _b[flipState.VERTICAL] = function (videos) {
            GEHelper.vFlipVideoElement(videos);
        },
        _b[flipState.BOTH] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
            GEHelper.vFlipVideoElement(videos);
        },
        _b),
    _a[flipState.HORIZONTAL] = (_c = {},
        _c[flipState.NORMAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
        },
        _c[flipState.VERTICAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
            GEHelper.vFlipVideoElement(videos);
        },
        _c[flipState.BOTH] = function (videos) {
            GEHelper.vFlipVideoElement(videos);
        },
        _c),
    _a[flipState.VERTICAL] = (_d = {},
        _d[flipState.NORMAL] = function (videos) {
            GEHelper.vFlipVideoElement(videos);
        },
        _d[flipState.HORIZONTAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
            GEHelper.vFlipVideoElement(videos);
        },
        _d[flipState.BOTH] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
        },
        _d),
    _a[flipState.BOTH] = (_e = {},
        _e[flipState.NORMAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
            GEHelper.vFlipVideoElement(videos);
        },
        _e[flipState.HORIZONTAL] = function (videos) {
            GEHelper.vFlipVideoElement(videos);
        },
        _e[flipState.VERTICAL] = function (videos) {
            GEHelper.hFlipVideoElement(videos);
        },
        _e),
    _a);
var colors = [
    'rgb(66, 133, 244)',
    'rgb(36, 193, 224)',
    'rgb(52, 168, 83)',
    'rgb(161, 66, 244)',
    'rgb(244, 57, 160)',
    'rgb(234, 67, 53)',
    'rgb(250, 123, 23)',
    'rgb(252, 201, 52)',
];
var MediaPipeUtils = /** @class */ (function () {
    function MediaPipeUtils() {
        var _this = this;
        this.isInitialized = false;
        this.videoInputList = [];
        this.canWorker = true;
        this.flipState = 0;
        this.VIDEO_WIDTH = 640;
        this.VIDEO_HEIGHT = 360;
        this.STAGE_WIDTH = 480;
        this.STAGE_HEIGHT = 260;
        this.SAMPLE_SIZE = 15;
        this.lastVideoTime = -1;
        this.isPrevHandDetected = false;
        this.isRunningHandGesture = false;
        this.isDrawDetectedHand = false;
        this.isInitGestureRecognition = false;
        this.alreadyInitGestureRecognizerOffscreenCanvas = false;
        this.isPrevPoseLandmarker = false;
        this.isRunningPoseLandmarker = false;
        this.isDrawDetectedPoseLandmarker = false;
        this.isInitPoseLandmarker = false;
        this.alreadyInitPoseLandmarkerOffscreenCanvas = false;
        this.isPrevFaceLandmarker = false;
        this.isRunWorkerFaceLandmarker = false;
        this.isRunningFaceLandmarker = false;
        this.isDrawDetectedFaceLandmarker = false;
        this.isInitFaceLandmarker = false;
        this.alreadyInitFaceLandmarkerOffscreenCanvas = false;
        this.isPrevObjectDetector = false;
        this.isRunningObjectDetector = false;
        this.isDrawDetectedObjectDetector = false;
        this.isInitObjectDetector = false;
        this.alreadyInitObjectDetectorOffscreenCanvas = false;
        this.totalMotions = { total: 0, direction: { x: 0, y: 0 } };
        this.motions = __spreadArray([], __read(Array(Math.ceil(this.STAGE_HEIGHT / this.SAMPLE_SIZE))), false).map(function (e) { return Array(_this.STAGE_WIDTH / _this.SAMPLE_SIZE); });
        this.motionWorker = new VideoMotionWorker();
        this.contextFlip = function (context, axis) {
            if (_this.flipState === 0) {
                context.scale(-1, 1);
                return {
                    x: -axis.x * 640,
                    y: axis.y * 360 - 20,
                };
            }
            else if (_this.flipState === 1) {
                context.scale(1, 1);
                return {
                    x: axis.x * 640,
                    y: axis.y * 360 - 20,
                };
            }
            else if (_this.flipState === 2) {
                context.scale(-1, -1);
                return {
                    x: -axis.x * 640,
                    y: -axis.y * 360 + 20,
                };
            }
            else if (_this.flipState === 3) {
                context.scale(1, -1);
                return {
                    x: axis.x * 640,
                    y: -axis.y * 360 + 20,
                };
            }
        };
        var uaResult = parser.getResult();
        if (uaResult.browser.name === 'Safari' || uaResult.os.name === 'iOS') {
            this.canWorker = false;
        }
    }
    Object.defineProperty(MediaPipeUtils.prototype, "isRunning", {
        get: function () {
            return Boolean(this.video.srcObject);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MediaPipeUtils.prototype, "allCanvases", {
        get: function () {
            return [
                this.canvasVideo,
                this.gestureRecognizerCanvasOverlay,
                this.poseLandmarkerCanvasOverlay,
                this.faceLandmarkerCanvasOverlay,
                this.objectDetectorCanvasOverlay,
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MediaPipeUtils.prototype, "overlayCanvases", {
        get: function () {
            return [
                this.gestureRecognizerCanvasOverlay,
                this.poseLandmarkerCanvasOverlay,
                this.faceLandmarkerCanvasOverlay,
                this.objectDetectorCanvasOverlay,
            ];
        },
        enumerable: false,
        configurable: true
    });
    MediaPipeUtils.prototype.changeCanWorker = function (canWorker) {
        this.canWorker = canWorker;
    };
    MediaPipeUtils.prototype.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var inputList, video;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isInitialized) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.checkPermission()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, getInputList()];
                    case 2:
                        inputList = _a.sent();
                        this.videoInputList = inputList
                            .filter(function (input) { return input.kind === 'videoinput' && input.deviceId; })
                            .map(function (item) { return [item.label, item.deviceId]; });
                        return [4 /*yield*/, this.compatabilityChecker()];
                    case 3:
                        _a.sent();
                        this.motionCanvas = document.createElement('canvas');
                        this.motionCanvas.width = this.VIDEO_WIDTH;
                        this.motionCanvas.height = this.VIDEO_HEIGHT;
                        video = document.createElement('video');
                        video.id = 'webCamElement';
                        video.autoplay = true;
                        video.width = this.VIDEO_WIDTH;
                        video.height = this.VIDEO_HEIGHT;
                        this.canvasVideo = GEHelper.getVideoElement(video);
                        GEHelper.hFlipVideoElement(this.allCanvases);
                        this.video = video;
                        Entry.addEventListener('beforeStop', this.reset.bind(this));
                        this.isInitialized = true;
                        this.initMotionWorkerEvent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.sleep = function (ms) {
        return new Promise(function (resolve) {
            if (ms) {
                setTimeout(resolve, ms);
            }
            else {
                requestAnimationFrame(resolve);
            }
        });
    };
    MediaPipeUtils.prototype.initMotionWorkerEvent = function () {
        var _this = this;
        this.motionWorker.onmessage = function (_a) {
            var data = _a.data;
            if (data.action === 'init_complete') {
                _this.motionDetect();
            }
            else if (data.action === 'next_detect_motion') {
                _this.totalMotions = data.result;
                setTimeout(_this.motionDetect.bind(_this), 100);
            }
        };
    };
    MediaPipeUtils.prototype.cameraOnOff = function (mode) {
        if (mode === 'on') {
            this.turnOnWebcam();
        }
        else {
            this.turnOffWebcam();
        }
    };
    MediaPipeUtils.prototype.changeSource = function (target) {
        return __awaiter(this, void 0, void 0, function () {
            var inputSource, stream, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        inputSource = this.videoInputList[target];
                        if (!inputSource) {
                            return [2 /*return*/];
                        }
                        this.sourceTarget = target;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                                audio: false,
                                video: {
                                    deviceId: {
                                        exact: inputSource[1],
                                    },
                                    width: this.VIDEO_WIDTH,
                                    height: this.VIDEO_HEIGHT,
                                },
                            })];
                    case 2:
                        stream = _a.sent();
                        this.stream = stream;
                        this.video.srcObject = this.stream;
                        this.video.width = this.VIDEO_WIDTH;
                        this.video.height = this.VIDEO_HEIGHT;
                        this.video.style.transform = 'scaleX(-1)';
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.setFlipState = function (state) {
        if (!this.canvasVideo) {
            return;
        }
        this.setForceFlipState(this.flipState, state);
    };
    MediaPipeUtils.prototype.setForceFlipState = function (prevState, nextState) {
        var action = flipActions[prevState][nextState];
        if (action) {
            action(this.allCanvases);
        }
        this.flipState = nextState;
    };
    MediaPipeUtils.prototype.initFlipStateCanvas = function (canvas) {
        if (this.flipState === 0) {
            GEHelper.hFlipVideoElement(canvas);
        }
        else if (this.flipState === 2) {
            GEHelper.vFlipVideoElement(canvas);
            GEHelper.hFlipVideoElement(canvas);
        }
        else if (this.flipState === 3) {
            GEHelper.vFlipVideoElement(canvas);
        }
    };
    MediaPipeUtils.prototype.setOpacityCamera = function (opacity) {
        GEHelper.setVideoAlpha(this.canvasVideo, opacity);
    };
    MediaPipeUtils.prototype.turnOffWebcam = function () {
        var _this = this;
        if (this.video.srcObject) {
            var stream = this.video.srcObject;
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
            window.requestAnimationFrame(function () {
                _this.video.srcObject = null;
                _this.stream = undefined;
            });
        }
        GEHelper.turnOffWebcam(this.canvasVideo);
        this.overlayCanvases.forEach(function (canvas) {
            if (canvas) {
                GEHelper.turnOffOverlay(canvas);
            }
        });
        this.setForceFlipState(this.flipState, 0);
    };
    MediaPipeUtils.prototype.turnOnWebcam = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stream, target, err_2, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        target = this.sourceTarget || 0;
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                                video: {
                                    deviceId: { exact: this.videoInputList[target][1] },
                                    width: this.VIDEO_WIDTH,
                                    height: this.VIDEO_HEIGHT,
                                },
                            })];
                    case 1:
                        stream = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _b.sent();
                        throw new Entry.Utils.IncompatibleError('IncompatibleError', [
                            Lang.Workspace.check_webcam_error,
                        ]);
                    case 3:
                        this.video.srcObject = stream;
                        this.stream = stream;
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.video.play()];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        _a = _b.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        GEHelper.drawVideoElement(this.canvasVideo);
                        this.overlayCanvases.forEach(function (canvas) {
                            if (canvas) {
                                GEHelper.drawOverlayElement(canvas);
                            }
                        });
                        this.motionWorker.postMessage({
                            action: 'init',
                            width: this.VIDEO_WIDTH,
                            height: this.VIDEO_HEIGHT,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.checkPermission = function () {
        return __awaiter(this, void 0, void 0, function () {
            var permission;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.permissions) return [3 /*break*/, 4];
                        return [4 /*yield*/, navigator.permissions.query({ name: 'camera' })];
                    case 1:
                        permission = _a.sent();
                        if (!(permission.state !== 'granted')) return [3 /*break*/, 3];
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ video: true })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ video: true })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.compatabilityChecker = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                    throw new Entry.Utils.IncompatibleError('IncompatibleError', [
                        Lang.Workspace.check_browser_error_video,
                    ]);
                }
                if (!this.stream && this.videoInputList.length == 0) {
                    throw new Entry.Utils.IncompatibleError('IncompatibleError', [
                        Lang.Workspace.check_webcam_error,
                    ]);
                }
                return [2 /*return*/];
            });
        });
    };
    MediaPipeUtils.prototype.isFlipState = function (type) {
        if (type === 'horizontal') {
            return this.flipState === flipState.BOTH || this.flipState === flipState.HORIZONTAL;
        }
        else if (type === 'vertical') {
            return this.flipState === flipState.BOTH || this.flipState === flipState.VERTICAL;
        }
    };
    MediaPipeUtils.prototype.motionDetect = function (sprite) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var id_1, returnMessage_1, _a, width, height, sampleSize, _b, minX, maxX, _c, minY, maxY, imageBitmap, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 4, , 5]);
                        if (!this.motionCanvas || !this.isRunning) {
                            resolve(undefined);
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _d.sent();
                        resolve(this.motionDetect(sprite));
                        return [2 /*return*/];
                    case 2:
                        id_1 = Entry.generateHash();
                        if (sprite) {
                            returnMessage_1 = function (_a) {
                                var data = _a.data;
                                if (data.action === 'sprite_return' && data.id === id_1) {
                                    _this.motionWorker.removeEventListener('message', returnMessage_1);
                                    resolve(data.result);
                                }
                            };
                            this.motionWorker.addEventListener('message', returnMessage_1);
                        }
                        _a = this, width = _a.STAGE_WIDTH, height = _a.STAGE_HEIGHT, sampleSize = _a.SAMPLE_SIZE;
                        _b = __read([0, width], 2), minX = _b[0], maxX = _b[1];
                        _c = __read([0, height], 2), minY = _c[0], maxY = _c[1];
                        return [4 /*yield*/, createImageBitmap(this.video)];
                    case 3:
                        imageBitmap = _d.sent();
                        this.motionWorker.postMessage({
                            sprite: sprite && {
                                x: sprite.x,
                                y: sprite.y,
                                width: sprite.width,
                                height: sprite.height,
                                scaleX: sprite.scaleX,
                                scaleY: sprite.scaleY,
                            },
                            action: 'motion',
                            range: {
                                minX: minX,
                                maxX: maxX,
                                minY: minY,
                                maxY: maxY,
                            },
                            imageBitmap: imageBitmap,
                            flipState: this.flipState,
                            id: id_1,
                        });
                        if (!sprite) {
                            resolve(undefined);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        console.error(e_1);
                        reject(e_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    MediaPipeUtils.prototype.getYX = function (a) {
        return Math.max(1, Math.min(10, 10 * (1 - (a - -0.15) / 0.25) + (1 - (0.1 - a) / 0.25)));
    };
    MediaPipeUtils.prototype.sendImageBitmapForGesture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.isRunningHandGesture) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _d.sent();
                        this.sendImageBitmapForGesture();
                        return [2 /*return*/];
                    case 2:
                        _b = (_a = this.gestureRecognizerWorker).postMessage;
                        _c = {
                            action: 'gesture_recognizer'
                        };
                        return [4 /*yield*/, createImageBitmap(this.video)];
                    case 3:
                        _b.apply(_a, [(_c.imageBitmap = _d.sent(),
                                _c.flipState = this.flipState,
                                _c)]);
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.sendImageBitmapForPoseLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.isRunningPoseLandmarker) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _d.sent();
                        this.sendImageBitmapForPoseLandmarker();
                        return [2 /*return*/];
                    case 2:
                        _b = (_a = this.poseLandmarkerWorker).postMessage;
                        _c = {
                            action: 'pose_landmarker'
                        };
                        return [4 /*yield*/, createImageBitmap(this.video)];
                    case 3:
                        _b.apply(_a, [(_c.imageBitmap = _d.sent(),
                                _c.flipState = this.flipState,
                                _c)]);
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.sendImageBitmapForFaceLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.isRunningFaceLandmarker) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _d.sent();
                        this.sendImageBitmapForFaceLandmarker();
                        return [2 /*return*/];
                    case 2:
                        _b = (_a = this.faceLandmarkerWorker).postMessage;
                        _c = {
                            action: 'face_landmarker'
                        };
                        return [4 /*yield*/, createImageBitmap(this.video)];
                    case 3:
                        _b.apply(_a, [(_c.imageBitmap = _d.sent(),
                                _c.flipState = this.flipState,
                                _c)]);
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.sendImageBitmapForObjectDetector = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.isRunningObjectDetector) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _d.sent();
                        this.sendImageBitmapForObjectDetector();
                        return [2 /*return*/];
                    case 2:
                        _b = (_a = this.objectDetectorWorker).postMessage;
                        _c = {
                            action: 'object_detector'
                        };
                        return [4 /*yield*/, createImageBitmap(this.video)];
                    case 3:
                        _b.apply(_a, [(_c.imageBitmap = _d.sent(),
                                _c.flipState = this.flipState,
                                _c)]);
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.initGestureRecognitionWorkerEvent = function () {
        var _this = this;
        this.gestureRecognizerWorker.addEventListener('message', function (_a) {
            var data = _a.data;
            if (!_this.isRunningHandGesture) {
                return;
            }
            if (['next_gesture_recognizer'].includes(data.action)) {
                if (GEHelper.isWebGL) {
                    _this.gestureRecognizerCanvasOverlay.texture.update();
                }
                _this.sendImageBitmapForGesture();
            }
            else if (data.action === 'start_gesture_recognizer') {
                _this.isPrevHandDetected = true;
                Entry.engine.fireEvent('when_hand_detection');
            }
            else if (data.action === 'stop_gesture_recognizer') {
                _this.countDetectedHand = 0;
                _this.isPrevHandDetected = false;
            }
            else if (data.action === 'count_detected_hand_gesture_recognizer') {
                _this.countDetectedHand = data.count;
            }
            else if (data.action === 'gesture_recognizer_data') {
                _this.prevGestureRecognizerResult = data.gestureRecognizerResult;
            }
        });
    };
    MediaPipeUtils.prototype.initPoseLandmarkerWorkerEvent = function () {
        var _this = this;
        this.poseLandmarkerWorker.addEventListener('message', function (_a) {
            var data = _a.data;
            if (!_this.isRunningPoseLandmarker) {
                return;
            }
            if (['next_pose_landmarker'].includes(data.action)) {
                if (GEHelper.isWebGL) {
                    _this.poseLandmarkerCanvasOverlay.texture.update();
                }
                _this.sendImageBitmapForPoseLandmarker();
            }
            else if (data.action === 'start_pose_landmarker') {
                _this.isPrevPoseLandmarker = true;
                Entry.engine.fireEvent('when_pose_landmarker');
            }
            else if (data.action === 'stop_pose_landmarker') {
                _this.countDetectedPose = 0;
                _this.isPrevPoseLandmarker = false;
            }
            else if (data.action === 'count_detected_pose_landmarker') {
                _this.countDetectedPose = data.count;
            }
            else if (data.action === 'pose_landmarker_data') {
                _this.prevPoseLandmarkerResult = data.poseLandmarkerResult;
            }
        });
    };
    MediaPipeUtils.prototype.initFaceLandmarkerWorkerEvent = function () {
        var _this = this;
        this.faceLandmarkerWorker.addEventListener('message', function (_a) {
            var data = _a.data;
            if (!_this.isRunningFaceLandmarker) {
                return;
            }
            if (['next_face_landmarker'].includes(data.action)) {
                if (GEHelper.isWebGL) {
                    _this.faceLandmarkerCanvasOverlay.texture.update();
                }
                _this.sendImageBitmapForFaceLandmarker();
            }
            else if (data.action === 'run_start_face_landmarker') {
                _this.isRunWorkerFaceLandmarker = true;
            }
            else if (data.action === 'run_stop_face_landmarker') {
                _this.isRunWorkerFaceLandmarker = false;
            }
            else if (data.action === 'start_face_landmarker') {
                _this.isPrevFaceLandmarker = true;
                Entry.engine.fireEvent('when_face_landmarker');
            }
            else if (data.action === 'stop_face_landmarker') {
                _this.countDetectedFace = 0;
                _this.isPrevFaceLandmarker = false;
            }
            else if (data.action === 'count_detected_face_landmarker') {
                _this.countDetectedFace = data.count;
            }
            else if (data.action === 'face_landmarker_data') {
                _this.prevFaceLandmarkerResult = data.faceLandmarkerResult;
            }
        });
    };
    MediaPipeUtils.prototype.initObjectDetectorWorkerEvent = function () {
        var _this = this;
        this.objectDetectorWorker.addEventListener('message', function (_a) {
            var data = _a.data;
            if (!_this.isRunningObjectDetector) {
                return;
            }
            if (['next_object_detector'].includes(data.action)) {
                if (GEHelper.isWebGL) {
                    _this.objectDetectorCanvasOverlay.texture.update();
                }
                _this.sendImageBitmapForObjectDetector();
            }
            else if (data.action === 'start_object_detector') {
                _this.isPrevObjectDetector = true;
                Entry.engine.fireEvent('when_object_detector');
            }
            else if (data.action === 'stop_object_detector') {
                _this.countDetectedObject = 0;
                _this.isPrevObjectDetector = false;
            }
            else if (data.action === 'count_detected_object_detector') {
                _this.countDetectedObject = data.count;
            }
            else if (data.action === 'object_detector_data') {
                _this.prevObjectDetectorResult = data.objectDetectorResult;
            }
        });
    };
    MediaPipeUtils.prototype.initHandGestureRecognition = function () {
        this.isInitGestureRecognition = true;
        this.gestureRecognizerVideoCanvas = document.createElement('canvas');
        this.gestureRecognizerVideoCanvas.width = this.VIDEO_WIDTH;
        this.gestureRecognizerVideoCanvas.height = this.VIDEO_HEIGHT;
        this.gestureRecognizerCanvasOverlay = GEHelper.getOverlayElement(this.gestureRecognizerVideoCanvas);
        GEHelper.drawOverlayElement(this.gestureRecognizerCanvasOverlay);
        this.initFlipStateCanvas(this.gestureRecognizerCanvasOverlay);
        if (this.canWorker) {
            // eslint-disable-next-line max-len
            this.gestureRecognizerOffscreenCanvas = this.gestureRecognizerVideoCanvas.transferControlToOffscreen();
            this.gestureRecognizerWorker = new Worker("".concat(Entry.Utils.getEntryjsPath(), "/extern/gesture-recognition.worker.js"));
            this.initGestureRecognitionWorkerEvent();
        }
        else {
            this.gestureRecognizerVideoCanvasCtx = this.gestureRecognizerVideoCanvas.getContext('2d');
            this.gestureRecognizerVideoCanvasCtx.font = '20px Arial';
            this.gestureRecognizerDrawingUtils = new DrawingUtils(this.gestureRecognizerVideoCanvasCtx);
        }
    };
    MediaPipeUtils.prototype.initPoseLandmarker = function () {
        this.isInitPoseLandmarker = true;
        this.poseLandmarkerVideoCanvas = document.createElement('canvas');
        this.poseLandmarkerVideoCanvas.width = this.VIDEO_WIDTH;
        this.poseLandmarkerVideoCanvas.height = this.VIDEO_HEIGHT;
        this.poseLandmarkerCanvasOverlay = GEHelper.getOverlayElement(this.poseLandmarkerVideoCanvas);
        GEHelper.drawOverlayElement(this.poseLandmarkerCanvasOverlay);
        this.initFlipStateCanvas(this.poseLandmarkerCanvasOverlay);
        if (this.canWorker) {
            // eslint-disable-next-line max-len
            this.poseLandmarkerOffscreenCanvas = this.poseLandmarkerVideoCanvas.transferControlToOffscreen();
            this.poseLandmarkerWorker = new Worker("".concat(Entry.Utils.getEntryjsPath(), "/extern/pose-landmarker.worker.js"));
            this.initPoseLandmarkerWorkerEvent();
        }
        else {
            this.poseLandmarkerVideoCanvasCtx = this.poseLandmarkerVideoCanvas.getContext('2d');
            this.poseLandmarkerVideoCanvasCtx.font = '20px Arial';
            this.poseLandmarkerDrawingUtils = new DrawingUtils(this.poseLandmarkerVideoCanvasCtx);
        }
    };
    MediaPipeUtils.prototype.initFaceLandmarker = function () {
        this.isInitFaceLandmarker = true;
        this.faceLandmarkerVideoCanvas = document.createElement('canvas');
        this.faceLandmarkerVideoCanvas.width = this.VIDEO_WIDTH;
        this.faceLandmarkerVideoCanvas.height = this.VIDEO_HEIGHT;
        this.faceLandmarkerCanvasOverlay = GEHelper.getOverlayElement(this.faceLandmarkerVideoCanvas);
        GEHelper.drawOverlayElement(this.faceLandmarkerCanvasOverlay);
        this.initFlipStateCanvas(this.faceLandmarkerCanvasOverlay);
        // eslint-disable-next-line max-len
        this.faceLandmarkerOffscreenCanvas = this.faceLandmarkerVideoCanvas.transferControlToOffscreen();
        this.faceLandmarkerWorker = new Worker("".concat(Entry.Utils.getEntryjsPath(), "/extern/face-landmarker.worker.js"));
        this.initFaceLandmarkerWorkerEvent();
    };
    MediaPipeUtils.prototype.initObjectDetector = function () {
        this.isInitObjectDetector = true;
        this.objectDetectorVideoCanvas = document.createElement('canvas');
        this.objectDetectorVideoCanvas.width = this.VIDEO_WIDTH;
        this.objectDetectorVideoCanvas.height = this.VIDEO_HEIGHT;
        this.objectDetectorCanvasOverlay = GEHelper.getOverlayElement(this.objectDetectorVideoCanvas);
        GEHelper.drawOverlayElement(this.objectDetectorCanvasOverlay);
        this.initFlipStateCanvas(this.objectDetectorCanvasOverlay);
        if (this.canWorker) {
            // eslint-disable-next-line max-len
            this.objectDetectorOffscreenCanvas = this.objectDetectorVideoCanvas.transferControlToOffscreen();
            this.objectDetectorWorker = new Worker("".concat(Entry.Utils.getEntryjsPath(), "/extern/object-detector.worker.js"));
            this.initObjectDetectorWorkerEvent();
        }
        else {
            this.objectDetectorVideoCanvasCtx = this.objectDetectorVideoCanvas.getContext('2d');
            this.objectDetectorVideoCanvasCtx.font = '20px Arial';
        }
    };
    MediaPipeUtils.prototype.startHandGestureRecognition = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!!this.stream) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.turnOnWebcam()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.isInitGestureRecognition) {
                            this.initHandGestureRecognition();
                        }
                        this.isRunningHandGesture = true;
                        if (!this.canWorker) return [3 /*break*/, 3];
                        if (!this.alreadyInitGestureRecognizerOffscreenCanvas) {
                            this.gestureRecognizerWorker.postMessage({
                                action: 'gesture_recognizer_init',
                                canvas: this.gestureRecognizerOffscreenCanvas,
                                lang: {
                                    leftHand: Lang.Blocks.left_hand,
                                    rightHand: Lang.Blocks.right_hand,
                                },
                                option: {
                                    isDrawDetectedHand: this.isDrawDetectedHand,
                                },
                            }, [this.gestureRecognizerOffscreenCanvas]);
                            this.alreadyInitGestureRecognizerOffscreenCanvas = true;
                        }
                        else {
                            this.gestureRecognizerWorker.postMessage({
                                action: 'gesture_recognizer_restart',
                            });
                            this.sendImageBitmapForGesture();
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.initPredictHandGesture()];
                    case 4:
                        _a.sent();
                        this.predictHandGesture();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.startPoseLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!!this.stream) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.turnOnWebcam()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.isInitPoseLandmarker) {
                            this.initPoseLandmarker();
                        }
                        this.isRunningPoseLandmarker = true;
                        if (!this.canWorker) return [3 /*break*/, 3];
                        if (!this.alreadyInitPoseLandmarkerOffscreenCanvas) {
                            this.poseLandmarkerWorker.postMessage({
                                action: 'pose_landmarker_init',
                                canvas: this.poseLandmarkerOffscreenCanvas,
                                lang: {
                                    person: Lang.video_object_params.person,
                                },
                                option: {
                                    isDrawDetectedPoseLandmarker: this.isDrawDetectedPoseLandmarker,
                                },
                            }, [this.poseLandmarkerOffscreenCanvas]);
                            this.alreadyInitPoseLandmarkerOffscreenCanvas = true;
                        }
                        else {
                            this.poseLandmarkerWorker.postMessage({
                                action: 'pose_landmarker_restart',
                            });
                            this.sendImageBitmapForPoseLandmarker();
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.initPredictPoseLandmarker()];
                    case 4:
                        _a.sent();
                        this.predictPoseLandmarker();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.startFaceLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uaResult, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!!this.stream) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.turnOnWebcam()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.isInitFaceLandmarker) {
                            this.initFaceLandmarker();
                        }
                        this.isRunningFaceLandmarker = true;
                        if (!this.alreadyInitFaceLandmarkerOffscreenCanvas) {
                            uaResult = parser.getResult();
                            this.faceLandmarkerWorker.postMessage({
                                action: 'face_landmarker_init',
                                canvas: this.faceLandmarkerOffscreenCanvas,
                                isSafari: uaResult.browser.name === 'Safari' || uaResult.os.name === 'iOS',
                                lang: {
                                    face: Lang.Blocks.video_face,
                                },
                                option: {
                                    isDrawDetectedFaceLandmarker: this.isDrawDetectedFaceLandmarker,
                                },
                            }, [this.faceLandmarkerOffscreenCanvas]);
                            this.alreadyInitFaceLandmarkerOffscreenCanvas = true;
                        }
                        else if (!this.isRunWorkerFaceLandmarker) {
                            this.faceLandmarkerWorker.postMessage({
                                action: 'face_landmarker_restart',
                            });
                            this.sendImageBitmapForFaceLandmarker();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.startObjectDetector = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!!this.stream) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.turnOnWebcam()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.isInitObjectDetector) {
                            this.initObjectDetector();
                        }
                        this.isRunningObjectDetector = true;
                        if (!this.canWorker) return [3 /*break*/, 3];
                        if (!this.alreadyInitObjectDetectorOffscreenCanvas) {
                            this.objectDetectorWorker.postMessage({
                                action: 'object_detector_init',
                                canvas: this.objectDetectorOffscreenCanvas,
                                lang: {
                                    objectNameList: Lang.video_object_params,
                                },
                                option: {
                                    isDrawDetectedObjectDetector: this.isDrawDetectedObjectDetector,
                                },
                            }, [this.objectDetectorOffscreenCanvas]);
                            this.alreadyInitObjectDetectorOffscreenCanvas = true;
                        }
                        else {
                            this.objectDetectorWorker.postMessage({
                                action: 'object_detector_restart',
                            });
                            this.sendImageBitmapForObjectDetector();
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.initPredictObjectDetector()];
                    case 4:
                        _a.sent();
                        this.predictObjectDetector();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_5 = _a.sent();
                        console.error(e_5);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.changeDrawDetectedHand = function (isDrawDetectedHand) {
        this.isDrawDetectedHand = isDrawDetectedHand;
        this.updateHandGestureRecognition();
    };
    MediaPipeUtils.prototype.changeDrawDetectedPoseLandmarker = function (isDrawDetectedPoseLandmarker) {
        this.isDrawDetectedPoseLandmarker = isDrawDetectedPoseLandmarker;
        this.updatePoseLandmarker();
    };
    MediaPipeUtils.prototype.changeDrawDetectedFaceLandmarker = function (isDrawDetectedFaceLandmarker) {
        this.isDrawDetectedFaceLandmarker = isDrawDetectedFaceLandmarker;
        this.updateFaceLandmarker();
    };
    MediaPipeUtils.prototype.changeDrawDetectedObjectDetector = function (isDrawDetectedObjectDetector) {
        this.isDrawDetectedObjectDetector = isDrawDetectedObjectDetector;
        this.updateObjectDetector();
    };
    MediaPipeUtils.prototype.updateHandGestureRecognition = function () {
        if (this.canWorker) {
            this.gestureRecognizerWorker.postMessage({
                action: 'gesture_recognizer_change_option',
                option: {
                    isDrawDetectedHand: this.isDrawDetectedHand,
                },
            });
        }
    };
    MediaPipeUtils.prototype.updatePoseLandmarker = function () {
        if (this.canWorker) {
            this.poseLandmarkerWorker.postMessage({
                action: 'pose_landmarker_change_option',
                option: {
                    isDrawDetectedPoseLandmarker: this.isDrawDetectedPoseLandmarker,
                },
            });
        }
    };
    MediaPipeUtils.prototype.updateFaceLandmarker = function () {
        this.faceLandmarkerWorker.postMessage({
            action: 'face_landmarker_change_option',
            option: {
                isDrawDetectedFaceLandmarker: this.isDrawDetectedFaceLandmarker,
            },
        });
    };
    MediaPipeUtils.prototype.updateObjectDetector = function () {
        if (this.canWorker) {
            this.objectDetectorWorker.postMessage({
                action: 'object_detector_change_option',
                option: {
                    isDrawDetectedObjectDetector: this.isDrawDetectedObjectDetector,
                },
            });
        }
    };
    MediaPipeUtils.prototype.stopHandGestureRecognition = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.canWorker) {
                    this.gestureRecognizerWorker.postMessage({
                        action: 'clear_gesture_recognizer',
                    });
                }
                else {
                    this.gestureRecognizerVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                    if (GEHelper.isWebGL) {
                        this.gestureRecognizerCanvasOverlay.texture.update();
                    }
                }
                this.isRunningHandGesture = false;
                this.isPrevHandDetected = false;
                this.countDetectedHand = 0;
                return [2 /*return*/];
            });
        });
    };
    MediaPipeUtils.prototype.stopPoseLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.canWorker) {
                    this.poseLandmarkerWorker.postMessage({
                        action: 'clear_pose_landmarker',
                    });
                }
                else {
                    this.poseLandmarkerVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                    if (GEHelper.isWebGL) {
                        this.poseLandmarkerCanvasOverlay.texture.update();
                    }
                }
                this.isRunningPoseLandmarker = false;
                this.isPrevPoseLandmarker = false;
                this.countDetectedPose = 0;
                return [2 /*return*/];
            });
        });
    };
    MediaPipeUtils.prototype.stopFaceLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.faceLandmarkerWorker.postMessage({
                    action: 'clear_face_landmarker',
                });
                this.isRunningFaceLandmarker = false;
                this.isPrevFaceLandmarker = false;
                this.isRunWorkerFaceLandmarker = false;
                this.countDetectedFace = 0;
                return [2 /*return*/];
            });
        });
    };
    MediaPipeUtils.prototype.stopObjectDetector = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.canWorker) {
                    this.objectDetectorWorker.postMessage({
                        action: 'clear_object_detector',
                    });
                }
                else {
                    this.objectDetectorVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                    if (GEHelper.isWebGL) {
                        this.objectDetectorCanvasOverlay.texture.update();
                    }
                }
                this.isRunningObjectDetector = false;
                this.isPrevObjectDetector = false;
                this.countDetectedObject = 0;
                return [2 /*return*/];
            });
        });
    };
    MediaPipeUtils.prototype.initPredictHandGesture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var vision, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, FilesetResolver.forVisionTasks("".concat(Entry.Utils.getEntryjsPath(), "/extern/wasm"))];
                    case 1:
                        vision = _b.sent();
                        _a = this;
                        return [4 /*yield*/, GestureRecognizer.createFromOptions(vision, {
                                baseOptions: {
                                    // eslint-disable-next-line max-len
                                    modelAssetPath: "".concat(Entry.Utils.getEntryjsPath(), "/extern/model/gesture_recognizer.task"),
                                    delegate: 'GPU',
                                },
                                runningMode: 'VIDEO',
                                numHands: 2,
                            })];
                    case 2:
                        _a.gestureRecognizer = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.initPredictPoseLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var vision, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, FilesetResolver.forVisionTasks("".concat(Entry.Utils.getEntryjsPath(), "/extern/wasm"))];
                    case 1:
                        vision = _b.sent();
                        _a = this;
                        return [4 /*yield*/, PoseLandmarker.createFromOptions(vision, {
                                baseOptions: {
                                    // eslint-disable-next-line max-len
                                    modelAssetPath: "".concat(Entry.Utils.getEntryjsPath(), "/extern/model/pose_landmarker_lite.task"),
                                    delegate: 'GPU',
                                },
                                runningMode: 'VIDEO',
                                numPoses: 4,
                            })];
                    case 2:
                        _a.poseLandmarker = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.initPredictObjectDetector = function () {
        return __awaiter(this, void 0, void 0, function () {
            var vision, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, FilesetResolver.forVisionTasks("".concat(Entry.Utils.getEntryjsPath(), "/extern/wasm"))];
                    case 1:
                        vision = _b.sent();
                        _a = this;
                        return [4 /*yield*/, ObjectDetector.createFromOptions(vision, {
                                baseOptions: {
                                    // eslint-disable-next-line max-len
                                    modelAssetPath: "".concat(Entry.Utils.getEntryjsPath(), "/extern/model/object_detector_lite.tflite"),
                                    delegate: 'GPU',
                                },
                                runningMode: 'VIDEO',
                                scoreThreshold: 0.5,
                                maxResults: 8,
                            })];
                    case 2:
                        _a.objectDetector = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.predictHandGesture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var results, startTimeMs, landmarks, handednesses_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 3, 4]);
                        results = void 0;
                        if (!this.gestureRecognizerVideoCanvasCtx || this.isRunningHandGesture === false) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _a.sent();
                        this.predictHandGesture();
                        return [2 /*return*/];
                    case 2:
                        if (this.video.currentTime !== this.lastVideoTime) {
                            this.lastVideoTime = this.video.currentTime;
                            startTimeMs = Date.now();
                            results = this.gestureRecognizer.recognizeForVideo(this.video, startTimeMs);
                        }
                        else {
                            return [2 /*return*/];
                        }
                        this.gestureRecognizerVideoCanvasCtx.save();
                        this.gestureRecognizerVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                        landmarks = results.landmarks, handednesses_1 = results.handednesses;
                        this.prevGestureRecognizerResult = results;
                        if (landmarks.length) {
                            if (!this.isPrevHandDetected) {
                                this.isPrevHandDetected = true;
                                Entry.engine.fireEvent('when_hand_detection');
                            }
                            if (landmarks.length !== this.countDetectedHand) {
                                this.countDetectedHand = landmarks.length;
                            }
                            if (!this.isDrawDetectedHand) {
                                return [2 /*return*/];
                            }
                            landmarks.forEach(function (landmark, i) {
                                var connectColor;
                                var landmarkColor;
                                var _a = __read(handednesses_1[i], 1), handedness = _a[0];
                                var mark12 = landmark[12];
                                var _b = _this.contextFlip(_this.gestureRecognizerVideoCanvasCtx, mark12), x = _b.x, y = _b.y;
                                if (handedness.categoryName === 'Left') {
                                    _this.gestureRecognizerVideoCanvasCtx.fillStyle = '#FF0000';
                                    _this.gestureRecognizerVideoCanvasCtx.fillText("".concat(i + 1, "-").concat(Lang.Blocks.right_hand), x, y);
                                    connectColor = '#FF0000';
                                    landmarkColor = '#00FF00';
                                }
                                else {
                                    _this.gestureRecognizerVideoCanvasCtx.fillStyle = '#00FF00';
                                    _this.gestureRecognizerVideoCanvasCtx.fillText("".concat(i + 1, "-").concat(Lang.Blocks.left_hand), x, y);
                                    connectColor = '#00FF00';
                                    landmarkColor = '#FF0000';
                                }
                                _this.contextFlip(_this.gestureRecognizerVideoCanvasCtx, mark12);
                                _this.gestureRecognizerDrawingUtils.drawConnectors(landmark, GestureRecognizer.HAND_CONNECTIONS, {
                                    color: connectColor,
                                    lineWidth: 4,
                                });
                                _this.gestureRecognizerDrawingUtils.drawLandmarks(landmark, {
                                    color: connectColor,
                                    lineWidth: 4,
                                    fillColor: landmarkColor,
                                    radius: function (e) { return _this.getYX(e.from.z || 0); },
                                });
                            });
                        }
                        else {
                            this.isPrevHandDetected = false;
                            this.countDetectedHand = 0;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        if (GEHelper.isWebGL) {
                            this.gestureRecognizerCanvasOverlay.texture.update();
                        }
                        this.gestureRecognizerVideoCanvasCtx.restore();
                        if (this.isRunningHandGesture === true) {
                            window.requestAnimationFrame(this.predictHandGesture.bind(this));
                        }
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.predictPoseLandmarker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var results, startTimeMs, landmarks, e_6;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, 7, 8]);
                        results = void 0;
                        if (!this.poseLandmarkerVideoCanvasCtx || this.isRunningPoseLandmarker === false) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _a.sent();
                        this.predictPoseLandmarker();
                        return [2 /*return*/];
                    case 2:
                        if (!(this.video.currentTime !== this.lastVideoTime)) return [3 /*break*/, 4];
                        this.lastVideoTime = this.video.currentTime;
                        startTimeMs = performance.now();
                        return [4 /*yield*/, this.poseLandmarker.detectForVideo(this.video, startTimeMs)];
                    case 3:
                        results = _a.sent();
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/];
                    case 5:
                        this.poseLandmarkerVideoCanvasCtx.save();
                        this.poseLandmarkerVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                        landmarks = results.landmarks;
                        this.prevPoseLandmarkerResult = results;
                        if (landmarks.length) {
                            if (!this.isPrevPoseLandmarker) {
                                this.isPrevPoseLandmarker = true;
                                Entry.engine.fireEvent('when_pose_landmarker');
                            }
                            if (landmarks.length !== this.countDetectedPose) {
                                this.countDetectedPose = landmarks.length;
                            }
                            if (!this.isDrawDetectedPoseLandmarker) {
                                return [2 /*return*/];
                            }
                            landmarks.forEach(function (landmark, i) {
                                var mark7 = landmark[7];
                                _this.poseLandmarkerVideoCanvasCtx.scale(-1, 1);
                                _this.poseLandmarkerVideoCanvasCtx.fillStyle = '#FF0000';
                                _this.poseLandmarkerVideoCanvasCtx.fillText("".concat(i + 1, "-").concat(Lang.video_object_params.person), -mark7.x * 640, mark7.y * 360 - 20);
                                _this.poseLandmarkerVideoCanvasCtx.scale(-1, 1);
                                _this.poseLandmarkerDrawingUtils.drawLandmarks(landmark, {
                                    radius: function (data) { return DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1); },
                                });
                                _this.poseLandmarkerDrawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
                            });
                        }
                        else {
                            this.isPrevPoseLandmarker = false;
                            this.countDetectedPose = 0;
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        e_6 = _a.sent();
                        console.error(e_6);
                        return [3 /*break*/, 8];
                    case 7:
                        if (GEHelper.isWebGL) {
                            this.poseLandmarkerCanvasOverlay.texture.update();
                        }
                        this.poseLandmarkerVideoCanvasCtx.restore();
                        if (this.isRunningPoseLandmarker === true) {
                            window.requestAnimationFrame(this.predictPoseLandmarker.bind(this));
                        }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.predictObjectDetector = function () {
        return __awaiter(this, void 0, void 0, function () {
            var results, startTimeMs, detections, e_7;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, 7, 8]);
                        results = void 0;
                        if (!this.objectDetectorVideoCanvasCtx || this.isRunningObjectDetector === false) {
                            return [2 /*return*/];
                        }
                        if (!(this.video.readyState < 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sleep()];
                    case 1:
                        _a.sent();
                        this.predictObjectDetector();
                        return [2 /*return*/];
                    case 2:
                        if (!(this.video.currentTime !== this.lastVideoTime)) return [3 /*break*/, 4];
                        this.lastVideoTime = this.video.currentTime;
                        startTimeMs = performance.now();
                        return [4 /*yield*/, this.objectDetector.detectForVideo(this.video, startTimeMs)];
                    case 3:
                        results = _a.sent();
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/];
                    case 5:
                        this.objectDetectorVideoCanvasCtx.save();
                        this.objectDetectorVideoCanvasCtx.clearRect(0, 0, this.video.width, this.video.height);
                        detections = results.detections;
                        this.prevObjectDetectorResult = results;
                        if (detections.length) {
                            if (!this.isPrevObjectDetector) {
                                this.isPrevObjectDetector = true;
                                Entry.engine.fireEvent('when_face_landmarker');
                            }
                            if (detections.length !== this.countDetectedObject) {
                                this.countDetectedObject = detections.length;
                            }
                            if (!this.isDrawDetectedObjectDetector) {
                                return [2 /*return*/];
                            }
                            detections.forEach(function (detect, i) {
                                _this.drawObjectDetections(detect, i);
                            });
                        }
                        else {
                            this.isPrevObjectDetector = false;
                            this.countDetectedObject = 0;
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        e_7 = _a.sent();
                        console.error(e_7);
                        return [3 /*break*/, 8];
                    case 7:
                        if (GEHelper.isWebGL) {
                            this.objectDetectorCanvasOverlay.texture.update();
                        }
                        this.objectDetectorVideoCanvasCtx.restore();
                        if (this.isRunningObjectDetector === true) {
                            window.requestAnimationFrame(this.predictObjectDetector.bind(this));
                        }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    MediaPipeUtils.prototype.objectContextFlip = function (context, axis) {
        if (this.flipState === 0) {
            context.scale(-1, 1);
            return {
                x: -axis.x - axis.offsetX,
                y: axis.y - axis.offsetY,
            };
        }
        else if (this.flipState === 1) {
            context.scale(1, 1);
            return {
                x: axis.x + 3,
                y: axis.y - axis.offsetY,
            };
        }
        else if (this.flipState === 2) {
            context.scale(-1, -1);
            return {
                x: -axis.x - axis.offsetX,
                y: -(axis.y - axis.offsetY - 6),
            };
        }
        else if (this.flipState === 3) {
            context.scale(1, -1);
            return {
                x: axis.x + 3,
                y: -(axis.y - axis.offsetY - 6),
            };
        }
    };
    MediaPipeUtils.prototype.drawObjectDetections = function (detect, i) {
        try {
            var boundingBox = detect.boundingBox, categories = detect.categories;
            var _a = __read(categories, 1), category = _a[0];
            var displayName = category.displayName || category.categoryName;
            var text = Lang.video_object_params[displayName] || displayName;
            var x = boundingBox.originX;
            var y = boundingBox.originY;
            var w = boundingBox.width;
            var h = boundingBox.height;
            var e = (this.VIDEO_WIDTH / 600) * 4;
            var measureText = this.objectDetectorVideoCanvasCtx.measureText(text);
            var l = measureText.width + 6;
            var measureSize = measureText.fontBoundingBoxAscent + measureText.fontBoundingBoxDescent + 2 * e;
            var m = x + w - l;
            this.objectDetectorVideoCanvasCtx.strokeStyle = colors[i];
            this.objectDetectorVideoCanvasCtx.beginPath();
            this.objectDetectorVideoCanvasCtx.moveTo(x, y);
            this.objectDetectorVideoCanvasCtx.lineTo(x + w, y);
            this.objectDetectorVideoCanvasCtx.lineTo(x + w, y + h);
            this.objectDetectorVideoCanvasCtx.lineTo(x, y + h);
            this.objectDetectorVideoCanvasCtx.lineTo(x, y);
            this.objectDetectorVideoCanvasCtx.stroke();
            this.objectDetectorVideoCanvasCtx.fillStyle = colors[i];
            this.objectDetectorVideoCanvasCtx.fillRect(m, y, l, measureSize);
            var _b = this.objectContextFlip(this.objectDetectorVideoCanvasCtx, {
                offsetX: l - 3,
                offsetY: 3 * e,
                x: m,
                y: y + measureSize,
            }), axisX = _b.x, axisY = _b.y;
            this.objectDetectorVideoCanvasCtx.fillStyle = 'white';
            this.objectDetectorVideoCanvasCtx.fillText(text, axisX, axisY);
            this.objectContextFlip(this.objectDetectorVideoCanvasCtx, {
                offsetX: 0,
                offsetY: 0,
                x: 0,
                y: 0,
            });
        }
        catch (e) {
            console.error(e.stack);
        }
    };
    MediaPipeUtils.prototype.getFlipDirectionToSign = function () {
        if (this.flipState === 0) {
            return {
                x: 1,
                y: 1,
            };
        }
        else if (this.flipState === 1) {
            return {
                x: -1,
                y: 1,
            };
        }
        else if (this.flipState === 2) {
            return {
                x: 1,
                y: -1,
            };
        }
        else if (this.flipState === 3) {
            return {
                x: -1,
                y: -1,
            };
        }
    };
    MediaPipeUtils.prototype.getHandPointAxis = function (hand, handPoint) {
        if (!this.prevGestureRecognizerResult) {
            return;
        }
        var landmarks = this.prevGestureRecognizerResult.landmarks;
        if (!landmarks.length) {
            return;
        }
        var landmark = landmarks[hand];
        var pointAxis = landmark[handPoint];
        var sign = this.getFlipDirectionToSign();
        return {
            x: (-pointAxis.x * this.STAGE_WIDTH + this.STAGE_WIDTH / 2) * sign.x,
            y: (-pointAxis.y * this.STAGE_HEIGHT + this.STAGE_HEIGHT / 2) * sign.y,
            z: pointAxis.z,
        };
    };
    MediaPipeUtils.prototype.getPosePointAxis = function (pose, posePoint) {
        if (!this.prevPoseLandmarkerResult) {
            return;
        }
        var landmarks = this.prevPoseLandmarkerResult.landmarks;
        if (!landmarks.length) {
            return;
        }
        var landmark = landmarks[pose];
        var pointAxis = landmark[posePoint];
        var sign = this.getFlipDirectionToSign();
        return {
            x: (-pointAxis.x * this.STAGE_WIDTH + this.STAGE_WIDTH / 2) * sign.x,
            y: (-pointAxis.y * this.STAGE_HEIGHT + this.STAGE_HEIGHT / 2) * sign.y,
            z: pointAxis.z,
        };
    };
    MediaPipeUtils.prototype.getFacePointAxis = function (faceNum, facePoint) {
        if (!this.prevFaceLandmarkerResult) {
            return;
        }
        var _a = this.prevFaceLandmarkerResult.face, face = _a === void 0 ? [] : _a;
        if (!face.length) {
            return;
        }
        var meshRaw = _get(face, "".concat(faceNum, ".meshRaw"));
        if (!meshRaw) {
            return;
        }
        var pointAxis = meshRaw[facePoint];
        var sign = this.getFlipDirectionToSign();
        return {
            x: (-pointAxis[0] * this.STAGE_WIDTH + this.STAGE_WIDTH / 2) * sign.x,
            y: (-pointAxis[1] * this.STAGE_HEIGHT + this.STAGE_HEIGHT / 2) * sign.y,
            z: pointAxis[2],
        };
    };
    MediaPipeUtils.prototype.getFaceGender = function (faceNum) {
        if (!this.prevFaceLandmarkerResult) {
            return;
        }
        var _a = this.prevFaceLandmarkerResult.face, face = _a === void 0 ? [] : _a;
        if (!face.length) {
            return;
        }
        return _get(face, "".concat(faceNum, ".gender"));
    };
    MediaPipeUtils.prototype.getFaceAge = function (faceNum) {
        if (!this.prevFaceLandmarkerResult) {
            return;
        }
        var _a = this.prevFaceLandmarkerResult.face, face = _a === void 0 ? [] : _a;
        if (!face.length) {
            return;
        }
        return Math.round(_get(face, "".concat(faceNum, ".age"), 0));
    };
    MediaPipeUtils.prototype.getFaceEmotion = function (faceNum) {
        if (!this.prevFaceLandmarkerResult) {
            return;
        }
        var _a = this.prevFaceLandmarkerResult.face, face = _a === void 0 ? [] : _a;
        if (!face.length) {
            return;
        }
        return _get(face, "".concat(faceNum, ".emotion.0.emotion"));
    };
    MediaPipeUtils.prototype.getObjectPointAxis = function (face, facePoint) {
        if (!this.prevObjectDetectorResult) {
            return;
        }
        var detections = this.prevObjectDetectorResult.detections;
        if (!detections.length) {
            return;
        }
        var detect = detections[face];
        return detect.boundingBox;
    };
    MediaPipeUtils.prototype.getHandedness = function (hand) {
        if (!this.prevGestureRecognizerResult) {
            return;
        }
        var handednesses = this.prevGestureRecognizerResult.handednesses;
        if (!handednesses.length) {
            return;
        }
        return handednesses[hand][0];
    };
    MediaPipeUtils.prototype.getGesture = function (hand) {
        if (!this.prevGestureRecognizerResult) {
            return;
        }
        var gestures = this.prevGestureRecognizerResult.gestures;
        if (!gestures.length) {
            return;
        }
        return gestures[hand][0];
    };
    MediaPipeUtils.prototype.reset = function () {
        if (this.isInitGestureRecognition) {
            this.changeDrawDetectedHand(false);
            this.stopHandGestureRecognition();
            this.prevGestureRecognizerResult = undefined;
        }
        if (this.isInitPoseLandmarker) {
            this.changeDrawDetectedPoseLandmarker(false);
            this.stopPoseLandmarker();
            this.prevPoseLandmarkerResult = undefined;
        }
        if (this.isInitFaceLandmarker) {
            this.changeDrawDetectedFaceLandmarker(false);
            this.stopFaceLandmarker();
            this.prevFaceLandmarkerResult = undefined;
        }
        if (this.isInitObjectDetector) {
            this.changeDrawDetectedObjectDetector(false);
            this.stopObjectDetector();
            this.prevObjectDetectorResult = undefined;
        }
        GEHelper.setVideoAlpha(this.canvasVideo, 50);
        this.turnOffWebcam();
    };
    MediaPipeUtils.prototype.destroy = function () {
        this.isInitialized = false;
    };
    return MediaPipeUtils;
}());
export default singleInstance(MediaPipeUtils);
