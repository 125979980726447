/**
 * 임시로 사용할 변수의 포인터 저장용.
 */
import { Point, Rectangle, Matrix } from 'pixi.js';
var PIXITempStore = /** @class */ (function () {
    function PIXITempStore() {
    }
    PIXITempStore.init = function () {
        this.point1 = new Point();
        this.point2 = new Point();
        this.rect1 = new Rectangle();
        this.rect2 = new Rectangle();
        this.matrix1 = new Matrix();
        this.matrix2 = new Matrix();
    };
    return PIXITempStore;
}());
export { PIXITempStore };
