var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isObject from 'lodash/isObject';
var default_1 = /** @class */ (function () {
    function default_1(socket) {
        this.socket = socket;
        this.listeners = [];
        socket.on('message', this._onAction.bind(this));
    }
    default_1.prototype._onAction = function (message) {
        // 객체 구조가 이모양인건 기존 호환성때문.
        // 정리해서 이쁘게 만들도록 하자 (entry-hw-server 수정필요)
        var data = message.data;
        var action = data.action, payload = data.data;
        switch (action) {
            case 'state':
                this._onStateAction(payload);
                break;
            case 'init':
                this._onInitAction(payload);
                break;
            default:
                this._onDefaultAction(data);
        }
    };
    /**
     * 현재 하드웨어 연결 상태를 표기한다.
     * connected 의 경우 어떤 하드웨어와 연결되었는지 표기된다.
     * @param payload {*} payload
     * @private
     */
    default_1.prototype._onStateAction = function (payload) {
        var statement = payload.statement, name = payload.name;
        this.dispatchEvent('state', statement, name);
    };
    default_1.prototype._onInitAction = function (_a) {
        var name = _a.name;
        this.dispatchEvent('init', name);
    };
    /**
     * 1.7.0 이전 하드웨어 버전의 원활한 통신을 위해 남겨두어야 한다.
     * @param data
     * @private
     */
    default_1.prototype._onDefaultAction = function (data) {
        if (data) {
            var portData = {};
            if (typeof data === 'string') {
                switch (data) {
                    case 'disconnectHardware': {
                        this.dispatchEvent('disconnect');
                        return;
                    }
                    default: {
                        portData = JSON.parse(data);
                        break;
                    }
                }
            }
            else if (isObject(data)) {
                portData = data;
            }
            this.dispatchEvent('data', portData);
        }
    };
    default_1.prototype.addEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(callback);
    };
    default_1.prototype.removeEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            return;
        }
        var stack = this.listeners[type];
        for (var i = 0, l = stack.length; i < l; i++) {
            if (stack[i] === callback) {
                stack.splice(i, 1);
                return;
            }
        }
    };
    default_1.prototype.dispatchEvent = function (eventName) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!(eventName in this.listeners)) {
            return true;
        }
        var stack = this.listeners[eventName].slice();
        for (var i = 0, l = stack.length; i < l; i++) {
            (_a = stack[i]).call.apply(_a, __spreadArray([this], __read(args), false));
        }
    };
    return default_1;
}());
export default default_1;
