import { Application, utils as PIXIUtils } from 'pixi.js';
import { PIXICollision as ndgmr } from '../etc/PIXI-ndgmr.Collision';
import '../etc/PIXICanvasInput';
import { PIXIAtlasManager } from '../atlas/PIXIAtlasManager';
import { PIXIPixelPerfectInteractionPlugIn } from '../plugins/PIXIPixelPerfectInteractionPlugIn';
import { PIXITempStore } from '../etc/PIXITempStore';
import { PIXITextMetricsPlugIn } from '../plugins/PIXITextMetricsPlugIn';
import { PIXIShortPropPlugin } from '../plugins/PIXIShortPropPlugin';
import { PIXIGraphicOverride } from '../plugins/PIXIGraphicOverride';
import { PIXIFontLoadHandler } from './PIXIFontLoadHandler';
import { pixiGetChildAt } from '../plugins/pixiGetChildAt';
var _PIXIGlobal = /** @class */ (function () {
    function _PIXIGlobal() {
    }
    _PIXIGlobal.prototype.initOnce = function () {
        if (this._init) {
            return;
        }
        this._init = true;
        this._isWebGLSupported();
        this.fontLoadChecker = new PIXIFontLoadHandler();
        //this.baseAsset = new PIXIBaseAsset();
        ndgmr.initTempObject();
        window.ndgmr = ndgmr;
        PIXITempStore.init();
        this.atlasManager = new PIXIAtlasManager();
        PIXIShortPropPlugin();
        pixiGetChildAt();
        PIXIPixelPerfectInteractionPlugIn();
        PIXITextMetricsPlugIn();
        PIXIGraphicOverride();
    };
    _PIXIGlobal.prototype.getNewApp = function (canvas) {
        PIXIUtils.skipHello();
        var app = new Application({
            view: canvas,
            width: canvas.width,
            height: canvas.height,
            autoStart: false,
            antialias: true,
            transparent: true,
        });
        app.stage.canvas = canvas;
        return app;
    };
    _PIXIGlobal.prototype._isWebGLSupported = function () {
        if (PIXIUtils.isWebGLSupported()) {
            return;
        }
        throw new Error('webgl not supported');
    };
    return _PIXIGlobal;
}());
export var PIXIGlobal = new _PIXIGlobal();
