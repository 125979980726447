var PropertyPanel = /** @class */ (function () {
    function PropertyPanel() {
        this.modes = {};
        this.selected = undefined;
    }
    PropertyPanel.prototype.generateView = function (parentDom) {
        var container = $(parentDom);
        this._view = Entry.Dom('div', {
            class: 'propertyPanel',
            parent: container,
        });
        this._tabView = Entry.Dom('div', {
            class: 'propertyTab',
            parent: this._view,
        });
        this._contentView = Entry.Dom('div', {
            class: 'propertyContent',
            parent: this._view,
        });
        this._cover = Entry.Dom('div', {
            classes: ['propertyPanelCover', 'entryRemove'],
            parent: this._view,
        });
        var splitter = Entry.Dom('div', {
            class: 'entryObjectSelectedImgWorkspace',
            parent: container,
        });
        this._initializeSplitter(splitter);
    };
    PropertyPanel.prototype.addMode = function (mode, contentObj) {
        var _this = this;
        if (this.modes[mode]) {
            this.removeMode(mode);
        }
        var contentDom = contentObj.getView();
        // will be removed after apply new Dom class
        contentDom = Entry.Dom(contentDom, {
            parent: this._contentView,
        });
        var tabDom = Entry.Dom("<div>".concat(Lang.Menus[mode], "</div>"), {
            classes: ['propertyTabElement', "propertyTab".concat(mode)],
            parent: this._tabView,
        });
        tabDom.bind('click', function () {
            _this.select(mode);
        });
        if (mode === 'console') {
            contentObj.codeMirror.refresh();
        }
        this._removeDom(mode);
        this.modes[mode] = {
            obj: contentObj,
            tabDom: tabDom,
            contentDom: contentDom,
        };
        if (mode === 'hw') {
            $('.propertyTabhw').bind('dblclick', function () {
                Entry.dispatchEvent('hwModeChange');
            });
        }
    };
    PropertyPanel.prototype.removeMode = function (mode) {
        this._removeDom(mode);
        var keys = Object.keys(this.modes);
        if (keys && keys.length > 0) {
            this.select(keys[0]);
        }
    };
    PropertyPanel.prototype.resize = function (canvasSize) {
        var selected = this.selected;
        if (!selected) {
            return;
        }
        var canvasHeight = (canvasSize * 9) / 16;
        this._view.css({
            width: "".concat(canvasSize, "px"),
            top: "".concat(canvasHeight + 35 + 40 + 48 - 22, "px"),
        });
        if (canvasSize >= 430) {
            this._view.removeClass('collapsed');
        }
        else {
            this._view.addClass('collapsed');
        }
        Entry.dispatchEvent('windowResized');
        var obj = this.modes[selected].obj;
        if (selected === 'hw') {
            if (this.modes.hw.obj.listPorts) {
                obj.resizeList();
            }
            else {
                obj.resize && obj.resize();
            }
        }
        else {
            obj.resize && obj.resize();
        }
    };
    PropertyPanel.prototype.select = function (modeName) {
        for (var key in this.modes) {
            var mode = this.modes[key];
            mode.tabDom.removeClass('selected');
            mode.contentDom.addClass('entryRemove');
            $(mode.contentDom).detach();
            mode.obj.visible = false;
        }
        var selected = this.modes[modeName];
        $(this._contentView).append(selected.contentDom);
        selected.tabDom.addClass('selected');
        selected.contentDom.removeClass('entryRemove');
        if (selected.obj.resize) {
            selected.obj.resize();
        }
        selected.obj.visible = true;
        this.selected = modeName;
    };
    PropertyPanel.prototype._initializeSplitter = function (splitter) {
        var _this = this;
        splitter.bind('mousedown touchstart', function (e) {
            e.preventDefault();
            if (Entry.disposeEvent) {
                Entry.disposeEvent.notify();
            }
            var container = Entry.container;
            splitter.addClass('enabled');
            _this._cover.removeClass('entryRemove');
            _this._cover._isVisible = true;
            container.splitterEnable = true;
            var beforeVisible = _this._cover.width() >= Entry.CANVAS_DEFAULT_WIDTH - 24;
            var isMoved = false;
            if (Entry.documentMousemove) {
                container.resizeEvent = Entry.documentMousemove.attach(_this, function (e) {
                    if (container.splitterEnable) {
                        isMoved = true;
                        Entry.resizeElement({
                            canvasWidth: e.clientX || e.x,
                        });
                    }
                });
            }
            $(document).bind('mouseup.container:splitter touchend.container:splitter', function () {
                var container = Entry.container;
                var listener = container.resizeEvent;
                if (listener) {
                    container.splitterEnable = false;
                    listener.destroy();
                    splitter.removeClass('enabled');
                    delete container.resizeEvent;
                }
                if (_this._cover._isVisible) {
                    _this._cover._isVisible = false;
                    _this._cover.addClass('entryRemove');
                }
                if (!isMoved &&
                    !beforeVisible &&
                    _this._cover.width() < Entry.CANVAS_DEFAULT_WIDTH - 24) {
                    Entry.resizeElement({ canvasWidth: Entry.CANVAS_MIN_WIDTH });
                }
                $(document).unbind('.container:splitter');
            });
        });
    };
    PropertyPanel.prototype._removeDom = function (mode) {
        if (this.modes[mode]) {
            this.modes[mode].tabDom.remove();
            this.modes[mode].contentDom.remove();
            if (mode === 'hw') {
                $(this.modes).removeClass('.propertyTabhw');
                $('.propertyTabhw').unbind('dblclick');
            }
        }
    };
    return PropertyPanel;
}());
export default PropertyPanel;
Entry.PropertyPanel = PropertyPanel;
