var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { stringToUint8Array } from '../../util/hardwareUtils';
var filters = [
    {
        vendorId: 3368,
        productId: 516,
        classCode: 255,
        subclassCode: 3,
    },
    {
        vendorId: 3368,
        productId: 516,
        classCode: 255,
        subclassCode: 0,
    },
];
var WebUsbFlasher = /** @class */ (function () {
    function WebUsbFlasher() {
        this.isTransfer = false;
        this.claimInterface = -1;
        this.endpointNumber = -1;
    }
    // TODO: 함수분리 리팩토링
    WebUsbFlasher.prototype.flashFirmware = function (firmwareUrl, moduleId) {
        return __awaiter(this, void 0, void 0, function () {
            var response, hexData, data, _a, result, chunkSize, offset, sentPages, end, nextPageData, cmdData, flashResult;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 13, 14]);
                        return [4 /*yield*/, fetch(firmwareUrl)];
                    case 1:
                        response = _b.sent();
                        return [4 /*yield*/, response.text()];
                    case 2:
                        hexData = _b.sent();
                        data = stringToUint8Array(hexData);
                        _a = this;
                        return [4 /*yield*/, navigator.usb.requestDevice({
                                filters: [{ vendorId: 0x0d28 }],
                            })];
                    case 3:
                        _a.device = _b.sent();
                        return [4 /*yield*/, this.device.open()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.device.selectConfiguration(1)];
                    case 5:
                        _b.sent();
                        this.findInterface();
                        return [4 /*yield*/, this.device.claimInterface(this.claimInterface)];
                    case 6:
                        _b.sent();
                        this.flashState = 'start';
                        return [4 /*yield*/, this.writeData([0x8a, 1])];
                    case 7:
                        result = _b.sent();
                        if (result[1] !== 0) {
                            throw Error('device open failed');
                        }
                        chunkSize = 62;
                        offset = 0;
                        sentPages = 0;
                        this.flashState = 'flashing';
                        _b.label = 8;
                    case 8:
                        if (!(offset < data.length)) return [3 /*break*/, 10];
                        end = Math.min(data.length, offset + chunkSize);
                        nextPageData = data.slice(offset, end);
                        cmdData = new Uint8Array(2 + nextPageData.length);
                        cmdData[0] = 0x8c;
                        cmdData[1] = nextPageData.length;
                        cmdData.set(nextPageData, 2);
                        // TODO: 퍼센트 로직도 분리
                        if (sentPages % 128 == 0) {
                            this.flashingPercent = (offset / data.length) * 100;
                            console.log(this.flashingPercent);
                        }
                        return [4 /*yield*/, this.writeBuffer(cmdData)];
                    case 9:
                        _b.sent();
                        sentPages++;
                        offset = end;
                        return [3 /*break*/, 8];
                    case 10:
                        this.flashingPercent = (offset / data.length) * 100;
                        console.log(this.flashingPercent);
                        this.flashState = 'end';
                        return [4 /*yield*/, this.writeData([0x8b])];
                    case 11:
                        flashResult = _b.sent();
                        if (flashResult[1] !== 0) {
                            throw Error('flash failed');
                        }
                        // INFO: reset
                        return [4 /*yield*/, this.writeData([0x89])];
                    case 12:
                        // INFO: reset
                        _b.sent();
                        return [3 /*break*/, 14];
                    case 13:
                        this.flashState = 'idle';
                        return [7 /*endfinally*/];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    WebUsbFlasher.prototype.findInterface = function () {
        var filteredInterfaces = this.device.configurations[0].interfaces.filter(function (interfaceItem) {
            var e_1, _a;
            var alternateInterface = interfaceItem.alternates[0];
            try {
                for (var filters_1 = __values(filters), filters_1_1 = filters_1.next(); !filters_1_1.done; filters_1_1 = filters_1.next()) {
                    var filter = filters_1_1.value;
                    if ((filter.classCode === null ||
                        alternateInterface.interfaceClass === filter.classCode) &&
                        !(filter.subclassCode !== null &&
                            alternateInterface.interfaceSubclass !== filter.subclassCode)) {
                        if (alternateInterface.endpoints.length === 0) {
                            return true;
                        }
                        if (alternateInterface.endpoints.length === 2 &&
                            alternateInterface.endpoints.every(function (endpoint) { return endpoint.packetSize === 64; })) {
                            return true;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (filters_1_1 && !filters_1_1.done && (_a = filters_1.return)) _a.call(filters_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return false;
        });
        var iface = filteredInterfaces[filteredInterfaces.length - 1];
        var altIface = iface.alternates[0];
        if (altIface.endpoints.length) {
            // study: endpoints 역할
            this.isTransfer = true;
            var epIn = altIface.endpoints.filter(function (e) { return 'in' == e.direction; })[0];
            this.endpointNumber = epIn.endpointNumber;
        }
        this.claimInterface = iface.interfaceNumber;
    };
    WebUsbFlasher.prototype.transfer = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isTransfer) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.device.transferOut(this.endpointNumber, new Uint8Array(data))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.device.transferIn(this.endpointNumber, 64)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [4 /*yield*/, this.device.controlTransferOut({
                            requestType: 'class',
                            recipient: 'interface',
                            request: 9,
                            value: 512,
                            index: this.claimInterface,
                        }, data)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.device.controlTransferIn({
                                requestType: 'class',
                                recipient: 'interface',
                                request: 1,
                                value: 256,
                                index: this.claimInterface,
                            }, 64)];
                    case 5: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // TODO: buffer랑 합치기
    WebUsbFlasher.prototype.writeData = function (data) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.transfer(new Uint8Array(data))];
                    case 1:
                        response = _b.sent();
                        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.buffer)) {
                            throw Error('writeData failed');
                        }
                        return [2 /*return*/, new Uint8Array(response.data.buffer)];
                }
            });
        });
    };
    WebUsbFlasher.prototype.writeBuffer = function (buffer) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.transfer(buffer)];
                    case 1:
                        response = _b.sent();
                        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.buffer)) {
                            throw Error('writeData failed');
                        }
                        return [2 /*return*/, new Uint8Array(response.data.buffer)];
                }
            });
        });
    };
    return WebUsbFlasher;
}());
export default WebUsbFlasher;
