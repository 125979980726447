var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var toFixed = function (num) { return parseFloat(num.toFixed(2)); };
export var CommonUtil = {
    isWebGlSupport: function () {
        try {
            var currentCanvas = document.createElement('canvas');
            return !!currentCanvas.getContext('webgl', { premultipliedalpha: false });
        }
        catch (e) {
            console.log('error', e);
            return false;
        }
    },
    stringToNumber: function (i, value, tempMap, tempMapCount) {
        var _a;
        // if (!isNaN(parseFloat(value))) {
        //     return parseFloat(value);
        // }
        if (!tempMap[i]) {
            tempMap[i] = {};
        }
        if (!((_a = tempMap[i]) === null || _a === void 0 ? void 0 : _a[value])) {
            if (!tempMapCount[i]) {
                tempMapCount[i] = 0;
            }
            tempMapCount[i] = tempMapCount[i] + 1;
            tempMap[i][value] = tempMapCount[i];
        }
        return tempMap[i][value];
    },
    shuffle: function (arr) {
        var j;
        var x;
        var i;
        for (i = arr.length; i; i -= 1) {
            j = Math.floor(Math.random() * i);
            x = arr[i - 1];
            arr[i - 1] = arr[j];
            arr[j] = x;
        }
    },
    getScores: function (confusionMatrix, numClasses) {
        var _a;
        var total = 0;
        var acc = 0;
        var precisions = [];
        var recalls = [];
        for (var i = 0; i < numClasses; i++) {
            var rowSum = 0;
            var colSum = 0;
            for (var j = 0; j < numClasses; j++) {
                rowSum += confusionMatrix[i][j];
                colSum += confusionMatrix[j][i];
            }
            var matrixValue = ((_a = confusionMatrix === null || confusionMatrix === void 0 ? void 0 : confusionMatrix[i]) === null || _a === void 0 ? void 0 : _a[i]) || 0;
            precisions.push(confusionMatrix[i][i] / rowSum || 0);
            recalls.push(confusionMatrix[i][i] / colSum || 0);
            total += rowSum;
            acc += matrixValue;
        }
        var precision = precisions.reduce(function (a, b) { return a + b; }, 0) / precisions.length;
        var recall = recalls.reduce(function (a, b) { return a + b; }, 0) / recalls.length;
        return {
            accuracy: toFixed(acc / total),
            precision: toFixed(precision),
            recall: toFixed(recall),
            f1: toFixed(2 / (1 / precision + 1 / recall)),
        };
    },
    arrayToMatrix: function (array, columns) {
        return Array(Math.ceil(array.length / columns))
            .fill('')
            .reduce(function (acc, cur, index) { return __spreadArray(__spreadArray([], __read(acc), false), [__spreadArray([], __read(array), false).splice(index * columns, columns)], false); }, []);
    },
};
export default CommonUtil;
