Entry.skeleton.basic_button_disabled = {
    path: function () {
        return "m 6 0\n            h 150\n            a 6 6 0 0 1 6 6\n            v 28\n            a 6 6 0 0 1 -6 6\n            h -150\n            a 6 6 0 0 1 -6 -6\n            v -28\n            a 6 6 0 0 1 6 -6\n            z";
    },
    box: function () {
        return {
            offsetX: -5,
            offsetY: 0,
            width: 162,
            height: 40,
            marginBottom: 3,
        };
    },
    contentPos: function () {
        // apply scale required.
        return { x: 81, y: 20 };
    },
    movable: false,
    readOnly: true,
    nextShadow: true,
    fontSize: 14,
    classes: ['basicButtonDisabledView'],
};
