var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import WebApiConnector from './webApiConnector';
import { getServiceClassesByModuleId } from './bluetoothServices';
var WebBluetoothConnector = /** @class */ (function (_super) {
    __extends(WebBluetoothConnector, _super);
    function WebBluetoothConnector(hwModule, hwLite) {
        var _this = _super.call(this) || this;
        _this.hwModule = hwModule;
        _this.setServiceClasses();
        return _this;
    }
    WebBluetoothConnector.prototype.connect = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setDevice()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setServices()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebBluetoothConnector.prototype.disconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.device.gatt.disconnect()];
                    case 1:
                        _a.sent();
                        this.hwModule = undefined;
                        this.device = undefined;
                        this.services = undefined;
                        this.serviceClasses = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    WebBluetoothConnector.prototype.initialDevice = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                if ((_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.initialHandshake) {
                    (_b = this.hwModule) === null || _b === void 0 ? void 0 : _b.initialHandshake();
                }
                return [2 /*return*/];
            });
        });
    };
    WebBluetoothConnector.prototype.setDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filters, optionalServices, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        filters = this.hwModule.bluetoothInfo.filters;
                        optionalServices = this.serviceClasses.map(function (serviceClass) { return serviceClass.uuid; });
                        _a = this;
                        return [4 /*yield*/, navigator.bluetooth.requestDevice({ filters: filters, optionalServices: optionalServices })];
                    case 1:
                        _a.device = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebBluetoothConnector.prototype.setServiceClasses = function () {
        this.serviceClasses = getServiceClassesByModuleId(this.hwModule.id);
    };
    WebBluetoothConnector.prototype.setServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var primaryServices, primaryServices_1, primaryServices_1_1, primaryService, _a, _b, serviceClass, _c, _d, e_1_1, e_2_1;
            var e_2, _e, e_1, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!this.device || !this.device.gatt) {
                            this.hwLite.getConnectFailedMenu();
                            return [2 /*return*/];
                        }
                        if (!!this.device.gatt.connected) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.device.gatt.connect()];
                    case 1:
                        _g.sent();
                        _g.label = 2;
                    case 2:
                        this.services = {};
                        return [4 /*yield*/, this.device.gatt.getPrimaryServices()];
                    case 3:
                        primaryServices = _g.sent();
                        _g.label = 4;
                    case 4:
                        _g.trys.push([4, 15, 16, 17]);
                        primaryServices_1 = __values(primaryServices), primaryServices_1_1 = primaryServices_1.next();
                        _g.label = 5;
                    case 5:
                        if (!!primaryServices_1_1.done) return [3 /*break*/, 14];
                        primaryService = primaryServices_1_1.value;
                        _g.label = 6;
                    case 6:
                        _g.trys.push([6, 11, 12, 13]);
                        _a = (e_1 = void 0, __values(this.serviceClasses)), _b = _a.next();
                        _g.label = 7;
                    case 7:
                        if (!!_b.done) return [3 /*break*/, 10];
                        serviceClass = _b.value;
                        if (!(primaryService.uuid === serviceClass.uuid)) return [3 /*break*/, 9];
                        _c = this.services;
                        _d = serviceClass.serviceName;
                        return [4 /*yield*/, serviceClass.create(primaryService)];
                    case 8:
                        _c[_d] = _g.sent();
                        _g.label = 9;
                    case 9:
                        _b = _a.next();
                        return [3 /*break*/, 7];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_1_1 = _g.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 13:
                        primaryServices_1_1 = primaryServices_1.next();
                        return [3 /*break*/, 5];
                    case 14: return [3 /*break*/, 17];
                    case 15:
                        e_2_1 = _g.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 17];
                    case 16:
                        try {
                            if (primaryServices_1_1 && !primaryServices_1_1.done && (_e = primaryServices_1.return)) _e.call(primaryServices_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    return WebBluetoothConnector;
}(WebApiConnector));
export default WebBluetoothConnector;
