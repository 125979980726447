// @ts-ignore
import each from 'lodash/each';
var PrimitiveMap = /** @class */ (function () {
    function PrimitiveMap() {
        this._map = {};
    }
    PrimitiveMap.prototype.add = function (key, value) {
        this._map[key] = value;
    };
    PrimitiveMap.prototype.getValue = function (key) {
        return this._map[key];
    };
    PrimitiveMap.prototype.hasValue = function (key) {
        return Boolean(this._map[key]);
    };
    PrimitiveMap.prototype.remove = function (key) {
        var v = this._map[key];
        delete this._map[key];
        return v;
    };
    PrimitiveMap.prototype.destroy = function () {
        this._map = null;
    };
    PrimitiveMap.prototype.each = function (f) {
        each(this._map, f);
    };
    return PrimitiveMap;
}());
export { PrimitiveMap };
