/**
 * useWebGL 일때 font 로드가 끝나면 생성된 모든 text 오브젝트를 다시 draw 하도록 함.
 */
import { TextMetrics } from 'pixi.js';
var LOADED = 'fontLoaded';
var PIXIFontLoadHandler = /** @class */ (function () {
    function PIXIFontLoadHandler() {
        this._items = [];
        this._handleFontLoaded = this._handleFontLoaded.bind(this);
        this._listen();
    }
    PIXIFontLoadHandler.prototype._listen = function () {
        if (window.fontLoaded) {
            this._fontLoaded = true;
        }
        else {
            Entry.addEventListener(LOADED, this._handleFontLoaded);
        }
    };
    PIXIFontLoadHandler.prototype._handleFontLoaded = function () {
        this._fontLoaded = true;
        Entry.removeEventListener(LOADED, this._handleFontLoaded);
        TextMetrics.clearMetrics(null);
        this._items.forEach(function (text) {
            //updateText() 는 private 함수이지만, override 했으므로, 호출하겠음.
            text.updateText(false);
        });
        Entry.requestUpdate = true;
        this._items = null;
    };
    /** @param {PIXIText} text */
    PIXIFontLoadHandler.prototype.manage = function (text) {
        if (this._fontLoaded) {
            return;
        }
        this._items.push(text);
    };
    /** @param {PIXIText} text */
    PIXIFontLoadHandler.prototype.unmanage = function (text) {
        if (this._fontLoaded) {
            return;
        }
        var arr = this._items;
        var index = arr.indexOf(text);
        if (index == -1) {
            return;
        }
        arr.splice(index, 1);
    };
    return PIXIFontLoadHandler;
}());
export { PIXIFontLoadHandler };
