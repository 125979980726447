'use strict';

Entry.Hamster = {
  setZero: function setZero() {
    Entry.Robomation.setZero();
  },
  afterReceive: function afterReceive(pd) {
    Entry.Robomation.afterReceive(pd, false);
  },
  getRobot: function getRobot() {
    var robot = Entry.Robomation.getRobot('hamster', 0);
    if (robot) {
      robot.setMotoring(Entry.hw.sendQueue);
    }
    return robot;
  },
  id: '2.4',
  name: 'hamster',
  url: 'http://www.robomation.net',
  imageName: 'hamster.png',
  title: {
    ko: '햄스터',
    en: 'Hamster',
    jp: 'ハムスター',
    vn: 'Hamster'
  },
  monitorTemplate: {
    imgPath: 'hw/hamster.png',
    width: 256,
    height: 256,
    listPorts: {
      temperature: {
        name: Lang.Blocks.HAMSTER_sensor_temperature,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      inputA: {
        name: Lang.Blocks.HAMSTER_sensor_input_a,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      inputB: {
        name: Lang.Blocks.HAMSTER_sensor_input_b,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      accelerationX: {
        name: Lang.Blocks.HAMSTER_sensor_acceleration_x,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      accelerationY: {
        name: Lang.Blocks.HAMSTER_sensor_acceleration_y,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      accelerationZ: {
        name: Lang.Blocks.HAMSTER_sensor_acceleration_z,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      buzzer: {
        name: Lang.Hw.buzzer,
        type: 'output',
        pos: {
          x: 0,
          y: 0
        }
      },
      note: {
        name: Lang.Hw.note,
        type: 'output',
        pos: {
          x: 0,
          y: 0
        }
      },
      outputA: {
        name: "".concat(Lang.Hw.output, "A"),
        type: 'output',
        pos: {
          x: 0,
          y: 0
        }
      },
      outputB: {
        name: "".concat(Lang.Hw.output, "B"),
        type: 'output',
        pos: {
          x: 0,
          y: 0
        }
      }
    },
    ports: {
      leftProximity: {
        name: Lang.Blocks.HAMSTER_sensor_left_proximity,
        type: 'input',
        pos: {
          x: 122,
          y: 156
        }
      },
      rightProximity: {
        name: Lang.Blocks.HAMSTER_sensor_right_proximity,
        type: 'input',
        pos: {
          x: 10,
          y: 108
        }
      },
      leftFloor: {
        name: Lang.Blocks.HAMSTER_sensor_left_floor,
        type: 'input',
        pos: {
          x: 100,
          y: 234
        }
      },
      rightFloor: {
        name: Lang.Blocks.HAMSTER_sensor_right_floor,
        type: 'input',
        pos: {
          x: 13,
          y: 180
        }
      },
      light: {
        name: Lang.Blocks.HAMSTER_sensor_light,
        type: 'input',
        pos: {
          x: 56,
          y: 189
        }
      },
      leftWheel: {
        name: Lang.Hw.leftWheel,
        type: 'output',
        pos: {
          x: 209,
          y: 115
        }
      },
      rightWheel: {
        name: Lang.Hw.rightWheel,
        type: 'output',
        pos: {
          x: 98,
          y: 30
        }
      },
      leftLed: {
        name: "".concat(Lang.Hw.left, " ").concat(Lang.Hw.led_en),
        type: 'output',
        pos: {
          x: 87,
          y: 210
        }
      },
      rightLed: {
        name: "".concat(Lang.Hw.right, " ").concat(Lang.Hw.led_en),
        type: 'output',
        pos: {
          x: 24,
          y: 168
        }
      }
    },
    mode: 'both'
  }
};
Entry.Hamster.setLanguage = function () {
  return {
    ko: {
      template: {
        hamster_gripper: '집게 %1 %2',
        hamster_release_gripper: '집게 끄기 %1',
        hamster_boolean: '%1?',
        hamster_play_note: '%1 %2 음을 연주하기 %3',
        hamster_hand_found: '손 찾음?',
        hamster_value: '%1',
        hamster_move_forward_once: '말판 앞으로 한 칸 이동하기 %1',
        hamster_turn_once: '말판 %1 으로 한 번 돌기 %2',
        hamster_move_forward_for_secs: '앞으로 %1 초 이동하기 %2',
        hamster_move_backward_for_secs: '뒤로 %1 초 이동하기 %2',
        hamster_turn_for_secs: '%1 으로 %2 초 돌기 %3',
        hamster_change_both_wheels_by: '왼쪽 바퀴 %1 오른쪽 바퀴 %2 만큼 바꾸기 %3',
        hamster_set_both_wheels_to: '왼쪽 바퀴 %1 오른쪽 바퀴 %2 (으)로 정하기 %3',
        hamster_change_wheel_by: '%1 바퀴 %2 만큼 바꾸기 %3',
        hamster_set_wheel_to: '%1 바퀴 %2 (으)로 정하기 %3',
        hamster_follow_line_using: '%1 선을 %2 바닥 센서로 따라가기 %3',
        hamster_follow_line_until: '%1 선을 따라 %2 교차로까지 이동하기 %3',
        hamster_set_following_speed_to: '선 따라가기 속도를 %1 (으)로 정하기 %2',
        hamster_stop: '정지하기 %1',
        hamster_set_led_to: '%1 LED를 %2 으로 정하기 %3',
        hamster_clear_led: '%1 LED 끄기 %2',
        hamster_beep: '삐 소리내기 %1',
        hamster_change_buzzer_by: '버저 음을 %1 만큼 바꾸기 %2',
        hamster_set_buzzer_to: '버저 음을 %1 (으)로 정하기 %2',
        hamster_clear_buzzer: '버저 끄기 %1',
        hamster_play_note_for: '%1 %2 음을 %3 박자 연주하기 %4',
        hamster_rest_for: '%1 박자 쉬기 %2',
        hamster_change_tempo_by: '연주 속도를 %1 만큼 바꾸기 %2',
        hamster_set_tempo_to: '연주 속도를 %1 BPM으로 정하기 %2',
        hamster_set_port_to: '포트 %1 를 %2 으로 정하기 %3',
        hamster_change_output_by: '출력 %1 를 %2 만큼 바꾸기 %3',
        hamster_set_output_to: '출력 %1 를 %2 (으)로 정하기 %3'
      },
      Helper: {
        hamster_gripper: '집게를 열거나 닫습니다.',
        hamster_release_gripper: '집게의 전원을 끄고 자유롭게 움직일 수 있도록 합니다.',
        hamster_boolean: "앞으로 기울임: 앞으로 기울이면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>뒤로 기울임: 뒤로 기울이면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>왼쪽으로 기울임: 왼쪽으로 기울이면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>오른쪽으로 기울임: 오른쪽으로 기울이면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>거꾸로 뒤집음: 거꾸로 뒤집으면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>기울이지 않음: 기울이지 않으면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>배터리 정상: 배터리 잔량이 충분하면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>배터리 부족: 배터리 잔량이 부족하면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.<br/>배터리 없음: 배터리 잔량이 없으면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.",
        hamster_play_note: '선택한 계이름과 옥타브의 음을 소리 냅니다.',
        hamster_beep: '버저 소리를 짧게 냅니다.',
        hamster_change_both_wheels_by: '왼쪽과 오른쪽 바퀴의 현재 속도 값(%)에 입력한 값을 각각 더합니다. 더한 결과가 양수 값이면 바퀴가 앞으로 회전하고, 음수 값이면 뒤로 회전합니다.',
        hamster_change_buzzer_by: '버저 소리의 현재 음 높이(Hz)에 입력한 값을 더합니다. 소수점 둘째 자리까지 입력할 수 있습니다.',
        hamster_change_output_by: '선택한 외부 확장 포트의 현재 출력 값에 입력한 값을 더합니다. 더한 결과는 외부 확장 포트의 모드에 따라 다음의 범위를 가집니다.<br/>서보 출력: 유효한 값의 범위는 1 ~ 180도, 0이면 PWM 펄스 없이 항상 0을 출력<br/>PWM 출력: 0 ~ 100%, PWM 파형에서 ON 상태의 듀티비(%)<br/>디지털 출력: 0이면 LOW, 0이 아니면 HIGH',
        hamster_change_tempo_by: '연주하거나 쉬는 속도의 현재 BPM(분당 박자 수)에 입력한 값을 더합니다.',
        hamster_change_wheel_by: '왼쪽/오른쪽/양쪽 바퀴의 현재 속도 값(%)에 입력한 값을 더합니다. 더한 결과가 양수 값이면 바퀴가 앞으로 회전하고, 음수 값이면 뒤로 회전합니다.',
        hamster_clear_buzzer: '버저 소리를 끕니다.',
        hamster_clear_led: '왼쪽/오른쪽/양쪽 LED를 끕니다.',
        hamster_follow_line_until: '왼쪽/오른쪽/앞쪽/뒤쪽의 검은색/하얀색 선을 따라 이동하다가 교차로를 만나면 정지합니다.',
        hamster_follow_line_using: '왼쪽/오른쪽/양쪽 바닥 센서를 사용하여 검은색/하얀색 선을 따라 이동합니다.',
        hamster_hand_found: "근접 센서 앞에 손 또는 물체가 있으면 '참'으로 판단하고, 아니면 '거짓'으로 판단합니다.",
        hamster_move_backward_for_secs: '입력한 시간(초) 동안 뒤로 이동합니다.',
        hamster_move_forward_for_secs: '입력한 시간(초) 동안 앞으로 이동합니다.',
        hamster_move_forward_once: '말판 위에서 한 칸 앞으로 이동합니다.',
        hamster_play_note_for: '선택한 계이름과 옥타브의 음을 입력한 박자만큼 소리 냅니다.',
        hamster_rest_for: '입력한 박자만큼 쉽니다.',
        hamster_set_both_wheels_to: '왼쪽과 오른쪽 바퀴의 속도를 입력한 값(-100 ~ 100%)으로 각각 설정합니다. 양수 값을 입력하면 바퀴가 앞으로 회전하고, 음수 값을 입력하면 뒤로 회전합니다. 숫자 0을 입력하면 정지합니다.',
        hamster_set_buzzer_to: '버저 소리의 음 높이를 입력한 값(Hz)으로 설정합니다. 소수점 둘째 자리까지 입력할 수 있습니다. 숫자 0을 입력하면 버저 소리를 끕니다.',
        hamster_set_following_speed_to: '선을 따라 이동하는 속도(1 ~ 8)를 설정합니다. 숫자가 클수록 이동하는 속도가 빠릅니다.',
        hamster_set_led_to: '왼쪽/오른쪽/양쪽 LED를 선택한 색깔로 켭니다.',
        hamster_set_output_to: '선택한 외부 확장 포트의 출력 값을 입력한 값으로 설정합니다. 입력하는 값은 외부 확장 포트의 모드에 따라 다음의 범위를 가집니다.<br/>서보 출력: 유효한 값의 범위는 1 ~ 180도, 0이면 PWM 펄스 없이 항상 0을 출력<br/>PWM 출력: 0 ~ 100%, PWM 파형에서 ON 상태의 듀티비(%)<br/>디지털 출력: 0이면 LOW, 0이 아니면 HIGH',
        hamster_set_port_to: '선택한 외부 확장 포트의 입출력 모드를 선택한 모드로 설정합니다.',
        hamster_set_tempo_to: '연주하거나 쉬는 속도를 입력한 BPM(분당 박자 수)으로 설정합니다.',
        hamster_set_wheel_to: '왼쪽/오른쪽/양쪽 바퀴의 속도를 입력한 값(-100 ~ 100%)으로 설정합니다. 양수 값을 입력하면 바퀴가 앞으로 회전하고, 음수 값을 입력하면 뒤로 회전합니다. 숫자 0을 입력하면 정지합니다.',
        hamster_stop: '양쪽 바퀴를 정지합니다.',
        hamster_turn_for_secs: '입력한 시간(초) 동안 왼쪽/오른쪽 방향으로 제자리에서 회전합니다.',
        hamster_turn_once: '말판 위에서 왼쪽/오른쪽 방향으로 제자리에서 90도 회전합니다.',
        hamster_value: '왼쪽 근접 센서: 왼쪽 근접 센서의 값 (값의 범위: 0 ~ 255, 초기값: 0)<br/>오른쪽 근접 센서: 오른쪽 근접 센서의 값 (값의 범위: 0 ~ 255, 초기값: 0)<br/>왼쪽 바닥 센서: 왼쪽 바닥 센서의 값 (값의 범위: 0 ~ 100, 초기값: 0)<br/>오른쪽 바닥 센서: 오른쪽 바닥 센서의 값 (값의 범위: 0 ~ 100, 초기값: 0)<br/>x축 가속도: 가속도 센서의 X축 값 (값의 범위: -32768 ~ 32767, 초기값: 0) 로봇이 전진하는 방향이 X축의 양수 방향입니다.<br/>y축 가속도: 가속도 센서의 Y축 값 (값의 범위: -32768 ~ 32767, 초기값: 0) 로봇의 왼쪽 방향이 Y축의 양수 방향입니다.<br/>z축 가속도: 가속도 센서의 Z축 값 (값의 범위: -32768 ~ 32767, 초기값: 0) 로봇의 위쪽 방향이 Z축의 양수 방향입니다.<br/>밝기: 밝기 센서의 값 (값의 범위: 0 ~ 65535, 초기값: 0) 밝을 수록 값이 커집니다.<br/>온도: 로봇 내부의 온도 값 (값의 범위: 섭씨 -40 ~ 88도, 초기값: 0)<br/>신호 세기: 블루투스 무선 통신의 신호 세기 (값의 범위: -128 ~ 0 dBm, 초기값: 0) 신호의 세기가 셀수록 값이 커집니다.<br/>입력 A: 외부 확장 포트 A로 입력되는 신호의 값 (값의 범위: 아날로그 입력 0 ~ 255, 디지털 입력 0 또는 1, 초기값: 0)<br/>입력 B: 외부 확장 포트 B로 입력되는 신호의 값 (값의 범위: 아날로그 입력 0 ~ 255, 디지털 입력 0 또는 1, 초기값: 0)'
      },
      Blocks: {
        HAMSTER_hand_found: '손 찾음?',
        HAMSTER_sensor_left_proximity: '왼쪽 근접 센서',
        HAMSTER_sensor_right_proximity: '오른쪽 근접 센서',
        HAMSTER_sensor_left_floor: '왼쪽 바닥 센서',
        HAMSTER_sensor_right_floor: '오른쪽 바닥 센서',
        HAMSTER_sensor_acceleration_x: 'x축 가속도',
        HAMSTER_sensor_acceleration_y: 'y축 가속도',
        HAMSTER_sensor_acceleration_z: 'z축 가속도',
        HAMSTER_sensor_light: '밝기',
        HAMSTER_sensor_temperature: '온도',
        HAMSTER_sensor_signal_strength: '신호 세기',
        HAMSTER_sensor_input_a: '입력 A',
        HAMSTER_sensor_input_b: '입력 B',
        HAMSTER_move_forward_once: '말판 앞으로 한 칸 이동하기',
        HAMSTER_turn_once_1: '말판',
        HAMSTER_turn_once_2: '으로 한 번 돌기',
        HAMSTER_turn_once_left: '왼쪽',
        HAMSTER_turn_right: '오른쪽',
        HAMSTER_move_forward: '앞으로 이동하기',
        HAMSTER_move_backward: '뒤로 이동하기',
        HAMSTER_turn_around_1: '',
        HAMSTER_turn_around_2: '으로 돌기',
        HAMSTER_move_forward_for_secs_1: '앞으로',
        HAMSTER_move_forward_for_secs_2: '초 이동하기',
        HAMSTER_move_backward_for_secs_1: '뒤로',
        HAMSTER_move_backward_for_secs_2: '초 이동하기',
        HAMSTER_turn_for_secs_1: '',
        HAMSTER_turn_for_secs_2: '으로',
        HAMSTER_turn_for_secs_3: '초 돌기',
        HAMSTER_change_both_wheels_by_1: '왼쪽 바퀴',
        HAMSTER_change_both_wheels_by_2: '오른쪽 바퀴',
        HAMSTER_change_both_wheels_by_3: '만큼 바꾸기',
        HAMSTER_set_both_wheels_to_1: '왼쪽 바퀴',
        HAMSTER_set_both_wheels_to_2: '오른쪽 바퀴',
        HAMSTER_set_both_wheels_to_3: '(으)로 정하기',
        HAMSTER_change_wheel_by_1: '',
        HAMSTER_change_wheel_by_2: '바퀴',
        HAMSTER_change_wheel_by_3: '만큼 바꾸기',
        HAMSTER_left_wheel: '왼쪽',
        HAMSTER_right_wheel: '오른쪽',
        HAMSTER_both_wheels: '양쪽',
        HAMSTER_set_wheel_to_1: '',
        HAMSTER_set_wheel_to_2: '바퀴',
        HAMSTER_set_wheel_to_3: '(으)로 정하기',
        HAMSTER_follow_line_using_1: '',
        HAMSTER_follow_line_using_2: '선을',
        HAMSTER_follow_line_using_3: '바닥 센서로 따라가기',
        HAMSTER_left_floor_sensor: '왼쪽',
        HAMSTER_right_floor_sensor: '오른쪽',
        HAMSTER_both_floor_sensors: '양쪽',
        HAMSTER_follow_line_until_1: '',
        HAMSTER_follow_line_until_2: '선을 따라',
        HAMSTER_follow_line_until_3: '교차로까지 이동하기',
        HAMSTER_left_intersection: '왼쪽',
        HAMSTER_right_intersection: '오른쪽',
        HAMSTER_front_intersection: '앞쪽',
        HAMSTER_rear_intersection: '뒤쪽',
        HAMSTER_set_following_speed_to_1: '선 따라가기 속도를',
        HAMSTER_set_following_speed_to_2: '(으)로 정하기',
        HAMSTER_front: '앞쪽',
        HAMSTER_rear: '뒤쪽',
        HAMSTER_stop: '정지하기',
        HAMSTER_set_led_to_1: '',
        HAMSTER_set_led_to_2: 'LED를',
        HAMSTER_set_led_to_3: '으로 정하기',
        HAMSTER_left_led: '왼쪽',
        HAMSTER_right_led: '오른쪽',
        HAMSTER_both_leds: '양쪽',
        HAMSTER_clear_led_1: '',
        HAMSTER_clear_led_2: 'LED 끄기',
        HAMSTER_color_cyan: '하늘색',
        HAMSTER_color_magenta: '자주색',
        HAMSTER_color_black: '검은색',
        HAMSTER_color_white: '하얀색',
        HAMSTER_color_red: '빨간색',
        HAMSTER_color_yellow: '노란색',
        HAMSTER_color_green: '초록색',
        HAMSTER_color_blue: '파란색',
        HAMSTER_beep: '삐 소리내기',
        HAMSTER_change_buzzer_by_1: '버저 음을',
        HAMSTER_change_buzzer_by_2: '만큼 바꾸기',
        HAMSTER_set_buzzer_to_1: '버저 음을',
        HAMSTER_set_buzzer_to_2: '(으)로 정하기',
        HAMSTER_clear_buzzer: '버저 끄기',
        HAMSTER_play_note_for_1: '',
        HAMSTER_play_note_for_2: '',
        HAMSTER_play_note_for_3: '음을',
        HAMSTER_play_note_for_4: '박자 연주하기',
        HAMSTER_rest_for_1: '',
        HAMSTER_rest_for_2: '박자 쉬기',
        HAMSTER_change_tempo_by_1: '연주 속도를',
        HAMSTER_change_tempo_by_2: '만큼 바꾸기',
        HAMSTER_set_tempo_to_1: '연주 속도를 분당',
        HAMSTER_set_tempo_to_2: '박자로 정하기',
        HAMSTER_set_port_to_1: '포트',
        HAMSTER_set_port_to_2: '를',
        HAMSTER_set_port_to_3: '으로 정하기',
        HAMSTER_change_output_by_1: '출력',
        HAMSTER_change_output_by_2: '를',
        HAMSTER_change_output_by_3: '만큼 바꾸기',
        HAMSTER_set_output_to_1: '출력',
        HAMSTER_set_output_to_2: '를',
        HAMSTER_set_output_to_3: '(으)로 정하기',
        HAMSTER_port_a: 'A',
        HAMSTER_port_b: 'B',
        HAMSTER_port_ab: 'A와 B',
        HAMSTER_analog_input: '아날로그 입력',
        HAMSTER_digital_input: '디지털 입력',
        HAMSTER_servo_output: '서보 출력',
        HAMSTER_pwm_output: 'PWM 출력',
        HAMSTER_digital_output: '디지털 출력',
        hamster_note_c: '도',
        hamster_note_c_sharp: '도♯(레♭)',
        hamster_note_d: '레',
        hamster_note_d_sharp: '레♯(미♭)',
        hamster_note_e: '미',
        hamster_note_f: '파',
        hamster_note_f_sharp: '파♯(솔♭)',
        hamster_note_g: '솔',
        hamster_note_g_sharp: '솔♯(라♭)',
        hamster_note_a: '라',
        hamster_note_a_sharp: '라♯(시♭)',
        hamster_note_b: '시',
        hamster_tilt_forward: '앞으로 기울임',
        hamster_tilt_backward: '뒤로 기울임',
        hamster_tilt_left: '왼쪽으로 기울임',
        hamster_tilt_right: '오른쪽으로 기울임',
        hamster_tilt_flip: '거꾸로 뒤집음',
        hamster_tilt_not: '기울이지 않음',
        hamster_battery_normal: '배터리 정상',
        hamster_battery_low: '배터리 부족',
        hamster_battery_empty: '배터리 없음',
        hamster_open_gripper: '열기',
        hamster_close_gripper: '닫기'
      }
    },
    en: {
      template: {
        hamster_gripper: '%1 gripper %2',
        hamster_release_gripper: 'release gripper %1',
        hamster_boolean: '%1?',
        hamster_play_note: 'play note %1 %2 %3',
        hamster_hand_found: 'hand found?',
        hamster_value: '%1',
        hamster_move_forward_once: 'move forward once on board %1',
        hamster_turn_once: 'turn %1 once on board %2',
        hamster_move_forward_for_secs: 'move forward for %1 secs %2',
        hamster_move_backward_for_secs: 'move backward for %1 secs %2',
        hamster_turn_for_secs: 'turn %1 for %2 secs %3',
        hamster_change_both_wheels_by: 'change wheels by left: %1 right: %2 %3',
        hamster_set_both_wheels_to: 'set wheels to left: %1 right: %2 %3',
        hamster_change_wheel_by: 'change %1 wheel by %2 %3',
        hamster_set_wheel_to: 'set %1 wheel to %2 %3',
        hamster_follow_line_using: 'follow %1 line using %2 floor sensor %3',
        hamster_follow_line_until: 'follow %1 line until %2 intersection %3',
        hamster_set_following_speed_to: 'set following speed to %1 %2',
        hamster_stop: 'stop %1',
        hamster_set_led_to: 'set %1 led to %2 %3',
        hamster_clear_led: 'clear %1 led %2',
        hamster_beep: 'beep %1',
        hamster_change_buzzer_by: 'change buzzer by %1 %2',
        hamster_set_buzzer_to: 'set buzzer to %1 %2',
        hamster_clear_buzzer: 'clear buzzer %1',
        hamster_play_note_for: 'play note %1 %2 for %3 beats %4',
        hamster_rest_for: 'rest for %1 beats %2',
        hamster_change_tempo_by: 'change tempo by %1 %2',
        hamster_set_tempo_to: 'set tempo to %1 bpm %2',
        hamster_set_port_to: 'set port %1 to %2 %3',
        hamster_change_output_by: 'change output %1 by %2 %3',
        hamster_set_output_to: 'set output %1 to %2 %3'
      },
      Helper: {
        hamster_gripper: 'Opens or closes the gripper.',
        hamster_release_gripper: 'Turns off the gripper so that it can be moved freely.',
        hamster_boolean: 'tilt forward: If tilted forward, true, otherwise false<br/>tilt backward: If tilted backward, true, otherwise false<br/>tilt left: If tilted to the left, true, otherwise false<br/>tilt right: If tilted to the right, true, otherwise false<br/>tilt flip: If upside-down, true, otherwise false<br/>not tilt: If not tilted, true, otherwise false<br/>battery normal: If the battery is enough, true, otherwise false<br/>battery low: If the battery is low, true, otherwise false<br/>battery empty: If the battery is empty, true, otherwise false',
        hamster_play_note: 'It sounds the selected tone and octave.',
        hamster_beep: 'Plays beep sound.',
        hamster_change_both_wheels_by: 'Adds the entered values to the current speed values (%) of the left and right wheels respectively. If the result is positive, the wheel rotates forward; if negative, the wheel rotates backward.',
        hamster_change_buzzer_by: 'Adds the entered value to the current pitch (Hz) of the buzzer sound. You can enter up to two decimal places.',
        hamster_change_output_by: 'Adds the entered value to the current output value of the selected external port. The result will be in the following range depending on the mode of the external port.<br/>Servo output: valid range from 1 to 180 degrees; if 0, output 0 without PWM pulse<br/>PWM output: 0 to 100%, duty ratio (%) of ON state in PWM waveform<br/>Digital output: LOW when 0, otherwise HIGH',
        hamster_change_tempo_by: 'Adds the entered value to the current BPM (beats per minute) of the playing or resting speed.',
        hamster_change_wheel_by: 'Adds the entered value to the current speed value (%) of the left/right/both wheels. If the result is positive, the wheel rotates forward; if negative, the wheel rotates backward.',
        hamster_clear_buzzer: 'Turns off buzzer sound.',
        hamster_clear_led: 'Turns off the left/right/both LEDs.',
        hamster_follow_line_until: 'Moves along the black/white line on the left/right/front/back, then stops when the robot meets the intersection.',
        hamster_follow_line_using: 'Moves along the black/white line by using the left/right/both floor sensors.',
        hamster_hand_found: 'If there is a hand or object in front of the proximity sensor, true, otherwise false',
        hamster_move_backward_for_secs: 'Moves backward for the number of seconds entered.',
        hamster_move_forward_for_secs: 'Moves forward for the number of seconds entered.',
        hamster_move_forward_once: 'Moves one space forward on the board.',
        hamster_play_note_for: 'It sounds the selected tone and octave as much as the beat you entered.',
        hamster_rest_for: 'Rests as much as the beat you entered.',
        hamster_set_both_wheels_to: 'Sets the speed of the left and right wheels to the entered values (-100 to 100%), respectively. If you enter a positive value, the wheel rotates forward. If you enter a negative value, the wheel rotates backward. Entering the number 0 stops it.',
        hamster_set_buzzer_to: 'Sets the pitch of the buzzer sound to the entered value (Hz). You can enter up to two decimal places. Entering the number 0 turns off the buzzer sound.',
        hamster_set_following_speed_to: 'Sets the speed (1 to 8) to move along the line. The larger the number, the faster the movement.',
        hamster_set_led_to: 'Turns left/right/both LEDs to the selected color.',
        hamster_set_output_to: 'Sets the output value of the selected external port to the entered value. The value has the following range according to the mode of external port.<br/>Servo output: valid range from 1 to 180 degrees; if 0, output 0 without PWM pulse<br/>PWM output: 0 to 100%, duty ratio (%) of ON state in PWM waveform<br/>Digital output: LOW when 0, otherwise HIGH',
        hamster_set_port_to: 'Sets the io mode of the selected external port to the selected mode.',
        hamster_set_tempo_to: 'Sets the playing or resting speed to the entered BPM (beats per minute).',
        hamster_set_wheel_to: 'Sets the speed of the left/right/both wheels to the entered value (-100 to 100%). If you enter a positive value, the wheel rotates forward. If you enter a negative value, the wheel rotates backward. Entering the number 0 stops it.',
        hamster_stop: 'Stops both wheels.',
        hamster_turn_for_secs: 'Turns left/right for the number of seconds entered.',
        hamster_turn_once: 'Turns left/right 90 degrees on the board.',
        hamster_value: 'left proximity: value of left proximity sensor (range: 0 to 255, initial value: 0)<br/>right proximity: value of right proximity sensor (range: 0 to 255, initial value: 0)<br/>left floor: value of left floor sensor (range: 0 to 100, initial value: 0)<br/>right floor: value of right floor sensor (range: 0 to 100, initial value: 0)<br/>x acceleration: x-axis value of acceleration sensor (range: -32768 to 32767, initial value: 0) The direction in which the robot moves forward is the positive direction of the x axis.<br/>y acceleration: y-axis value of acceleration sensor (range: -32768 to 32767, initial value: 0) The left direction of the robot is the positive direction of the y axis.<br/>z acceleration: z-axis value of acceleration sensor (range: -32768 to 32767, initial value: 0) The upward direction of the robot is the positive direction of the z axis.<br/>light: value of light sensor (range: 0 to 65535, initial value: 0) The brighter, the larger the value.<br/>temperature: temperature value inside the robot (range: -40 to 88 degrees Celsius, initial value: 0)<br/>signal strength: signal strength of Bluetooth communication (range: -128 to 0 dBm, initial value: 0) As the signal strength increases, the value increases.<br/>input A: value of signal input to external port A (range: analog input 0 to 255, digital input 0 or 1, initial value: 0)<br/>input B: value of signal input to external port B (range: analog input 0 to 255, digital input 0 or 1, initial value: 0)'
      },
      Blocks: {
        HAMSTER_hand_found: 'hand found?',
        HAMSTER_sensor_left_proximity: 'left proximity',
        HAMSTER_sensor_right_proximity: 'right proximity',
        HAMSTER_sensor_left_floor: 'left floor',
        HAMSTER_sensor_right_floor: 'right floor',
        HAMSTER_sensor_acceleration_x: 'x acceleration',
        HAMSTER_sensor_acceleration_y: 'y acceleration',
        HAMSTER_sensor_acceleration_z: 'z acceleration',
        HAMSTER_sensor_light: 'light',
        HAMSTER_sensor_temperature: 'temperature',
        HAMSTER_sensor_signal_strength: 'signal strength',
        HAMSTER_sensor_input_a: 'input A',
        HAMSTER_sensor_input_b: 'input B',
        HAMSTER_move_forward_once: 'move forward once on board',
        HAMSTER_turn_once_1: 'turn',
        HAMSTER_turn_once_2: 'once on board',
        HAMSTER_turn_once_left: 'left',
        HAMSTER_turn_right: 'right',
        HAMSTER_move_forward: 'move forward',
        HAMSTER_move_backward: 'move backward',
        HAMSTER_turn_around_1: 'turn',
        HAMSTER_turn_around_2: '',
        HAMSTER_move_forward_for_secs_1: 'move forward for',
        HAMSTER_move_forward_for_secs_2: 'secs',
        HAMSTER_move_backward_for_secs_1: 'move backward',
        HAMSTER_move_backward_for_secs_2: 'secs',
        HAMSTER_turn_for_secs_1: 'turn',
        HAMSTER_turn_for_secs_2: 'for',
        HAMSTER_turn_for_secs_3: 'secs',
        HAMSTER_change_both_wheels_by_1: 'change wheel by left:',
        HAMSTER_change_both_wheels_by_2: 'right:',
        HAMSTER_change_both_wheels_by_3: '',
        HAMSTER_set_both_wheels_to_1: 'set wheel to left:',
        HAMSTER_set_both_wheels_to_2: 'right:',
        HAMSTER_set_both_wheels_to_3: '',
        HAMSTER_change_wheel_by_1: 'change',
        HAMSTER_change_wheel_by_2: 'wheel by',
        HAMSTER_change_wheel_by_3: '',
        HAMSTER_left_wheel: 'left',
        HAMSTER_right_wheel: 'right',
        HAMSTER_both_wheels: 'both',
        HAMSTER_set_wheel_to_1: 'set',
        HAMSTER_set_wheel_to_2: 'wheel to',
        HAMSTER_set_wheel_to_3: '',
        HAMSTER_follow_line_using_1: 'follow',
        HAMSTER_follow_line_using_2: 'line using',
        HAMSTER_follow_line_using_3: 'floor sensor',
        HAMSTER_left_floor_sensor: 'left',
        HAMSTER_right_floor_sensor: 'right',
        HAMSTER_both_floor_sensors: 'both',
        HAMSTER_follow_line_until_1: 'follow',
        HAMSTER_follow_line_until_2: 'line until',
        HAMSTER_follow_line_until_3: 'intersection',
        HAMSTER_left_intersection: 'left',
        HAMSTER_right_intersection: 'right',
        HAMSTER_front_intersection: 'front',
        HAMSTER_rear_intersection: 'rear',
        HAMSTER_set_following_speed_to_1: 'set following speed to',
        HAMSTER_set_following_speed_to_2: '',
        HAMSTER_front: 'front',
        HAMSTER_rear: 'rear',
        HAMSTER_stop: 'stop',
        HAMSTER_set_led_to_1: 'set',
        HAMSTER_set_led_to_2: 'led to',
        HAMSTER_set_led_to_3: '',
        HAMSTER_left_led: 'left',
        HAMSTER_right_led: 'right',
        HAMSTER_both_leds: 'both',
        HAMSTER_clear_led_1: 'clear',
        HAMSTER_clear_led_2: 'led',
        HAMSTER_color_cyan: 'sky blue',
        HAMSTER_color_magenta: 'purple',
        HAMSTER_color_black: 'black',
        HAMSTER_color_white: 'white',
        HAMSTER_color_red: 'red',
        HAMSTER_color_yellow: 'yellow',
        HAMSTER_color_green: 'green',
        HAMSTER_color_blue: 'blue',
        HAMSTER_beep: 'beep',
        HAMSTER_change_buzzer_by_1: 'change buzzer by',
        HAMSTER_change_buzzer_by_2: '',
        HAMSTER_set_buzzer_to_1: 'set buzzer to',
        HAMSTER_set_buzzer_to_2: '',
        HAMSTER_clear_buzzer: 'clear buzzer',
        HAMSTER_play_note_for_1: 'play note',
        HAMSTER_play_note_for_2: '',
        HAMSTER_play_note_for_3: 'for',
        HAMSTER_play_note_for_4: 'beats',
        HAMSTER_rest_for_1: 'rest for',
        HAMSTER_rest_for_2: 'beats',
        HAMSTER_change_tempo_by_1: 'change tempo by',
        HAMSTER_change_tempo_by_2: '',
        HAMSTER_set_tempo_to_1: 'set tempo to',
        HAMSTER_set_tempo_to_2: 'bpm',
        HAMSTER_set_port_to_1: 'set port',
        HAMSTER_set_port_to_2: 'to',
        HAMSTER_set_port_to_3: '',
        HAMSTER_change_output_by_1: 'change output',
        HAMSTER_change_output_by_2: 'by',
        HAMSTER_change_output_by_3: '',
        HAMSTER_set_output_to_1: 'set output',
        HAMSTER_set_output_to_2: 'to',
        HAMSTER_set_output_to_3: '',
        HAMSTER_port_a: 'A',
        HAMSTER_port_b: 'B',
        HAMSTER_port_ab: 'A and B',
        HAMSTER_analog_input: 'analog input',
        HAMSTER_digital_input: 'digital input',
        HAMSTER_servo_output: 'servo output',
        HAMSTER_pwm_output: 'pwm output',
        HAMSTER_digital_output: 'digital output',
        hamster_note_c: 'C',
        hamster_note_c_sharp: 'C♯(D♭)',
        hamster_note_d: 'D',
        hamster_note_d_sharp: 'D♯(E♭)',
        hamster_note_e: 'E',
        hamster_note_f: 'F',
        hamster_note_f_sharp: 'F♯(G♭)',
        hamster_note_g: 'G',
        hamster_note_g_sharp: 'G♯(A♭)',
        hamster_note_a: 'A',
        hamster_note_a_sharp: 'A♯(B♭)',
        hamster_note_b: 'B',
        hamster_tilt_forward: 'tilt forward',
        hamster_tilt_backward: 'tilt backward',
        hamster_tilt_left: 'tilt left',
        hamster_tilt_right: 'tilt right',
        hamster_tilt_flip: 'tilt flip',
        hamster_tilt_not: 'not tilt',
        hamster_battery_normal: 'battery normal',
        hamster_battery_low: 'battery low',
        hamster_battery_empty: 'battery empty',
        hamster_open_gripper: 'open',
        hamster_close_gripper: 'close'
      }
    },
    jp: {
      template: {
        hamster_gripper: 'グリッパを %1 %2',
        hamster_release_gripper: 'グリッパをオフ %1',
        hamster_boolean: '%1?',
        hamster_play_note: '%1 %2 を演奏する %3'
      },
      Helper: {
        hamster_gripper: 'Opens or closes the gripper.',
        hamster_release_gripper: 'Turns off the gripper so that it can be moved freely.',
        hamster_boolean: 'tilt forward: If tilted forward, true, otherwise false<br/>tilt backward: If tilted backward, true, otherwise false<br/>tilt left: If tilted to the left, true, otherwise false<br/>tilt right: If tilted to the right, true, otherwise false<br/>tilt flip: If upside-down, true, otherwise false<br/>not tilt: If not tilted, true, otherwise false<br/>battery normal: If the battery is enough, true, otherwise false<br/>battery low: If the battery is low, true, otherwise false<br/>battery empty: If the battery is empty, true, otherwise false',
        hamster_play_note: '選択された音階（音名、オクターブ）が鳴ります。'
      },
      Blocks: {
        hamster_note_c: 'ド',
        hamster_note_c_sharp: 'ド♯(レ♭)',
        hamster_note_d: 'レ',
        hamster_note_d_sharp: 'レ♯(ミ♭)',
        hamster_note_e: 'ミ',
        hamster_note_f: 'ファ',
        hamster_note_f_sharp: 'ファ♯(ソ♭)',
        hamster_note_g: 'ソ',
        hamster_note_g_sharp: 'ソ♯(ラ♭)',
        hamster_note_a: 'ラ',
        hamster_note_a_sharp: 'ラ♯(シ♭)',
        hamster_note_b: 'シ',
        hamster_tilt_forward: '前に傾けたか',
        hamster_tilt_backward: '後に傾けたか',
        hamster_tilt_left: '左に傾けたか',
        hamster_tilt_right: '右に傾けたか',
        hamster_tilt_flip: '上下裏返したか',
        hamster_tilt_not: '傾けなかったか',
        hamster_battery_normal: '電池が正常か',
        hamster_battery_low: '電池が足りないか',
        hamster_battery_empty: '電池がないか',
        hamster_open_gripper: '開く',
        hamster_close_gripper: '閉める'
      }
    },
    vn: {
      template: {
        hamster_gripper: '%1 gripper %2',
        hamster_release_gripper: 'release gripper %1',
        hamster_boolean: '%1?',
        hamster_play_note: 'play note %1 %2 %3'
      },
      Helper: {
        hamster_gripper: 'Opens or closes the gripper.',
        hamster_release_gripper: 'Turns off the gripper so that it can be moved freely.',
        hamster_boolean: 'tilt forward: If tilted forward, true, otherwise false<br/>tilt backward: If tilted backward, true, otherwise false<br/>tilt left: If tilted to the left, true, otherwise false<br/>tilt right: If tilted to the right, true, otherwise false<br/>tilt flip: If upside-down, true, otherwise false<br/>not tilt: If not tilted, true, otherwise false<br/>battery normal: If the battery is enough, true, otherwise false<br/>battery low: If the battery is low, true, otherwise false<br/>battery empty: If the battery is empty, true, otherwise false',
        hamster_play_note: 'It sounds the selected tone and octave.'
      },
      Blocks: {
        hamster_note_c: 'C',
        hamster_note_c_sharp: 'C♯(D♭)',
        hamster_note_d: 'D',
        hamster_note_d_sharp: 'D♯(E♭)',
        hamster_note_e: 'E',
        hamster_note_f: 'F',
        hamster_note_f_sharp: 'F♯(G♭)',
        hamster_note_g: 'G',
        hamster_note_g_sharp: 'G♯(A♭)',
        hamster_note_a: 'A',
        hamster_note_a_sharp: 'A♯(B♭)',
        hamster_note_b: 'B',
        hamster_tilt_forward: 'tilt forward',
        hamster_tilt_backward: 'tilt backward',
        hamster_tilt_left: 'tilt left',
        hamster_tilt_right: 'tilt right',
        hamster_tilt_flip: 'tilt flip',
        hamster_tilt_not: 'not tilt',
        hamster_battery_normal: 'battery normal',
        hamster_battery_low: 'battery low',
        hamster_battery_empty: 'battery empty',
        hamster_open_gripper: 'open',
        hamster_close_gripper: 'close'
      }
    }
  };
};
Entry.Hamster.blockMenuBlocks = ['hamster_hand_found', 'hamster_boolean', 'hamster_value', 'hamster_move_forward_once', 'hamster_turn_once', 'hamster_move_forward_for_secs', 'hamster_move_backward_for_secs', 'hamster_turn_for_secs', 'hamster_change_both_wheels_by', 'hamster_set_both_wheels_to', 'hamster_change_wheel_by', 'hamster_set_wheel_to', 'hamster_follow_line_using', 'hamster_follow_line_until', 'hamster_set_following_speed_to', 'hamster_stop', 'hamster_set_led_to', 'hamster_clear_led', 'hamster_beep', 'hamster_change_buzzer_by', 'hamster_set_buzzer_to', 'hamster_clear_buzzer', 'hamster_play_note', 'hamster_play_note_for', 'hamster_rest_for', 'hamster_change_tempo_by', 'hamster_set_tempo_to', 'hamster_set_port_to', 'hamster_change_output_by', 'hamster_set_output_to', 'hamster_gripper', 'hamster_release_gripper'];
Entry.Hamster.getBlocks = function () {
  return {
    hamster_hand_found: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      statements: [],
      params: [],
      events: {},
      def: {
        params: [],
        type: 'hamster_hand_found'
      },
      "class": 'hamster_sensor',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.checkHandFound(script) : false;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.hand_found()',
          blockType: 'param'
        }]
      }
    },
    hamster_boolean: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_boolean_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.hamster_tilt_forward, 'TILT_FORWARD'], [Lang.Blocks.hamster_tilt_backward, 'TILT_BACKWARD'], [Lang.Blocks.hamster_tilt_left, 'TILT_LEFT'], [Lang.Blocks.hamster_tilt_right, 'TILT_RIGHT'], [Lang.Blocks.hamster_tilt_flip, 'TILT_FLIP'], [Lang.Blocks.hamster_tilt_not, 'TILT_NOT'], [Lang.Blocks.hamster_battery_normal, 'BATTERY_NORMAL'], [Lang.Blocks.hamster_battery_low, 'BATTERY_LOW'], [Lang.Blocks.hamster_battery_empty, 'BATTERY_EMPTY']],
        value: 'TILT_FORWARD',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_boolean'
      },
      paramsKeyMap: {
        DEVICE: 0
      },
      "class": 'hamster_sensor',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.checkBoolean(script) : false;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.boolean_value(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_tilt_forward, 'TILT_FORWARD'], [Lang.Blocks.hamster_tilt_backward, 'TILT_BACKWARD'], [Lang.Blocks.hamster_tilt_left, 'TILT_LEFT'], [Lang.Blocks.hamster_tilt_right, 'TILT_RIGHT'], [Lang.Blocks.hamster_tilt_flip, 'TILT_FLIP'], [Lang.Blocks.hamster_tilt_not, 'TILT_NOT'], [Lang.Blocks.hamster_battery_normal, 'BATTERY_NORMAL'], [Lang.Blocks.hamster_battery_low, 'BATTERY_LOW'], [Lang.Blocks.hamster_battery_empty, 'BATTERY_EMPTY']],
            value: 'TILT_FORWARD',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_value: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      fontColor: '#fff',
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_sensor_left_proximity, 'leftProximity'], [Lang.Blocks.HAMSTER_sensor_right_proximity, 'rightProximity'], [Lang.Blocks.HAMSTER_sensor_left_floor, 'leftFloor'], [Lang.Blocks.HAMSTER_sensor_right_floor, 'rightFloor'], [Lang.Blocks.HAMSTER_sensor_acceleration_x, 'accelerationX'], [Lang.Blocks.HAMSTER_sensor_acceleration_y, 'accelerationY'], [Lang.Blocks.HAMSTER_sensor_acceleration_z, 'accelerationZ'], [Lang.Blocks.HAMSTER_sensor_light, 'light'], [Lang.Blocks.HAMSTER_sensor_temperature, 'temperature'], [Lang.Blocks.HAMSTER_sensor_signal_strength, 'signalStrength'], [Lang.Blocks.HAMSTER_sensor_input_a, 'inputA'], [Lang.Blocks.HAMSTER_sensor_input_b, 'inputB']],
        value: 'leftProximity',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_value'
      },
      paramsKeyMap: {
        DEVICE: 0
      },
      "class": 'hamster_sensor',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        if (robot) {
          return robot.getValue(script);
        }
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.sensor_value(%1)',
          blockType: 'param',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_sensor_left_proximity, 'leftProximity'], [Lang.Blocks.HAMSTER_sensor_right_proximity, 'rightProximity'], [Lang.Blocks.HAMSTER_sensor_left_floor, 'leftFloor'], [Lang.Blocks.HAMSTER_sensor_right_floor, 'rightFloor'], [Lang.Blocks.HAMSTER_sensor_acceleration_x, 'accelerationX'], [Lang.Blocks.HAMSTER_sensor_acceleration_y, 'accelerationY'], [Lang.Blocks.HAMSTER_sensor_acceleration_z, 'accelerationZ'], [Lang.Blocks.HAMSTER_sensor_light, 'light'], [Lang.Blocks.HAMSTER_sensor_temperature, 'temperature'], [Lang.Blocks.HAMSTER_sensor_signal_strength, 'signalStrength'], [Lang.Blocks.HAMSTER_sensor_input_a, 'inputA'], [Lang.Blocks.HAMSTER_sensor_input_b, 'inputB']],
            value: 'leftProximity',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_move_forward_once: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_move_forward_once'
      },
      "class": 'hamster_board',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.boardForward(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.board_forward()'
        }]
      }
    },
    hamster_turn_once: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_turn_once_left, 'LEFT'], [Lang.Blocks.HAMSTER_turn_right, 'RIGHT']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null],
        type: 'hamster_turn_once'
      },
      paramsKeyMap: {
        DIRECTION: 0
      },
      "class": 'hamster_board',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.boardTurn(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.board_turn(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_turn_once_left, 'LEFT'], [Lang.Blocks.HAMSTER_turn_right, 'RIGHT']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_move_forward_for_secs: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['1']
        }, null],
        type: 'hamster_move_forward_for_secs'
      },
      paramsKeyMap: {
        SECS: 0
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.moveForwardSecs(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.move_forward(%1)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_move_backward_for_secs: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['1']
        }, null],
        type: 'hamster_move_backward_for_secs'
      },
      paramsKeyMap: {
        SECS: 0
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.moveBackwardSecs(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.move_backward(%1)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_turn_for_secs: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_turn_once_left, 'LEFT'], [Lang.Blocks.HAMSTER_turn_right, 'RIGHT']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, {
          type: 'text',
          params: ['1']
        }, null],
        type: 'hamster_turn_for_secs'
      },
      paramsKeyMap: {
        DIRECTION: 0,
        SECS: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.turnSecs(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.turn(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_turn_once_left, 'LEFT'], [Lang.Blocks.HAMSTER_turn_right, 'RIGHT']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_change_both_wheels_by: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['10']
        }, {
          type: 'text',
          params: ['10']
        }, null],
        type: 'hamster_change_both_wheels_by'
      },
      paramsKeyMap: {
        LEFT: 0,
        RIGHT: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.changeWheels(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.add_wheels(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_set_both_wheels_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['30']
        }, {
          type: 'text',
          params: ['30']
        }, null],
        type: 'hamster_set_both_wheels_to'
      },
      paramsKeyMap: {
        LEFT: 0,
        RIGHT: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setWheels(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_wheels(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_change_wheel_by: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_wheel, 'LEFT'], [Lang.Blocks.HAMSTER_right_wheel, 'RIGHT'], [Lang.Blocks.HAMSTER_both_wheels, 'BOTH']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, {
          type: 'text',
          params: ['10']
        }, null],
        type: 'hamster_change_wheel_by'
      },
      paramsKeyMap: {
        WHEEL: 0,
        VELOCITY: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.changeWheel(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.add_wheel(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_wheel, 'LEFT'], [Lang.Blocks.HAMSTER_right_wheel, 'RIGHT'], [Lang.Blocks.HAMSTER_both_wheels, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_set_wheel_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_wheel, 'LEFT'], [Lang.Blocks.HAMSTER_right_wheel, 'RIGHT'], [Lang.Blocks.HAMSTER_both_wheels, 'BOTH']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, {
          type: 'text',
          params: ['30']
        }, null],
        type: 'hamster_set_wheel_to'
      },
      paramsKeyMap: {
        WHEEL: 0,
        VELOCITY: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setWheel(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_wheel(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_wheel, 'LEFT'], [Lang.Blocks.HAMSTER_right_wheel, 'RIGHT'], [Lang.Blocks.HAMSTER_both_wheels, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_follow_line_using: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_color_black, 'BLACK'], [Lang.Blocks.HAMSTER_color_white, 'WHITE']],
        value: 'BLACK',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_floor_sensor, 'LEFT'], [Lang.Blocks.HAMSTER_right_floor_sensor, 'RIGHT'], [Lang.Blocks.HAMSTER_both_floor_sensors, 'BOTH']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null, null],
        type: 'hamster_follow_line_using'
      },
      paramsKeyMap: {
        COLOR: 0,
        SENSOR: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.followLine(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.follow_line(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_black, 'BLACK'], [Lang.Blocks.HAMSTER_color_white, 'WHITE']],
            value: 'BLACK',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_floor_sensor, 'LEFT'], [Lang.Blocks.HAMSTER_right_floor_sensor, 'RIGHT'], [Lang.Blocks.HAMSTER_both_floor_sensors, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_follow_line_until: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_color_black, 'BLACK'], [Lang.Blocks.HAMSTER_color_white, 'WHITE']],
        value: 'BLACK',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_floor_sensor, 'LEFT'], [Lang.Blocks.HAMSTER_right_floor_sensor, 'RIGHT'], [Lang.Blocks.HAMSTER_front, 'FRONT'], [Lang.Blocks.HAMSTER_rear, 'REAR']],
        value: 'FRONT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null, null],
        type: 'hamster_follow_line_until'
      },
      paramsKeyMap: {
        COLOR: 0,
        DIRECTION: 1
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.followLineUntil(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.follow_line_until(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_black, 'BLACK'], [Lang.Blocks.HAMSTER_color_white, 'WHITE']],
            value: 'BLACK',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_floor_sensor, 'LEFT'], [Lang.Blocks.HAMSTER_right_floor_sensor, 'RIGHT'], [Lang.Blocks.HAMSTER_front, 'FRONT'], [Lang.Blocks.HAMSTER_rear, 'REAR']],
            value: 'FRONT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_set_following_speed_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: ['5', null],
        type: 'hamster_set_following_speed_to'
      },
      paramsKeyMap: {
        SPEED: 0
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setLineTracerSpeed(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_line_speed(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }]
        }]
      }
    },
    hamster_stop: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_stop'
      },
      "class": 'hamster_wheel',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.stop(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.stop()'
        }]
      }
    },
    hamster_set_led_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
        value: '4',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null, null],
        type: 'hamster_set_led_to'
      },
      paramsKeyMap: {
        LED: 0,
        COLOR: 1
      },
      "class": 'hamster_led',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setLed(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_led_red(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '4']
        }, {
          syntax: 'Hamster.set_led_yellow(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '6']
        }, {
          syntax: 'Hamster.set_led_green(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '2']
        }, {
          syntax: 'Hamster.set_led_sky_blue(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '3']
        }, {
          syntax: 'Hamster.set_led_blue(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '1']
        }, {
          syntax: 'Hamster.set_led_purple(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '5']
        }, {
          syntax: 'Hamster.set_led_white(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_color_red, '4'], [Lang.Blocks.HAMSTER_color_yellow, '6'], [Lang.Blocks.HAMSTER_color_green, '2'], [Lang.Blocks.HAMSTER_color_cyan, '3'], [Lang.Blocks.HAMSTER_color_blue, '1'], [Lang.Blocks.HAMSTER_color_magenta, '5'], [Lang.Blocks.HAMSTER_color_white, '7']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '7']
        }]
      }
    },
    hamster_clear_led: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
        value: 'LEFT',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null],
        type: 'hamster_clear_led'
      },
      paramsKeyMap: {
        LED: 0
      },
      "class": 'hamster_led',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.clearLed(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.clear_led(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_left_led, 'LEFT'], [Lang.Blocks.HAMSTER_right_led, 'RIGHT'], [Lang.Blocks.HAMSTER_both_leds, 'BOTH']],
            value: 'LEFT',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_beep: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_beep'
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.beep(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.beep()'
        }]
      }
    },
    hamster_change_buzzer_by: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['10']
        }, null],
        type: 'hamster_change_buzzer_by'
      },
      paramsKeyMap: {
        HZ: 0
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.changeBuzzer(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.add_buzzer(%1)'
        }]
      }
    },
    hamster_set_buzzer_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['1000']
        }, null],
        type: 'hamster_set_buzzer_to'
      },
      paramsKeyMap: {
        HZ: 0
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setBuzzer(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_buzzer(%1)'
        }]
      }
    },
    hamster_clear_buzzer: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_clear_buzzer'
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.clearBuzzer(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.clear_buzzer()'
        }]
      }
    },
    hamster_play_note: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
        value: '4',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, '4', null],
        type: 'hamster_play_note'
      },
      paramsKeyMap: {
        NOTE: 0,
        OCTAVE: 1
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.playNote(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.play_pitch_c(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['4']
        }, {
          syntax: 'Hamster.play_pitch_c_sharp(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['5']
        }, {
          syntax: 'Hamster.play_pitch_d(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['6']
        }, {
          syntax: 'Hamster.play_pitch_d_sharp(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['7']
        }, {
          syntax: 'Hamster.play_pitch_e(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['8']
        }, {
          syntax: 'Hamster.play_pitch_f(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['9']
        }, {
          syntax: 'Hamster.play_pitch_f_sharp(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['10']
        }, {
          syntax: 'Hamster.play_pitch_g(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['11']
        }, {
          syntax: 'Hamster.play_pitch_g_sharp(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['12']
        }, {
          syntax: 'Hamster.play_pitch_a(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['13']
        }, {
          syntax: 'Hamster.play_pitch_a_sharp(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['14']
        }, {
          syntax: 'Hamster.play_pitch_b(%2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          params: ['15']
        }]
      }
    },
    hamster_play_note_for: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
        value: '4',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
        value: '1',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, '4', {
          type: 'text',
          params: ['0.5']
        }, null],
        type: 'hamster_play_note_for'
      },
      paramsKeyMap: {
        NOTE: 0,
        OCTAVE: 1,
        BEAT: 2
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.playNoteBeat(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.play_note_c(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['4']
        }, {
          syntax: 'Hamster.play_note_c_sharp(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['5']
        }, {
          syntax: 'Hamster.play_note_d(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['6']
        }, {
          syntax: 'Hamster.play_note_d_sharp(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['7']
        }, {
          syntax: 'Hamster.play_note_e(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['8']
        }, {
          syntax: 'Hamster.play_note_f(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['9']
        }, {
          syntax: 'Hamster.play_note_f_sharp(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['10']
        }, {
          syntax: 'Hamster.play_note_g(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['11']
        }, {
          syntax: 'Hamster.play_note_g_sharp(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['12']
        }, {
          syntax: 'Hamster.play_note_a(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['13']
        }, {
          syntax: 'Hamster.play_note_a_sharp(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['14']
        }, {
          syntax: 'Hamster.play_note_b(%2, %3)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_note_c, '4'], [Lang.Blocks.hamster_note_c_sharp, '5'], [Lang.Blocks.hamster_note_d, '6'], [Lang.Blocks.hamster_note_d_sharp, '7'], [Lang.Blocks.hamster_note_e, '8'], [Lang.Blocks.hamster_note_f, '9'], [Lang.Blocks.hamster_note_f_sharp, '10'], [Lang.Blocks.hamster_note_g, '11'], [Lang.Blocks.hamster_note_g_sharp, '12'], [Lang.Blocks.hamster_note_a, '13'], [Lang.Blocks.hamster_note_a_sharp, '14'], [Lang.Blocks.hamster_note_b, '15']],
            value: '4',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7']],
            value: '1',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }, {
            type: 'Block',
            accept: 'string'
          }],
          params: ['15']
        }]
      }
    },
    hamster_rest_for: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['0.25']
        }, null],
        type: 'hamster_rest_for'
      },
      paramsKeyMap: {
        BEAT: 0
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.restBeat(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.rest(%1)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_change_tempo_by: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['20']
        }, null],
        type: 'hamster_change_tempo_by'
      },
      paramsKeyMap: {
        BPM: 0
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.changeTempo(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.add_tempo(%1)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_set_tempo_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'text',
          params: ['60']
        }, null],
        type: 'hamster_set_tempo_to'
      },
      paramsKeyMap: {
        BPM: 0
      },
      "class": 'hamster_buzzer',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setTempo(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_tempo(%1)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_set_port_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
        value: 'A',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
        value: '0',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null, null],
        type: 'hamster_set_port_to'
      },
      paramsKeyMap: {
        PORT: 0,
        MODE: 1
      },
      "class": 'hamster_port',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setIoMode(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_io_mode_analog_input(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '0']
        }, {
          syntax: 'Hamster.set_io_mode_digital_input(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '1']
        }, {
          syntax: 'Hamster.set_io_mode_servo_output(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '8']
        }, {
          syntax: 'Hamster.set_io_mode_pwm_output(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '9']
        }, {
          syntax: 'Hamster.set_io_mode_digital_output(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_analog_input, '0'], [Lang.Blocks.HAMSTER_digital_input, '1'], [Lang.Blocks.HAMSTER_servo_output, '8'], [Lang.Blocks.HAMSTER_pwm_output, '9'], [Lang.Blocks.HAMSTER_digital_output, '10']],
            value: '0',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }],
          params: [null, '10']
        }]
      }
    },
    hamster_change_output_by: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
        value: 'A',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, {
          type: 'text',
          params: ['10']
        }, null],
        type: 'hamster_change_output_by'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'hamster_port',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.changeOutput(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.add_output(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_set_output_to: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
        value: 'A',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, {
          type: 'text',
          params: ['100']
        }, null],
        type: 'hamster_set_output_to'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'hamster_port',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.setOutput(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_output(%1, %2)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.HAMSTER_port_a, 'A'], [Lang.Blocks.HAMSTER_port_b, 'B'], [Lang.Blocks.HAMSTER_port_ab, 'AB']],
            value: 'A',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    hamster_gripper: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [[Lang.Blocks.hamster_open_gripper, 'OPEN'], [Lang.Blocks.hamster_close_gripper, 'CLOSE']],
        value: 'OPEN',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null, null],
        type: 'hamster_gripper'
      },
      paramsKeyMap: {
        ACTION: 0
      },
      "class": 'hamster_port',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.gripper(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.set_gripper(%1)',
          textParams: [{
            type: 'Dropdown',
            options: [[Lang.Blocks.hamster_open_gripper, 'OPEN'], [Lang.Blocks.hamster_close_gripper, 'CLOSE']],
            value: 'OPEN',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringValue
          }]
        }]
      }
    },
    hamster_release_gripper: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [null],
        type: 'hamster_release_gripper'
      },
      "class": 'hamster_port',
      isNotFor: ['hamster'],
      func: function func(sprite, script) {
        var robot = Entry.Hamster.getRobot();
        return robot ? robot.releaseGripper(script) : script;
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'Hamster.release_gripper()'
        }]
      }
    }
  };
};
module.exports = Entry.Hamster;