"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PIXITextMetricsPlugIn = PIXITextMetricsPlugIn;
var _pixi = require("pixi.js");
function PIXITextMetricsPlugIn() {
  var WordWrap = _pixi.TextMetrics.wordWrap;
  _pixi.TextMetrics.__breakAllWordWrap = __breakAllWordWrap;
  _pixi.TextMetrics.wordWrap = function (text, style) {
    var canvas = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _pixi.TextMetrics._canvas;
    return style.wordBreakAll ? _pixi.TextMetrics.__breakAllWordWrap(text, style, canvas) : WordWrap(text, style, canvas);
  };

  /**
   *
   * @param text
   * @param {PIXI.TextStyle | PIXITextStyle} style
   * @param canvas
   * @return {string}
   * @private
   */
  function __breakAllWordWrap(text, style, canvas) {
    var context = canvas.getContext('2d');
    var width = 0;
    var line = '';
    var lines = '';
    var cache = {};
    var letterSpacing = style.letterSpacing,
      whiteSpace = style.whiteSpace;

    // How to handle whitespaces
    var collapseSpaces = _pixi.TextMetrics.collapseSpaces(whiteSpace);
    var collapseNewlines = _pixi.TextMetrics.collapseNewlines(whiteSpace);

    // whether or not spaces may be added to the beginning of lines
    var canPrependSpaces = !collapseSpaces;

    // There is letterSpacing after every char except the last one
    // t_h_i_s_' '_i_s_' '_a_n_' '_e_x_a_m_p_l_e_' '_!
    // so for convenience the above needs to be compared to width + 1 extra letterSpace
    // t_h_i_s_' '_i_s_' '_a_n_' '_e_x_a_m_p_l_e_' '_!_
    // ________________________________________________
    // And then the final space is simply no appended to each line
    var wordWrapWidth = style.wordWrapWidth + letterSpacing;

    // break text into words, spaces and newline chars
    var tokens = _pixi.TextMetrics.tokenize(text);
    for (var i = 0; i < tokens.length; i++) {
      // get the word, space or newlineChar
      var token = tokens[i];

      // if word is a new line
      if (_pixi.TextMetrics.isNewline(token)) {
        // keep the new line
        if (!collapseNewlines) {
          lines += _pixi.TextMetrics.addLine(line);
          canPrependSpaces = !collapseSpaces;
          line = '';
          width = 0;
          continue;
        }

        // if we should collapse new lines
        // we simply convert it into a space
        token = ' ';
      }

      // if we should collapse repeated whitespaces
      if (collapseSpaces) {
        // check both this and the last tokens for spaces
        var currIsBreakingSpace = _pixi.TextMetrics.isBreakingSpace(token);
        var lastIsBreakingSpace = _pixi.TextMetrics.isBreakingSpace(line[line.length - 1]);
        if (currIsBreakingSpace && lastIsBreakingSpace) {
          continue;
        }
      }

      // get word width from cache if possible
      var tokenWidth = _pixi.TextMetrics.getFromCache(token, letterSpacing, cache, context);

      // word is longer than desired bounds
      if (width + tokenWidth > wordWrapWidth) {
        var characters = token.split('');
        for (var j = 0; j < characters.length; j++) {
          var _char = characters[j];
          var characterWidth = _pixi.TextMetrics.getFromCache(_char, letterSpacing, cache, context);
          if (width + characterWidth > wordWrapWidth) {
            //newLine
            lines += _pixi.TextMetrics.addLine(line);
            canPrependSpaces = false;
            line = '';
            width = 0;
          }
          line += _char;
          width += characterWidth;
        }
      }

      // word could fit
      else {
        // word won't fit because of existing words
        // start a new line
        if (tokenWidth + width > wordWrapWidth) {
          // if its a space we don't want it
          canPrependSpaces = false;

          // add a new line
          lines += _pixi.TextMetrics.addLine(line);

          // start a new line
          line = '';
          width = 0;
        }

        // don't add spaces to the beginning of lines
        if (line.length > 0 || !_pixi.TextMetrics.isBreakingSpace(token) || canPrependSpaces) {
          // add the word to the current line
          line += token;

          // update width counter
          width += tokenWidth;
        }
      }
    }
    lines += _pixi.TextMetrics.addLine(line, false);
    return lines;
  }

  //createjs와 동일한 글씨 크기 측정을 위해
  /**
   *
   * @param {PIXI.TextStyle} style
   * @param text
   * @return {number}
   * @private
   */
  function _getMeasuredWidth(font, text) {
    var ctx = _pixi.TextMetrics._context;
    ctx.save();
    ctx.font = font;
    var w = ctx.measureText(text).width;
    ctx.restore();
    return w;
  }

  //createjs와 동일한 글씨 크기 측정을 위해
  _pixi.TextMetrics.measureText = function (text, style, wordWrap) {
    var canvas = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _pixi.TextMetrics._canvas;
    wordWrap = wordWrap === undefined || wordWrap === null ? style.wordWrap : wordWrap;
    var font = style.toFontString();
    // const fontProperties = TextMetrics.measureFont(font); //skip for performance
    var fontProperties = null;
    var context = canvas.getContext('2d');
    context.font = font;
    var outputText = wordWrap ? _pixi.TextMetrics.wordWrap(text, style, canvas) : text;
    var lines = outputText.split(/(?:\r\n|\r|\n)/);
    var lineWidths = new Array(lines.length);
    var maxLineWidth = 0;
    for (var i = 0; i < lines.length; i++) {
      var lineWidth = context.measureText(lines[i]).width + (lines[i].length - 1) * style.letterSpacing;
      lineWidths[i] = lineWidth;
      maxLineWidth = Math.max(maxLineWidth, lineWidth);
    }
    var width = maxLineWidth + style.strokeThickness;
    var lineHeight = style.lineHeight || _getMeasuredWidth(font, 'M') * 1.2;
    var height = lineHeight * lines.length;
    return new _pixi.TextMetrics(text, style, width, height, lines, lineWidths, lineHeight + style.leading, maxLineWidth, fontProperties);
  };
}