import { PIXIAtlasHelper } from '../PIXIAtlasHelper';
import PIXIHelper from '../../helper/PIXIHelper';
import { cwarn } from '../../utils/logs';
var LoadingState;
(function (LoadingState) {
    LoadingState[LoadingState["NONE"] = 1] = "NONE";
    LoadingState[LoadingState["LOADING"] = 2] = "LOADING";
    LoadingState[LoadingState["COMPLETE"] = 3] = "COMPLETE";
    LoadingState[LoadingState["DESTROYED"] = 4] = "DESTROYED";
})(LoadingState || (LoadingState = {}));
var AtlasImageLoadingInfo = /** @class */ (function () {
    /**
     * model 의 이미지를 로드 후, imgRect.scaleFactor가 1이 아닐경우 imgRect 만큼 리사이즈한 canvas 를 소스로 설정하긔
     * @param model
     * @param _imgRect
     * @param _onLoadCallback
     */
    function AtlasImageLoadingInfo(model, _imgRect, _onLoadCallback) {
        this._imgRect = _imgRect;
        this._onLoadCallback = _onLoadCallback;
        this.loadState = LoadingState.NONE;
        this._triedCnt = 0;
        this._subCallback = [];
        this._realPath = this._getImageSrc(model);
        this._rawPath = PIXIAtlasHelper.getRawPath(model);
        this._picName = model.name;
    }
    AtlasImageLoadingInfo.prototype.source = function () {
        return this._img || this._canvas;
    };
    /**
     * 이미지가 로드 되면 호출 할 콜백들. 이미지가 로드 된 후 add된 값들은 무시 됨.
     * @param fn
     */
    AtlasImageLoadingInfo.prototype.addCallback = function (fn) {
        if (!fn || this.isReady) {
            return;
        }
        this._subCallback.push(fn);
    };
    AtlasImageLoadingInfo.prototype.load = function () {
        var _this = this;
        if (this.loadState != LoadingState.NONE) {
            return;
        }
        this.loadState = LoadingState.LOADING;
        var img = new Image();
        this._img = img;
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            Entry.Loader.removeQueue();
            if (_this.loadState == LoadingState.DESTROYED) {
                return;
            }
            _this.loadState = LoadingState.COMPLETE;
            _this._canvas = _this._resizeIfNotValidSize();
            if (_this._canvas) {
                _this._destroyImage();
            }
            _this._onLoadCallback(_this);
            _this._onLoadCallback = null;
            _this._subCallback.forEach(function (fn) {
                fn(_this);
            });
            _this._subCallback = null;
        };
        img.onerror = function (err) {
            Entry.Loader.removeQueue();
            if (_this.loadState == LoadingState.DESTROYED) {
                return;
            }
            if (!_this._triedCnt) {
                if (Entry.type !== 'invisible') {
                    console.log('err=', _this._picName, 'load failed');
                }
                _this._triedCnt = 1;
                _this._loadPath(_this._realPath);
            }
            else if (_this._triedCnt < 3) {
                _this._triedCnt++;
                _this._loadPath("".concat(Entry.mediaFilePath, "_1x1.png"));
            }
            else {
                //prevent infinite call
                img.onerror = null;
            }
        };
        this._loadPath(this._realPath);
    };
    AtlasImageLoadingInfo.prototype._loadPath = function (path) {
        if (this.loadState == LoadingState.DESTROYED) {
            return;
        }
        Entry.Loader.addQueue();
        this._img.src = path;
    };
    Object.defineProperty(AtlasImageLoadingInfo.prototype, "isReady", {
        get: function () {
            return this.loadState == LoadingState.COMPLETE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AtlasImageLoadingInfo.prototype, "path", {
        /** pictureModel.fileurl or pictureModel.filename **/
        get: function () {
            return this._rawPath;
        },
        enumerable: false,
        configurable: true
    });
    AtlasImageLoadingInfo.prototype._getImageSrc = function (picture) {
        if (picture.fileurl) {
            return picture.fileurl;
        }
        var name = picture.filename;
        return "".concat(Entry.defaultPath, "/uploads/").concat(name.substring(0, 2), "/").concat(name.substring(2, 4), "/image/").concat(name, ".png");
    };
    AtlasImageLoadingInfo.prototype.destroy = function () {
        this.loadState = LoadingState.DESTROYED;
        this._destroyImage();
        if (this._canvas) {
            this._canvas = null;
        }
        this._subCallback = null;
        this._rawPath = this._realPath = null;
    };
    AtlasImageLoadingInfo.prototype._destroyImage = function () {
        if (!this._img) {
            return;
        }
        this._img.onload = this._img.onerror = null;
        this._img = null;
    };
    AtlasImageLoadingInfo.prototype._resizeIfNotValidSize = function () {
        var img = this._img;
        var sw = img.naturalWidth || img.width;
        var sh = img.naturalHeight || img.height;
        this.srcWidth = sw;
        this.srcHeight = sh;
        var r = this._imgRect;
        var isScale1 = r.scaleFactorX == 1 && r.scaleFactorY == 1;
        var isSameSizeWithData = r.dataWidth == sw && r.dataHeight == sh;
        if (isScale1 && isSameSizeWithData) {
            return null;
        }
        // if(!isScale1) {
        //     let sfx = r.scaleFactorX.toFixed(3);
        //     let sfy = r.scaleFactorY.toFixed(3);
        //     clog(`tex-rezie (${sw},${sh})->(${r.width},${r.height}). factor:(${sfx},${sfy})`);
        // }
        if (!isSameSizeWithData) {
            cwarn("Image size not match. data(w=".concat(r.dataWidth, ",h=").concat(r.dataHeight, "), real(w=").concat(sw, ",h=").concat(sh, ")"));
        }
        var canvas = PIXIHelper.getOffScreenCanvas();
        this.srcWidth = canvas.width = r.width;
        this.srcHeight = canvas.height = r.height;
        var ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.drawImage(img, 0, 0, r.width, r.height);
        return canvas;
    };
    return AtlasImageLoadingInfo;
}());
export { AtlasImageLoadingInfo };
