/**
 * BaseTexture 의 canvas 에 drawing이 잘 되는지 확인하기 위한 debug 클라스.
 */
var style = function (viewerHeight) { return "<style>\n    .atlas-canvas-container {\n        position: absolute;\n        width: 100%;\n        pointer-events: none;\n        display: none;\n    }\n    .atlas-canvas {\n        width: auto; height: ".concat(viewerHeight, "px; float: right; border: 1px solid red;\n    }\n</style>"); };
var dom = "<div class='atlas-canvas-container'></div>";
var AtlasCanvasViewer = /** @class */ (function () {
    function AtlasCanvasViewer(viewerHeight) {
        if (viewerHeight === void 0) { viewerHeight = 200; }
        this.$style = $(style(viewerHeight));
        this.$container = $(dom);
    }
    AtlasCanvasViewer.prototype.attachToBody = function () {
        var _this = this;
        if (this.attached) {
            return;
        }
        this.attached = true;
        setTimeout(function () {
            _this.$container.click(function () { return false; });
            $('head').append(_this.$style);
            $('body').append(_this.$container);
        }, 500);
    };
    AtlasCanvasViewer.prototype.add = function (c) {
        var isCanvas = c instanceof HTMLCanvasElement;
        if (!isCanvas) {
            return;
        }
        this.attachToBody();
        var cc = $(c);
        this.$container.append(cc);
        cc.addClass('atlas-canvas');
    };
    AtlasCanvasViewer.prototype.removeCanvas = function (c) {
        if (!c) {
            return;
        }
        $(c).remove();
    };
    AtlasCanvasViewer.prototype.empty = function () {
        this.$container.empty();
    };
    AtlasCanvasViewer.prototype.toggleVisible = function () {
        this.$container.toggle();
    };
    return AtlasCanvasViewer;
}());
export { AtlasCanvasViewer };
