'use strict';

Entry.Creamo = {
  id: '20.1',
  name: 'creamo',
  url: 'http://www.creamo.co.kr',
  imageName: 'creamo.png',
  title: {
    en: 'creamo',
    ko: '크리모'
  },
  setZero: function setZero() {
    Entry.hw.sendQueue.readablePorts = [];
    for (var port = 0; port < 20; port++) {
      Entry.hw.sendQueue[port] = 0;
      Entry.hw.sendQueue.readablePorts.push(port);
    }
    Entry.hw.update();
  },
  //수정
  sensorTypes: {
    ALIVE: 0,
    DIGITAL: 1,
    ANALOG: 2,
    PWM: 3,
    SERVO_PIN: 4,
    TONE: 5,
    PULSEIN: 6,
    ULTRASONIC: 7,
    TIMER: 8,
    DC_MOTOR_LEFT: 9,
    DC_MOTOR_RIGHT: 10
  },
  monitorTemplate: {
    imgPath: 'hw/arduino.png',
    width: 200,
    height: 200,
    listPorts: {
      '1': {
        name: Lang.Hw.port_en + ' LED1 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '2': {
        name: Lang.Hw.port_en + ' LED2 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '3': {
        name: Lang.Hw.port_en + ' 4 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '4': {
        name: Lang.Hw.port_en + ' 5 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '6': {
        name: Lang.Hw.port_en + ' 6 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '7': {
        name: Lang.Hw.port_en + ' 7 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '8': {
        name: Lang.Hw.port_en + ' 8 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '9': {
        name: Lang.Hw.port_en + ' 9 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '10': {
        name: Lang.Hw.port_en + ' 10 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '11': {
        name: Lang.Hw.port_en + ' 11 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '12': {
        name: Lang.Hw.port_en + ' 12 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      '13': {
        name: Lang.Hw.port_en + ' 13 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a0: {
        name: Lang.Hw.port_en + ' A0 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a1: {
        name: Lang.Hw.port_en + ' A1 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a2: {
        name: Lang.Hw.port_en + ' A2 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a3: {
        name: Lang.Hw.port_en + ' A3 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a4: {
        name: Lang.Hw.port_en + ' A4 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      },
      a5: {
        name: Lang.Hw.port_en + ' A5 ' + Lang.Hw.port_ko,
        type: 'input',
        pos: {
          x: 0,
          y: 0
        }
      }
    },
    mode: 'both'
  }
};
Entry.Creamo.blockMenuBlocks = [
//creamo
'creamo_toggle_led', 'creamo_get_number_sensor_value', 'creamo_toggle_pwm', 'creamo_toggle_motor'];
Entry.Creamo.setLanguage = function () {
  return {
    ko: {
      template: {
        creamo_get_number_sensor_value: '가변저항_블록 %1 번 센서값  ',
        creamo_get_port_number: '%1',
        creamo_get_pwm_port_number: '%1  ',
        creamo_motor_port_number: '%1',
        creamo_toggle_led: 'LED_블록 %1 번 핀 %2 %3',
        creamo_toggle_motor: 'Motor_블록 %1 번 핀 %2 %3',
        creamo_toggle_pwm: '디지털 %1 번 핀을 %2 (으)로 정하기 %3'
      },
      Menus: {
        creamo: '크리모'
      },
      Device: {
        creamo: '크리모'
      }
    },
    en: {
      template: {
        creamo_get_number_sensor_value: 'PWM_Block %1 Sensor value',
        creamo_get_port_number: '%1',
        creamo_get_pwm_port_number: '%1',
        creamo_motor_port_number: '%1',
        creamo_toggle_led: 'LED_Block %1 Pin %2 %3',
        creamo_toggle_motor: 'Motor_Block %1 pin %2 %3',
        creamo_toggle_pwm: 'Digital %1 Pin %2 %3'
      },
      Menus: {
        creamo: 'creamo'
      },
      Device: {
        creamo: 'creamo'
      }
    }
  };
};
Entry.Creamo.getBlocks = function () {
  return {
    //region creamo
    creamo_get_number_sensor_value: {
      parent: 'arduino_get_number_sensor_value',
      isNotFor: ['creamo'],
      def: {
        params: [{
          type: 'arduino_get_sensor_number'
        }],
        type: 'creamo_get_number_sensor_value'
      },
      "class": 'arduino_value',
      syntax: {
        js: [],
        py: ['creamo.get_number_sensor_value(%1)']
      }
    },
    creamo_get_port_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['7', '7'], ['8', '8'], ['12', '12']],
        value: '7',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [['7', '7'], ['8', '8'], ['12', '12']],
            value: '7',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
          }],
          keyOption: 'arduino_get_port_number'
        }]
      }
    },
    //LED
    creamo_toggle_led: {
      parent: 'arduino_toggle_led',
      isNotFor: ['creamo'],
      def: {
        params: [{
          type: 'creamo_get_port_number'
        }, null, null],
        type: 'creamo_toggle_led'
      },
      "class": 'arduino_set',
      syntax: {
        js: [],
        py: ['creamo.toggle_led(%1)']
      }
    },
    //모터
    creamo_toggle_motor: {
      parent: 'arduino_toggle_led',
      isNotFor: ['creamo'],
      def: {
        params: [{
          type: 'creamo_motor_port_number'
        }, null, null],
        type: 'creamo_toggle_motor'
      },
      "class": 'arduino_set',
      syntax: {
        js: [],
        py: ['creamo.toggle_led(%1)']
      }
    },
    creamo_motor_port_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['6', '6'], ['9', '9']],
        value: '6',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [['6', '6'], ['9', '9']],
            value: '6',
            fontSize: 11,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
          }],
          keyOption: 'arduino_get_port_number'
        }]
      }
    },
    creamo_toggle_pwm: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic',
      statements: [],
      params: [{
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Block',
        accept: 'string'
      }, {
        type: 'Indicator',
        img: 'block_icon/hardware_icon.svg',
        size: 12
      }],
      events: {},
      def: {
        params: [{
          type: 'creamo_get_pwm_port_number'
        }, '255', null],
        type: 'creamo_toggle_pwm'
      },
      paramsKeyMap: {
        PORT: 0,
        VALUE: 1
      },
      "class": 'arduino_set',
      isNotFor: ['creamo'],
      func: function func(sprite, script) {
        var port = script.getNumberValue('PORT');
        var value = script.getNumberValue('VALUE');
        value = Math.round(value);
        value = Math.max(value, 0);
        value = Math.min(value, 255);
        Entry.hw.setDigitalPortValue(port, value);
        return script.callReturn();
      },
      syntax: {
        js: [],
        py: [{
          syntax: 'creamo.set_pin_digital(%1, %2)',
          textParams: [{
            type: 'Block',
            accept: 'string'
          }, {
            type: 'Block',
            accept: 'string'
          }]
        }]
      }
    },
    creamo_get_pwm_port_number: {
      color: EntryStatic.colorSet.block["default"].HARDWARE,
      outerLine: EntryStatic.colorSet.block.darken.HARDWARE,
      skeleton: 'basic_string_field',
      statements: [],
      params: [{
        type: 'Dropdown',
        options: [['5', '5'], ['6', '6'], ['9', '9']],
        value: '5',
        fontSize: 11,
        bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
        arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE
      }],
      events: {},
      def: {
        params: [null]
      },
      paramsKeyMap: {
        PORT: 0
      },
      func: function func(sprite, script) {
        return script.getStringField('PORT');
      },
      syntax: {
        js: [],
        py: [{
          syntax: '%1',
          textParams: [{
            type: 'Dropdown',
            options: [['5', '5'], ['6', '6'], ['9', '9']],
            value: '3',
            fontSize: 11,
            bgColor: EntryStatic.colorSet.block.darken.HARDWARE,
            arrowColor: EntryStatic.colorSet.arrow["default"].HARDWARE,
            converter: Entry.block.converters.returnStringOrNumberByValue
          }],
          keyOption: 'arduino_get_pwm_port_number'
        }]
      }
    }
    //endregion creamo
  };
};

module.exports = Entry.Creamo;