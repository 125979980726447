var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as PIXI from 'pixi.js';
import PIXIHelper from '../class/pixi/helper/PIXIHelper';
import { PIXIGlobal } from '../class/pixi/init/PIXIGlobal';
import { GEDragHelper } from './GEDragHelper';
import { EaselResManager } from './EaselResManager';
import { PIXIBrushAdaptor } from '../class/pixi/etc/PIXIBrushAdaptor';
import { PIXIPaintAdaptor } from '../class/pixi/etc/PIXIPaintAdaptor';
import { PIXIScaleAdaptor } from '../class/pixi/atlas/PIXIScaleAdaptor';
var INITIAL_VIDEO_PARAMS = {
    WIDTH: 480,
    HEIGHT: 270,
    X: -240,
    Y: -135,
    SCALE_X: 0.75,
    SCALE_Y: 0.75,
    ALPHA: 0.5,
};
var isFirefox = typeof InstallTrigger !== 'undefined';
var CreateJsApplication = /** @class */ (function () {
    function CreateJsApplication(canvas) {
        var stage = new createjs.Stage(canvas.id);
        createjs.Touch.enable(stage);
        stage.enableMouseOver(10);
        stage.mouseMoveOutside = true;
        this.stage = stage;
    }
    CreateJsApplication.prototype.render = function () {
        this.stage.update();
    };
    CreateJsApplication.prototype.destroy = function (destroyOption) {
        this.stage = null;
    };
    return CreateJsApplication;
}());
var GEHelperBase = /** @class */ (function () {
    function GEHelperBase() {
        this._isWebGL = false;
    }
    GEHelperBase.prototype.INIT = function (isWebGL) {
        this._isWebGL = isWebGL;
    };
    return GEHelperBase;
}());
export { GEHelperBase };
var emptyFn = function () {
    var arg = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arg[_i] = arguments[_i];
    }
};
var _GEHelper = /** @class */ (function (_super) {
    __extends(_GEHelper, _super);
    function _GEHelper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**  pixi 객체로부터 rotate를 읽을 때 사용할 값 */
        _this.rotateRead = 1;
        /**  pixi 객체에 rotate를 할당 할 때 사용할 값 */
        _this.rotateWrite = 1;
        return _this;
    }
    Object.defineProperty(_GEHelper.prototype, "isWebGL", {
        get: function () {
            return this._isWebGL;
        },
        enumerable: false,
        configurable: true
    });
    _GEHelper.prototype.INIT = function (isWebGL) {
        _super.prototype.INIT.call(this, isWebGL);
        if (this._isInitialized) {
            return;
        }
        this._isInitialized = true;
        GEDragHelper.INIT(isWebGL);
        (this.colorFilter = new _ColorFilterHelper()).INIT(isWebGL);
        (this.textHelper = new _TextHelper()).INIT(isWebGL);
        (this.brushHelper = new _BrushHelper()).INIT(isWebGL);
        if (this._isWebGL) {
            // this.rndPosition = ()=>{ return Math.random() * 0.8 - 0.4; };
            this.rndPosition = function () { return 0; };
            this.rotateRead = 180 / Math.PI;
            this.rotateWrite = Math.PI / 180;
            PIXIGlobal.initOnce();
            this.resManager = PIXIGlobal.atlasManager;
            this.Ticker = {
                reset: emptyFn,
                setFPS: emptyFn,
            };
        }
        else {
            this.rndPosition = function () { return 0; };
            this.resManager = new EaselResManager();
            this.Ticker = {
                reset: createjs.Ticker.reset,
                setFPS: createjs.Ticker.setFPS,
            };
        }
        this.resManager.INIT();
    };
    _GEHelper.prototype.newApp = function (canvas) {
        var app;
        if (this._isWebGL) {
            app = PIXIGlobal.getNewApp(canvas);
        }
        else {
            app = new CreateJsApplication(canvas);
        }
        return app;
    };
    _GEHelper.prototype.cloneStamp = function (entity) {
        if (this._isWebGL) {
            var orgObj = entity.object;
            var orgTex = orgObj.internal_getOriginalTex && orgObj.internal_getOriginalTex();
            var object = PIXIHelper.sprite('StampEntity', orgTex || orgObj.texture);
            object.visible = orgObj.visible;
            object.interactive = false;
            object.interactiveChildren = false;
            object.setTransform(orgObj.x, orgObj.y, orgObj.scale.x, orgObj.scale.y, orgObj.rotation, orgObj.skew.x, orgObj.skew.y, orgObj.pivot.x, orgObj.pivot.y);
            return object;
        }
        else {
            var object = entity.object.clone();
            object.mouseEnabled = false;
            object.tickEnabled = false;
            object.filters = null;
            return object;
        }
    };
    _GEHelper.prototype.hitTestMouse = function (object) {
        if (this._isWebGL) {
            var pixiApp = Entry.stage._app;
            var im = pixiApp.renderer.plugins.interaction;
            var hitObject = im.hitTest(im.mouse.global, object);
            return !!hitObject;
        }
        else {
            if (object.alpha < 0.001) {
                return false;
            }
            var stage = Entry.stage.canvas;
            var pt = object.globalToLocal(stage.mouseX, stage.mouseY);
            return object.hitTest(pt.x, pt.y);
        }
    };
    _GEHelper.prototype.tickByEngine = function () {
        if (this._isWebGL) {
            Entry.stage._app.ticker.start();
        }
        else {
            createjs.Ticker.addEventListener('tick', Entry.stage.canvas);
        }
    };
    // for createJS ONLY issue, #12012
    _GEHelper.prototype.disableTickByEngine = function () {
        if (this._isWebGL) {
            return;
        }
        else {
            createjs.Ticker.removeEventListener('tick', Entry.stage.canvas);
        }
    };
    _GEHelper.prototype.getNewContainer = function () {
        if (this._isWebGL) {
            return new PIXI.Container();
        }
        else {
            return new createjs.Container();
        }
    };
    // this function returns corresponding VideoElement,
    _GEHelper.prototype.getVideoElement = function (video) {
        console.log('getVideoElement');
        var videoElement = null;
        var WIDTH = INITIAL_VIDEO_PARAMS.WIDTH, X = INITIAL_VIDEO_PARAMS.X, Y = INITIAL_VIDEO_PARAMS.Y, SCALE_X = INITIAL_VIDEO_PARAMS.SCALE_X, SCALE_Y = INITIAL_VIDEO_PARAMS.SCALE_Y, ALPHA = INITIAL_VIDEO_PARAMS.ALPHA;
        var HEIGHT = INITIAL_VIDEO_PARAMS.HEIGHT;
        if (this._isWebGL) {
            var videoTexture = PIXI.Texture.from(video);
            videoElement = new PIXI.Sprite(videoTexture);
            if (isFirefox) {
                HEIGHT *= 1.33;
            }
        }
        else {
            videoElement = new createjs.Bitmap(video);
        }
        videoElement.width = WIDTH;
        videoElement.height = HEIGHT;
        videoElement.x = X;
        videoElement.y = Y;
        videoElement.alpha = ALPHA;
        if (this._isWebGL) {
            videoElement.scale.x = SCALE_X;
            videoElement.scale.y = SCALE_Y;
        }
        else {
            videoElement.scaleX = SCALE_X;
            videoElement.scaleY = SCALE_Y;
            videoElement.on('tick', function () {
                if (videoElement.cacheCanvas) {
                    videoElement.updateCache();
                }
            });
        }
        return videoElement;
    };
    _GEHelper.prototype.getOverlayElement = function (canvas) {
        var overlayElement = null;
        var WIDTH = INITIAL_VIDEO_PARAMS.WIDTH, X = INITIAL_VIDEO_PARAMS.X, Y = INITIAL_VIDEO_PARAMS.Y, SCALE_X = INITIAL_VIDEO_PARAMS.SCALE_X, SCALE_Y = INITIAL_VIDEO_PARAMS.SCALE_Y, ALPHA = INITIAL_VIDEO_PARAMS.ALPHA;
        var HEIGHT = INITIAL_VIDEO_PARAMS.HEIGHT;
        if (this._isWebGL) {
            var videoTexture = PIXI.Texture.from(canvas);
            overlayElement = new PIXI.Sprite(videoTexture);
            if (isFirefox) {
                HEIGHT *= 1.33;
            }
        }
        else {
            overlayElement = new createjs.Bitmap(canvas);
        }
        overlayElement.width = WIDTH;
        overlayElement.height = HEIGHT;
        overlayElement.x = X;
        overlayElement.y = Y;
        overlayElement.alpha = ALPHA;
        if (this._isWebGL) {
            overlayElement.scale.x = SCALE_X;
            overlayElement.scale.y = SCALE_Y;
        }
        else {
            overlayElement.scaleX = SCALE_X;
            overlayElement.scaleY = SCALE_Y;
            overlayElement.on('tick', function () {
                if (overlayElement.cacheCanvas) {
                    overlayElement.updateCache();
                }
            });
        }
        return overlayElement;
    };
    _GEHelper.prototype.createNewIndicatorGraphic = function () {
        var graphic = this.newGraphic();
        graphic.width = 640;
        graphic.height = 360;
        graphic.x = INITIAL_VIDEO_PARAMS.X;
        graphic.y = INITIAL_VIDEO_PARAMS.Y;
        return graphic;
    };
    _GEHelper.prototype.drawVideoElement = function (videoElement) {
        if (!this.videoContainer) {
            this.videoContainer = Entry.stage.canvas.getChildAt(2);
        }
        this.videoContainer.addChild(videoElement);
        this.tickByEngine();
    };
    _GEHelper.prototype.drawOverlayElement = function (overlayElement) {
        if (!this.overlayContainer) {
            this.overlayContainer = Entry.stage.canvas.getChildAt(3);
        }
        var isAlready = this.overlayContainer.children.some(function (child) {
            child === overlayElement;
        });
        if (!isAlready) {
            this.overlayContainer.addChild(overlayElement);
        }
        this.tickByEngine();
    };
    _GEHelper.prototype.drawDetectedGraphic = function () {
        if (!this.poseIndicatorGraphic) {
            this.poseIndicatorGraphic = this.createNewIndicatorGraphic();
            Entry.stage.canvas.addChildAt(this.poseIndicatorGraphic, 4);
        }
        if (!this.faceIndicatorGraphic) {
            this.faceIndicatorGraphic = this.createNewIndicatorGraphic();
            Entry.stage.canvas.addChildAt(this.faceIndicatorGraphic, 4);
        }
        if (!this.objectIndicatorGraphic) {
            this.objectIndicatorGraphic = this.createNewIndicatorGraphic();
            Entry.stage.canvas.addChildAt(this.objectIndicatorGraphic, 4);
        }
        this.tickByEngine();
    };
    _GEHelper.prototype.turnOffWebcam = function (canvasVideo) {
        if (!canvasVideo) {
            return;
        }
        var targetContainer = Entry.stage.canvas.getChildAt(2);
        targetContainer.removeChild(canvasVideo);
    };
    _GEHelper.prototype.turnOffOverlay = function (overlayElement) {
        if (!overlayElement) {
            return;
        }
        var targetContainer = Entry.stage.canvas.getChildAt(3);
        targetContainer.removeChild(overlayElement);
    };
    _GEHelper.prototype.destroyWebcam = function () {
        this.videoContainer = null;
    };
    _GEHelper.prototype.destroyOverlay = function () {
        this.overlayContainer = null;
    };
    _GEHelper.prototype.destroy = function () {
        this.videoContainer = null;
        this.overlayContainer = null;
        this.faceIndicatorGraphic = null;
        this.poseIndicatorGraphic = null;
        this.objectIndicatorGraphic = null;
    };
    _GEHelper.prototype.hFlipVideoElement = function (canvasVideo) {
        if (Array.isArray(canvasVideo)) {
            canvasVideo.forEach(function (video) {
                if (!video) {
                    return;
                }
                var x = video.x, y = video.y, scaleX = video.scaleX, scaleY = video.scaleY, rotation = video.rotation, skewX = video.skewX, skewY = video.skewY, regX = video.regX, regY = video.regY;
                video.setTransform(-x, y, -scaleX, scaleY, rotation, skewX, skewY, regX, regY);
            });
        }
        else {
            var x = canvasVideo.x, y = canvasVideo.y, scaleX = canvasVideo.scaleX, scaleY = canvasVideo.scaleY, rotation = canvasVideo.rotation, skewX = canvasVideo.skewX, skewY = canvasVideo.skewY, regX = canvasVideo.regX, regY = canvasVideo.regY;
            canvasVideo.setTransform(-x, y, -scaleX, scaleY, rotation, skewX, skewY, regX, regY);
        }
    };
    _GEHelper.prototype.vFlipVideoElement = function (canvasVideo) {
        if (Array.isArray(canvasVideo)) {
            canvasVideo.forEach(function (video) {
                if (!video) {
                    return;
                }
                var x = video.x, y = video.y, scaleX = video.scaleX, scaleY = video.scaleY, rotation = video.rotation, skewX = video.skewX, skewY = video.skewY, regX = video.regX, regY = video.regY;
                video.setTransform(x, -y, scaleX, -scaleY, rotation, skewX, skewY, regX, regY);
            });
        }
        else {
            var x = canvasVideo.x, y = canvasVideo.y, scaleX = canvasVideo.scaleX, scaleY = canvasVideo.scaleY, rotation = canvasVideo.rotation, skewX = canvasVideo.skewX, skewY = canvasVideo.skewY, regX = canvasVideo.regX, regY = canvasVideo.regY;
            canvasVideo.setTransform(x, -y, scaleX, -scaleY, rotation, skewX, skewY, regX, regY);
        }
    };
    _GEHelper.prototype.setVideoAlpha = function (canvasVideo, value) {
        canvasVideo.alpha = (100 - value) / 100;
    };
    _GEHelper.prototype.removeAllChildInHandler = function (handler) {
        while (handler.children.length > 0) {
            var child = handler.getChildAt(0);
            handler.removeChild(child);
        }
    };
    _GEHelper.prototype.resetHandlers = function () {
        if (!this.faceIndicatorGraphic ||
            !this.poseIndicatorGraphic ||
            !this.objectIndicatorGraphic) {
            return;
        }
        if (this.isWebGL) {
            this.faceIndicatorGraphic.clear();
            this.poseIndicatorGraphic.clear();
            this.objectIndicatorGraphic.clear();
            this.removeAllChildInHandler(this.objectIndicatorGraphic);
            this.removeAllChildInHandler(this.poseIndicatorGraphic);
            this.removeAllChildInHandler(this.faceIndicatorGraphic);
        }
        else {
            this.faceIndicatorGraphic.graphics.clear();
            this.poseIndicatorGraphic.graphics.clear();
            this.objectIndicatorGraphic.graphics.clear();
        }
    };
    _GEHelper.prototype.drawHumanPoints = function (poses, flipStatus) {
        return __awaiter(this, void 0, void 0, function () {
            var R, handler, child;
            var _this = this;
            return __generator(this, function (_a) {
                R = 5;
                handler = this.poseIndicatorGraphic;
                if (this._isWebGL) {
                    while (handler.children.length > 0) {
                        child = handler.getChildAt(0);
                        handler.removeChild(child);
                    }
                }
                else {
                    handler = this.poseIndicatorGraphic.graphics;
                }
                handler.clear();
                poses.map(function (pose, index) {
                    var _a = pose.keypoints[3].position, x = _a.x, y = _a.y;
                    if (_this._isWebGL) {
                        var text = PIXIHelper.text("".concat(Lang.Blocks.video_human, "-").concat(index + 1), '20px Nanum Gothic', '', 'middle', 'center');
                        text.x = x - 20;
                        text.y = y - 20;
                        handler.addChild(text);
                    }
                    else {
                        handler.append({
                            exec: function (ctx) {
                                ctx.font = '20px Nanum Gothic';
                                ctx.color = 'blue';
                                ctx.fillText("".concat(Lang.Blocks.video_human, "-").concat(index + 1), x - 20, y - 20);
                            },
                        });
                    }
                    pose.keypoints.map(function (item) {
                        var _a = item.position, x = _a.x, y = _a.y;
                        var recalculatedY = flipStatus.vertical ? INITIAL_VIDEO_PARAMS.HEIGHT - y : y;
                        handler.beginFill(0x0000ff);
                        handler.drawCircle(x, recalculatedY, R);
                        handler.endFill();
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    _GEHelper.prototype.drawHumanSkeletons = function (adjacents, flipStatus) {
        return __awaiter(this, void 0, void 0, function () {
            var coordList, handler;
            return __generator(this, function (_a) {
                coordList = [];
                handler = this.poseIndicatorGraphic;
                adjacents.forEach(function (adjacentList) {
                    adjacentList.forEach(function (pair) {
                        var start = pair[0].position;
                        var end = pair[1].position;
                        if (flipStatus.vertical) {
                            start.y = INITIAL_VIDEO_PARAMS.HEIGHT - start.y;
                            end.y = INITIAL_VIDEO_PARAMS.HEIGHT - end.y;
                        }
                        coordList.push({ start: start, end: end });
                    });
                });
                if (this._isWebGL) {
                    handler.lineStyle(5, 0x0000ff);
                }
                else {
                    handler = handler.graphics;
                    handler.setStrokeStyle(8, 'round').beginStroke('blue');
                }
                coordList.forEach(function (coord) {
                    var start = coord.start, end = coord.end;
                    handler.moveTo(start.x, start.y).lineTo(end.x, end.y);
                });
                return [2 /*return*/];
            });
        });
    };
    _GEHelper.prototype.drawFaceEdges = function (faces, flipStatus) {
        return __awaiter(this, void 0, void 0, function () {
            var handler, child;
            var _this = this;
            return __generator(this, function (_a) {
                handler = this.faceIndicatorGraphic;
                if (this._isWebGL) {
                    handler.clear();
                    while (handler.children.length > 0) {
                        child = handler.getChildAt(0);
                        handler.removeChild(child);
                    }
                    handler.lineStyle(2, 0xff0000);
                }
                else {
                    handler = handler.graphics;
                    handler.clear();
                    handler.setStrokeStyle(2, 'round').beginStroke('red');
                }
                faces.forEach(function (face, index) {
                    var positions = face.landmarks._positions;
                    positions.forEach(function (item, i) {
                        if (i === 0 ||
                            i === 17 ||
                            i === 27 ||
                            i === 31 ||
                            i === 36 ||
                            i === 42 ||
                            i === 48) {
                            return;
                        }
                        var prev = face.landmarks._positions[i - 1];
                        _this.drawEdge(prev, item, handler, flipStatus);
                    });
                    // compensation for missing edges
                    _this.drawEdge(positions[42], positions[47], handler, flipStatus);
                    _this.drawEdge(positions[41], positions[36], handler, flipStatus);
                    _this.drawEdge(positions[60], positions[67], handler, flipStatus);
                    _this.drawEdge(positions[0], positions[17], handler, flipStatus);
                    _this.drawEdge(positions[16], positions[26], handler, flipStatus);
                    _this.drawEdge(positions[27], positions[31], handler, flipStatus);
                    _this.drawEdge(positions[27], positions[35], handler, flipStatus);
                    _this.drawEdge(positions[30], positions[31], handler, flipStatus);
                    _this.drawEdge(positions[30], positions[35], handler, flipStatus);
                    var refPoint = positions[57];
                    var x = refPoint._x;
                    var y = refPoint._y;
                    var WIDTH = INITIAL_VIDEO_PARAMS.WIDTH, HEIGHT = INITIAL_VIDEO_PARAMS.HEIGHT;
                    if (flipStatus.horizontal) {
                        x = WIDTH - x;
                    }
                    if (flipStatus.vertical) {
                        y = HEIGHT - y;
                    }
                    if (_this._isWebGL) {
                        var text = PIXIHelper.text("".concat(Lang.Blocks.video_face, "-").concat(index + 1), '20px Nanum Gothic', '', 'middle', 'center');
                        text.x = x;
                        text.y = y - 10;
                        handler.addChild(text);
                    }
                    else {
                        handler.append({
                            exec: function (ctx) {
                                ctx.font = '20px Nanum Gothic';
                                ctx.color = '#0000ff';
                                ctx.fillText("".concat(Lang.Blocks.video_face, "-").concat(index + 1), x, y - 10);
                            },
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    _GEHelper.prototype.drawEdge = function (pos1, pos2, handler, flipStatus) {
        var WIDTH = INITIAL_VIDEO_PARAMS.WIDTH, HEIGHT = INITIAL_VIDEO_PARAMS.HEIGHT;
        var _x = pos2._x, _y = pos2._y;
        var prevX = pos1._x;
        var prevY = pos1._y;
        if (flipStatus.horizontal) {
            _x = WIDTH - _x;
            prevX = WIDTH - prevX;
        }
        if (flipStatus.vertical) {
            _y = HEIGHT - _y;
            prevY = HEIGHT - prevY;
        }
        handler.moveTo(prevX, prevY).lineTo(_x, _y);
    };
    _GEHelper.prototype.drawObjectBox = function (objects, flipStatus) {
        return __awaiter(this, void 0, void 0, function () {
            var objectsList, handler, child;
            return __generator(this, function (_a) {
                objectsList = [];
                objects.forEach(function (object) {
                    var bbox = object.bbox;
                    var name = object.class
                        ? "".concat(Lang.Blocks.video_object, "-").concat(Lang.video_object_params[object.class])
                        : '';
                    var x = bbox[0];
                    var y = bbox[1];
                    var width = bbox[2];
                    var height = bbox[3];
                    if (flipStatus.horizontal) {
                        x = INITIAL_VIDEO_PARAMS.WIDTH - x - width;
                    }
                    if (flipStatus.vertical) {
                        y = INITIAL_VIDEO_PARAMS.HEIGHT - y - height;
                    }
                    var textpoint = { x: x + 20, y: y + 20 };
                    objectsList.push({ textpoint: textpoint, name: name, x: x, y: y, width: width, height: height });
                });
                handler = this.objectIndicatorGraphic;
                if (this._isWebGL) {
                    handler.clear();
                    while (handler.children.length > 0) {
                        child = handler.getChildAt(0);
                        handler.removeChild(child);
                    }
                    handler.lineStyle(5, 0xff0000);
                    objectsList.forEach(function (target, index) {
                        var textpoint = target.textpoint, name = target.name, x = target.x, y = target.y, width = target.width, height = target.height;
                        if (name) {
                            var text = PIXIHelper.text("".concat(name, "-").concat(index + 1), '20px Nanum Gothic', '', 'middle', 'center');
                            text.x = textpoint.x;
                            text.y = textpoint.y;
                            handler.addChild(text);
                        }
                        handler.drawRect(x, y, width, height);
                    });
                }
                else {
                    handler = handler.graphics;
                    handler.clear();
                    objectsList.forEach(function (target, index) {
                        var textpoint = target.textpoint, name = target.name, x = target.x, y = target.y, width = target.width, height = target.height;
                        if (name) {
                            handler.append({
                                exec: function (ctx) {
                                    ctx.font = '20px Nanum Gothic';
                                    ctx.fillText("".concat(name, "-").concat(index + 1), textpoint.x - 5, textpoint.y + 5);
                                },
                            });
                        }
                        handler
                            .setStrokeStyle(8, 'round')
                            .beginStroke('red')
                            .drawRect(x, y, width, height);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    _GEHelper.prototype.getTransformedBounds = function (sprite) {
        if (this._isWebGL) {
            return sprite.getBounds(false);
        }
        else {
            return sprite.getTransformedBounds();
        }
    };
    _GEHelper.prototype.calcParentBound = function (obj) {
        if (this._isWebGL) {
            return PIXIHelper.getTransformBound(obj);
        }
        else {
            return obj.getTransformedBounds();
        }
    };
    _GEHelper.prototype.newContainer = function (debugName) {
        if (this._isWebGL) {
            return PIXIHelper.container(debugName);
        }
        else {
            return new createjs.Container();
        }
    };
    /**
     * stage wall 생성만을 위한 함수
     * @param path
     */
    _GEHelper.prototype.newWallTexture = function (path) {
        if (this._isWebGL) {
            return PIXI.Texture.from(path);
        }
        else {
            var img = new Image();
            img.src = path;
            return img;
        }
    };
    /**
     * stage wall 생성만을 위한 함수
     * @param tex
     */
    _GEHelper.prototype.newWallSprite = function (tex) {
        if (this._isWebGL) {
            return new PIXI.Sprite(tex);
        }
        else {
            return new createjs.Bitmap(tex);
        }
    };
    _GEHelper.prototype.newEmptySprite = function () {
        if (this._isWebGL) {
            return PIXIHelper.sprite();
        }
        else {
            return new createjs.Bitmap();
        }
    };
    _GEHelper.prototype.newSpriteWithCallback = function (url, callback) {
        var img = new Image();
        if (callback) {
            var handle_1 = function () {
                img.removeEventListener('load', handle_1);
                callback();
            };
            img.addEventListener('load', handle_1);
        }
        img.src = url;
        if (this._isWebGL) {
            var texture = PIXI.Texture.from(img);
            return PIXI.Sprite.from(texture);
        }
        else {
            return new createjs.Bitmap(img);
        }
    };
    _GEHelper.prototype.newGraphic = function () {
        if (this._isWebGL) {
            return new PIXI.Graphics();
        }
        else {
            return new createjs.Shape();
        }
    };
    _GEHelper.prototype.newAScaleAdaptor = function (target) {
        if (this._isWebGL) {
            return PIXIScaleAdaptor.factory(target);
        }
        //createjs 는 사용하는 코드측에서 분기 처리.
        return null;
    };
    return _GEHelper;
}(GEHelperBase));
export var GEHelper = new _GEHelper();
var _ColorFilterHelper = /** @class */ (function (_super) {
    __extends(_ColorFilterHelper, _super);
    function _ColorFilterHelper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    _ColorFilterHelper.prototype.hue = function (value) {
        if (this._isWebGL) {
            var cmHue = new PIXI.filters.ColorMatrixFilter();
            // @ts-ignore
            return cmHue.hue(value);
        }
        else {
            var cmHue = new createjs.ColorMatrix();
            cmHue.adjustColor(0, 0, 0, value);
            var hueFilter = new createjs.ColorMatrixFilter(cmHue);
            return hueFilter;
        }
    };
    _ColorFilterHelper.prototype.brightness = function (value) {
        if (this._isWebGL) {
            value /= 255;
        }
        // pixi 필터에 brightness 가 있지만, createjs 와 matrix 값이 달라 결과가 다르게 보임. 그래서 동일하게 구현함.
        var matrix = [
            1,
            0,
            0,
            0,
            value,
            0,
            1,
            0,
            0,
            value,
            0,
            0,
            1,
            0,
            value,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            1,
        ];
        return this.newColorMatrixFilter(matrix);
    };
    /**
     * @param matrixValue
     */
    _ColorFilterHelper.prototype.newColorMatrixFilter = function (matrixValue) {
        if (this._isWebGL) {
            matrixValue.length = 20; // pixi matrix 는 5 * 4
            var m = new PIXI.filters.ColorMatrixFilter();
            m._loadMatrix(matrixValue, false);
            return m;
        }
        else {
            //createjs matrix 는 5*5
            var cm = new createjs.ColorMatrix();
            cm.copy(matrixValue);
            return new createjs.ColorMatrixFilter(cm);
        }
    };
    /**
     *
     * @param entity - EntityObject
     * @param cache
     */
    _ColorFilterHelper.prototype.setCache = function (entity, cache) {
        if (this._isWebGL) {
            //do nothing
        }
        else {
            cache ? entity.cache() : entity.object.uncache();
        }
    };
    return _ColorFilterHelper;
}(GEHelperBase));
var _TextHelper = /** @class */ (function (_super) {
    __extends(_TextHelper, _super);
    function _TextHelper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    _TextHelper.prototype.setColor = function (target, color) {
        if (this._isWebGL) {
            target.style.fill = color;
        }
        else {
            target.color = color;
        }
    };
    /**
     * @param str
     * @param font size & fontface - 10pt NanumGothic
     * @param color css style color - #ffffff
     * @param textBaseline
     * @param textAlign
     * @
     */
    _TextHelper.prototype.newText = function (str, font, color, textBaseline, textAlign) {
        if (this._isWebGL) {
            return PIXIHelper.text(str, font, color, textBaseline, textAlign);
        }
        else {
            var text = new createjs.Text(str, font, color);
            textBaseline ? (text.textBaseline = textBaseline) : 0;
            textAlign ? (text.textAlign = textAlign) : 0;
            return text;
        }
    };
    return _TextHelper;
}(GEHelperBase));
var _BrushHelper = /** @class */ (function (_super) {
    __extends(_BrushHelper, _super);
    function _BrushHelper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    _BrushHelper.prototype.newBrush = function () {
        if (this._isWebGL) {
            return new PIXIBrushAdaptor();
        }
        else {
            return new createjs.Graphics();
        }
    };
    _BrushHelper.prototype.newPaint = function () {
        if (this._isWebGL) {
            return new PIXIPaintAdaptor();
        }
        else {
            return new createjs.Graphics();
        }
    };
    _BrushHelper.prototype.newShape = function (brush) {
        if (this._isWebGL) {
            var shape = PIXIHelper.newPIXIGraphics();
            brush.internalSetShape(shape);
            return shape;
        }
        else {
            var shape = new createjs.Shape(brush);
            return shape;
        }
    };
    return _BrushHelper;
}(GEHelperBase));
