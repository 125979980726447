'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _tool = require("@entrylabs/tool");
var _domUtils = _interopRequireDefault(require("./domUtils"));
Entry.ContextMenu = {};
(function (ctx) {
  var ATTR_KEY = 'data-option-index';
  ctx.visible = false;
  ctx._hideEvent = null;
  ctx.createDom = function () {
    this.dom = Entry.Dom('div', {
      id: 'entry-contextmenu',
      parent: $('body')
    });
    _bindEvent.call(this);
  };
  ctx.show = function (options, className, coordinate) {
    var _this = this;
    if (!options.length) {
      return;
    }
    this._options = options;
    this.createDom();
    if (this._hideEvent) {
      this._hideEvent.destroy();
    }
    if (className !== undefined) {
      this._className = className;
      this.dom.addClass(className);
    }
    this._hideEvent = Entry.documentMousedown.attach(this, this.hide);
    this.mouseCoordinate = coordinate || Entry.mouseCoordinate;
    this.contextMenu = new _tool.ContextMenu({
      type: 'contextMenu',
      data: {
        items: options,
        coordinate: this.mouseCoordinate,
        onOutsideClick: function onOutsideClick() {
          _this.hide();
        }
      },
      container: this.dom[0]
    });
    this.visible = true;
  };
  ctx.position = function (pos) {
    var dom = this.dom;
    dom.css({
      left: 0,
      top: 0
    });
    var width = dom.width();
    var height = dom.height();
    var win = $(window);
    var winWidth = win.width();
    var winHeight = win.height();
    if (pos.x + width > winWidth) {
      pos.x -= width + 3;
    }
    if (pos.y + height > winHeight) {
      pos.y -= height;
    }
    dom.css({
      left: pos.x,
      top: pos.y
    });
  };
  ctx.hide = function () {
    this.visible = false;
    var dom = this.dom;
    if (!dom) {
      return;
    }
    dom.addClass('entryRemove');
    if (this._className) {
      dom.removeClass(this._className);
      delete this._className;
    }
    if (this._hideEvent) {
      this._hideEvent.destroy();
      this._hideEvent = null;
    }
    if (this.contextMenu) {
      this.contextMenu.isShow && this.contextMenu.hide();
      this.contextMenu.remove();
      this.contextMenu = null;
    }
  };
  ctx.onContextmenu = function (target, callback) {
    var _this2 = this;
    var longPressEvent = function longPressEvent(e) {
      var startEvent = Entry.Utils.convertMouseEvent(e);
      _this2.coordi = {
        x: startEvent.clientX,
        y: startEvent.clientY
      };
      if (_this2.longTouchEvent) {
        clearTimeout(_this2.longTouchEvent);
        _this2.longTouchEvent = null;
      }
      _this2.longTouchEvent = setTimeout(function () {
        callback(_this2.coordi);
        _this2.longTouchEvent = undefined;
      }, 900);
    };
    _domUtils["default"].addEventListenerMultiple(target, 'touchstart touchmove touchend mousemove mouseup mousedown', function (e) {
      switch (e.type) {
        case 'touchstart':
          {
            longPressEvent(e);
            break;
          }
        case 'mousemove':
        case 'touchmove':
          {
            var startEvent = Entry.Utils.convertMouseEvent(e);
            if (!_this2.coordi) {
              return;
            }
            var diff = Math.sqrt(Math.pow(startEvent.pageX - _this2.coordi.x, 2) + Math.pow(startEvent.pageY - _this2.coordi.y, 2));
            if (diff > 5 && _this2.longTouchEvent) {
              clearTimeout(_this2.longTouchEvent);
              _this2.longTouchEvent = undefined;
            }
            break;
          }
        case 'mouseup':
        case 'touchend':
          // e.stopPropagation();
          if (_this2.longTouchEvent) {
            clearTimeout(_this2.longTouchEvent);
            _this2.longTouchEvent = undefined;
          }
          break;
        case 'mousedown':
          if (Entry.Utils.isRightButton(e)) {
            e.stopPropagation();
            _this2.coordi = {
              x: e.clientX,
              y: e.clientY
            };
            clearTimeout(_this2.longTouchEvent);
            _this2.longTouchEvent = undefined;
            callback(_this2.coordi);
          }
          if (Entry.isMobile()) {
            longPressEvent(e);
          }
          break;
      }
    });
  };
  function _bindEvent() {
    var that = this;
    this.dom.on('mousedown touchstart', function (e) {
      e.stopPropagation();
    });

    //event delegation
    this.dom.on('mousedown touchstart', 'li', function (e) {
      e.stopPropagation();
      var options = that._options;
      if (_.isEmpty(options)) {
        return that.hide();
      }
      var _options$this$getAttr = options[this.getAttribute(ATTR_KEY)],
        enable = _options$this$getAttr.enable,
        callback = _options$this$getAttr.callback;
      if (enable && callback) {
        e.preventDefault();
        that.hide();
        callback(e);
      }
    });
    Entry.Utils.disableContextmenu(this.dom);
  }
})(Entry.ContextMenu);