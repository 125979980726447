Entry.skeleton.clickable_text = {
    path: function (blockView) {
        var width = blockView.contentWidth;
        var height = blockView.contentHeight;
        height = Math.max(18, height + 2);
        width = Math.max(0, width - height + 12);
        var halfHeight = height / 2;
        return ("m ".concat(halfHeight, ",0 h ").concat(width, " a ").concat(halfHeight, ",").concat(halfHeight, " 0 1,1 0,").concat(height) +
            "H ".concat(halfHeight, " A ").concat(halfHeight, ",").concat(halfHeight, " 0 1,1 ").concat(halfHeight, ",0 z"));
    },
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 5;
        var height = blockView ? blockView.contentHeight : 18;
        var _a = blockView._schema.skeletonOptions, skeletonOptions = _a === void 0 ? {} : _a;
        var _b = skeletonOptions.box, box = _b === void 0 ? {} : _b;
        return Object.assign({
            offsetX: 0,
            offsetY: 0,
            width: width + 12,
            height: Math.max(height + 2, 18),
            marginBottom: 0,
        }, box);
    },
    contentPos: function (blockView) {
        // apply scale required.
        var height = Math.max(blockView.contentHeight, 16);
        var width = Math.max(blockView.contentWidth, 16);
        return { x: width / 2 + 5, y: height / 2 + 1 };
    },
    movable: false,
    readOnly: true,
    nextShadow: false,
    classes: ['clickableText'],
};
