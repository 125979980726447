var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import WebApiConnector from './webApiConnector';
import throttle from 'lodash/throttle';
var Buffer = require('buffer/').Buffer;
var LineBreakTransformer = /** @class */ (function () {
    function LineBreakTransformer() {
        this.container = '';
    }
    LineBreakTransformer.prototype.transform = function (chunk, controller) {
        var _a, _b;
        try {
            this.container += chunk;
            // @ts-ignore
            var lines = this.container.split(((_b = (_a = Entry.hwLite) === null || _a === void 0 ? void 0 : _a.hwModule) === null || _b === void 0 ? void 0 : _b.delimeter) || '\r\n');
            this.container = lines.pop();
            lines.forEach(function (line) { return controller.enqueue(line); });
        }
        catch (e) {
            controller.enqueue(chunk);
        }
    };
    LineBreakTransformer.prototype.flush = function (controller) {
        controller.enqueue(this.container);
    };
    return LineBreakTransformer;
}());
var WebSerialConnector = /** @class */ (function (_super) {
    __extends(WebSerialConnector, _super);
    function WebSerialConnector(hwModule, hwLite) {
        var _this = _super.call(this) || this;
        _this.hwLite = hwLite;
        _this.hwModule = hwModule;
        _this.isSendAsyncRun = false;
        _this.hwLite.setStatus('disconnected');
        Entry.addEventListener('beforeStop', _this.checkConditionBeforeStop.bind(_this));
        _this.sendAsyncWithThrottle = throttle(_this.sendAsync, _this.hwModule.duration);
        return _this;
    }
    WebSerialConnector.prototype.connect = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var port, portData, encoder, writable, writableStream, readable;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, navigator.serial.requestPort()];
                    case 1:
                        port = _b.sent();
                        portData = (this.hwModule || {}).portData;
                        return [4 /*yield*/, port.open(portData || {
                                baudRate: 9600,
                                dataBits: 8,
                                parity: 'none',
                                bufferSize: 256,
                                stopBits: 1,
                            })];
                    case 2:
                        _b.sent();
                        this.port = port;
                        encoder = new TextEncoderStream();
                        writable = port.writable;
                        this.connectionType = portData === null || portData === void 0 ? void 0 : portData.connectionType;
                        if ((portData === null || portData === void 0 ? void 0 : portData.writeAscii) || (portData === null || portData === void 0 ? void 0 : portData.connectionType) === 'ascii') {
                            writableStream = encoder.readable.pipeTo(writable);
                            this.writableStream = writableStream;
                            this.writer = encoder.writable.getWriter();
                        }
                        else {
                            this.writer = port.writable.getWriter();
                        }
                        readable = this.port.readable;
                        if ((portData === null || portData === void 0 ? void 0 : portData.readAscii) || this.connectionType === 'ascii') {
                            readable = readable.pipeThrough(new TextDecoderStream());
                        }
                        if (((_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.delimeter) || this.connectionType === 'ascii') {
                            readable = readable.pipeThrough(new TransformStream(new LineBreakTransformer()));
                        }
                        this.reader = readable.getReader();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.disconnect = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, 6, 8]);
                        return [4 /*yield*/, ((_a = this.reader) === null || _a === void 0 ? void 0 : _a.cancel().catch(function (error) {
                                console.error(error);
                            }))];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, ((_b = this.writer) === null || _b === void 0 ? void 0 : _b.close())];
                    case 2:
                        _d.sent();
                        if (!(this.connectionType === 'ascii' && this.writableStream)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.writableStream];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        err_1 = _d.sent();
                        console.error(err_1);
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, ((_c = this.port) === null || _c === void 0 ? void 0 : _c.close())];
                    case 7:
                        _d.sent();
                        this.port = null;
                        this.reader = null;
                        this.writer = null;
                        this.writableStream = null;
                        this.hwModule = null;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.initialDevice = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.hwModule.initialHandshake) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.hwModule.initialHandshake()];
                    case 1:
                        result = _b.sent();
                        if (!result) {
                            throw new Error('Handshake Error : 디바이스와 연결에 실패하였습니다.');
                        }
                        _b.label = 2;
                    case 2:
                        if ((_a = this.hwModule.portData) === null || _a === void 0 ? void 0 : _a.constantServing) {
                            this.constantServing();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 디바이스와 1회성 통신
     * @param data
     * @returns Promise resolves to resulting message
     */
    WebSerialConnector.prototype.sendAsync = function (data, isResetReq, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var encodedData, _a, value, done, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.isSendAsyncRun) {
                            return [2 /*return*/];
                        }
                        else {
                            this.isSendAsyncRun = true;
                        }
                        if (!data || this.hwLite.getStatus() !== 'connected') {
                            return [2 /*return*/];
                        }
                        encodedData = typeof data === 'string' ? data : Buffer.from(data, 'utf8');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, 5, 6]);
                        if (this.hwLite.getStatus() !== 'connected') {
                            Entry.toast.alert(Lang.Msgs.hw_connection_failed_title, Lang.Msgs.hw_connection_failed_desc, false);
                            throw new Error('HARDWARE LITE NOT CONNECTED');
                        }
                        return [4 /*yield*/, this.writer.write(encodedData)];
                    case 2:
                        _b.sent();
                        if (isResetReq) {
                            this.isSendAsyncRun = false;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.reader.read()];
                    case 3:
                        _a = _b.sent(), value = _a.value, done = _a.done;
                        if (callback) {
                            return [2 /*return*/, callback(value)];
                        }
                        this.hwLite._updatePortData();
                        return [2 /*return*/, value];
                    case 4:
                        err_2 = _b.sent();
                        console.error(err_2);
                        this.hwLite.getConnectFailedMenu();
                        return [3 /*break*/, 6];
                    case 5:
                        this.isSendAsyncRun = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.constantServing = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var reqLocal, _e, value, done, error_1;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 2, , 3]);
                        if (this.hwLite.getStatus() === 'disconnected') {
                            return [2 /*return*/];
                        }
                        if (((_b = (_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.portData) === null || _b === void 0 ? void 0 : _b.constantServing) !== 'ReadOnly') {
                            reqLocal = (_c = this.hwModule) === null || _c === void 0 ? void 0 : _c.requestLocalData();
                            if (reqLocal && this.hwLite.getStatus() === 'connected') {
                                if (Entry.engine.isState('run')) {
                                    this.writer.write(Buffer.from(reqLocal));
                                }
                            }
                        }
                        return [4 /*yield*/, this.reader.read()];
                    case 1:
                        _e = _f.sent(), value = _e.value, done = _e.done;
                        if (done) {
                            this.hwLite.getConnectFailedMenu();
                            return [2 /*return*/];
                        }
                        (_d = this.hwModule) === null || _d === void 0 ? void 0 : _d.handleLocalData(value);
                        this.hwLite._updatePortData();
                        setTimeout(function () {
                            _this.constantServing();
                        }, this.hwModule.duration || 0);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _f.sent();
                        console.error(error_1);
                        this.hwLite.getConnectFailedMenu();
                        return [2 /*return*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.readPortData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, value, done, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (!(this.hwLite.getStatus() === 'connected' && Entry.engine.isState('run'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.reader.read()];
                    case 1:
                        _a = _b.sent(), value = _a.value, done = _a.done;
                        if (!value) {
                            this.reader.cancel();
                            throw new Error("reader's value is undefined. check device");
                        }
                        return [2 /*return*/, value];
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        console.error(error_2);
                        this.hwLite.getConnectFailedMenu();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.writePortData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data && this.hwLite.getStatus() === 'connected')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.writer.write(Buffer.from(data))];
                    case 1:
                        result = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.removeSerialPort = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, 6, 8]);
                        // INFO: 디바이스가 제거되었을 때, reader만 단독 예외처리
                        return [4 /*yield*/, ((_a = this.reader) === null || _a === void 0 ? void 0 : _a.cancel().catch(function (error) {
                                console.error(error);
                            }))];
                    case 1:
                        // INFO: 디바이스가 제거되었을 때, reader만 단독 예외처리
                        _d.sent();
                        return [4 /*yield*/, ((_b = this.writer) === null || _b === void 0 ? void 0 : _b.close())];
                    case 2:
                        _d.sent();
                        if (!(this.connectionType === 'ascii' && this.writableStream)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.writableStream];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        error_3 = _d.sent();
                        console.error(error_3);
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, ((_c = this.port) === null || _c === void 0 ? void 0 : _c.close())];
                    case 7:
                        _d.sent();
                        this.port = null;
                        this.reader = null;
                        this.writer = null;
                        this.writableStream = null;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    // engine 동작중 에러 발생시 호출, 디바이스에 read, write가 모두 안되는 것이 전제
    WebSerialConnector.prototype.handleConnectErrorInEngineRun = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // INFO: Engin.toggleStop에서 setZero가 실행되지 않도록 상태변경
                        if (this.hwLite.getStatus() === 'willDisconnect') {
                            return [2 /*return*/];
                        }
                        this.hwLite.setStatus('willDisconnect');
                        if (!Entry.engine.isState('run')) return [3 /*break*/, 2];
                        return [4 /*yield*/, Entry.engine.toggleStop()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.removeSerialPort()];
                    case 3:
                        _a.sent();
                        this.hwLite.getConnectFailedMenu();
                        Entry.toast.alert(Lang.Msgs.hw_connection_failed_title, Lang.Msgs.hw_connection_failed_desc, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    WebSerialConnector.prototype.update = function () {
        var _a, _b, _c;
        if (this.hwLite.getStatus() !== 'connected') {
            console.error('Cannot update hwLite queue. Check connection status.');
            return;
        }
        if ((_b = (_a = this.hwModule) === null || _a === void 0 ? void 0 : _a.portData) === null || _b === void 0 ? void 0 : _b.constantServing) {
            var reqLocal = (_c = this.hwModule) === null || _c === void 0 ? void 0 : _c.requestLocalData();
            if (reqLocal) {
                this.writer.write(Buffer.from(reqLocal));
            }
        }
    };
    WebSerialConnector.prototype.sendAsciiAsBuffer = function (asciiStr) {
        this.writer.write(Buffer.from(asciiStr, 'utf8'));
    };
    WebSerialConnector.prototype.checkConditionBeforeStop = function () {
        this.isSendAsyncRun = false;
    };
    return WebSerialConnector;
}(WebApiConnector));
export default WebSerialConnector;
