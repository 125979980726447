import _take from 'lodash/take';
import _takeRight from 'lodash/takeRight';
var getFirstBoxWidth = function (blockView) {
    var contents = _take(blockView._contents, 3);
    return contents.reduce(function (acc, content) { return acc + content.box.width; }, 20);
};
var getSecondBoxWidth = function (blockView) {
    var contents = _takeRight(blockView._contents, 3);
    return contents.reduce(function (acc, content) { return acc + content.box.width; }, 20);
};
Entry.skeleton.basic_create_value = {
    executable: true,
    path: function (blockView) {
        var height = blockView.contentHeight % 1000000;
        height = Math.max(30, height + 2);
        var statements = blockView._statements;
        var statementHeight = statements[0] ? statements[0].height : 20;
        var halfHeight = height / 2;
        statementHeight = Math.max(statementHeight, 20);
        return "M 0 0                \n                V 1\n                h ".concat(getFirstBoxWidth(blockView), "\n                a 14 14 0 0 1 0 28\n                H 26\n                l -6 6\n                l -6 -6\n                v ").concat(statementHeight, "\n                l 6 6\n                l 6 -6\n                h ").concat(getSecondBoxWidth(blockView) - 15, "\n                a ").concat(halfHeight, " ").concat(halfHeight, " 0 0 1 0 ").concat(height, "\n                H 0\n                z");
    },
    magnets: function (blockView) {
        return {};
    },
    box: function (blockView) {
        var width = blockView ? blockView.contentWidth : 150;
        var height = blockView ? blockView.height : 28;
        return {
            offsetX: -8,
            offsetY: 0,
            width: width + 30,
            height: Math.max(30, height),
            marginBottom: 0,
        };
    },
    statementPos: function (blockView) {
        var height1 = Math.max(30, (blockView.contentHeight % 1000000) + 2) + 1;
        return [{ x: 14, y: height1 - 3 }];
    },
    contentPos: function (blockView) {
        var height = Math.max(blockView.contentHeight % 1000000, 28);
        return { x: 14, y: height / 2 + 1 };
    },
};
