'use strict';

(function (c) {
  var COMMAND_TYPES = Entry.STATIC.COMMAND_TYPES;
  c[COMMAND_TYPES.createComment] = {
    "do": function _do(data, board, block) {
      var comment = new Entry.Comment(data, board, block);
      if (block) {
        block.connectComment(comment);
      } else {
        board.code.createThread([comment], 0);
      }
      board.set({
        isVisibleComment: true
      });
    },
    state: function state(data) {
      return [data];
    },
    log: function log() {
      return [];
    },
    undo: 'removeComment'
  };
  c[COMMAND_TYPES.removeComment] = {
    "do": function _do(target) {
      var comment = this.editor.board.findBlock(target);
      comment.destroy();
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      return [comment.toJSON(), comment.board, comment.block];
    },
    log: function log() {
      return [];
    },
    undo: 'createComment'
  };
  c[COMMAND_TYPES.showAllComment] = {
    "do": function _do(board) {
      board.set({
        isVisibleComment: true
      });
    },
    state: function state(board) {
      return [board];
    },
    log: function log() {
      return [];
    },
    undo: 'hideAllComment'
  };
  c[COMMAND_TYPES.hideAllComment] = {
    "do": function _do(board) {
      board.set({
        isVisibleComment: false
      });
    },
    state: function state(board) {
      return [board];
    },
    log: function log() {
      return [];
    },
    undo: 'showAllComment'
  };
  c[COMMAND_TYPES.moveComment] = {
    "do": function _do(target, x, y) {
      var comment = this.editor.board.findBlock(target);
      if (x) {
        comment.moveTo(x, y);
      } else {
        comment.updatePos();
      }
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      return [comment, comment.x, comment.y];
    },
    log: function log() {
      return [];
    },
    undo: 'moveComment'
  };
  c[COMMAND_TYPES.toggleComment] = {
    "do": function _do(comment) {
      comment.set({
        isOpened: !comment.isOpened
      });
    },
    state: function state(comment) {
      return [comment];
    },
    log: function log() {
      return [];
    },
    undo: 'toggleComment'
  };
  c[COMMAND_TYPES.cloneComment] = {
    "do": function _do(data, board) {
      var comment = new Entry.Comment(data, board);
      board.code.createThread([comment], 0);
      board.set({
        isVisibleComment: true
      });
    },
    state: function state(comment) {
      return [comment];
    },
    log: function log() {
      return [];
    },
    undo: 'uncloneComment'
  };
  c[COMMAND_TYPES.uncloneComment] = {
    "do": function _do(target) {
      var comment = this.editor.board.findBlock(target);
      comment.destroy();
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      return [target, comment.board];
    },
    log: function log() {
      return [];
    },
    undo: 'cloneComment'
  };
  c[COMMAND_TYPES.separateComment] = {
    "do": function _do(target) {
      var comment = this.editor.board.findBlock(target);
      comment.separateFromBlock();
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      return [comment.toJSON(), comment.block];
    },
    log: function log() {
      return [];
    },
    undo: 'connectComment'
  };
  c[COMMAND_TYPES.connectComment] = {
    "do": function _do(target, block) {
      var comment = this.editor.board.findBlock(target);
      comment.connectToBlock(block);
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      return [comment.toJSON()];
    },
    log: function log() {
      return [];
    },
    undo: 'separateComment'
  };
  c[COMMAND_TYPES.writeComment] = {
    "do": function _do(target, value) {
      var comment = this.editor.board.findBlock(target);
      comment.writeComment(value);
    },
    state: function state(target) {
      var comment = this.editor.board.findBlock(target);
      var json = comment.toJSON();
      return [json, json.value];
    },
    log: function log() {
      return [];
    },
    undo: 'writeComment'
  };
})(Entry.Command);